import ProjectLayout from '@/Layouts/ProjectLayout.vue';

const routes = [
  {
    path: '/:handle/:key/defects',
    component: ProjectLayout,
    children: [
      {
        path: '',
        name: 'Defects',
        component: () => import('@/views/Projects/Defect/Index'),
      },
    ],
  },
];

export default routes.map((route) => {
  const meta = {
    requiresAuth: true,
  };
  return { ...route, meta };
});
