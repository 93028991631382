<template>
  <div class="d-flex justify-center align-center flex-column">
    <img 
      :src="imageSrc" 
      alt="Empty state image" 
      class="placeholder-img mb-6" 
      :style="'max-width: ' + imageMaxWidth"
    >
    <div>
      <div class="d-flex flex-column align-center">
        <p class="ma-0 font-weight-bold fs-24px">
          {{ title }}
        </p>
        <slot name="description" />
      </div>
      <div 
        v-if="isButtonTextNotEmpty"
        class="mt-6 align-center justify-center"
      >
        <div>
          <v-btn
            v-if="writeEntity"
            dark 
            depressed 
            height="40px"
            :color="buttonColor"
            class="white--text rounded-lg btn-theme"
            :class="{ 'disabled-action': isProjectArchived }"
            :to="!isProjectArchived ? buttonRoute : null"
            @click="onButtonClick"
          >
            {{ buttonText }}
            <v-icon
              class="ml-1"
              size="16"
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    props: {
      isProjectArchived: {
        type: Boolean,
        required: false
      },
      imageSrc: {
        type: String,
      },
      title: {
        type: String,
      },
      buttonText: {
        type: String,
      },
      buttonColor: {
        type: String,
        default: 'blue',
      },
      buttonRoute: {
        type: Object,
      },
      imageMaxWidth: {
        type: String,
        default: '480px',
      },
      hideButton: {
        type: Boolean,
        default: false,
      },
      writeEntity:{
        type: Boolean,
        default: true
      }
    },
    computed: {
      isButtonTextNotEmpty() {
          return !!this.buttonText;
      },
    },
    methods: {
      onButtonClick() {
        if (!this.isProjectArchived) {
        this.$emit('button-click');
        }
      },
    }
};
</script>

<style scoped>
.placeholder-img {
  width: 100%;
}
</style>
