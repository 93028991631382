<template>
  <div>
    <CustomizeHeader />
    <v-row>
      <v-col
        cols="6"
        class="pr-1"
      >
        <div class="customize_charts __single_charts mt-2">
          <div class="customize_charts_header">
            <h4>{{ $t('dashboard.signleValueChart') }}</h4>
          </div>     
          <div class="customize_charts_body mt-2">
            <div
              v-for="(item, index) in entitiesType"
              :key="index"
              class="customize_chart_single_chart"
            >
              <CounterChart 
                class="mb-4"
                :data="item"
                value="XXXX"
              />
              <v-btn 
                class="text-capitalize __main-btn"
                :class="{'danger': chartData.includes(item.entityType)}"
                block
                large
                depressed
                @click="chartData.includes(item.entityType) ? $emit('removeCounterChart', item.entityType) : addChart('counterChart', item.entityType, 3, 4)"
              >
                {{ chartData.includes(item.entityType) ? $t('remove') : $t('add') }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
      <v-col
        cols="6"
        class="pl-1"
      >
        <div class="customize_charts __donut_charts mt-2">
          <div class="customize_charts_header">
            <h4>{{ $t('dashboard.donutChart') }}</h4>
          </div>     
          <div class="customize_charts_body mt-2">
            <div class="chart-info">
              <p>Split</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="#667085"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-help-circle-outline 
                  </v-icon>
                </template>
                <span>Tooltip</span>
              </v-tooltip>
            </div>
            <div>
              <v-select
                v-model="donutSelectedEntity"
                type="text"
                dense
                single-line
                height="38px"
                :items="entitiesType"
                item-text="name"
                item-value="entityType"
                clear-icon="body-2"
                append-icon="mdi-chevron-down"
                class="rounded-lg field-theme custom-prepend"
                background-color="#F9F9FB"
                :placeholder="$t('chooseStatus')"
                :menu-props="{ offsetY: true }"
              />
            </div>  
            <DonutChart
              :data="defaultDonut"
            />
            <div class="chart-action text-right">
              <v-btn 
                class="text-capitalize __main-btn"
                large
                depressed
                @click="donutSelectedEntity ? addChart('donutChart', donutSelectedEntity, 4, 10) : undefined"
              >
                {{ $t('add') }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="pl-1 mt-5"
      >
        <div class="customize_charts __donut_charts ma-2">
          <div class="customize_charts_header">
            <h4>{{ $t('dashboard.lineChart') }}</h4>
          </div>     
          <div class="customize_charts_body mt-2">
            <div class="chart-info">
              <p>Split</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="#667085"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-help-circle-outline 
                  </v-icon>
                </template>
                <span>Tooltip</span>
              </v-tooltip>
            </div>
            <div>
              <v-select
                v-model="lineSelectedEntity"
                type="text"
                dense
                single-line
                height="38px"
                :items="entitiesType"
                item-text="name"
                item-value="entityType"
                clear-icon="body-2"
                append-icon="mdi-chevron-down"
                class="rounded-lg field-theme custom-prepend"
                background-color="#F9F9FB"
                :placeholder="$t('chooseStatus')"
                :menu-props="{ offsetY: true }"
              />
            </div>  
            <LineChart
              :data="defaultLine"
            />
            <div class="chart-action text-right">
              <v-btn 
                class="text-capitalize __main-btn"
                large
                depressed
                @click="lineSelectedEntity ? addChart('lineChart', lineSelectedEntity, 12, 10) : undefined"
              >
                {{ $t('add') }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import _ from 'lodash';
import CustomizeHeader from './CustomizeChart/CustomizeHeader.vue'
import CounterChart from '@/components/Dashboard/Charts/CounterChart'
import LineChart from '@/components/Dashboard/Charts/LineChart'
import DonutChart from '@/components/Dashboard/Charts/DonutChart'
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('user');

export default {
  components: { 
    CustomizeHeader,
    CounterChart,
    DonutChart,
    LineChart,
   },
  props: {
    chartData: Array
  },
  data() {
    return {
      donutSelectedEntity: null,
      lineSelectedEntity: null,
      defaultDonut: {
        name: 'XXX',        
        "status": [
            {
                "color": "#42A5F5",
                "name": "Status#1",
                "value": 42,
                "selected": true
            },
            {
                "color": "#66BB6A",
                "name": "Status#2",
                "value": 44,
                "selected": true
            },
            {
                "color": "#FFA726",
                "name": "Status#3",
                "value": 32,
                "selected": true
            },
            {
                "color": "#EF5350",
                "name": "Status#4",
                "value": 312,
                "selected": true
            },
        ],
        split: 'status'
      },
      defaultLine: {
        "chartData": {
            "XX1": 5,
            "XX2": 8,
            "XX3": 1,
            "XX4": 6,
            "XX5": 12,
            "XX6": 4,
            "XX7": 8
        },
        "name": "XXXX",
      },
      entitiesType:[
        {
          entityType: 'testCase',
          value: 'XXXX',
          name: 'Test Cases',
          disabled: false,
        },
        {
          entityType: 'testRun',
          value: 'XXXX',
          name: 'Test Runs',
          disabled: false,
        },
        {
          entityType: 'defect',
          value: 'XXXX',
          name: 'Defects',
          disabled: true,
        },
        {
          entityType: 'milestone',
          value: 'XXXX',
          name: 'Milestones',
          disabled: false
        },
        {
          entityType: 'testPlan',
          value: 'XXXX',
          name: 'Test plans',
          disabled: false,
        },
        {
          entityType: 'testProgram',
          value: 'XXXX',
          name: 'Test Programs',
          disabled: true
        }
      ]
    };
  },
  methods: {
    addChart(chartType, entityType, w, h){
      this.$emit('addChart', {chartType, entityType, w, h})
    }
  },
};
</script>
<style lang="scss">
.customize_charts{
  height: 100%;
}
.customize_charts_header{
  padding: 14px 24px;
  background-color: #FFF;
  border-radius: 8px;
}
.customize_charts_header h4{
  font-weight: 600;
  font-size: 20px;
}
.customize_charts.__single_charts .customize_charts_body{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  height: 100%;
}
.customize_charts.__single_charts .customize_charts_body .customize_chart_single_chart{
  background-color: #FFF;
  border-radius: 8px;
  padding: 24px;
  min-width: calc(50% - 4px)
}
.customize_charts.__donut_charts .customize_charts_body{
  height: 100%;
  background-color: #FFF;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.customize_charts.__donut_charts .customize_charts_body .chart-info{
  display: flex;
  gap: 8px; 
}
.customize_charts.__donut_charts .customize_charts_body .chart-info p{
  margin-bottom: 0px;
  color: #0C111D;
}
.customize_charts.__donut_charts .customize_charts_body .chart-action .v-btn{
  min-width: 130px !important;
}
</style>
