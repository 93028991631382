import DefaultLayout from '@/Layouts/DefaultLayout.vue';

const routes = [
  {
    path: '/:handle/workspace',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'Workspace',
        component: () => import('@/views/Workspace/Index.vue'),
        props: true,
      },
    ],
  },
];

export default routes.map((route) => {
  const meta = {
    ...route.meta,
    requiresAuth: true,
    requiredAuthz: ['read_activity'],
    authzScope: 'org',
  };
  return { ...route, meta };
});
