<template>
  <div>
    <v-sheet
      color="#F2F4F7"
      class="d-flex align-center justify-center pointer"
      height="40px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-3 py-2 d-flex flex-row">{{ $t('filters') }} <v-icon
        size="16px"
        class="ml-2"
      >mdi-filter-variant</v-icon></span>
    </v-sheet>

    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>

          <v-expansion-panels
            v-model="priorityPanel"
            flat
            class="mb-5"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('priority') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-for="(priority, index) in priorities"
                  :key="index"
                  v-model="selectedPriorities"
                  :value="priority.name"
                  class="field-theme"
                  :ripple="false"
                  off-icon="icon-checkbox-off"
                  on-icon="icon-checkbox-on"
                  :hide-details="true"
                >
                  <template v-slot:label>
                    <span class="fs-14px text-theme-label">{{ priority.name }}</span>
                  </template>
                </v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div>
            <div class="text-start">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t("numberOfTestCases") }}
              </v-label>
            </div>
            <v-range-slider
              v-model="testcases"
              class="slider-theme"
              color="blue"
              track-color="#F2F4F7"
              thumb-color="#FFFFFF"
              hide-details
            />
            <div class="d-flex align-center">
              <v-text-field
                v-model="testcases[0]"
                background-color="#F9F9FB"
                class="field-theme"
                height="38"
                hide-details
              />
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-text-field
                v-model="testcases[1]"
                background-color="#F9F9FB"
                class="field-theme"
                height="38"
                hide-details
              />
            </div>
          </div>

          <div class="text-start pt-6">
            <div class="text-start mb-2">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('creationDate') }}
              </v-label>
            </div>
            <div class="d-flex align-center">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    single-line
                    class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                    :value="startDate"
                    background-color="#F9F9FB"
                    clearable
                    readonly
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <calendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @change="menu1 = false"
                />
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#F9F9FB"
                    class="text-field mt-0 field-theme pa-0 rounded-lg custom-prepend"
                    :value="endDate"
                    readonly
                    height="40"
                    hide-details
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <calendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @change="menu2 = false"
                />
              </v-menu>
            </div>
            <v-expansion-panels
              v-model="tagsPanel"
              flat
              class="mb-5"
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">
                  <div class="text-start">
                    <v-label class="text-theme-label font-weight-medium">
                      {{ $t('testruns.create_testrun.tag') }}
                    </v-label>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    v-for="(tag, index) in tags"
                    :key="index"
                  >
                    <v-checkbox
                      v-model="selectedTags"
                      :value="tag"
                      class="field-theme"
                      :ripple="false"
                      off-icon="icon-checkbox-off"
                      on-icon="icon-checkbox-on"
                      :hide-details="true"
                    >
                      <template v-slot:label>
                        <span class="fs-14px text-theme-label">{{ tag.name }}</span>
                      </template>
                    </v-checkbox>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div>
              <div class="mb-1 text-start mt-4">
                {{ $t('Progress') }}
              </div>
              <v-range-slider
                v-model="users"
                class="slider-theme"
                color="blue"
                track-color="#F2F4F7"
                thumb-color="#FFFFFF"
                hide-details
              />
              <div class="d-flex align-center">
                <v-text-field
                  v-model="users[0]"
                  background-color="#F9F9FB"
                  class="field-theme"
                  height="38"
                  dense
                />
                <div class="mx-4 font-weight-bold text-h6">
                  -
                </div>
                <v-text-field
                  v-model="users[1]"
                  background-color="#F9F9FB"
                  class="field-theme"
                  height="38"
                  dense
                />
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          full-width
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="clearAll"
        >
          {{ $t('clearAll') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="apply"
        >
          {{ $t('apply') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>
  
<script>
import calendarBlueIcon from '@/assets/svg/calendar-blue.svg';
import colorPreferencesMixin from '@/mixins/colorPreferences';
export default {
    name: "MilestoneTestRunFilter",
    components: {
        calendarBlueIcon,
    },
    mixins: [colorPreferencesMixin],
    props: {
        tags: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            priorityPanel: 0,
            tagsPanel: 0,
            showDialog: false,
            selectedPriorities: [],
            testcases: [0, 1000],
            users: [0, 100],
            selectedTags:[],
            startDate: new Date().toISOString().substr(0, 10),
            endDate: new Date().toISOString().substr(0, 10),
            menu1: false,
            menu2: false,
            priorities: []
        };
    },
    created() {
      this.priorities = this.getPriorities("testRun");
    },
    methods: {
        apply() {
            this.$emit("applyFilters", {
                selectedPriorities: this.selectedPriorities,
                testCases: this.testcases,
                users: this.users,
                dateRange: {
                    start: this.startDate,
                    end: this.endDate,
                },
                selectedTags:this.selectedTags
            });
            this.showDialog = false;
        },
        clearAll() {
            this.selectedPriorities = [];
            this.testrun = [0, 100];
            this.testcases = [0, 100];
            this.users = [0, 100];
            this.startDate = new Date().toISOString().substr(0, 10);
            this.endDate = new Date().toISOString().substr(0, 10);
            this.dueStartDate = new Date().toISOString().substr(0, 10);
            this.dueEndDate = new Date().toISOString().substr(0, 10);
        },
    },
};
</script>
  
<style>
.v-dialog--fullscreen {
    max-height: 100vh !important;
    width: 485px !important;
    right: 0 !important;
    left: auto !important;
}

.v-expansion-panel-content__wrap {
    padding: 0 !important;
}
</style>
  