<template>
  <v-menu
    left
    :nudge-bottom="4"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        class="rounded-lg text-none"
        height="40"
        min-width="40"
        elevation="0"
        v-on="on"
      >
        <span class="mr-1">{{ selectedEntity ?? $t('filters') }}</span>
        <v-icon size="16px">
          mdi-filter-variant
        </v-icon>
      </v-btn>
    </template>
    <v-list class="d-flex flex-column justify-space-between rounded-xl">
      <v-list-item
        v-for="(item, index) in entitiesType"
        :key="index"
        class="custom-menu-item"
        :disabled="item.disabled"
        @click="changeEntity(item)"
      >
        <div class="custom-color-0c111d font-inter custom-text-14">
          {{ item.name }}
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
  <script>
  export default{
    props:{
      chartId: Number,
      entity: String,
    },
    data(){
      return{
        selectedEntity: null,
        entitiesType: [
        {
          value: 'testCase',
          name: 'Test Cases',
          disabled: false,
        },
        {
          value: 'testRun',
          name: 'Test Runs',
          disabled: false,
        },
        {
          value: 'defect',
          name: 'Defects',
          disabled: true,
        },
        {
          value: 'milestone',
          name: 'Milestones',
          disabled: false,
        },
        {
          value: 'testPlan',
          name: 'Test plans',
          disabled: false,
        },
        {
          value: 'testProgram',
          name: 'Test Programs',
          disabled: true,
        }
      ]
      }
    },
    created(){
      this.selectedEntity = this.entitiesType.find(element => element.value == this.entity).name;
    },
    methods:{
      changeEntity(entity){
        this.selectedEntity = entity.name;
        this.$emit('updateEntity', entity.value, this.chartId)
      },
    }
  }
  </script>
  <style scoped>
  .custom-menu-item{
    min-height: 35px;
  }
  </style>