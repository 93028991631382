import { Axios } from 'axios';

/**
 *
 * @param {Axios} api
 * @returns
*/

export default function makeAssistService(api) {
  return {
    getAssistResponse: async function (handle, data, source) {
      return api.post(`/${handle}/assist`, data, {
        ...(source ? {cancelToken: source.token} : undefined),
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL
      });
    }
  }
}
