import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import makeUser from './modules/user';
import makeProject from './modules/project';
import makeOrg from './modules/org';
import makeAttachment from './modules/attachment';
import makeHeader from './modules/tableHeaders';
import makeError404 from './modules/error404';
Vue.use(Vuex);

export default function makeStore(api) {
  return new Vuex.Store({
    state: {
      isTextAssistOpen: false,
      textAssistField: {},
      textAssistCallback: () => {},
      isProjectMenuCollapsed: false,
      isMenuCollapsed: false,
      isTestMenuCollapsed: false,
      isLoading: false,
      loadingText: '',
    },
    mutations: {
      toggleMenu(state) {
        state.isMenuCollapsed = !state.isMenuCollapsed;
      },
      toggleProjectMenu(state) {
        state.isProjectMenuCollapsed = !state.isProjectMenuCollapsed;
      },
      toggleTestMenu(state) {
        state.isTestMenuCollapsed = !state.isTestMenuCollapsed;
      },
      toggleTextAssist(state) {
        state.isTextAssistOpen = !state.isTextAssistOpen;
      },
      setTextAssistData(state, { field, callback }) {
        state.textAssistField = field;
        state.textAssistCallback = callback;
      },
    },
    getters: {
      isMenuCollapsed: (state) => state.isMenuCollapsed,
      isProjectMenuCollapsed: (state) => state.isProjectMenuCollapsed,
      isTestMenuCollapsed: (state) => state.isTestMenuCollapsed,
      isTextAssistOpen: (state) => state.isTextAssistOpen,
      textAssistField: (state) => state.textAssistField,
      textAssistCallback: (state) => state.textAssistCallback,
      isLoading: (state) => false, //state.isLoading,
      loadingText: (state) => state.loadingText,
    },
    modules: {
      user: makeUser(api),
      headers: makeHeader(),
      attachment: makeAttachment(api),
      project: makeProject(api),
      org: makeOrg(api),
      error404: makeError404(),
    },
    plugins: [
      createPersistedState({
        paths: [
          'user',
          'headers',
          'org',
        ],
      }),
    ],
  });
}
