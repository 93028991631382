<template>
  <MilestoneTestView 
    v-model="testActivities"
    hide-action-btn
    :write-entity="_writeEntity"
  />
</template>
<script>
import MilestoneTestView from '@/views/Tests/Milestones/MilestoneTestActivitiesView'
import Loader from '@/components/base/Loader'
import makeMilestonesService from '@/services/api/milestone'
import handleLoading from '@/mixins/loader.js'
import { showErrorToast } from '@/utils/toast';

let makeMilestoneService;

export default{
  components:{
    MilestoneTestView,
  },
  mixins:[handleLoading],
  data(){
    return{
      testActivities:{
        name: "",
        testRuns: [],
        testPlans: [],
        executionsProgress: 0,
        percentage: 0,
        milestoneUid: "",
        milestoneExternalId: "",
        milestoneSource: "",
      },
      selectedMilestone: {},
    }
  },
  computed:{
    _writeEntity(){
      return this.authorityTo('write_entity')
    }
  },
  created(){
    makeMilestoneService = makeMilestonesService(this.$api);
  },
  async mounted(){
    await this.init([this.findMilestone()]);
  },
  methods:{
    async findMilestone() {
      try {
        const handle = this.$route.params.handle;
        const response = await makeMilestoneService.findMilestone(handle, this.$route.params.key, this.$route.params.id);
        this.selectedMilestone = response.data;
        this.testActivities.testPlans = this.selectedMilestone.testPlans;
        this.testActivities.testRuns = this.selectedMilestone.testRuns;
        this.testActivities.name = this.selectedMilestone.name;
        this.testActivities.executionsPercentage = this.selectedMilestone.percentage;
        this.testActivities.executionsProgress = this.selectedMilestone.executionsProgress;
        this.testActivities.uid = this.selectedMilestone.uid;
        this.testActivities.externalId = this.selectedMilestone.externalId;
        this.testActivities.source = this.selectedMilestone.source;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'milestone' });
        console.error('Failed to fetch milestone:', err);
      }
    },
  },
}
</script>
