<template>
  <div class="pl-3 pt-3">
    <PlanHeader
      :is-duplicate="PlanViewType === 'Duplicate'"
      :title="$t('plans.title')"
      :action-text="$t('plans.createTestPlan')"
      :active_label="$t('plans.active')"
      :archived_label="$t('plans.archived')"
      :btn_show="_writeEntity"
      :filter="filter"
      :active-item-count="activeItemCount"
      :archived-item-count="archivedItemCount"
      @update-filter="updateFilter"
    />
    <Loader v-if="loaderState" />
    <template v-else-if="activeItemCount == 0 && !isFilter && filter != 'archived' && !tableLoadingState">
      <div class="mt-3 mb-0 white rounded-lg mx-0 plans-placeholder-height d-flex justify-center align-center">
        <ActiveEmptyState
          :write-entity="_writeEntity"
          :image-src="require('@/assets/png/test-plans-placeholder.png')"
          :title="$t('plans.placeholder.title')"
          :description="$t('plans.placeholder.description')"
          :button-text="$t('plans.createTestPlan')"
          :button-route="{ name: 'TestPlanCreate' }"
          button-color="primary"
          :is-project-archived="isProjectArchived"
        >
          <template #description>
            <p class="mb-0 mt-3 plan-description">
              {{ $t('plans.placeholder.description') }}
            </p>
          </template>
        </ActiveEmptyState>
      </div>
    </template>
    <template v-else-if="archivedItemCount == 0 && !isFilter && filter != 'ongoing' && !tableLoadingState">
      <div class="mt-3 mb-0 white rounded-lg mx-0 plans-placeholder-height d-flex justify-center align-center">
        <ArchivedEmptyState
          :image-src="require('@/assets/png/test-plans-placeholder.png')"
          :title="$t('plans.archived_empty_state.title')"
        >
          <template #description>
            <p class="mb-0 mt-3">
              {{ $t('plans.archived_empty_state.description.part1') }}
            </p>
            <p class="mb-0">
              {{ $t('plans.archived_empty_state.description.part2') }}
            </p>
          </template>
        </ArchivedEmptyState>
      </div>
    </template>
    <template v-else>
      <div>
        <template>
          <v-card
            class="py-6 px-6 app-height-global"
            rounded="lg"
            elevation="0"
            width="100%"
          >
            <div class="d-flex flex-row justify-space-between align-center">
              <div class="d-flex flex-row justify-start align-center">
                <v-responsive
                  class="ma-0"
                  max-width="344"
                >
                  <v-text-field
                    v-model="searchFilter"
                    :loading="loading"
                    :placeholder="$t('searchByName')"
                    background-color="#F9F9FB"
                    class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
                    height="40"
                    dense
                    hide-details
                  >
                    <template v-slot:prepend-inner>
                      <SearchIcon />
                    </template>
                  </v-text-field>
                </v-responsive>
                <PlanFilter 
                  :configurations="configurations" 
                  :milestones="milestones"  
                  :tags="tags"
                  @applyFilters="applyFilters"
                />
              </div>
              
              <SettingsMenu 
                table-type="plan"
              />
            </div>
            <test-plans-table
              :filtered-headers="filteredHeaders"
              :filtered-items="filteredPlans"
              :item-key="itemKey"
              :row-class="rowClass"
              :clear-selection="clearSelection"
              :write-entity="_writeEntity"
              :delete-entity="_deleteEntity"
              @edit-item="editItem"
              @select-row="handleRowClick"
              @select-item="setselected"
              @archive-item="confirmArchiveTestPlan"
              @unarchive-item="confirmUnArchiveTestPlan"
              @delete-item="confirmDeletePlan"
              @add-to-milestone="handleAddToMilestione"
              @duplicate-plan="handleDuplicatePlan"
            />
          </v-card>
          <div 
            v-if="selecteditems.length > 0"
            class="action-btn-wrapper pa-3"
          >
            <div class="d-flex flex-row justify-end">
              <v-menu
                v-model="menuOpen"
                :close-on-content-click="false"
                offset-y
                top
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    height="40px"
                    depressed
                    color="primary"
                    class="font-inter text-capitalize rounded-lg fw-semibold"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                    :disabled="!hasSelectedItems"
                    v-on="on"
                  >
                    {{ $t('actions') }}
                    <v-icon size="20">
                      {{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list class="actions-list font-inter text-left">
                  <v-list-item
                    v-if="filter === 'ongoing' && _writeEntity && selecteditems.length == 1"
                    :class="{
                      'actions-item': true,
                      'disabled-action': isProjectArchived
                    }"
                    @click="handleDuplicatePlan(selecteditems[0])"
                  >
                    <v-list-item-title class="d-flex align-center">
                      <DuplicateIcon class="mr-1" />
                      {{ $t('duplicate') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="filter === 'ongoing' && _writeEntity"
                    :class="{
                      'actions-item': true,
                      'disabled-action': isProjectArchived
                    }"
                    @click="openAddToMilestoneDialog()"
                  >
                    <v-list-item-title class="d-flex align-center">
                      <AddToMilesonteIcon class="mr-1" />
                      {{ $t('plans.addToMilestone') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="filter === 'ongoing' && _writeEntity"
                    :class="{
                      'actions-item': true,
                      'disabled-action': isProjectArchived
                    }"
                    @click="handleConfirmDialog('multi_archive')"
                  >
                    <v-list-item-title class="d-flex align-center">
                      <ArchiveIcon class="mr-1" />
                      {{ $t('plans.archive') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-else-if="filter !== 'ongoing' && _writeEntity"
                    :class="{
                      'actions-item': true,
                      'disabled-action': isProjectArchived
                    }"
                    @click="handleConfirmDialog('multi_unarchive')"
                  >
                    <v-list-item-title class="d-flex align-center">
                      <UnarchiveIcon class="mr-1" />
                      {{ $t('plans.unarchive') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="_deleteEntity"
                    :class="{
                      'actions-item': true,
                      'disabled-action': isProjectArchived
                    }"
                    @click="handleConfirmDialog('multi_delete')"
                  >
                    <v-list-item-title class="error--text d-flex align-center">
                      <DeleteIcon class="mr-1" />
                      {{ $t('delete') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </template>
      </div>
      <plans-list-filter
        :open="isOpenFilter"
        @filters="applyFilters"
        @close="isOpenFilter = false"
      />
      <PlanAddToMilestone
        v-if="showAddToMilestoneDialog"
        :test-plan="selectedPlan"
        :selected-plans="selecteditems"
        :show-dialog="showAddToMilestoneDialog"
        @change-milestone-drawer-state="toggleMilestoneDrawer"
      />
    </template>
    <PlanDiscardDialog
      v-model="showConfirmDialog"
      :title="dialog.confirmDialogTitle"
      :content="dialog.confirmDialogContent"
      :content_part2="dialog.confirmDialogContentPartTwo"
      :run_name="dialog.confirmDialogName"
      :btn_label="dialog.confirmDialogBtnLabel"
      :color="dialog.confirmDialogBtnColor"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmBtnClick(dialog.confirmType)"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import DeleteIcon from '@/assets/svg/delete.svg';
import ArchiveIcon from '@/assets/svg/archived.svg';
import UnarchiveIcon from '@/assets/svg/unarchive-16x16.svg';
import AddToMilesonteIcon from '@/assets/svg/milestone.svg';
import Loader from '@/components/base/Loader.vue';
import PlansListFilter from '@/views/Tests/Plans/Components/List/PlansListFilter';
import DuplicateIcon from '@/assets/svg/duplicate.svg';

import makeMilestonesService from '@/services/api/milestone'
import makePlanService from '@/services/api/plan'
import makeConfigurationService from '@/services/api/configuration' 
import makeTagService from '@/services/api/tag';

import { showErrorToast, showSuccessToast } from '@/utils/toast';
import TestPlansTable from '@/components/TestPlans/TestPlansTable.vue';
import { runStateMap } from '@/constants/grid';
import PlanDiscardDialog from '@/components/TestPlans/PlanDiscardDialog.vue';
import PlanFilter from '@/components/TestPlans/PlanFilter.vue';
import PlanHeader from '@/components/TestPlans/PlanHeader.vue';
import PlanAddToMilestone from '@/components/TestPlans/PlanAddToMilestone.vue';
import SearchIcon from '@/assets/svg/search-icon.svg';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import { sleep } from '@/utils/util';
import projectStatus from '@/mixins/projectStatus';
import ActiveEmptyState from '@/components/base/ActiveEmptyState.vue';
import ArchivedEmptyState from '@/components/base/ArchivedEmptyState.vue';

let planService;

export default {
  components: {
    PlanHeader,
    PlanFilter,
    PlanAddToMilestone,
    PlanDiscardDialog,
    DuplicateIcon,
    TestPlansTable,
    PlansListFilter,
    DeleteIcon,
    ArchiveIcon,
    UnarchiveIcon,
    AddToMilesonteIcon,
    SettingsMenu,
    Loader,
    SearchIcon,
    ActiveEmptyState,
    ArchivedEmptyState,
  },
  mixins: [projectStatus],
  props: ['PlanViewType', 'NewRunForm'],
  data() {
    return {
      selectedPlan: {},
      appliedFilters: null,
      loading: false,
      loaderState: false,
      tab: 'ongoing',
      isOpenFilter: false,
      headers: [],
      isColumnFilter: false,
      menuOpen: false,
      showConfirmBulkDeleteDialog: false,
      showConfirmBulkUnarchiveDialog: false,
      showConfirmBulkArchiveDialog: false,
      searchFilter: '',
      itemKey: 'uid',
      filter: 'ongoing',
      rowClass: () => 'test-plan-item',
      clearSelection: false,
      isFilter: false,
      showConfirmDialog: false,
      showAddToMilestoneDialog: false,
      dialog: {
        confirmDialogTitle: '',
        confirmDialogContent: '',
        confirmDialogContentPartTwo: '',
        confirmDialogBtnLabel: '',
        confirmDialogBtnColor: 'primary',
        confirmDialogName: '',
        confirmType: '',
      },
      selecteditems: [],
      milestones: [],
      configurations: [],
      tags: [],
      tableLoadingState: false,
      items: [],
    };
  },

  computed: {
    _writeEntity(){
      return this.authorityTo('write_entity')
    },
    _deleteEntity(){
      return this.authorityTo('delete_entity')
    },
    ...mapState('user', ['currentAccount']),
    ...mapGetters({
      dynamicHeaders:'headers/dynamicHeaders'
    }),
    activeMilestones() {
      return this.milestones.filter((milestone) => milestone.archivedAt === null && milestone.deletedAt === null);
    },
    filteredPlans() {
      let filtered = this.testPlans;
      if (this.searchFilter) {
        filtered = filtered.filter((item) => this.matchesFilter(item));
      }
      if (this.isFilter && this.appliedFilters) {
        filtered = filtered.filter((item) => this.matchApplyFilter(item));
      }
      return filtered;
    },
    filteredHeaders() {
      const filtered = this.headers.filter((header) => header.checked);
      if (filtered.length < 9)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false
      return filtered
    },
    testPlans() {
      let filtered = this.items.filter((item) => ((item.archivedAt !== null) === (this.filter === 'ongoing' ? false : true)));
      return filtered.map((item) => {
        const createdFormat = new Date(item.createdAt);
        const createdAt = `${createdFormat.getFullYear()}-${String(createdFormat.getMonth() + 1).padStart(2, '0')}-${new String(createdFormat.getDate()).padStart(2, '0')}`
        return {
          ...item,
          progress: this.staticValues(item, runStateMap, 'New'),
          createdAt
        }
      });
    },
    activeItemCount() {
      return this.items.filter((item) => item?.archivedAt === null).length;
    },
    archivedItemCount() {
      return this.items.filter((item) => item?.archivedAt !== null).length;
    },
    seletedItemsCount() {
      return this.selecteditems.length;
    },
    hasSelectedItems() {
      return this.selecteditems.length > 0;
    },
  },
  
  watch: {
    filteredPlans(newValue) {
      if (newValue === 'SEARCH_BACKEND') {
        this.searchTestPlans();
      }
    },
    selectedProject() {
      let handle = this.currentAccount.handle
      this.getProjectTestPlans(handle);
    },
    searchFilter: {
      handler() {
        this.loading = true;
        this.$nextTick(() => {
          this.loading = false;
        });
      },
      immediate: true
    },
  },

  mounted() {
    this.init();
  },
 
  created() {
    if(!this.dynamicHeaders.plan) {
      this.initializeHeaders({ type: 'plan' });
    }
    this.headers = this.dynamicHeaders.plan;
    planService = makePlanService(this.$api);
  },
  
  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    toggleMilestoneDrawer(newState) {
        this.showAddToMilestoneDialog = newState
    },
    async removePlan(handle, projectKey, uid) {
      try {
        await planService.deleteTestPlan(handle, projectKey, uid);
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    async init() {
      this.loaderState = false;
      this.tableLoadingState = true;
      let loaderStateTimeout = null;

      loaderStateTimeout = setTimeout(() => {
        this.loaderState = true;
      }, 1000);
      try {
        let handle = this.$route.params.handle;
        await Promise.all([
          this.getProjectTestPlans(handle),
          this.getMilestones(),
          this.getConfigurations(),
          this.getTags()
        ]);
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'plans' });
      } finally {
        clearTimeout(loaderStateTimeout); 
        const hideLoader = async () => {

          await sleep(1000); 
          this.loaderState = false; 
          this.tableLoadingState = false;
        };
        hideLoader(); 
      }
    },
    updateFilter(newFilter) {
      this.filter = newFilter;
    },
    async getMilestones() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const milestoneService = makeMilestonesService(this.$api);
      try {
        const response = await milestoneService.getMilestones(handle, projectKey);
        this.milestones = response.data?.milestones;
        return response.data?.milestones;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'milestones' });
        return [];
      }
    },
    async getConfigurations() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const configurationService = makeConfigurationService(this.$api);
      try {
        const response = await configurationService.getConfigurations(handle, projectKey, 10, 0);
        this.configurations = response.data?.configurations;
        return response.data?.configurations;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'configurations' });
        return [];
      }
    },
    async getTags() {
      const handle = this.$route.params.handle;
      const tagService = makeTagService(this.$api);
      try {
          const response = await tagService.getTags(handle);
          this.tags = response.data
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'tags' });
        return [];
      }
    },
    handleDuplicatePlan(item) {
      this.$router.push({
        name: 'TestPlanDuplicate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          plan: item,
        },
      });    
    },
    handleTab(value) {
      this.tab = value;
    },
    onCreateTestPlan() {
      this.$router.push({ name: 'TestPlanCreate' });
    },
    handleClickArchive() {
      this.showConfirmBulkArchiveDialog = true;
    },
    async handleConfirmArchiveClick(status) {
      if(!this._writeEntity){
        this.unauthorizedToast;
        return ;
      }
      let plans = []
      this.selecteditems.map((item) => {
        let plan = {
          id: item.uid,
          archived: status
        };
        plans = [...plans, plan]
      });

      const successMessage = status
        ? this.$t('toast.archiveSuccess', { item: this.$t('plans.title') })
        : this.$t('toast.unarchiveSuccess', { item: this.$t('plans.title') });

      const errorMessage = status
        ? this.$t('toast.archiveError', { item: this.$t('plans.title') })
        : this.$t('toast.unarchiveError', { item: this.$t('plans.title') });

      try {
        await planService.updateTestPlans(this.currentAccount.handle, this.$route.params.key, { plans });
        await this.refreshData();
        showSuccessToast(this.$swal, successMessage);
        
      } catch (error) {
        showErrorToast(this.$swal, errorMessage);
      } finally {
        this.handleCloseClick();
      }

    },
    handleClickUnarchive() {
      this.showConfirmBulkUnarchiveDialog = true;
    },
    async handleConfirmDeleteMultiClick() {
      if(!this._deleteEntity){
        this.unauthorizedToast;
        return ;
      }
      let planIds = []
      this.selecteditems.map((item) => {
        planIds = [...planIds, item.uid]
      });

      let seletedItemsCount = this.seletedItemsCount;

      try {
        await planService.deleteTestPlans(this.currentAccount.handle, this.$route.params.key, { planIds: planIds });
        await this.refreshData();
        showSuccessToast(this.$swal, this.$t("plans.deleteTestPlans", { count: seletedItemsCount }));
      } catch (error) {
        showErrorToast(this.$swal, this.$t("plans.deleteTestPlans", { count: seletedItemsCount }));
      } finally {
        this.handleCloseClick();
      }

    },
    async handleConfirmDeleteClick() {
      if(!this._deleteEntity){
        this.unauthorizedToast;
        return ;
      }
      try {
        await this.removePlan(this.currentAccount.handle, this.$route.params.key, this.selectedPlan.uid);
        await this.refreshData();
        showSuccessToast(this.$swal, this.$t("plans.delete1TestPlan"));
      } catch (error) {
        showErrorToast(this.$swal, this.$t("plans.delete1TestPlan"));
      } finally {
        this.handleCloseClick();
      }
    },
    handleColumnReset() {
      this.resetHeaders('plan');
      this.headers.map((header) => { header.isSelected = true })
    },
    applyFilters(filters) {
      if (filters) {
        this.appliedFilters = filters;
        this.isFilter = true;
      } else {
        this.isFilter = false;
      }
      this.refreshData()
    },
    handleAddToMilestione(item) {
      this.selectedPlan = item
      this.showAddToMilestoneDialog = true;
    },
    confirmDeletePlan(item) {
      this.selectedPlan = item;
      this.handleConfirmDialog('delete')
    },
    async getProjectTestPlans(handle) {
      try {
        const key = this.$route.params.key;
        const response = await planService.getAllTestPlans(handle, key, 99999, 0);
        this.items = response.data?.plans;
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'plans' });
      }
    },
    async refreshData() {
      let handle = this.currentAccount.handle;
      this.getProjectTestPlans(handle);
    },
    async searchTestPlans() {
      if (this.searchFilter) {
        await this.$store.dispatch('plan/searchTestPlans', {
          handle: this.currentAccount.handle,
          projectKey: this.$route.params.key,
          text: this.searchFilter,
          perPage: this.perPage,
          currentPage: this.currentPage,
        });
      } else {
        await this.refreshData();
      }
    },
    editItem(item) {
      this.selectedPlan = item;
      this.$router.push({
        name: 'TestPlanDetail',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          planId: item.uid
        },
      });
    },
    handleRowClick(item) {
      this.$router.push({
        name: 'TestPlanRerun',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          planId: item.uid,
        },
      });
    },
    setselected(selecteditems) {
      this.clearSelection = false
      this.selecteditems = selecteditems
    },
    confirmArchiveTestPlan(item) {
      this.selectedPlan = item;
      this.handleConfirmDialog('archive')
    },
    confirmUnArchiveTestPlan(item) {
      this.selectedPlan = item;
      this.handleConfirmDialog('unarchive')
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter?.toLowerCase();
      return item?.name?.toLowerCase()?.includes(lowerCaseFilter)
    },
    staticValues(item, obj, defaultValue) {
      return obj[item.customFields?.state] || obj[defaultValue];
    },
    matchApplyFilter(item) {
      if (this.appliedFilters?.panel_configurations.length > 0 && !this.appliedFilters?.panel_configurations.includes(item.customFields.configurations))
        return false;
      if (this.appliedFilters?.panel_priority.length > 0 && !this.appliedFilters?.panel_priority.includes(item.priority))
        return false;
      if (this.appliedFilters?.panel_status.length > 0 && !this.appliedFilters.panel_status.includes(item.customFields.status))
        return false
      if (this.appliedFilters?.panel_milestone.length > 0 && !this.appliedFilters?.panel_milestone.find((milestone) => milestone.uid == item?.test_milestoneUid))
        return false
      if (this.appliedFilters?.panel_tag.length > 0 && !this.appliedFilters?.panel_tag.find((tag) => tag.name == item.customFields.tags))
        return false
      // if (this.appliedFilters?.testcases_range[0] > item.customFields.case_count || this.appliedFilters?.testcases_range[1] < item.customFields.case_count)
      //   return false
      // if (this.appliedFilters?.progressRange[0] > item.customFields.progress || this.appliedFilters?.progressRange[1] < item.customFields.progress)
      //   return false
      if (this.appliedFilters?.dateRange.start > item.createdAt || this.appliedFilters?.dateRange.end < item.createdAt)
        return false
      return true
    },
    handleConfirmBtnClick(type) {
      switch (type) {
        case 'multi_archive':
          this.handleConfirmArchiveClick(true);
          break;
        case 'multi_unarchive':
          this.handleConfirmArchiveClick(false);
          break;
        case 'multi_delete':
          this.handleConfirmDeleteMultiClick();
          break;
        case 'archive':
          this.handleConfirmOnArchiveClick(true);
          break;
        case 'unarchive':
          this.handleConfirmOnArchiveClick(false);
          break;
        case 'delete':
          this.handleConfirmDeleteClick();
          break;
      }
    },
    handleCloseClick() {
      this.showConfirmDialog = false;
      this.selectedPlan = [];
      this.selecteditems = [];
      this.clearSelection = true;
    },
    handleConfirmDialog(type) {
      if (!this.isProjectArchived) {
      this.showConfirmDialog = true;
      switch (type) {
        case 'multi_archive':
          this.dialog.confirmDialogTitle = this.$t('plans.archiveDialog.titleMulti');
          this.dialog.confirmDialogContent = this.$t('plans.archiveDialog.content');
          this.dialog.confirmDialogContentPartTwo = '';
          this.dialog.confirmDialogBtnLabel = this.$t('plans.archiveDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'primary';
          this.dialog.confirmDialogName = '';
          this.dialog.confirmType = 'multi_archive';
          break;
        case 'multi_unarchive':
          this.dialog.confirmDialogTitle = this.$t('plans.unArchiveDialog.titleMulti');
          this.dialog.confirmDialogContent = this.$t('plans.unArchiveDialog.content');
          this.dialog.confirmDialogContentPartTwo = '';
          this.dialog.confirmDialogBtnLabel = this.$t('plans.unArchiveDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'primary';
          this.dialog.confirmDialogName = '';
          this.dialog.confirmType = 'multi_unarchive';
          break;
        case 'multi_delete':
          this.dialog.confirmDialogTitle = this.$t('plans.deleteDialog.title');
          this.dialog.confirmDialogContent = this.$t('plans.deleteDialog.content');
          this.dialog.confirmDialogContentPartTwo = (this.filter === 'ongoing' ? this.$t('plans.deleteDialog.contentPart2') : '');
          this.dialog.confirmDialogBtnLabel = this.$t('plans.deleteDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'danger';
          this.dialog.confirmDialogName = '';
          this.dialog.confirmType = 'multi_delete';
          break;
        case 'archive':
          this.dialog.confirmDialogTitle = this.$t('plans.archiveDialog.title');
          this.dialog.confirmDialogContent = this.$t('plans.archiveDialog.content');
          this.dialog.confirmDialogContentPartTwo = '';
          this.dialog.confirmDialogBtnLabel = this.$t('plans.archiveDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'primary';
          this.dialog.confirmDialogName = this.selectedPlan.name;
          this.dialog.confirmType = 'archive';
          break;
        case 'unarchive':
          this.dialog.confirmDialogTitle = this.$t('plans.unArchiveDialog.title');
          this.dialog.confirmDialogContent = this.$t('plans.unArchiveDialog.content');
          this.dialog.confirmDialogContentPartTwo = '';
          this.dialog.confirmDialogBtnLabel = this.$t('plans.unArchiveDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'primary';
          this.dialog.confirmDialogName = this.selectedPlan.name;
          this.dialog.confirmType = 'unarchive';
          break;
        case 'delete':
          this.dialog.confirmDialogTitle = this.$t('plans.deleteDialog.title');
          this.dialog.confirmDialogContent = this.$t('plans.deleteDialog.content');
          this.dialog.confirmDialogContentPartTwo = (this.filter === 'ongoing' ? this.$t('plans.deleteDialog.contentPart2') : '');
          this.dialog.confirmDialogBtnLabel = this.$t('plans.deleteDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'danger';
          this.dialog.confirmDialogName = '';
          this.dialog.confirmType = 'delete';
          break;
      }
    }
    },
    async handleConfirmOnArchiveClick(status) {
      if(!this._writeEntity){
        this.unauthorizedToast;
        return ;
      }
      const payload = {
        archived: status,
      };
      const successMessage = status
        ? this.$t('toast.archiveSuccess', { item: this.$t('plans.title_single') })
        : this.$t('toast.unarchiveSuccess', { item: this.$t('plans.title_single') });

      const errorMessage = status
        ? this.$t('toast.archiveError', { item: this.$t('plans.title_single') })
        : this.$t('toast.unarchiveError', { item: this.$t('plans.title_single') });

      try {
        await planService.updateTestPlan(this.currentAccount.handle, this.$route.params.key, this.selectedPlan.uid, payload);
        showSuccessToast(this.$swal, successMessage);
        await this.refreshData();
      } catch (error) {
        showErrorToast(this.$swal, errorMessage);
      } finally {
        this.handleCloseClick();
      }

    },
    openAddToMilestoneDialog() {
      if (!this.isProjectArchived) {
      this.showAddToMilestoneDialog = true;
      }
    }
  },
};
</script>

<style scoped>
.action-btn-wrapper {
  position: sticky;
  bottom: 0;
  background-color: white;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  z-index: 8;
}
.plans-placeholder-height {
  min-height: calc(100vh - 24px - 6.5rem);
  height: 100%;
}
.plan-description {
  max-width: 600px !important;
  text-align: center !important
}
</style>
