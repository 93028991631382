import mammoth from 'mammoth'
import pdfjs from 'pdfjs-dist'

const getDocxContent = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onloadend = () => {
      const arrayBuffer = reader.result;
      mammoth.extractRawText({ arrayBuffer: arrayBuffer })
        .then(result => {
          const text = result.value;
          resolve(text);
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    };
    
    reader.readAsArrayBuffer(file);
  });
};

const getPdfContent = async (file) => {
  
  const reader = new FileReader();
  
  return new Promise((resolve, reject) => {
    reader.onloadend = async () => {
      const arrayBuffer = reader.result;
      const PDFSrc = Buffer.from(arrayBuffer);
      try{
        const pdf = await pdfjs.getDocument(PDFSrc).promise
        const pageList = await Promise.all(Array.from({ length: pdf.numPages }, (_, i) => pdf.getPage(i + 1)));
        const textList = await Promise.all(pageList.map((p) => p.getTextContent()));
        const content =  textList
        .map(({ items }) => items.map(({ str }) => str).join(""))
        .join("");

        resolve(content)
      }catch(err){
        reject(err)
      }
    }
    reader.readAsArrayBuffer(file)
  })
}

const getTxtContent = async (file) => {
  const reader = new FileReader();
  
  return new Promise((resolve, reject) => {
    const txtDecoder = new TextDecoder('utf-8')
    reader.onloadend = async () => {
      const arrayBuffer = reader.result;
      try{
        const text = txtDecoder.decode(arrayBuffer);
        resolve(text)
      }catch(err){
        reject(err)
      }
    }
    reader.readAsArrayBuffer(file)
  })
}

const handleFile = async (file) => {
  try {
    const mimeTypeHandlers = {
      'text/plain': getTxtContent,
      'application/pdf': getPdfContent,
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': getDocxContent,
    };
    const extractedText = await mimeTypeHandlers[file.type](file);
    return extractedText;
  } catch (error) {
    console.error(error);
  }
};

export default { handleFile }