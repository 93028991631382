import { Axios } from 'axios';

/**
 *
 * @param {Axios} api
 * @returns
*/

export default function makeDefectService(api) {
  return {
    getDefects: function (handle, projectKey,params) {
      return api.get(`/${handle}/projects/${projectKey}/defects`,{params});
    },

    getDefect: function (handle, projectKey, uid) {
      return api.get(`/${handle}/projects/${projectKey}/defects/${uid}`);
    },

    createDefect: function (handle, projectKey, data) {
      return api.post(`/${handle}/projects/${projectKey}/defects`, data);
    },

    updateDefect: function (handle, projectKey, { uid, ...data }) {
      return api.patch(`/${handle}/projects/${projectKey}/defects/${uid}`, data);
    },

    addComment: function (handle, projectKey, { uid, comment }) {
      return api.post(`/${handle}/projects/${projectKey}/defects/${uid}/comment`, {
        comment,
      });
    },
    linkDefect: function (handle, projectKey, defectId, data) {
      return api.post(`/${handle}/projects/${projectKey}/defects/${defectId}/link`, data);
    },
    getJiraIssueTypes: function (handle, projectKey, integrationUid,params) {
      return api.get(`/${handle}/projects/${projectKey}/integrations/${integrationUid}/types`,{params});
    },
    getJiraCreateMetadata: function (handle, projectKey, integrationUid,params) {
      return api.get(`/${handle}/projects/${projectKey}/integrations/${integrationUid}/fields`,{params});
    },
    getDefectPriorities: function (handle, projectKey) {
      return api.get(`/${handle}/projects/${projectKey}/defects/priorities`);
    },
    getDefectStatuses: function (handle, projectKey) {
      return api.get(`/${handle}/projects/${projectKey}/defects/statuses`);
    },
    getSignedAttachmentUrl: function({params,payload}){
      return api.post(`/${params.handle}/projects/${params.projectKey}/defects/${params.defectId}/attachments`, payload)
    },
    cleanupAttachments: function({id, params}){
      return api.delete(`/${params.handle}/projects/${params.projectKey}/defects/${params.defectId}/attachments/${id}/failed`)
    },
    deleteAttachments: function({id, params}){
      return api.delete(`/${params.handle}/projects/${params.projectKey}/defects/${params.defectId}/attachments/${id}`)
    }
  };
}
