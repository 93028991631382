<template>
  <div>
    <v-dialog
      :value="true"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
      @input="$emit('close-dialog')"
    >
      <!-- Loading Overlay -->
      <v-overlay
        :value="isLoading"
        absolute
      >
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>

      <!-- Dialog Content -->
      <v-card
        :disabled="isLoading"
        class="fill-height d-flex flex-column"
      >
        <!-- Header -->
        <div class="header-section d-flex justify-space-between align-center px-6">
          <h3 class="integration-title">
            {{ $t('integrations.edit_integration.edit') }} {{ editedData.name }}
          </h3>
          <v-btn
            icon
            @click="handleClose"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <!-- Main Content -->
        <v-card-text class="flex-grow-1 overflow-y-auto px-6">
          <!-- Step 1: Basic Info -->
          <div v-if="currentStep === 1">
            <!-- Image Upload Section -->
            <div class="mt-2">
              <p class="field-label mb-2">
                {{ $t('integrations.create_intergrations.thumbnail') }}
              </p>
              <div class="create-project-layout">
                <v-hover v-slot="{ hover }">
                  <div
                    class="project-logo"
                    :style="{ ...imageStyle }"
                    :class="{ hovering: hover }"
                    @click="openFileDialog"
                    @drop.prevent="handleDrop"
                    @dragover.prevent
                    @dragenter.prevent
                  >
                    <input
                      ref="fileInput"
                      type="file"
                      :accept="`${projectImageTypes.join(', ')}`"
                      style="display: none"
                      @change="handleFileChange"
                    >
                    <template v-if="!imageSrc">
                      <p class="ma-0 fs-14px">
                        {{ $t('drag_and_drop.part1') }}
                      </p>
                      <p class="ma-0 fw-semibold mt-2 blue--text fs-14px">
                        {{ $t('drag_and_drop.part3') }}
                      </p>
                    </template>
                    <template v-if="hover && imageSrc">
                      <v-icon
                        class="edit-icon"
                        @click.stop="openFileDialog"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        class="delete-icon"
                        @click.stop="removeImage"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </div>
                </v-hover>
              </div>
            </div>

            <!-- Name Field -->
            <div class="mb-4">
              <p class="field-label mb-2">
                {{ $t('name') }} <strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-text-field
                v-model="form.name"
                type="text"
                class="rounded-lg field-theme"
                background-color="#F9F9FB"
                height="38px"
                :placeholder="$t('name')"
                :rules="[rules.required]"
                hide-details="auto"
              />
            </div>

            <!-- Description Field -->
            <div class="mb-6">
              <p class="field-label mb-2">
                {{ $t('integrations.edit_integration.description') }} <strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-textarea
                v-model="form.description"
                class="rounded-lg field-theme"
                background-color="#F9F9FB"
                :placeholder="$t('integrations.edit_integration.description')"
                :rules="[rules.required]"
                rows="3"
                hide-details="auto"
                auto-grow
              />
            </div>

            <!-- Attachments Section -->
            <div class="mt-6">
              <p class="field-label mb-2">
                Attachments
              </p>
              <!-- Add this hidden file input -->
              <input
                ref="attachmentInput"
                type="file"
                multiple
                style="display: none"
                @change="handleAttachmentChange"
              >
              <div 
                class="attachment-upload-area"
                @click="openAttachmentDialog"
                @drop.prevent="handleAttachmentDrop"
                @dragover.prevent
                @dragenter.prevent
              >
                <v-icon
                  size="24"
                  color="grey"
                  class="mb-2"
                >
                  mdi-cloud-upload-outline
                </v-icon>
                <div class="text-center">
                  <span class="drag-drop-text">{{ $t('integrations.edit_integration.drag_and_drop') }}</span>
                  <br>
                  <span class="browse-text">{{ $t('integrations.edit_integration.browse_files') }}</span>
                </div>
              </div>
              <!-- Attachment List -->
              <div
                v-if="attachments.length > 0"
                class="attachment-list mt-3"
              >
                <div 
                  v-for="(file, index) in attachments"
                  :key="index"
                  class="attachment-item d-flex justify-space-between align-center py-2"
                >
                  <div class="d-flex align-center">
                    <v-icon
                      color="grey"
                      size="20"
                      class="mr-2"
                    >
                      mdi-file-outline
                    </v-icon>
                    <div>
                      <span class="filename">{{ file.name }}</span>
                      <div class="text-caption text-grey">
                        {{ file.size }} KB
                      </div>
                    </div>
                  </div>
                  <v-btn
                    icon
                    x-small
                    @click="removeAttachment(index)"
                  >
                    <v-icon color="grey">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <!-- Step 2: Configurations -->
          <div v-else-if="currentStep === 2">
            <h3>{{ $t('integrations.create_intergrations.configurations') }}</h3>
            
            <!-- Existing Configurations List -->
            <div
              v-if="configurations.length > 0 && editConfigIndex === null"
              class="mb-4 configurations-list"
            >
              <div 
                v-for="(config, index) in configurations" 
                :key="index"
                class="configuration-item"
              >
                <div class="d-flex justify-space-between align-center">
                  <span class="configuration-name">{{ config.name }}</span>
                  <div class="d-flex align-center">
                    <v-btn
                      icon
                      small
                      @click="editConfiguration(index)"
                    >
                      <EditIcon />
                    </v-btn>
                    <v-btn
                      icon
                      small
                      @click="removeConfiguration(index)"
                    >
                      <DeleteIcon />
                    </v-btn>
                  </div>
                </div>
                <!-- Configuration Details -->
                <div class="configuration-details mt-2">
                  <p class="configuration-name body-2 font-weight-bold ma-0 text-capitalize">
                    <span class="text-capitalize mr-1">{{ $t('integrations.edit_integration.service_projects', { serviceName: serviceName, entityName: serviceEntityName() }) }}</span>
                  </p>
                  <div class="d-flex flex-wrap">
                    <v-chip 
                      v-for="(project, i) in getServiceProjects(config)" 
                      :key="i"
                      class="ma-2"
                      small
                    >
                      {{ project }}
                    </v-chip>
                  </div>
                  <p class="configuration-name body-2 font-weight-bold ma-0">
                    {{ $t('integrations.create_intergrations.testfiesta_projects') }}
                  </p>
                  <div class="d-flex flex-wrap">
                    <v-chip 
                      v-for="(project, i) in getTestFiestaProjects(config)" 
                      :key="i"
                      class="ma-2"
                      small
                    >
                      {{ project }}
                    </v-chip>
                  </div>
                </div>
              </div>
            </div>

            <!-- Configuration Dropdown -->
            <IntegrationConfigurationDropdown
              :resources="resources"
              :testfiesta-projects="testFiestaProjects"
              :service-projects="serviceProjects"
              :integration-uid="String(integrationUid)"
              :service-name="serviceName.toLowerCase()"
              :edit-config-data="editConfigData"
              @add-configuration="handleAddConfiguration"
              @edit-configuration="handleEditConfiguration"
            />
          </div>
        </v-card-text>

        <!-- Footer Actions -->
        <div class="d-flex justify-space-between px-6 py-4 bg-white">
          <v-btn
            width="204.5px"
            :color="currentStep === 1 ? '#F2F4F7' : '#F2F4F7'"
            height="40"
            class="text-capitalize"
            @click="handleStepAction('back')"
          >
            {{ currentStep === 1 ? $t('cancel') : $t('integrations.edit_integration.back') }}
          </v-btn>
          <v-btn
            width="204.5px"
            color="primary"
            height="40"
            class="text-capitalize"
            :disabled="!isStepValid"
            @click="handleStepAction('next')"
          >
            {{ currentStep === 2 ? $t('save') : $t('integrations.edit_integration.continue') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DeleteIcon from '../../assets/svg/delete.svg';
import EditIcon from '../../assets/svg/edit.svg';
import fileValidator from '@/mixins/fileValidator.js';
import { projectImageTypes } from '@/constants/fileTypes.js';
import { showErrorToast,showSuccessToast } from '@/utils/toast';
import IntegrationConfigurationDropdown from '@/components/Integration/IntegrationConfigurationDropdown.vue';
import makeProjectsService from '@/services/api/project';
import makeIntegrationsService from '@/services/api/integrations';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EditIntegration',
  components: {
    EditIcon,
    DeleteIcon,
    IntegrationConfigurationDropdown,
  },
  mixins: [fileValidator],
  props: {
    editedData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      currentStep: 1,
      isLoading: false,
      isDataInitialized: false,
      serviceProjects: [],
      testFiestaProjects: [],
      resources: [],
      imageSrc: '',
      file: null,
      projectImageTypes,
      configurations: [],
      editConfigData: {},
      editConfigIndex: null,
      serviceEnities: {
        jira: 'Projects',
        github: 'Repositories',
      },
      form: {
        name: '',
        description: '',
      },
      rules: {
        required: (value) => !!value || this.$t('required'),
      },
      attachments: [],
    };
  },
  
  computed: {
    imageStyle() {
      return this.imageSrc ? { backgroundImage: `url(${this.imageSrc})` } : {};
    },
    isValidForSave() {
      return this.form.name && 
             this.form.name.trim() !== '' && 
             this.configurations.length > 0;
    },
    integrationUid() {
      return this.editedData?.uid;
    },
    serviceName() {
      return this.editedData?.service || '';
    },
    showOrgSelection() {
      return this.serviceName?.toLowerCase() === 'jira';
    },
    progressPercentage() {
      return this.currentStep * 50; // 2 steps, 50% each
    },
    isStepValid() {
      if (this.currentStep === 1) {
        return this.form.name.trim() !== '' && this.form.description.trim() !== '';
      }
      // For step 2, if we're editing a configuration, disable the save button
      if (this.editConfigIndex !== null) {
        return false;
      }
      return this.configurations.length > 0;
    },
    ...mapGetters({
      uploadedFiles: 'attachment/uploadedFiles'
    }),
  },

  watch: {
    editedData: {
      immediate: true,
      handler(newVal) {
        if (newVal?.uid && !this.isDataInitialized) {
          this.initializeData();
        }
      }
    },
    
    // Watch for service name changes to handle Jira case
    serviceName: {
      immediate: true,
      handler(newVal) {
        if (newVal?.toLowerCase() === 'jira' && this.integrationUid) {
          this.fetchResources();
        }
      }
    }
  },

  created() {
    // Initialize form data when component is created
    this.initializeFormData();
    if (this.showOrgSelection) {
      this.fetchResources();
    }
  },

  methods: {
    ...mapActions({
      uploadToServer: 'attachment/uploadToServer',
    }),
    serviceEntityName() {
      return this.serviceEnities[this.serviceName?.toLowerCase()] || 'Projects';
    },
    async initializeData() {
      if (this.isDataInitialized) return;
      
      this.isLoading = true;
      try {
        // Reset form data
        this.resetForm();
        
        // Load initial data from editedData
        if (this.editedData) {
          this.form.name = this.editedData.name || '';
          this.form.description = this.editedData.description || '';
          this.imageSrc = this.editedData.avatarUrl || '';
          this.configurations = [...(this.editedData.configurations || [])];
        }

        // Fetch required data in parallel
        await Promise.all([
          this.fetchTestfiestaProjects(),
          this.showOrgSelection ? this.fetchResources() : Promise.resolve()
        ]);

        this.isDataInitialized = true;
      } catch (error) {
        showErrorToast(this.$swal, 'Failed to load integration data');
      } finally {
        this.isLoading = false;
      }
    },

    async fetchTestfiestaProjects() {
      try {
        const projectsService = makeProjectsService(this.$api);
        const params = 'status=active&includeCount=true';
        const res = await projectsService.getProjects(this.$route.params.handle, params);
        this.testFiestaProjects = res.data.projects || [];
      } catch (err) {
        throw new Error('Failed to fetch Testfiesta projects');
      }
    },

    async fetchResources() {
      if (!this.integrationUid) return;
      
      try {
        const integrationsService = makeIntegrationsService(this.$api);
        const res = await integrationsService.getOrganizations(
          this.$route.params.handle,
          this.integrationUid
        );
        this.resources = res.data;
      } catch (err) {
        showErrorToast(this.$swal, 'Failed to fetch resources');
      }
    },

    openFileDialog() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const files = Array.from(event.target.files);
      const validationResult = this.validateMimeTypes(files, this.projectImageTypes);

      if (!validationResult.valid) {
        showErrorToast(this.$swal, this.$t('integrations.error.fileFormatNotSupported'));
      } else {
        const file = event.target.files[0];
        this.previewImage(file);
      }
    },

    previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
        this.file = file;
      }
    },

    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      if (file) {
        const validationResult = this.validateMimeTypes([file], this.projectImageTypes);
        if (validationResult.valid) {
          this.previewImage(file);
        } else {
          showErrorToast(this.$swal, this.$t('integrations.error.fileFormatNotSupported'));
        }
      }
    },

    async uploadLogo(file) {
      try {
        const handle = this.$route.params.handle;
        const integrationsService = makeIntegrationsService(this.$api);
        
        const params = {
          integrationUid: this.editedData.uid,
          mediaType: 'logo', // Specify logo type
          fileName: file.name,
          fileType: file.type,
          size: file.size
        };

        const response = await this.uploadToServer({
          handle,
          file,
          apiService: integrationsService,
          params
        });

        if (response?.objectUrl) {
          this.imageSrc = response.objectUrl;
          // No need for additional update call since backend handles it
        }
      } catch (error) {
        console.error('Failed to upload logo:', error);
        showErrorToast(this.$swal, this.$t('integrations.error.logoUploadFailed'));
      }
    },

    removeImage() {
      this.imageSrc = '';
      const handle = this.$route.params.handle;
      const integrationsService = makeIntegrationsService(this.$api);
      integrationsService.updateIntegration(
        handle,
        this.editedData.uid,
        { avatarUrl: null }
      ).catch(error => {
        console.error('Failed to remove logo:', error);
        showErrorToast(this.$swal, this.$t('integrations.error.logoRemoveFailed'));
      });
    },

    closeDrawer() {
      // Emit event to update drawer state in parent
      this.$emit('update:drawer', false);
    },
    
    editConfiguration(index) {
      this.editConfigData = { ...this.configurations[index] };
      this.editConfigIndex = index;
    },

    handleAddConfiguration(config) {
      this.configurations.push(config);
    },

    handleEditConfiguration(config) {
      if (this.editConfigIndex !== null) {
        this.configurations.splice(this.editConfigIndex, 1, config);
        this.editConfigData = {};
        this.editConfigIndex = null;
      }
    },

    removeConfiguration(index) {
      this.configurations.splice(index, 1);
    },

    handleStepAction(action) {
      if (action === 'back') {
        if (this.currentStep === 1) {
          this.handleClose();
        } else {
          this.currentStep--;
        }
      } else if (action === 'next') {
        if (this.currentStep === 1) {
          this.currentStep++;
        } else {
          this.handleSave();
        }
      }
    },

    async handleSave() {
      if (!this.isStepValid) return;
      this.isLoading = true;
      
      try {
        
        // Then create/update the integration
        const projectUids = this.configurations.reduce((keys, config) => {
          if (config.projects) {
            Object.keys(config.projects).forEach(key => keys.add(key));
          }
          return keys;
        }, new Set());

        const integrationData = {
          name: this.form.name.trim(),
          description: this.form.description,
          service: this.serviceName,
          projectUids: Array.from(projectUids),
          configuration: {
            projectConfigurations: this.configurations
          }
        };
        
        const integrationsService = makeIntegrationsService(this.$api);
        await integrationsService.updateIntegration(
          this.$route.params.handle,
          this.editedData.uid,
          integrationData
        );
        
        // upload the logo if there's a new one
        if (this.file) {
          this.uploadLogo(this.file);
        }

        // Start attachment uploads in background
        if (this.attachments.length) {
          this.uploadIntegrationAttachments(this.editedData.uid).catch(error => {
            console.error('Failed to upload attachments:', error);
            showErrorToast(this.$swal, this.$t('integrations.error.attachmentUploadFailed'));
          });
        }
        
        showSuccessToast(this.$swal, this.$t('integrations.success.saved'));
        this.$emit('refresh');
        this.$emit('close-dialog');
      } catch (error) {
        showErrorToast(this.$swal, this.$t('integrations.error.saveFailed'));
      } finally {
        this.isLoading = false;
      }
    },

    async uploadIntegrationAttachments(integrationUid) {
      const handle = this.$route.params.handle;
      const integrationsService = makeIntegrationsService(this.$api);
      const mediaType = 'attachment';

      const uploadPromises = this.attachments.map(async attachment => {
        try {
          const params = {
            handle,
            integrationUid,
            relatedTo: 'integration'
          };

          await this.uploadToServer({
            handle,
            mediaType,
            file: attachment.file,
            apiService: integrationsService,
            params
          });
        } catch (error) {
          console.error(`Failed to upload attachment ${attachment.name}:`, error);
          throw error;
        }
      });

      // Let uploads happen in parallel but don't wait for them
      return Promise.allSettled(uploadPromises);
    },

    resetForm() {
      this.isDataInitialized = false;
      // Reset all form data to initial state
      this.form.name = this.editedData.name || '';
      this.imageSrc = this.editedData.avatarUrl || '';
      this.selectedTestFiestaProjects = this.editedData.projects || [];
      this.selectedServiceProjects = this.editedData.selectedProjectIds || [];
      this.currentFilter = 'all';
      this.search = '';
      
      // Reset file input if it exists
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = '';
      }
    },
    handleClose() {
      this.resetForm();
      this.$emit('close-dialog');
    },
    handleCancel() {
      this.resetForm();
      this.$emit('close-dialog');
    },
    handleSubmit(event) {
      event.preventDefault();
      // ... rest of your submit logic
    },
    openAttachmentDialog() {
      // This will trigger the hidden file input
      this.$refs.attachmentInput.click();
    },
    async handleAttachmentChange(event) {
      const files = Array.from(event.target.files || []);
      files.forEach(file => {
        const attachment = {
          file,
          name: file.name,
          size: Math.round(file.size / 1024), // Convert to KB
          type: file.type,
          extension: file.name.split('.').pop().toUpperCase()
        };
        this.attachments.push(attachment);
      });
      // Reset file input
      event.target.value = '';
    },
    async handleAttachmentDrop(event) {
      event.preventDefault();
      const files = Array.from(event.dataTransfer.files);
      files.forEach(file => {
        const attachment = {
          file,
          name: file.name,
          size: Math.round(file.size / 1024), // Convert to KB
          type: file.type,
          extension: file.name.split('.').pop().toUpperCase()
        };
        this.attachments.push(attachment);
      });
    },
    removeAttachment(index) {
      this.attachments.splice(index, 1);
    },
    initializeFormData() {
      if (this.editedData) {
        this.form.name = this.editedData.name || '';
        this.form.description = this.editedData.description || '';
        this.imageSrc = this.editedData.avatarUrl || '';
      }
    },
    getTestFiestaProjects(config) {
      if (!config.projects || !this.testFiestaProjects) return [];
      
      return Object.keys(config.projects)
        .map(key => {
          const project = this.testFiestaProjects.find(p => p.uid === parseInt(key));
          return project ? project.name : null;
        })
        .filter(Boolean);
    },
    
    getServiceProjects(config) {
      if (!config.projects) return [];
      
      // Flatten all project arrays from all testfiesta projects
      return Object.values(config.projects)
        .flat()
        .map(project => project.projectName)
        .filter(Boolean);
    },
    async uploadAttachment(file) {
      try {
        const handle = this.$route.params.handle;
        const integrationsService = makeIntegrationsService(this.$api);
        
        const params = {
          integrationUid: this.editedData.uid,
          mediaType: 'attachment',
          fileName: file.name,
          fileType: file.type,
          size: file.size
        };

        return await this.uploadToServer({
          handle,
          file,
          apiService: integrationsService,
          params
        });
      } catch (error) {
        console.error('Failed to upload attachment:', error);
        throw error;
      }
    },
    async handleAttachments() {
      if (!this.attachments.length) return;

      try {
        const uploadPromises = this.attachments.map(attachment => 
          this.uploadAttachment(attachment.file)
        );

        await Promise.all(uploadPromises);
      } catch (error) {
        showErrorToast(this.$swal, this.$t('integrations.error.attachmentUploadFailed'));
        throw error;
      }
    }
  },
};
</script>

<style>
.v-dialog--fullscreen {
  max-height: 100vh !important;
  width: 485px !important;
  right: 0 !important;
  left: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>

<style lang="scss" scoped>
.dialog-theme {
  .v-dialog {
    max-height: 100vh !important;
    width: 485px !important;
    right: 0 !important;
    left: auto !important;
  }
}

.d-flex.justify-space-between.align-center {
  padding: 16px 24px;
  margin-bottom: 8px;
}

.field-label {
  font-size: 14px;
  font-weight: 500;
  color: #344054;
  margin-bottom: 8px;
  margin-top: 24px;
}

.field-theme {
  :deep(.v-input__slot) {
    min-height: 38px !important;
    box-shadow: none !important;
  }
}

.project-logo {
  border-radius: 50%;
  border: 2px dashed #d0d5dd;
  width: 136px;
  height: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: #f9fafb;
  margin-left: 24px;

  .upload-content {
    padding: 0 16px;
    width: 100%;
    
    .drag-drop-text {
      font-size: 12px;
      color: #667085;
      margin-bottom: 4px;
      white-space: normal;
      line-height: 1.2;
    }

    .browse-text {
      font-size: 12px;
      color: #0C2FF3;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .edit-icon,
  .delete-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    display: none;
    cursor: pointer;
    
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .edit-icon {
    left: calc(50% - 30px);
  }

  .delete-icon {
    left: calc(50% + 6px);
  }

  &:hover {
    border-color: #2196f3;

    .edit-icon,
    .delete-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.create-project-layout {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: flex;
}

.attachment-upload-area {
  border: 1px dashed #D0D5DD;
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  cursor: pointer;
  background-color: #F9FAFB;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: #2196F3;
  }
}

.attachment-list {
  .attachment-item {
    background-color: #F9FAFB;
    border-radius: 6px;
    padding: 0 12px;
    margin-bottom: 4px;

    .filename {
      font-size: 14px;
      color: #344054;
    }

    &:hover {
      background-color: #F2F4F7;
    }
  }
}

.header-section {
  padding-top: 24px;
  padding-bottom: 12px;
}

.integration-title {
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  margin: 0;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.configuration-item {
  background-color: #f9fafb;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 12px;

  .configuration-name {
    font-size: 14px;
    color: #344054;
    font-weight: 500;
  }

  .configuration-details {
    margin-top: 12px;
  }

  &:hover {
    background-color: #f2f4f7;
  }
}

.v-chip {
  margin: 4px;
  background-color: #f2f4f7;
  color: #344054;
}
</style>
