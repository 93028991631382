<template>
  <v-card
    class="white py-6 px-6 mt-3"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex align-center justify-space-between">
      <h2>{{ $t('users') }}</h2>
      <v-btn
        v-if="inviteVisibility"
        class="btn-theme text-capitalize"
        height="40"
        color="primary"
        :depressed="true"
        elevation="0"
        @click="showDialog = true"
      >
        {{ $t('inviteUser') }} <v-icon
          class="ml-1"
          size="xs"
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </div>
    <div class="mt-4 d-flex">
      <v-chip 
        :class="{ 'blue--text': filter === 'active' }" 
        width="200px" 
        :color="filter === 'active' ? '#e6ecff' : '#f9fafb'"
        label 
        @click="updateFilter('active')"
      >
        <div
          class="px-2"
          :class="{ 'font-weight-bold': filter === 'active' }"
        >
          {{ $t('active') }} <span class="ml-2">{{ activeUserCount }}</span>
        </div>
      </v-chip>
      <div class="ml-2">
        <v-chip 
          :class="{ 'blue--text': filter === 'pending' }" 
          width="200px" 
          :color="filter === 'pending' ? '#e6ecff' : '#f9fafb'"
          label 
          @click="updateFilter('pending')"
        >
          <div
            class="px-2"
            :class="{ 'font-weight-bold': filter === 'pending' }" 
          >
            {{ $t('pending') }} <span class="ml-2">{{ pendingUserCount }}</span>
          </div>
        </v-chip>
      </div>
      <div class="ml-2">
        <v-chip 
          :class="{ 'blue--text': filter === 'requested' }" 
          width="200px" 
          :color="filter === 'requested' ? '#e6ecff' : '#f9fafb'"
          label 
          @click="updateFilter('requested')"
        >
          <div
            class="px-2"
            :class="{ 'font-weight-bold': filter === 'requested' }" 
          >
            {{ $t('requested') }} <span class="ml-2 red--text">{{ requestedUserCount }}</span>
          </div>
        </v-chip>
      </div>
      <div class="ml-2">
        <v-chip 
          :class="{ 'blue--text': filter === 'expired' }" 
          width="200px" 
          :color="filter === 'expired' ? '#e6ecff' : '#f9fafb'"
          label 
          @click="updateFilter('expired')"
        >
          <div
            class="px-2"
            :class="{ 'font-weight-bold': filter === 'expired' }" 
          >
            {{ $t('expired') }} <span class="ml-2 red--text">{{ expiredUserCount }}</span>
          </div>
        </v-chip>
      </div>
    </div>
    <v-dialog
      v-if="inviteVisibility"
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('inviteNewUser') }} 
            </h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="mt-6">
            <div class="text-start">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('email') }}  <strong class="red--text text--lighten-1">*</strong>
              </v-label>
            </div>
            <v-chip-group column>
              <v-chip
                v-for="(email, index) in emails"
                :key="index"
                close
                class="custom-chip-theme bg-white rounded-lg"
                link
                close-icon="mdi-close"
                @click:close="removeEmail(index)"
              >
                {{ email }}
              </v-chip>
            </v-chip-group>
            <v-form
              class="text-right"
              @submit.prevent="addEmail"
            >
              <v-text-field
                v-model="newEmail"
                type="text"
                dense
                class="text-field field-theme rounded-lg"
                background-color="#F9F9FB"
                height="38"
                :error="showEmailError"
                :rules="emailValidation"
                filled
                clear-icon="body-2"
                :placeholder="$t('email')"
                required
                @click:append="addEmail"
                @keyup.space="addEmail"
              />
              <v-btn
                width="10rem"
                color="primary"
                height="40"
                :depressed="true"
                class="text-capitalize btn-theme rounded-lg"
                elevation="0"
                @click="addEmail"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
                {{ $t('addEmail') }}
              </v-btn>
            </v-form>
          </div>
          <div class="mt-4 item">
            <v-checkbox
              v-model="applyExistingRole"
              class="field-theme mb-4"
              hide-details
              :ripple="false"
              off-icon="icon-checkbox-off"
              on-icon="icon-checkbox-on"
            > 
              <template v-slot:label>
                <span class="fs-14 text-theme-label">{{ $t('applyScopeOfRights') }}</span>
              </template>
            </v-checkbox>
            <div v-if="applyExistingRole">
              <div class="text-start">
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('user') }}  <strong class="red--text text--lighten-1">*</strong>
                </v-label>
              </div>
              <v-select
                v-model="selectedUser"
                :items="usersFullName"
                :rules="roleRules"
                :error="showRoleError"
                item-text="fullName"
                item-value="uid"
                background-color="#F9F9FB"
                :placeholder="$t('chooseRole')"
                append-icon="mdi-chevron-down"
                class="rounded-lg field-theme"
                height="38px"
                required
                dense
                :menu-props="{ offsetY: true }"
              />
            </div>
          </div>
          <div>
            <div class="text-start">
              <v-label
                class="text-theme-label font-weight-medium"
                :class="{ 'grey--text': applyExistingRole }"
              >
                {{ $t('role') }}  <strong class="red--text text--lighten-1">*</strong>
              </v-label>
            </div>
            <v-select
              v-model="selectedRole"
              :items="roles"
              :rules="roleRules"
              :error="showRoleError"
              item-text="name"
              item-value="uid"
              :background-color="applyExistingRole ? 'grey lighten-3' : '#F9F9FB'"
              :placeholder="$t('chooseRole')"
              append-icon="mdi-chevron-down"
              class="rounded-lg field-theme"
              :disabled="applyExistingRole"
              height="38px"
              required
              dense
              :menu-props="{ offsetY: true }"
            />
          </div>
          <div>
            <div class="text-start">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('tags') }}
              </v-label>
            </div>
            <v-select
              v-model="selectedTags"
              :items="tags"
              dense
              background-color="#F9F9FB"
              append-icon="mdi-chevron-down"
              :menu-props="{ offsetY: true }"
              class="rounded-lg field-theme custom-prepend mh-38px"
              multiple
              item-text="name"
              item-value="uid"
              :placeholder="$t('tagPage.chooseType')"
              persistent-placeholder
            >
              <template v-slot:selection="{ item }">
                <div
                  class="d-flex align-center custom-chip-theme ma-1"
                >
                  <div class="text-theme-label label text-truncate mr-1">
                    {{ item.name }}
                  </div>
                  <v-icon
                    size="16px"
                    @click="onRemoveSelectedTags(item.name)"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </template>

              <template v-slot:item="{ item, on, attrs }">
                <v-list-item
                  :ripple="false"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-action>
                    <v-checkbox
                      hide-details
                      :input-value="tagsSelection(item.name)"
                      class="field-theme mt-0 pt-0"
                      :ripple="false"
                      off-icon="icon-checkbox-off"
                      on-icon="icon-checkbox-on"
                    >
                      <template v-slot:label>
                        <span class="fs-14px text-theme-label">{{ `${item.name}` }}</span>
                      </template>
                    </v-checkbox>
                  </v-list-item-action>
                </v-list-item>
              </template>

              <template v-slot:append-item>
                <button
                  plain
                  class="btn-nav-back pl-3 py-1"
                  @click="addUserTags"
                >
                  <v-icon color="blue">
                    mdi-plus
                  </v-icon>
                  <span class="d-flex-inline justify-center align-center ma-0 blue--text">
                    {{ $t("createTag") }}
                  </span>
                </button>
              </template>
            </v-select>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          elevation="0"
          @click="inviteUsers"
        >
          {{ $t('invite') }}
        </v-btn>
      </div>
    </v-dialog>
    <CreateTagDialog 
      v-model="showCreateTagDialog"
      :data="selectedTag"
      @create-new-tag="createTag"
      @close-dialog="showCreateTagDialog = false"
    />
  </v-card>
</template>

<script>
import makeOrgService from '@/services/api/org';
import validator from 'validator';
import { createNamespacedHelpers } from 'vuex';
import { emailValidationRules } from "@/utils/validation";
import CreateTagDialog from '@/components/User/CreateTagDialog.vue';
import makeTagService from "@/services/api/tag";
import { showSuccessToast, showErrorToast } from '@/utils/toast';

const { mapState } = createNamespacedHelpers('project');

export default {
  components: {
    CreateTagDialog
  },
  props: {
    filter: String,
    activeUserCount: Number,
    pendingUserCount: Number,
    requestedUserCount: Number,
    expiredUserCount: Number,
    users: {
      type: Array,
      default: () => {
        return [];
      }
    },
    roles:{
      type: Array,
      default: () =>{
        return []
      }
    },
    tags: {
      type: Array,
      default: () => {
        return [];
      }
    },
    writeMember: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      applyExistingRole: false,
      showDialog: false,
      showInvites: true,
      newEmail: '',
      emails: [],
      showRoleError: false,
      showEmailError: false,
      showCreateTagDialog: false,
      selectedTag: {},
      selectedRole: '',
      selectedUser: null,
      // selectedProject: '',
      selectedTags: [],
      emailValidation: emailValidationRules(this),
      autoCompleteTags: [],
      sendingInvite: false,
      roleRules: [
        v => !!v || this.$t('Role is Required')
      ],
    };
  },
  computed: {
    ...mapState(['projects']),  
    // Get username to prevent invites in personal accounts 
    username(){
      return this.$store.getters['user/user'].handle;
    },
    usersFullName(){
      return this.users.map(user => {
        return {
          ...user,
          fullName: `${user.firstName} ${user.lastName}`
        }
      });
    },
    inviteVisibility(){
      return this.$route.params.handle !== this.username && this.writeMember ? true : false;
    },
    projectOptions(){
      return this.projects.map(project => ({
      name: project.name,
      key: project.key
    }));
    }
  },
  watch: {
    newEmail: {
      handler() {
        this.updateEmailValidationRules();
      },
    },
  },
  methods: {
    updateEmailValidationRules() {
      const isDuplicate = this.emails.includes(this.newEmail);
      
      if (this.emails.length === 0 && !this.newEmail) {
        this.emailValidation = emailValidationRules(this);
      } else if (this.emails.length > 0 && !this.newEmail) {
        this.emailValidation = [];
      } else {
        this.emailValidation = emailValidationRules(this);
        if (isDuplicate) {
          this.emailValidation.push(this.$t('error.dublicateEmail'));
        }
      }
    },
    addUserTags() {
      this.showCreateTagDialog = true;
      this.showDialog = false;
      this.emails = [];
      this.selectedRole = '';
      this.selectedTags = [];
    },
    onRemoveSelectedTags(name) {
      const index = this.selectedTags.findIndex((tagUid) => {
        const tag = this.tags.find(t => t.uid === tagUid);
        return tag && tag.name === name;
      });
      if (index !== -1) {
        this.selectedTags.splice(index, 1);
      }
    },
    tagsSelection(name) {
      if (!this.selectedTags) return false;
      return this.selectedTags.some((tagUid) => {
        const tag = this.tags.find(t => t.uid === tagUid);
        return tag && tag.name === name;
      });
    },
    async addEmail() {
      const isDuplicate = this.emails.includes(this.newEmail);

      if (isDuplicate) {
        this.emailValidation.push(this.$t('error.dublicateEmail'));
        return; 
      }

      if (this.newEmail && validator.isEmail(this.newEmail.trim())) {
        this.emails.push(this.newEmail.trim());
        this.newEmail = '';
      } else {
        this.showEmailError = true;
      }
    },
    async createTag(tag) {
      
      this.showCreateTagDialog = false;

      const tagService = makeTagService(this.$api);
    
      try {
        const payload = {
          name: tag.name,
          description: tag.description,
          entityTypes: [tag.entityTypes],
        }

        const response = await tagService.createTag(this.$route.params.handle, payload);
        if(response.status === 200){
          this.$emit('update-tags');
          showSuccessToast(this.$swal, "createSuccess", { item: "Tag" });
          this.showDialog = true;
        }
        
      } catch (err) {
        showErrorToast(this.$swal, "createError", { item: "Tag" });
      } 

    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    updateFilter(filter) {
      this.$emit('update-filter', filter);
    },
    async inviteUsers() {
      if(this.newEmail)
        await this.addEmail()
      
      const orgService = makeOrgService(this.$api);
      if (this.sendingInvite) return;
      let hasError = false;

      if (!this.emails.length) {
        this.showEmailError = true;
        hasError = true;
      }

      if(this.applyExistingRole && !this.selectedUser) {
        hasError = true;
        this.showRoleError = true;
      }

      if (hasError) return;

      const selectedUserObj = this.users.find(user => user.uid === this.selectedUser);

      const sendRoleUid = this.applyExistingRole ? selectedUserObj.role.uid : this.selectedRole;
      const payloads = this.emails.map(email => ({
        roleUid: sendRoleUid,
        handle: this.$store.state.user.currentAccount.handle,
        userEmail: email,
        tagUids: this.selectedTags
      }));

      this.sendingInvite = true;
      try {
        await Promise.all(payloads.map(payload => orgService.newInvite(payload)));
        this.$emit('completed');

        // reset all states
        this.showDialog = false;
        this.emails = [];
        this.selectedRole = '';
        this.selectedTags = [];
      } catch (error) {
        if (!error.response) return this.$emit('showError');

        this.$emit('showError', (error.response?.data.data && error.response?.data.data[0]?.msg) || error.response?.data.message);
      }
      this.sendingInvite = false;
    },
    cancel() {
      this.showDialog = false;
    }
  },
};
</script>


<style scoped>
.item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>