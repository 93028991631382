<template>
  <div>
    <div
      v-for="category in tools"
      :key="category.category"
    >
      <div class="bg-gray-theme py-4 px-6 my-4">
        <h3 class="fs-20 fw-semibold">
          {{ category.category }}
        </h3>
      </div>
      <v-row>
        <v-col
          v-for="tool in category.items"
          :key="tool.name"
          cols="4"
        >
          <v-sheet
            class="project-item pa-4 bg-gray-theme"
            rounded="lg"
          >
            <div class="d-flex justify-between align-center">
              <v-avatar
                class="mr-2"
                size="40"
              >
                <v-img
                  :src="tool.image"
                  :lazy-src="require('@/assets/png/project.png')"
                  width="100%"
                  alt="logo"
                />
              </v-avatar>
              <div class="fw-semibold fs-14px">
                {{ tool.name }}
              </div>
              <v-btn
                color="blue"
                depressed
                height="40px"
                class="text-capitalize rounded-lg btn-theme white--text px-4"
                @click="addIntegration(tool.name, tool.description)"
              >
                {{ $t('Add') }}
                <v-icon
                  class="ml-1"
                  size="16px"
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
            <div class="text-start pt-2 pb-4 description fs-12px">
              {{ tool.description }}
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntegrationListAll',
  props: {
    tools: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async addIntegration(name, description) {
      this.$emit('select-integration', { name, description });
    },
  },
};
</script>

<style scoped>
.font-weight-bold {
  margin-top: 20px;
}
</style>
