<template>
  <div>
    <v-card
      v-if="loaderState"
      class="py-6 px-6 mt-3"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <Loader />
    </v-card>

    <v-card
      v-if="!loaderState"
      class="py-6 px-6 mt-3 ml-3"
      rounded="lg"
      elevation="0"
    >
      <v-row>
        <v-col flex>
          <div
            class="back-to-projects"
            @click="handleBackClick"
          >
            <v-icon color="blue">
              mdi-chevron-left
            </v-icon>
            <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
              {{ $t('Back to Milestone Info') }} 
            </p>
          </div>
        </v-col>
      </v-row>
      <template>
        <div class="d-flex align-center justify-space-between mt-5">
          <div
            class="d-flex align-center"
          >
            <div v-if="isEditing">
              <v-text-field
                v-model="selectedMilestone.name"
                class="title-edit-field font-weight-bold fs-24px text-theme-base edit-title-input pt-0 mt-0"
                hide-details
                @keyup.enter="saveHeader"
                @blur="saveHeader"
              />
            </div>
            <div v-else>
              <div class="d-flex align-center">
                <v-tooltip
                  bottom
                  max-width="430px"
                  :disabled="selectedMilestone?.name?.length < 30"
                  content-class="tooltip-theme"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <h2 
                      class="custom__tooltip__title"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ selectedMilestone.name }}
                    </h2>
                  </template>
                  <span>{{ selectedMilestone.name }}</span>
                </v-tooltip>
                <PencilIcon 
                  class="ml-3" 
                  @click="editHeader"
                />
              </div>
            </div>
          </div>
          <div
            class="options-container"
          >
            <v-row class="align-center">
              <div class="d-flex align-center">
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('tags') }}:
                </v-label>
              </div>
              <v-col
                cols="auto"
                class="pb-4"
              >
                <v-select
                  v-model="selectedMilestone.customFields.tags"
                  :value="value"
                  class="rounded-lg pt-0 field-theme custom-prepend mh-38px"
                  append-icon="mdi-chevron-down"
                  background-color="#F9F9FB"
                  multiple
                  hide-details
                  :items="tags"
                  item-text="name"
                  item-value="name"
                  :placeholder="$t('selectTags')"
                  :menu-props="{ offsetY: true }"
                />
              </v-col>
            </v-row>
            <v-row
              class="align-center ma-0"
            >
              <div
                class="d-flex align-center"
              >
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('status') }}:
                </v-label>
              </div>
              <v-col
                cols="auto"
                class="pb-4"
              >
                <v-select
                  v-model="selectedMilestone.customFields.status"
                  :items="statuses"
                  item-text="name"
                  item-value="type"
                  class="rounded-lg select-input-none select-custom-milestone field-theme custom-prepend"
                  background-color="#F9F9FB"
                  :placeholder="$t('status')"
                  append-icon="mdi-chevron-down"
                  :menu-props="{ offsetY: true }"
                />
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="d-flex align-center">
          <div v-if="isEditing">
            <v-text-field
              v-model="selectedMilestone.description"
              class="subdescription-edit-field"
              hide-details
              @keyup.enter="saveHeader"
              @blur="saveHeader"
            />
          </div>
          <div v-else>
            <div class="d-flex">
              <p class="text-start display-10 my-2">
                {{ selectedMilestone.description }}
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex align-center">
          <p class="text-start fs-16px mb-0 mr-2">
            {{ $t('milestone.create_milestone.dueDate') + ':' }}
          </p>
          <v-menu
            v-model="form.dueDate"
            :close-on-content-click="false"
            :max-width="'290px'"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                color="blue"
                class="date-picker-field pt-0 mt-0 fs-16px fw-semibold"
                :value="dueDate"
                readonly
                :rules="[validateDueDate]"
                hide-details
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="dueDate"
              @change="form.dueDate = false"
            />
          </v-menu>
        </div>
        <p 
          v-if="!validateDueDate(dueDate)" 
          class="red--text mb-0"
        >
          {{ $t('milestone.create_milestone.dueDateError') }}
        </p>  
        <div class="mt-4 d-flex">
          <v-chip
            :class="{ 'blue--text': tableTestType === 'plans' }"
            width="200px"
            :color="tableTestType === 'plans' ? 'blue-light' : 'gray-light'"
            label
            @click="changeTestType('plans')"
          >
            <div class="font-weight-bold px-2">
              {{ $t('testPlans') }} <span class="ml-1">{{ getActivePlanItemsCount }}</span>
            </div>
          </v-chip>
          <div class="ml-2">
            <v-chip
              :class="{ 'blue--text': tableTestType === 'runs' }"
              width="200px"
              :color="tableTestType === 'runs' ? 'blue-light' : 'gray-light'"
              label
              @click="changeTestType('runs')"
            >
              <div class="font-weight-bold px-2">
                {{ $t('testRuns') }} <span class="ml-1">{{ getActiveRunItemsCount }}</span>
              </div>
            </v-chip>
          </div>
        </div>
      </template>
    </v-card>
      
    <v-card
      v-if="!loaderState"
      class="py-6 px-6 mt-3 ml-3"
      rounded="lg"
      elevation="0"
    >
      <div class="fs-24px text-start font-weight-bold">
        {{ selectedTestType }}
      </div>
      <div class="mt-6 d-flex">
        <v-chip
          :class="{ 'blue--text': tableFilter === 'all' }"
          width="200px"
          :color="tableFilter === 'all' ? 'blue-light' : 'gray-light'"
          label
          @click="changeFilter('all')"
        >
          <div class="font-weight-bold px-2">
            {{ $t('all') }} <span class="ml-1">{{ isTableTypePlans ? getActivePlanItemsCount : getActiveRunItemsCount }}</span>
          </div>
        </v-chip>
        <div class="ml-2">
          <v-chip
            :class="{ 'blue--text': tableFilter === 'selected' }"
            width="200px"
            :color="tableFilter === 'selected' ? 'blue-light' : 'gray-light'"
            label
            @click="changeFilter('selected')"
          >
            <div class="font-weight-bold px-2">
              {{ $t('selected') }} <span class="ml-1">{{ isTableTypePlans ? selectedPlanLength : selectedRunLength }}</span>
            </div>
          </v-chip>
        </div>
      </div>
      <v-row class="align-center mt-3">
        <v-col
          cols="6"
          sm="6"
          class="py-0"
        >
          <div class="d-flex flex-row justify-start align-center">
            <v-responsive
              class="ma-0"
              max-width="344"
            >
              <v-text-field
                v-model="searchFilter"
                class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
                :placeholder="$t('searchByName')"
                height="40"
                background-color="#F9F9FB"
                hide-details
              >
                <template v-slot:prepend-inner>
                  <SearchIcon />
                </template>
              </v-text-field>
            </v-responsive>
            <PlanFilter 
              v-if="tableTestType === 'plans'"
              :configurations="configurations" 
              :milestones="activeMilestones"  
              :tags="tags"
              @applyFilters="applyPlanFilters"
            />
            <RunFilter
              v-else
              :configurations="configurations" 
              :milestones="activeMilestones"  
              :tags="tags"
              @applyFilters="applyRunFilters"
            />
          </div>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          class="d-flex justify-end"
        >
          <SettingsMenu 
            :key="selectedTableMenu"
            :table-type="selectedTableMenu" 
          />
        </v-col>
      </v-row>
      <MileatoneTestPlanTable
        v-if="tableTestType === 'plans'"
        v-model="selectedPlans"
        :plans-data="filteredTestPlans"
        :table-filter="tableFilter"
        :filtered-headers="filteredPlanHeaders"
        :filtered-items="filteredTestPlans"
      />

      <MilestoneTestRunTable
        v-if="tableTestType === 'runs'"
        v-model="selectedRuns"
        :runs-data="filteredTestRuns"
        :table-filter="tableFilter"
        :filtered-headers="filteredRunHeaders"
        :filtered-items="filteredTestRuns"
      />
      
      <v-row
        justify="end"
        class="mt-4"
      >
        <v-col cols="12">
          <v-flex class="mt-6 d-sm-flex justify-end">
            <v-btn
              depressed
              color="gray-100"
              height="40"
              class="text-capitalize rounded-lg font-weight-bold black--text mr-4 mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="handleBackClick"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              depressed
              color="primary"
              height="40"
              class="text-capitalize btn-theme rounded-lg mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="updateMilestone"
            >
              {{ $t('Save') }}
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-card>
  
    <ProjectDiscardDialog
      v-model="showConfirmBackDialog"
      :title="$t('milestone.edit_milestone.close_dialog.title')"
      :content="$t('milestone.edit_milestone.close_dialog.title')"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmClick"
    />
    <RemoveTestRunDialog
      v-model="showConfirmRemoveTestRunDialog"
      :title="$t('milestone.close_remove_dialog.title')"
      :content="$t('milestone.close_remove_dialog.title')"
      :content_part2="$t('milestone.close_remove_dialog.content_part1')"
      :btn_label="$t('milestone.close_remove_dialog.remove_button')"
      @close="handleCloseClick"
      @handleConfirmClick="handleRemoveConfirmClick"
    />
  </div>
</template>


<script>
import { mapGetters, mapActions as projectMapAcions } from 'vuex';
import ProjectDiscardDialog from '@/components/Project/ProjectDiscardDialog.vue';
import MilestoneTestRunTable from '@/components/Milestone/MilestoneTestRunTable.vue';
import MileatoneTestPlanTable from '@/components/Milestone/MilestoneTestPlanTable.vue';
import RemoveTestRunDialog from '@/components/Milestone/MilestoneEditRemoveDialog.vue';
import makeMilestonesService from '@/services/api/milestone'
import makeConfigurationService from '@/services/api/configuration' 
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import RunFilter from '@/components/TestRuns/RunFilter.vue';  
import PlanFilter from '@/components/TestPlans/PlanFilter'
import { showSuccessToast } from '@/utils/toast';
import { dateMixin } from '@/mixins/date.js';
import handleLoading from '@/mixins/loader.js'
import makeRunService from '@/services/api/run'
import makePlanService from '@/services/api/plan'
import SearchIcon from '@/assets/svg/search-icon.svg';
import PencilIcon from '@/assets/svg/pencil.svg';
import makeTagService from '@/services/api/tag';
import { showErrorToast } from '@/utils/toast';
import dayjs from 'dayjs';
import Loader from '@/components/base/Loader'

let makeMilestoneService;
export default {
  name: 'MilestoneEditView',
  components: {
    ProjectDiscardDialog,
    MilestoneTestRunTable,
    MileatoneTestPlanTable,
    RemoveTestRunDialog,
    SearchIcon,
    PencilIcon,
    SettingsMenu,
    Loader,
    RunFilter,
    PlanFilter
  },
  mixins: [dateMixin, handleLoading],
  data() {
    return {
      loaderState: false,
      form: {
        projectName: '',
        description: '',
        users: [],
        dueDate: false,
      },
      selectedMilestone: {
        customFields: {
          test_runs: [],
          status: '',
          tags: [],
        }
      },
      showConfirmRemoveTestRunDialog: false,
      showConfirmBackDialog: false,
      testRuns: [],
      testPlans: [],
      selectedRuns: [],
      selectedPlans: [],
      tableFilter: 'all',
      tableTestType: 'plans',
      searchFilter: '',
      tags: [],
      runHeaders: [],
      planHeaders: [],
      milestones: [],
      configurations: [],
      appliedRunFilters: null,
      appliedPlanFilters: null,
      runItems: [],
      planItems: [],
      startDate: new Date().toISOString().substr(0, 10),
      dueDate: new Date().toISOString().substr(0, 10),
      isEditing: false,
      statuses: ['Active', 'Upcoming', 'Blocked'],
    };
  },
  computed: {
    ...mapGetters({
      dynamicHeaders: 'headers/dynamicHeaders'
    }),
    getActivePlanItems() {
      return this.planItems?.filter((plan) => !plan.archivedAt);
    },
    getActiveRunItems() {
      return this.runItems?.filter((run) => !run.archivedAt);
    },
    getActivePlanItemsCount() {
      return this.getActivePlanItems.length;
    },
    getActiveRunItemsCount() {
      return this.getActiveRunItems.length;
    },
    selectedTestType() {
      const testType = {
        plans: this.$t('testPlans'),
        runs: this.$t('testRuns')
      }
      return testType[this.tableTestType];
    },
    selectedTableMenu(){
      return this.tableTestType  == 'runs' ? 'milestoneEditRuns' : 'milestoneEditPlans';
    },
    selectedRunLength() {
      return this.selectedRuns.length;
    },
    selectedPlanLength() {
      return this.selectedPlans.length;
    },
    isTableTypePlans() {
      return this.tableTestType === 'plans';
    },
    filteredRunHeaders() {
      return this.runHeaders?.filter((header) => header.checked);
    },
    filteredPlanHeaders() {
      return this.planHeaders?.filter((header) => header.checked);
    },
    filteredMenuHeaders() {
      return this.runHeaders?.filter((header) => header.text != 'Actions');
    },
    filteredTestRuns() {
      return this.filteredItems(this.testRuns);
    },

    filteredTestPlans() {
      return this.filteredItems(this.testPlans);
    },

    filteredItems() {
      return (items) => {
        if (this.searchFilter && !this.appliedRunFilters && !this.appliedPlanFilters) {
          return items.filter(item => this.matchesFilter(item));
        } else if (this.tableTestType === 'runs' && this.appliedRunFilters && !this.searchFilter) {
          return items.filter(item => this.matchRunApplyFilter(item));
        } else if (this.tableTestType === 'runs' && this.appliedRunFilters && this.searchFilter) {
          const mainFiltered = items.filter(item => this.matchRunApplyFilter(item))
          return mainFiltered.filter(item => this.matchesFilter(item));
        }
        else if(this.tableTestType === 'plans' && this.appliedPlanFilters && !this.searchFilter){
          return items.filter(item => this.matchPlanApplyFilter(item))
        }
        else if(this.tableTestType === 'plans' && this.appliedPlanFilters && this.searchFilter){
          const mainFiltered = items.filter(item => this.matchPlanApplyFilter(item))
          return mainFiltered.filter(item => this.matchesFilter(item))
        }
        return items;
      };
    },
    validateDueDate() {
      return (value) => {
        if (new Date(value) < new Date(this.selectedMilestone.customFields.startDate)) {
          return false; 
        }
        return true;
      };
    },
    activeMilestones() {
      return this.milestones.filter((milestone) => milestone.archivedAt === null && milestone.deletedAt === null);
    },
  },
  watch: {
    currentOrg: 'refreshData',
    selectedMilestone(newValue) {
      if (newValue) {
        this.dueDate = this.formatDate(newValue.dueAt, 'YYYY-MM-DD')
      }
    },
  },
  async created() {
    if(!this.dynamicHeaders.milestoneEditRuns) {
      this.initializeHeaders({ type: 'milestoneEditRuns' });
    }
    this.runHeaders = this.dynamicHeaders.milestoneEditRuns;

    if(!this.dynamicHeaders.milestoneEditPlans) {
      this.initializeHeaders({ type: 'milestoneEditPlans' });
    }
    this.planHeaders = this.dynamicHeaders.milestoneEditPlans;
    makeMilestoneService = makeMilestonesService(this.$api);
  },
  async mounted(){
    const handle = this.$route.params.handle;
    await this.init([
      this.findMilestone(handle),
      this.getTags(),
      this.getAllTestRuns({ handle, projectKey: this.$route.params.key, perPage: 99999, currentPage: 0 }), 
      this.getAllTestPlans({ handle, projectKey: this.$route.params.key, perPage: 99999, currentPage: 0}),
    ]);

    this.testRuns = this.getActiveRunItems;
    this.testPlans = this.getActivePlanItems; 
    
    this.getMilestones();
    this.getConfigurations();
  
  },
  methods: {
    ...projectMapAcions("headers", ['initializeHeaders']),
    handleRemoveConfirmClick(){

    },
    async getAllTestRuns({handle, projectKey, perPage, currentPage}){
        const runService = makeRunService(this.$api);
        await runService.getTestRuns(handle, projectKey, perPage, currentPage).then(response => {
          this.runItems = response.data.runs;
        })
    },
    async getAllTestPlans({handle, projectKey, perPage, currentPage}){
      const planService = makePlanService(this.$api);
      await planService.getAllTestPlans(handle, projectKey, perPage, currentPage).then(response => {
        this.planItems = response.data.plans;
      })
    },
    editHeader() {
      this.isEditing = true;
    },
    saveHeader() {
      this.isEditing = false;
    },
    applyPlanFilters(filters){
      this.appliedPlanFilters = filters ?? null;
    },
    matchPlanApplyFilter(item){
      if (this.appliedPlanFilters?.panel_priority.length > 0 && 
        !this.appliedPlanFilters.panel_priority.includes(item.priority)) {
        return false;
      }
      if (this.appliedPlanFilters?.panel_status.length > 0 && 
        !this.appliedPlanFilters.panel_status.includes(item.status)) {
        return false;
      }
      if (this.appliedPlanFilters?.panel_tag.length > 0) {
        let tagExists = false
        for(const tag of this.appliedPlanFilters.panel_tag){
          if(item.customFields.tags.includes(tag)){
            tagExists = true
            break;
          }
        }
        if(!tagExists)
          return false
      }
      if (this.appliedPlanFilters?.dateRange.start && this.appliedPlanFilters?.dateRange.start > item.createdAt && 
        this.appliedPlanFilters?.dateRange.end && this.appliedPlanFilters?.dateRange.end < item.createdAt) {
        return false;
      }
      if (this.appliedPlanFilters?.testruns_range[0] > item.runs.length || 
        this.appliedPlanFilters?.testruns_range[1] < item.runs.length) {
        return false;
      }
      return true
    },
    applyRunFilters(filters){
        this.appliedRunFilters = filters ?? null;
    },
    matchRunApplyFilter(item) {
      if (this.appliedRunFilters?.panel_priority.length > 0 && 
        !this.appliedRunFilters.panel_priority.includes(item.priority)) {
        return false;
      }
      if (this.appliedRunFilters?.panel_status.length > 0 && 
        !this.appliedRunFilters.panel_status.includes(item.status)) {
        return false;
      }
      if (this.appliedRunFilters?.panel_milestone.length > 0 && 
        !this.appliedRunFilters.panel_milestone.includes(item.customFields.milestone) && 
        !this.appliedRunFilters.panel_milestone.includes('None')) {
        return false;
      }
      if (this.appliedRunFilters?.panel_tag.length > 0 && 
        !this.appliedRunFilters.panel_tag.includes(item.customFields.tags) && 
        !this.appliedRunFilters.panel_tag.includes('none')) {
        return false;
      }
      if (this.appliedRunFilters?.testcases_range[0] > item.testcases || 
        this.appliedRunFilters?.testcases_range[1] < item.testcases) {
        return false;
      }
      if (this.appliedRunFilters?.progress_range[0] > item.percentage || 
        this.appliedRunFilters?.progress_range[1] < item.percentage) {
        return false;
      }
      if (this.appliedRunFilters?.dateRange.start && this.appliedRunFilters?.dateRange.start > item.createdAt || 
        this.appliedRunFilters?.dateRange.end && this.appliedRunFilters?.dateRange.end < item.createdAt) {
        return false;
      }
      return true;
    },
    async updateMilestone() {
      if (!this.validateDueDate(this.dueDate)) return;

      const payload = {
        name: this.selectedMilestone.name,
        description: this.selectedMilestone.description,
        dueDate: dayjs(this.dueDate).startOf('day').utc().toISOString(),
        runIds: this.selectedRuns.map((e) => e.uid),
        planIds: this.selectedPlans.map((e) => e.uid),
        customFields: this.selectedMilestone.customFields
      };

      try {
        await makeMilestoneService.updateMilestone(this.$route.params.handle, this.$route.params.key, this.selectedMilestone.uid, payload);
        showSuccessToast(this.$swal, this.$t('milestone.update_milestone_success'));
        this.$router.push({
          name: 'Milestones',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
          },
        });
      } catch (err) {
        showErrorToast(this.$swal, this.$t('toast.updateError', { item: 'milestone' }));
        console.error('Failed to update milestone:', err);
      }


    },
    async getTags() {
      const handle = this.$route.params.handle;
      const tagService = makeTagService(this.$api);
        try {
          const response = await tagService.getTags(handle, 'milestones');
          this.tags = response.data
        } catch (err) {
          showErrorToast(this.$swal, 'fetchError', { item: 'tags' });
          return [];
        }
    }, 
    async getMilestones() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const milestoneService = makeMilestonesService(this.$api);
      try {
        const response = await milestoneService.getMilestones(handle, projectKey);
        this.milestones = response.data?.milestones;
        return response.data?.milestones;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'milestones' });
        return [];
      }
    },
    async getConfigurations() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const configurationService = makeConfigurationService(this.$api);
      try {
        const response = await configurationService.getConfigurations(handle, projectKey, 10, 0);
        this.configurations = response.data?.configurations;
        return response.data?.configurations;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'configurations' });
        return [];
      }
    },
    handleBackClick() {
      this.showConfirmBackDialog = true;
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({
        name: 'Milestones',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
        },
      });
    },
    changeFilter(filter) {
      this.tableFilter = filter;
    },
    changeTestType(type) {
      this.tableTestType = type;
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();

      return item.name.toLowerCase().includes(lowerCaseFilter);
    },
    async findMilestone(handle) {
      try {
        const response = await makeMilestoneService.findMilestone(handle, this.$route.params.key, this.$route.params.id);
        this.selectedMilestone = response.data;
        this.selectedPlans = response.data?.testPlans;
        this.selectedRuns = response.data?.testRuns;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'milestone' });
        console.error('Failed to fetch milestone:', err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.options-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}

.search_input {
  width: 100%;

  @media screen and (min-width: 600px) {
    width: 300px;
  }
}

.project-logo {
  border-radius: 50%;
  border: 2px dashed grey;
  width: 150px;
  height: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
}

.project-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}

.edit-icon,
.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.delete-icon {
  margin-left: 60px;
}

.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}

.title-edit-field {
  font-size: 34px;
}

.subdescription-edit-field {
  font-size: 17px;
  width: 600px;
}

.date-picker-field {
  width: 100px
}

.text-theme-label {
  color: #667085!important;
}

.select-custom-milestone {
  height: 40px;
  width: 130px;
  border-radius: 6px;
  color: #0C111D;
  padding-top: 5px;
  background-color: #F9FAFB;
}
</style>
