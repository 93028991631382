<template>
  <v-main>
    <AddTestRunView 
      v-if="showRuns"
      v-model="selectedRuns"
      :back-title="$t('plans.create.backToCreatePlans')"
      @back="showRuns = false" 
      @onFilterChange="tableFilter = $event"
    >
      <template #action>
        <div class="action-btn-wrapper pa-3">
          <div class="d-flex">
            <v-btn
              v-if="tableFilter === 'all'"
              class="font-inter text-capitalize fw-semibold text-theme-label rounded-lg"
              height="40px"
              depressed
              color="#F2F4F7"
              @click="handleAddTestRuns"
            >
              {{ $t('plans.createTestRun') }}
            </v-btn>

            <v-menu
              v-if="tableFilter === 'selected'"
              v-model="menuOpen"
              :close-on-content-click="false"
              offset-y
              top
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  depressed
                  background-color="#F2F4F7"
                  class="font-inter text-capitalize rounded-lg fw-semibold text-theme-label"
                  height="38px"
                  v-on="on"
                >
                  {{ $t('actions') }} <v-icon>{{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </template>

              <v-list class="actions-list font-inter">
                <v-list-item
                  class="actions-item text-left"
                  @click="handleAddTestRuns"
                >
                  <v-list-item-title>{{ $t('plans.createTestRun') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="actions-item text-left"
                >
                  <v-list-item-title>{{ $t('plans.create.testRuns.addConfiguration') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              class="font-inter text-capitalize fw-semibold rounded-lg white--text ml-3"
              height="40px"
              depressed
              color="blue"
              :loading="createButtonLoading"
              @click="createTestPlanAddRuns(selectedRuns)"
            >
              {{ $t('save') }}
            </v-btn>
          </div>
        </div>
      </template>
    </AddTestRunView>
    <v-card
      v-else
      class="test-plans-create py-6 px-6 mt-3 font-inter app-height-global"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-btn
        class="btn-back font-inter text-none"
        :to="{ name: 'TestPlans' }"
        depressed
        small
        plain
        @click="handleBackClick"
      >
        <v-icon color="blue">
          mdi-chevron-left
        </v-icon>
        <span> {{ $t('plans.create.backToPlans') }} </span>
      </v-btn>
      <v-form
        ref="planForm"
        class="flex-none mt-8"
        role="createPlanForm"
      >
        <v-row justify="center">
          <v-col
            md="4"
            lg="3"
          >
            <h1 class="text-start">
              {{ $t('plans.createTestPlan') }}
            </h1>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            md="4"
            lg="3"
          >
            <div>
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('milestone.create_milestone.milestone_name') }}<strong class="red--text text--lighten-1">*</strong> 
              </p>
              <v-text-field
                v-model="form.name"
                :rules="requiredRule"
                type="text"
                dense
                single-line
                filled
                placeholder="Name"
                class="rounded-lg"
                clear-icon="body-2"
                background-color="#F9F9FB"
              />
            </div>
            <div>
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('description') }}
              </p>
              <v-text-field
                v-model="form.description"
                type="text"
                dense
                single-line
                filled
                :label="$t('description')"
                class="rounded-lg"
                clear-icon="body-2"
                background-color="#F9F9FB"
              />
            </div>
            <div>
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('milestone.title') }}
                <strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-select
                v-model="form.milestone"
                :rules="requiredRulesForArrayType"
                type="text"
                dense
                single-line
                filled
                :label="$t('milestone.choose')"
                item-text="name"
                item-value="uid"
                :items="activeMilestones"
                class="rounded-lg mh-38px"
                clear-icon="body-2"
                background-color="#F9F9FB"
                :menu-props="{ offsetY: true }"
                append-icon="mdi-chevron-down"
                multiple
              >
                <template v-slot:selection="{ item }">
                  <v-tooltip
                    bottom
                    max-width="485px"
                    :disabled="item.name.length < 61"
                    content-class="tooltip-theme"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="d-flex align-center custom-chip-theme mr-1 mb-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <div class="text-theme-label label text-truncate mr-1">
                          {{ item.name }}
                        </div>
                        <v-icon
                          size="16px"
                          @click="onRemoveSelectedMilestone(item.uid)"
                        >
                          mdi-close
                        </v-icon>
                      </div>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-action>
                      <v-checkbox
                        hide-details
                        :input-value="milestoneSelection(item.uid)"
                        class="field-theme mt-0 pt-0"
                        :ripple="false"
                        off-icon="icon-checkbox-off"
                        on-icon="icon-checkbox-on"
                      >
                        <template v-slot:label>
                          <v-tooltip
                            bottom
                            max-width="485px"
                            :disabled="item.name.length < 61"
                            content-class="tooltip-theme"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                class="fs-14px text-theme-label text-truncate"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ item.name }}
                              </span>
                            </template>
                            <span>{{ item.name }}</span>
                          </v-tooltip>
                        </template>
                      </v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-select>
            </div>
            <div class="mb-6">
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('tags') }}
              </p>
              <v-select
                v-model="form.tags"
                type="text"
                dense
                single-line
                filled
                :label="$t('tags')"
                hide-details
                :items="tags"
                item-text="name"
                item-value="name"
                class="rounded-lg"
                clear-icon="body-2"
                background-color="#F9F9FB"
                :menu-props="{ offsetY: true }"
                append-icon="mdi-chevron-down"
                multiple
              >
                <template v-slot:selection="{ item }">
                  <div
                    class="d-flex align-center custom-chip-theme mr-1 mb-1"
                  >
                    <div class="text-theme-label label text-truncate mr-1">
                      {{ item.name }}
                    </div>
                    <v-icon
                      size="16px"
                      @click="onRemoveSelectedTags(item.name)"
                    >
                      mdi-close
                    </v-icon>
                  </div>
                </template>

                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-action>
                      <v-checkbox
                        hide-details
                        :input-value="tagsSelection(item.name)"
                        class="field-theme mt-0 pt-0"
                        :ripple="false"
                        off-icon="icon-checkbox-off"
                        on-icon="icon-checkbox-on"
                      >
                        <template v-slot:label>
                          <span class="fs-14px text-theme-label">{{ `${item.name}` }}</span>
                        </template>
                      </v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-select> 
            </div>
            <div>
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('status') }}
                <strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-select
                v-model="form.status"
                :rules="requiredRule"
                type="text"
                dense
                single-line
                filled
                :placeholder="$t('status')"
                :items="statuses"
                class="rounded-lg"
                clear-icon="body-2"
                background-color="#F9F9FB"
                item-text="name"
                item-value="id"
                :menu-props="{ offsetY: true }"
                append-icon="mdi-chevron-down"
                return-object
              />
            </div>
            <div>
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('priority') }}
                <strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-select
                v-model="form.priority"
                :rules="requiredRule"
                type="text"
                dense
                single-line
                filled
                :placeholder="$t('choosePriority')"
                :items="priorities"
                class="rounded-lg"
                clear-icon="body-2"
                background-color="#F9F9FB"
                item-text="name"
                item-value="id"
                :menu-props="{ offsetY: true }"
                append-icon="mdi-chevron-down"
                return-object
              />
            </div>
          </v-col>
        </v-row>
      </v-form>
      <div class="actions-container">
        <v-menu
          v-model="menuOpen"
          :close-on-content-click="false"
          offset-y
          top
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              depressed
              background-color="#F2F4F7"
              class="font-inter text-capitalize black--text mr-4 mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
              height="38px"
              v-on="on"
            >
              {{ $t('actions') }} <v-icon>{{ 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </template>

          <v-list class="actions-list font-inter">
            <v-list-item
              class="actions-item"
              @click="handleTestPlanDuplicate"
            >
              <v-list-item-title>{{ $t('plans.create.rerun') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              class="actions-item"
              @click="handleTestPlanAddRuns"
            >
              <v-list-item-title>{{ $t('plans.create.addTestRuns') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-if="!showAddUsersView || (showAddUsersView && !toBeSelectedUserLength)"
          dark
          depressed
          color="primary"
          class="mt-2 f-color-white btn-theme text-capitalize"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '180px'"
          height="38px"
          :loading="createButtonLoading"
          :class="{ 'disabled-action': createButtonLoading }"
          @click="createTestPlan"
        >
          {{ $t('save') }}
        </v-btn>
      </div>
      <confirm-dialog
        v-model="showConfirmBackDialog"
        :title="$t('plans.create.discussConfirmation.title')"
        :description="$t('plans.create.discussConfirmation.description')"
        :actions="[
          { label: $t('cancel'), color: 'secondary', action: 'close' },
          { label: $t('confirm'), color: 'primary', action: 'confirm' },
        ]"
        @close="showConfirmBackDialog = false"
        @confirm="handleConfirmClick"
      />
    </v-card>
  </v-main>
</template>

<script>
import { createNamespacedHelpers, mapGetters, mapState } from 'vuex';
import AddTestRunView from '@/views/Tests/Plans/AddTestRunView'
import ConfirmDialog from '@/views/Tests/Plans/Components/ConfirmDialog';
import makePlanService from '@/services/api/plan';
import makeMilestonesService from '@/services/api/milestone';
import makeTagsService from '@/services/api/tag';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import { requiredAndMax255FieldValidationRules, requiredFieldValidationRulesForArray, requiredFieldValidationRules } from "@/utils/validation";
import colorPreferencesMixin from '@/mixins/colorPreferences';

let planService;
let milestoneService;
let tagService;
export default {
  components: {
    ConfirmDialog,
    AddTestRunView
  },
  mixins: [colorPreferencesMixin],
  data() {
    return {
      planNameRule: requiredAndMax255FieldValidationRules(this),
      requiredRule: requiredFieldValidationRules(this),
      requiredRulesForArrayType: requiredFieldValidationRulesForArray(this),
      form: {
        name: '',
        description: '',
        milestone: [],
        tags: [],
        status: '',
        priority: '',
      },
      menuOpen: false,
      showConfirmBackDialog: false,
      showAddUsersView: false,
      toBeSelectedUser: [],
      selectedUser: [],
      createButtonLoading: false,
      tableFilter: 'all',
      selectedRuns: [],
      search: '',
      appliedFilters: {
        roles: [],
        projects: [],
        tags: [],
      },
      milestones: [],
      tags: [],
      showRuns: false,
      statuses: [],
      priorities: []
    };
  },
  created() {
    planService = makePlanService(this.$api);
    milestoneService = makeMilestonesService(this.$api);
    tagService = makeTagsService(this.$api);

    this.priorities = this.getPriorities("testPlan");
    this.statuses = this.getStatuses("testPlan");

  },
  computed: {
    ...mapState('user', ['currentAccount']),
    ...mapGetters({ currentOrg: 'user/currentAccount', }),
    toBeSelectedUserLength() {
      return this.users.filter((user) => user.toBeSelected).length;
    },
    activeMilestones() {
      return this.milestones.filter((milestone) => milestone.archivedAt === null && milestone.deletedAt === null);
    },
  },

  async mounted() {
    await this.getAllMileStones();
    await this.getAllTags();
  },

  methods: {
    // get test milestones
    async getAllMileStones() {
      try {
        const response = await milestoneService.getMilestones(
          this.$route.params.handle,
          this.$route.params.key
        );
        if (response.status === 200) {
          this.milestones = response.data.milestones;
        }
      } catch (err) {
        console.log(err)
      }
    },

		resetForm() {
      this.form = {
        name: '',
        description: '',
        milestone: [],
        tags: [],
        status: '',
        priority: '',
      };
    },

    onRemoveSelectedMilestone(uid) {
      const index = this.form.milestone.findIndex((item) => item === uid);
      if (index !== -1) {
        this.form.milestone.splice(index, 1);
      }
    },

    milestoneSelection(uid) {
      return this.form.milestone.some((item) => item === uid);
    },
    onRemoveSelectedTags(name) {
      const index = this.form.tags.findIndex((item) => item === name);
      if (index !== -1) {
        this.form.tags.splice(index, 1);
      }
    },
    tagsSelection(name) {
      return this.form.tags.some((item) => item === name);
    },
    async getAllTags() {
      try {
        const response = await tagService.getTags(
          this.$route.params.handle,
          'plans'
        );
        if (response.status === 200) {
          this.tags = response.data;
        }
      } catch (err) {
        console.log(err)
      }
    },

    handleBackClick() {
      this.showConfirmBackDialog = true;
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({ name: 'TestPlans' });
    },
    clearAll() {
      this.appliedFilters = {
        roles: [],
        projects: [],
        tags: [],
      };
    },
    applyFilters(filters) {
      this.appliedFilters = filters;
    },
    handleTestPlanAddRuns() {
      if (this.$refs.planForm.validate()) {
        this.showRuns = true;
        this.menuOpen = false;
      }
    },
    handleAddTestRuns() {
      this.$router.push({
        name: 'TestRunCreate',
        params: {
        handle: this.$route.params.handle,
        key: this.$route.params.key
        },
      });
    },
    handleTestPlanDuplicate() {
      const query = this.$route.query.activeAddMilestone ? { activeAddMilestone: 'true' } : {};
      this.$router.push({
        name: 'TestPlanDuplicate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
        },
        query: query
      });
    },

    async handleCreateTestPlan(runsList) {
      const runs = runsList.map(item => item.uid);
      const payload = this.generatePayload(runs);

      this.createButtonLoading = true;

      try {
        await planService.createTestPlan(
          this.$route.params.handle,
          this.$route.params.key,
          payload
        );
        this.$router.push({
          name: this.$route.query?.page || 'TestPlans',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
          },
        });
        showSuccessToast(this.$swal, this.$t('toast.addSuccess', { item: 'Test plan' }));
      } catch {
        showErrorToast(this.$swal, this.$t('toast.createError', { item: 'Test plan' }));
      } finally {
        this.createButtonLoading = false;
      }
    },

    generatePayload(runs) {
      return {
        name: this.form?.name,
        description: this.form.description,
        externalId: "1",
        source: "testfiesta",
        status: this.form.status?.id,
        milestoneUids: this.form.milestone,
        priority: this.form.priority?.id,
        testRunUids: runs || [],
        customFields: {
          tags: this.form.tags,
          configurations: 'Browsers',
          archived: false,
        },
      };
    },

    async createTestPlanAddRuns(runsList) {
      await this.handleCreateTestPlan(runsList);
    },

    async createTestPlan() {
      if (this.$refs.planForm.validate()) {
        const runsList = this.selectedRuns.map(item => item.uid);
        await this.handleCreateTestPlan(runsList);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.test-plans-create {
  background: #ffffff;
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #18181a;
  }
}

.btn-back {
  width: max-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0c2ff3;

  display: flex;
  align-items: center;
}

.actions-container {
  margin-top: auto;

  display: flex;
  justify-content: flex-end;

  button {
    height: 40px !important;
    text-transform: none;
  }
}

.actions-list {
  font-family: Inter;
  padding: 0;

  .actions-item {
    height: 36px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
