<template>
  <v-card
    class="white py-4 px-6 mb-3"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div
      v-if="isDuplicate"
      class="d-flex flex-row align-baseline justify-space-between"
    >
      <div class="d-flex flex-column">
        <div
          class="d-flex flex-row mb-2"
          @click="handleBackClick"
        >
          <v-icon color="blue">
            mdi-chevron-left
          </v-icon>
          <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
            {{ $t('testruns.create_testrun.back_to_create_testrun') }}
          </p>
        </div>
        <h2>{{ $t('testruns.create_testrun.rerunTestRun') }}</h2>
      </div>
      <v-col
        cols="auto"
        class="pointer"
        @click="closeShowAddUsersView"
      >
        <v-icon>mdi-close</v-icon>
      </v-col>
    </div>
    <div
      v-else
      class="d-flex flex-row align-center justify-space-between"
    >
      <h2 class="text-theme-base">
        {{ $t(title) }}
      </h2>
      <v-btn
        v-if="btn_show && writeEntity"
        color="primary"
        depressed
        :class="{
          'btn-theme rounded-lg': true,
          'disabled-action': isProjectArchived
        }"
        height="40"
        @click="navigateToTestRunCreate"
      >
        {{ $t(actionText) }}
        <v-icon
          class="ml-3"
          size="20"
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </div>
    <div class="d-flex align-center justify-start ml-0 py-4">
      <v-chip
        :class="{ 'blue--text': filter === 'ongoing' }"
        width="115px"
        :color="filter === 'ongoing' ? '#e6ecff' : '#f9fafb'"
        label
        @click="updateFilter('ongoing')"
      >
        <div class="font-weight-bold px-2">
          {{ active_label }} <span class="ml-2">{{ ongoingItemCount }}</span>
        </div>
      </v-chip>
      <div class="ml-2">
        <v-chip
          :class="{ 'blue--text': filter === 'archived' }"
          width="115px"
          :color="filter === 'archived' ? '#e6ecff' : '#f9fafb'"
          label
          @click="updateFilter('archived')"
        >
          <div class="font-weight-bold px-2">
            {{ archived_label }} <span class="ml-2">{{ archivedItemCount }}</span>
          </div>
        </v-chip>
      </div>
    </div>
    <slot name="additional-actions" />
    <ProjectDiscardDialog
      v-model="showConfirmBackDialog"
      :title="$t('testruns.create_testrun.close_dialog.title')"
      @close="handleCloseClick"
      @handleConfirmClick="closeShowAddUsersView"
    />
  </v-card>
</template>

<script>
import ProjectDiscardDialog from '@/components/Project/ProjectDiscardDialog.vue';
import projectStatus from '@/mixins/projectStatus';

export default {
  name: 'RunHeader',
  components: {
    ProjectDiscardDialog,
  },
  mixins: [projectStatus],
  props: {
    isDuplicate: Boolean,
    title: String,
    actionText: String,
    filter: String,
    active_label: String,
    archived_label: String,
    btn_show: Boolean,
    ongoingItemCount: Number,
    archivedItemCount: Number,
    writeEntity: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showConfirmBackDialog: false,
    };
  },

  methods: {
    updateFilter(filter) {
      this.$emit('update-filter', filter);
    },
    handleBackClick() {
      this.showConfirmBackDialog = true;
    },
    closeShowAddUsersView() {
      this.$router.replace({ name: 'TestRunCreate' });
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    navigateToTestRunCreate() {
    if (!this.isProjectArchived) {
      this.$router.push({ name: 'TestRunCreate' });
    }
  },
  }
};
</script>

<style scoped>
.horizontal-margin {
  margin: 0px 10px;
}

.tray-btn-margin {
  min-width: 40px !important;
  width: 40px !important;
  padding: 10px 0px !important;
}

.tray-btn-outline {
  border-radius: 8px;
  box-shadow: none;
}
</style>