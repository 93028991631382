<template>
  <v-dialog
    v-model="showModal"
    max-width="500"
  >
    <v-card id="upload-avatar-modal">
      <v-card-title>{{ $t('changeAvatar') }}</v-card-title>
      <v-card-text>
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit }"
        >
          <v-form
            role="changeAvatarForm"
            @submit.prevent="handleSubmit(updateAvatar)"
          >
            <div>
              <cropper
                v-if="image.src"
                ref="cropper"
                class="cropper"
                stencil-component="circle-stencil"
                :src="image.src"
              />
              <div
                v-else
                style="height: 300px; width: 100%"
              >
                <h4>Select image</h4>
              </div>
            </div>
            <div>
              <v-btn
                class="mr-8"
                :disabled="loading"
                @click="hiddenModalUpload"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                class="mr-8"
                :disabled="loading"
                color="success"
                @click="$refs.file.click()"
              >
                <input
                  id="upload-avatar-input-file"
                  ref="file"
                  type="file"
                  hidden
                  :accept="`${profileImageTypes.join(', ')}`"
                  @change="uploadImage($event)"
                >
                {{ $t('chooseImage') }}
              </v-btn>
              <v-btn
                :disabled="loading"
                color="primary"
                type="submit"
              >
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  size="24"
                  color="white"
                />
                {{ $t('save') }}
              </v-btn>
            </div>
          </v-form>
        </ValidationObserver>
      </v-card-text>
    </v-card>
    <template v-slot:activator="{ on }">
      <v-btn
        id="upload-avatar-change-avatar-btn"
        outlined
        color="secondary"
        icon
        v-on="on"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
  </v-dialog>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import { mapActions } from 'vuex'
import 'vue-advanced-cropper/dist/style.css';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapMutations } = createNamespacedHelpers('user');
import { showErrorToast } from '@/utils/toast';
import fileValidator from '@/mixins/fileValidator.js'
import { profileImageTypes } from '@/constants/fileTypes.js'
import makeOrgService from '@/services/api/org'
import makeUserService from '@/services/api/user'
export default {
  components: {
    Cropper,
  },
  mixins: [fileValidator],
  props: {
    isOrg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profileImageTypes,
      showModal: false,
      loading: false,
      imageFile: null, // To store the selected image file
      image: {
        src: null,
        type: null,
      },
    };
  },
  computed: {
    ...mapState({ currentUser: 'user' }),
  },
  methods: {
    ...mapMutations(['updateUser']),
    ...mapActions({
      uploadToServer: 'attachment/uploadToServer',
      setUser: 'user/setUser',
    }),
    async updateAvatar() {
      if (!this.imageFile) return;
      this.loading = true;

      try {
        // Get the result from the cropper
        const result = this.$refs.cropper.getResult();
        const dataUrl = result.canvas.toDataURL(this.imageFile.type);

        // Convert DataURL to Blob
        let response = await fetch(dataUrl);
        const blob = await response.blob();
        const fileUpload = new File([blob], this.imageFile.name, { type: this.imageFile.type });

        const handle = this.$store.getters['user/currentAccount'].handle;
        const mediaType = 'profile-picture';
        const apiService = this.isOrg ? makeOrgService(this.$api) : makeUserService(this.$api);
        const params = {
          handle
        }
        const objectUrl = await this.uploadToServer({mediaType,file: fileUpload, apiService, params});
        if (this.isOrg)
          this.$emit('uploaded', objectUrl);
        else
          this.updateUser({avatarUrl: objectUrl})

        // Show success notification
        this.showSuccessNotification();
      } catch (error) {
        // Handle error
        this.showErrorNotification(error);
      } finally {
        this.loading = false;
        this.hiddenModalUpload();
      }
    },
    async finalizeUpload(userService, avatarUrl) {
      if (this.isOrg) {
        this.$emit('uploaded', avatarUrl);
      } else {
        const response = await userService.updateProfile({ ...this.currentUser, avatar_url: avatarUrl });
        this.updateUser(response.data.user); // Assuming response contains the updated user data
      }
    },
    uploadImage(event) {
      const files = Array.from(event.target.files);

      const validationResult = this.validateMimeTypes(files, profileImageTypes);

      if (!validationResult.valid) {
        showErrorToast(this.$swal, this.$t('error.fileFormatNotSupported'));
      } else {
        this.imageFile = files[0];

        this.prepareImageForUpload();
      }
    },
    prepareImageForUpload() {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image.src = e.target.result;
      };
      reader.readAsDataURL(this.imageFile);
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    },
    showModalUpload() {
      const self = this;
      this.toDataUrl(this.currentUser.avatar_url, function (myBase64) {
        const type = myBase64.split(';')[0].split(':')[1];
        self.image.type = type;
      });
      this.image.src = this.currentUser.avatar_url;
      this.showModal = true;
    },
    showSuccessNotification() {
      this.$swal({
        title: this.$t('avatarChanged'),
        icon: 'success',
        showConfirmButton: false,
        position: 'bottom-right',
        timer: 2000,
        toast: true,
      });
    },
    showErrorNotification(error) {
      console.error(error);
      const errorMessage = error.response?.data?.error || this.$t('error');
      this.$swal({
        title: errorMessage,
        icon: 'error',
        showConfirmButton: false,
        position: 'bottom-right',
        timer: 2000,
        toast: true,
      });
    },
    hiddenModalUpload() {
      this.showModal = false;
      this.loading = false;
      this.image = { src: null, type: null };

      if (this.$refs.observer) {
        this.$refs.observer.reset();
      }
    },
  },
  destroyed() {
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  },
};
</script>

<style lang="scss">
.cropper {
  height: 300px;
  width: 100%;
}
</style>
