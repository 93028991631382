<template>
  <v-dialog
    v-model="showDialog"
    class="test-cases-filter-drawer dialog-theme"
    transition="slide-x-transition"
    attach
    fullscreen
    width="485px"
  >
    <v-overlay
      :value="loading"
      absolute
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>

    <v-card :disabled="loading">
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ $t('defect.editDefect') }}
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
          class="mt-10"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('name') }}
              </div>
              <v-text-field
                v-model="defect.name"
                class="round-8 field-theme"
                background-color="#F9F9FB"
                dense
                height="38px"
                :placeholder="$t('name')"
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('priority') }}
              </div>
              <v-select
                v-model="defect.priority"
                :items="priorities"
                dense
                item-text="name"
                item-value="id"
                append-icon="mdi-chevron-down"
                class="rounded-lg field-theme custom-prepend"
                height="38px"
                background-color="#F9F9FB"
                :menu-props="{ offsetY: true }"
                clearable
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('status') }}
              </div>
              <v-select
                v-model="defect.status"
                :items="statuses"
                dense
                item-text="name"
                item-value="id"
                append-icon="mdi-chevron-down"
                class="rounded-lg field-theme custom-prepend"
                height="38px"
                background-color="#F9F9FB"
                :menu-props="{ offsetY: true }"
                clearable
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <div class="actions-container d-flex justify-space-between">
      <v-btn
        width="204.5px"
        color="#F2F4F7"
        height="40"
        :depressed="true"
        class="text-capitalize btn-theme"
        elevation="0"
        @click="$emit('close-dialog')"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        width="204.5px"
        class="btn-theme"
        height="40"
        color="primary"
        :depressed="true"
        elevation="0"
        :disabled="!validForm"
        @click="onEdit()"
      >
        {{ $t('defect.sendUpdate') }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'UpdateDefect',
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: () => ({})
    },

    priorities: {
      type: Array,
      default: () => []
    },

    statuses: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      validForm: true,
      defect: {
        uid: '',
        name: '',
        priority: '',
        status: '',
      },
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    }
  },

  watch: {
    showDialog(value) {
      if (!value) return;
      
      this.defect = {
        uid: this.data.uid || '',
        name: this.data.name || '',
        priority: this.data.priority || '',
        status: this.data.status || ''
      };
    }
  },
  methods: {
    onEdit() {
      const isValidForm = this.$refs.form.validate()
      if (!isValidForm) {
        return
      }

      const payload = {
        uid: this.defect.uid,
        name: this.defect.name,
        ...(this.defect.priority && { priority: this.defect.priority }),
        ...(this.defect.status && { status: this.defect.status })
      }

      this.$emit('edit', payload)
    },
    getPriorityColor(priorityId) {
      const priority = this.priorities.find(p => p.id === priorityId);
      return priority?.color || '#0c111d';
    },

    getPriorityName(priorityId) {
      const priority = this.priorities.find(p => p.id === priorityId);
      return priority?.name || '';
    },

    getStatusColor(statusId) {
      const status = this.statuses.find(s => s.id === statusId);
      return status?.color || '#0c111d';
    },

    getStatusName(statusId) {
      const status = this.statuses.find(s => s.id === statusId);
      return status?.name || '';
    },

    getDefaultPriority() {
      return this.priorities.find(p => p.isDefault)?.id;
    },

    getCompletedStatuses() {
      return this.statuses.filter(s => s.isCompleted).map(s => s.id);
    },

    getPriorityByName(name) {
      const normalizedName = name?.toLowerCase()?.replace(/\s+/g, '');
      return this.priorities.find(
        p => p.name?.toLowerCase()?.replace(/\s+/g, '') === normalizedName
      );
    },

    getStatusByName(name) {
      const normalizedName = name?.toLowerCase()?.replace(/\s+/g, '');
      return this.statuses.find(
        s => s.name?.toLowerCase()?.replace(/\s+/g, '') === normalizedName
      );
    }

  }
}
</script>