export default function makeDashboardService(api){
  return {
    getDashboard: async function(handle, id, payload){
      return api.get(`/${handle}/dashboard/${id ?? ''}`, {
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
        params:{
          ...payload
        }
      })
    },
    updateDashboard(handle, id,payload){
      return api.patch(`/${handle}/dashboard/${id ?? ''}`, payload, {
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
      })

    }
  }
}