<template>
  <div
    id="case-index-container"
    class="d-flex flex-column align-stretch font-inter"
  >
    <v-row>
      <v-col class="pb-1">
        <v-card
          class="py-6 px-6"
          rounded="lg"
          elevation="0"
          width="100%"
        >
          <v-row v-if="caseType != 'EditCase'">
            <v-col flex>
              <div
                class="back-to-projects"
                @click="handleBackClick"
              >
                <v-icon color="blue">
                  mdi-chevron-left
                </v-icon>
                <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
                  {{ backTitle }}
                </p>
              </div>
            </v-col>
            <v-col
              cols="auto"
              class="pointer"
              @click="handleCloseClick"
            >
              <v-icon>mdi-close</v-icon>
            </v-col>
          </v-row>
          <v-row class="pt-0">
            <v-col
              flex
              class="pt-0"
            >
              <p
                v-if="caseType == 'EditCase'"
                class="text-start font-weight-bold mb-2 fs-24px"
              >
                {{ $t('testruns.testCases') }}
              </p>
              <p
                v-else
                class="text-start font-weight-bold mb-2 fs-24px"
              >
                {{ $t('testruns.addtestcases') }}
              </p>
              <div class="mt-6 d-flex">
                <v-chip
                  :class="{ 'blue--text': !tableFilter }"
                  width="200px"
                  :color="!tableFilter ? 'blue-light' : 'gray-light'"
                  label
                  @click="changeFilter(false)"
                >
                  <div class="font-weight-bold px-2">
                    {{ $t('all') }} <span class="ml-2">{{ casesCount }}</span>
                  </div>
                </v-chip>
                <div class="ml-2">
                  <v-chip
                    :class="{ 'blue--text': tableFilter }"
                    width="200px"
                    :color="tableFilter ? 'blue-light' : 'gray-light'"
                    label
                    @click="changeFilter(true)"
                  >
                    <div class="font-weight-bold px-2">
                      {{ $t('selected') }} <span class="ml-2">{{ selectedCaseCount }}</span>
                    </div>
                  </v-chip>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <CaseManagement
      :show-collapse="false"
      :show-create="false"
      :select-option="tableFilter"
      :quick-create="false"
      :allow-action="false"
      :cases="cases"
      :folders="folders"
      :run-cases="value"
      :from-run="true"
      :set-selected-folder-uid.sync="folderUid"
      @refresh-folders="getFolders"
      @selectedCases="handleCases"
      @updateCaseCount="UpdateCases"
      @folder-select="getCases"
      @update-cases="updateCases"
    >
      <template slot="control-area">
        <div
          class="action-btn-wrapper pa-3"
        >
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn
                depressed
                height="40"
                class="fw-semibold black--text btn-theme mr-3"
                color="#F2F4F7"
                @click="onAddTestCase"
              >
                <v-icon
                  class="mr-1"
                  size="16"
                >
                  mdi-plus
                </v-icon> {{ $t('createNewTestCase') }} 
              </v-btn>
              <slot name="action" />
            </v-col>
          </v-row>
        </div>
      </template>
    </CaseManagement>
    
    <RunDiscardDialog
      v-model="showAddDialog"
      :title="ConfirmDialog_Title"
      :content="ConfirmDialog_Content"
      :content_part2="ConfirmDialog_Contentpart_two"
      :run_name="ConfirmDialog_RunName"
      :btn_label="ConfirmDialog_btn_label"
      :color="ConfirmDialog_btn_color"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmBtnClick(ConfirmType)"
    />
  </div>
</template>

<script>
import RunDiscardDialog from '@/components/TestRuns/RunDiscardDialog.vue';
import CaseManagement from '@/components/Cases/CaseManagement'
import makeRunService from '@/services/api/run';
import makeCasesService from '@/services/api/case';
import { createNamespacedHelpers } from 'vuex';
import { showErrorToast, showSuccessToast } from '@/utils/toast';
import makeFoldersService from '@/services/api/folder';
const { mapState } = createNamespacedHelpers('user');

let runsService;

export default {
  name: 'TestRunCreate',
  components: {
    CaseManagement,
    RunDiscardDialog,
  },
  props: {
    value: {
      type: Array,
    },
    customCases: {
      type: Array,
    },
    caseType: {
      type: String,
    },
    backTitle: {
      type: String,
    },
  },
  data() {
    return {
      showConfirmBackDialog: false,
      showAddDialog: false,
      tableFilter: false,
      cases: [],
      folders: [],
      selectedCases: this.value,
      isColumnFilter: false,
      isLoading: false,
      ConfirmDialog_Title: '',
      ConfirmDialog_Content: '',
      ConfirmDialog_Contentpart_two: '',
      ConfirmDialog_btn_label: '',
      ConfirmDialog_btn_color: 'primary',
      ConfirmDialog_RunName: '',
      ConfirmType: '',
      casesCount: 0,
      selectedCaseCount: 0,
      drawer: false,
      selectedCase: null,
      folderUid: null,
    };
  },
  computed: {
    ...mapState(['currentAccount']),
    anySelectedCases() {
      return this.selectedCases.length > 0;
    },
  },
  created() {
    runsService = makeRunService(this.$api);
  },
  mounted() {
    this.getFolders(
      this.$route.params.handle,
      this.$route.params.key
    )
    this.cases.forEach((cse) => {
      this.$set(cse, 'toBeSelected', false);
    });
    this.selectedCaseCount = this.selectedCases?.length ?? 0;
  },
  methods: {
    selectDefaultFolder() {
      if (this.folders && this.folders.length > 0 && !this.$route.params.folderUid) {
        const initialSelectedFolder = this.folders[0].uid
        this.folderUid = initialSelectedFolder;
        this.getCases(initialSelectedFolder);
      }
    },
    async getCases(folderUID) {
      if(!folderUID) return;
      const caseService = makeCasesService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      await caseService.getFolderCases(handle, projectKey, folderUID).then(response => {
        this.cases = response.data.cases;
        this.casesCount = this.cases.length;
      }).catch(err => {
          showErrorToast(this.$swal, 'fetchError', { item: 'test cases folders' });
      })
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    handleConfirmBtnClick(type) {
      this.showAddDialog = false;

      if (type == 'cancel')
        this.$router.replace({
          name: 'Runs',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key
          }
        });
    },
    handleCases(cases) {
      this.selectedCases = cases;
      this.$emit('input', this.selectedCases)
    },
    async getFolders(handle, projectKey) {
      const folderService = makeFoldersService(this.$api);
      await folderService.getProjectFolders(handle, projectKey).then(response => {
        this.folders = response.data.folders;
        this.selectDefaultFolder();
      }).catch(() => {
        showErrorToast(this.$swal, this.$t("test_folder.refresh_failed"));
      })
    },
    UpdateCases(selectedCount, casesCount) {
      this.selectedCaseCount = selectedCount;
      this.casesCount = casesCount;
    },
    handleBackClick() {
      this.$emit('back')
    },
    handleAddClick(type) {
      if (type == 'add') {
        this.ConfirmDialog_Title = this.$t('testruns.test_case.addcase.title');
        this.ConfirmDialog_Content = this.$t('testruns.test_case.addcase.content');
        this.ConfirmDialog_Contentpart_two = '';
        this.ConfirmDialog_btn_label = this.$t('add');
        this.ConfirmDialog_btn_color = 'primary';
        this.ConfirmDialog_RunName = '';
        this.ConfirmType = 'add';
        this.showAddDialog = true;
      } else if (type == 'remove') {
        this.ConfirmDialog_Title = this.$t('testruns.test_case.removecase.title');
        this.ConfirmDialog_Content = this.$t('testruns.test_case.removecase.content');
        this.ConfirmDialog_Contentpart_two = '';
        this.ConfirmDialog_btn_label = this.$t('remove');
        this.ConfirmDialog_btn_color = 'danger';
        this.ConfirmDialog_RunName = '';
        this.ConfirmType = 'remove';
        this.showAddDialog = true;
      } else if (type == 'cancel') {
        this.ConfirmDialog_Title = this.$t('testruns.edit_testrun.title');
        this.ConfirmDialog_Content = this.$t('testruns.edit_testrun.content');
        this.ConfirmDialog_Contentpart_two = '';
        this.ConfirmDialog_btn_label = this.$t('testruns.edit_testrun.btn_label');
        this.ConfirmDialog_btn_color = 'primary';
        this.ConfirmDialog_RunName = '';
        this.ConfirmType = 'cancel';
        this.showAddDialog = true;
      }
    },
    onAddTestCase() {
      this.$router.push({
        name: 'CreateTestCases',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
        },
        query: {
          redirectTo: 'TestRunEdit',
          runId: this.$route.params.id,
          folderUid: this.$route.params.folderUid,
        },
      });
    },
    handleCloseClick() {
      this.$emit('close')
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({
        name: 'TestRunCreate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      });
    },
    changeFilter(filter) {
      this.tableFilter = filter;
    },
    updateCases(newCases) {
      this.cases = newCases;
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}

.search_input {
  width: 100%;

  @media screen and (min-width: 600px) {
    width: 300px;
  }
}

.project-logo {
  border-radius: 50%;
  border: 2px dashed grey;
  width: 150px;
  height: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
}

.project-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}

.edit-icon,
.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.delete-icon {
  margin-left: 60px;
}

.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}
.action-btn-wrapper {
  position: sticky;
    bottom: 0;
    background-color: white;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    z-index: 8;
}
</style>
