<template>
  <v-menu
    content-class="custom_ele rounded-lg shadow-theme menu-options"
    nudge-bottom="50"
    min-width="191"
    :close-on-content-click="false"
    left
  >
    <template v-slot:activator="{ on }">
      <v-sheet
        color="#F2F4F7"
        width="40px"
        class="d-flex align-center justify-center cursor-pointer v-sheet-theme"
        height="40px"
        rounded="lg"
        :outlined="true"
        v-on="on"
      >
        <GearIcon />
      </v-sheet>
    </template>
    <v-list :flat="true">
      <h4 class="font-weight-regular text-start ml-4 mb-4 mt-2">
        {{ $t('columns') }}
      </h4>
      <template v-for="(item, index) in filteredMenuHeaders">      
        <v-list-item 
          v-if="item.text" 
          :key="index" 
          dense
        >
          <v-list-item-action start>
            <v-checkbox 
              v-model="item.checked" 
              :value="item.checked" 
              class="field-theme"
              :ripple="false"
              off-icon="icon-checkbox-off"
              on-icon="icon-checkbox-on"
              @change="handleItemChange"
            >
              <template v-slot:label>
                <span class="fs-14 text-theme-label">{{ item.text }}</span>
              </template>
            </v-checkbox>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-list-item
        class="pl-4 mt-2"
        :ripple="false"
        :class="{ 'primary--text': true }"
        @click="restore"
      >
        {{ $t('restoreDefault') }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GearIcon from '@/assets/svg/gear.svg';

export default {
  components: {
    GearIcon
  },
  props: {
    tableType: {
      type: String,
      required: true
    },
    requiredItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filterItems: [],
    };
  },
  computed: {
    ...mapGetters({
      dynamicHeaders: 'headers/dynamicHeaders'
    }),
    filteredMenuHeaders() {
      return this.filterItems.filter((header) => header.value != 'actions');
    },
    hasUnchecked() {
      return this.filterItems.some(item => !item.checked);
    },
  },
  created() {
    if (!this.dynamicHeaders[this.tableType]) {
      this.initializeHeaders({ type: this.tableType });
    }
    this.filterItems = this.dynamicHeaders[this.tableType];
    this.updateHeaders({ type: this.tableType, headers: this.filterItems });
  },
  methods: {
    ...mapActions('headers', ['updateHeaders', 'resetHeaders', 'initializeHeaders']),
    handleItemChange() {
      this.updateHeaders({ type: this.tableType, headers: this.filterItems });
    },
    restore() {
      const updatedHeaders = this.filterItems.map(item => ({
        ...item,
        checked: true
      }));
      
      this.filterItems = [...updatedHeaders];
      this.updateHeaders({ 
        type: this.tableType, 
        headers: updatedHeaders
      });
    },
  }
};
</script>

<style scoped>
.menu-options {
  z-index: 19 !important;
}
.v-list-item {
  min-height: 32px !important;
}
.v-list-item__action {
  margin: 4px 0 !important;
}
.v-input--selection-controls {
  margin: 0 !important;
  padding: 0 !important;
}
.primary--text {
  color: var(--v-primary-base) !important;
}
</style> 