import { openDB, wrap } from 'idb/with-async-ittr';
import dayjs from 'dayjs';
import { DB_NAME } from '@/constants/dashboardConstants';

function applyFilters(record, filters) {
  for (const filter of filters) {
    console.log(filter);
  }
  return true; // CTODO
}

//function insertSorted(array, value) {
//  let low = 0,
//      high = array.length;
//
//  while (low < high) {
//    // Split it in half with bitwise operators
//    let mid = (low + high) >>> 1;
//    if (array[mid] < value) low = mid + 1;
//    else high = mid;
//  }
//
//  array.splice(low, 0, value);
//
//  return array;
//}

// CTODO - handle Z results
function applyTreatment(data, primaryTreatment, secondaryTreatment, filtersCount) {
  // Count, plot, or sum
  // over time, number, or category
  let intermediateData = {};
  //let intermediateOrder = [];

  if (primaryTreatment === 'count') {
    for (const value of Object.values(data)) {
      if (value.filters && value.filters.length === filtersCount) {
        for (const xVal of value.x_result) {
          intermediateData[xVal] = intermediateData[xVal] ?? 0;
          // Sometimes we 
          intermediateData[xVal] += (value.y_result.length === 0 ? 1 :
            value.y_result.length);
          //if (secondaryTreatment === 'number') {
          //  intermediateOrder = insertSorted(intermediateOrder, xVal);
          if (secondaryTreatment == 'time') {
            // Convert the key to something prettier.
            // TODO - Configure format and range.
            let newX = dayjs(xVal).format('MMM DD');
            if (intermediateData[newX]) {
              intermediateData[newX] = intermediateData[newX] +
                intermediateData[xVal];
            } else {
              intermediateData[newX] = intermediateData[xVal];
            }
            delete intermediateData[xVal];
            //intermediateOrder = insertSorted(intermediateOrder, newX);
            //} else {
            //  intermediateOrder.push(xVal);
          }
        }
      }
    }
  } else if (primaryTreatment === 'sum') {
    for (const value of Object.values(data)) {
      if (value.filters && value.filters.length === filtersCount) {
        for (const xVal of value.x_result) {
          intermediateData[xVal] = intermediateData[xVal] ?? 0;
          for (const yVal of value.y_result) {
            // CTODO - typeof === 'number'?  When do these get converted?
            if (!(isNaN(parseFloat(yVal)) && isFinite(yVal))) {
              intermediateData[xVal] += yVal;
            } // Else, ignore it
          }
          //if (secondaryTreatment === 'number') {
          //  intermediateOrder = insertSorted(intermediateOrder, xVal);
          //} else if (secondaryTreatment == 'time') {
          //  // Convert the key to unix time so we can use numerical sort.
          //  let newX = dayjs(xVal).unix();
          //  if (intermediateData[newX]) {
          //    intermediateData[newX] = intermediateData[newX] +
          //                             intermediateData[xVal];
          //  } else {
          //    intermediateData[newX] = intermediateData[xVal];
          //  }
          //  delete intermediateData[xVal];
          //  intermediateOrder = insertSorted(intermediateOrder, newX);
          //} else {
          //  intermediateOrder.push(xVal);
          //}
        }
      }
    }
  } else if (primaryTreatment === 'plot') {
    for (const value of Object.values(data)) {
      if (value.filters && value.filters.length === filtersCount) {
        for (const xVal of value.x_result) {
          intermediateData[xVal] = intermediateData[xVal] ?? [];
          intermediateData[xVal].push(...value.y_result);
          //if (secondaryTreatment === 'number') {
          //  intermediateOrder = insertSorted(intermediateOrder, xVal);
          //} else if (secondaryTreatment == 'time') {
          //  // Convert the key to unix time so we can use numerical sort.
          //  let newX = dayjs(xVal).unix();
          //  intermediateData[newX] = intermediateData[newX] +
          //                           intermediateData[xVal] ??
          //                           intermediateData[xVal];
          //  delete intermediateData[xVal];
          //  intermediateOrder = insertSorted(intermediateOrder, newX);
          //} else {
          //  intermediateOrder.push(xVal);
          //} 
        }
      }
    }
  } else {
    throw ('Invalid treatment type.');
  }

  let formattedData = [];
  for (const [xData, yData] of Object.entries(intermediateData)) {
    let newPoint = {};
    newPoint.x = xData;
    // CTODO - Could be a string in a sideways bar?  How to account above?
    if (typeof yData === 'number') {
      newPoint.y = yData;
      formattedData.push(newPoint);
    } else if (yData.constructor === Array) { // List
      for (const subY of yData) {
        let subPoint = Object.assign({}, newPoint);
        subPoint.y = subY;
        formattedData.push(subPoint);
      }
    }
  }

  return formattedData;
}

// CTODO
// Pass version to openDB (change name)  -- Can we avoid passing version entirely and allow
//   version should be YYMMDDHHXXXX          it to autoincrement?
// On typedata pull, increment and reopen
// Clear IDB on purposeful logout

export default function DashboardService(api) {
  return { // CTODO - move all idb to idb package to make cleaner
    updateIDB: async function (schema) {
      // CTODO - document "schema" schema
      const DB = await openDB(DB_NAME, undefined, {
        // CTODO - handle errors
        upgrade(db) {
          try {
            // If we pass types independently during a small update.
            if (schema.types) {
              for (const type of schema?.types) {
                if (!db.objectStoreNames.contains(type)) {
                  db.createObjectStore(
                    type,
                    { autoIncrement: true, keyPath: 'uid' }
                  );
                }
              }
            }
            // CTODO (maybe) - how to contemplate the 'source' of the orig data for
            //     indexes/refs????
            //     is this likely already handled by non-matching IDs? what about collisions?
            for (const [type, relationships] of Object.entries(schema.relationships)) {
              let objectStore;
              if (!db.objectStoreNames.contains(type)) {
                objectStore = db.createObjectStore(
                  type,
                  { autoIncrement: true, keyPath: 'uid' }
                );
              } else {
                objectStore = db.transaction(type).objectStore(type);
              }
              for (const relationship of relationships) {
                let newIndexName = relationship.direction
                  + '__' + relationship.primary_key
                  + '__' + relationship.secondary_table
                  + '__' + relationship.secondary_key
                  + '__idx';
                if (!objectStore.indexNames.contains(newIndexName)) {
                  objectStore.createIndex(
                    newIndexName,
                    relationship.primary_key,
                    { unique: false }
                  );
                }
              }
              // TODO - Can we DRY this?  It's the same as addData, but in the constructor so
              //        we can't just call the function.
              if (schema?.data?.[type] !== undefined) {
                for (const data of schema.data?.[type]) {
                  if (typeof data.custom_fields === 'string') {
                    data.custom_fields = JSON.parse(data.custom_fields);
                  } // TODO - handle this more programmatically?
                  objectStore.add(data);
                }
              }
            }
          } catch (err) {
            // TODO - Return a real error here.
            console.log(err);
          }
        },
      });
      return DB;
    },
    addData: async function (db, type, schemaData) {
      if (schemaData.length > 0) {
        let objectStore;
        if (!db.objectStoreNames.contains(type)) {
          db.close()
          db = await this.updateIDB({
            types: [type],
            data: schemaData
          });
        } else {
          objectStore = db.transaction(type, 'readwrite').objectStore(type);
          for (const data of schemaData) {
            objectStore.add(data);
          }
        }
      }
      return wrap(db);
    },
    getData: async function (db, type, key) { // CTODO - Remove old methods that aren't used

      return new Promise((resolve, reject) => {
        let transaction = db.transaction([type], 'readwrite');

        transaction.onerror = () => {
          reject();
        };

        let store = transaction.objectStore(type);
        store.get(key);

        resolve(store.result);
      });
    },
    getAllData: async function (db, type) {

      let transaction = db.transaction([type], 'readwrite');

      try {
        let results = await transaction.objectStore(type).getAll();
        return results;
      } catch (err) {
        return [];
      }
    },
    updateChart: async function (db, chartData) { // CTODO - clean up all functions and pass DB
      let returnData = [];
      for (const seriesData of chartData?.chartInfo?.series) {
        returnData.push(await this.pullSeriesData(db, seriesData)); // CTODO - put this back in context of its series to return the series rather than just raw data
      }
      return returnData; // CTODO - put this back in context of its series to return the series rather than just raw data
    },
    cloneDashboard: function (name, body, org) {
      return this.saveDashboard(name, body, org);
    },
    pullSeriesData: async function (db, seriesData) {

      let filters = {};
      let filtersCount = 0;
      let dataTypes = new Set();

      let primaryDimensionSplit = seriesData?.ref?.axises?.primary?.dimension.split('.') || '';
      let primaryDataType = primaryDimensionSplit[0];
      let primaryDataKey = primaryDimensionSplit[1]; // CTODO - handle further nested keys
      // CTODO - what if there is no nesting
      //         because we're counting?
      let primaryDataTreatment = seriesData?.ref?.axises?.primary?.treatment;
      if (primaryDataType && primaryDataType !== '') {
        dataTypes.add(primaryDataType);
      }
      let secondaryDimensionSplit = seriesData?.ref?.axises?.secondary?.dimension.split('.') || '';
      let secondaryDataType = secondaryDimensionSplit[0];
      let secondaryDataKey = secondaryDimensionSplit[1]; // CTODO - handle further nested keys
      let secondaryDataTreatment = seriesData?.ref?.axises?.secondary?.treatment;
      if (secondaryDataType && secondaryDataType !== '') {
        dataTypes.add(secondaryDataType);
      }
      let tertiaryDimensionSplit = seriesData?.ref?.axises?.tertiary?.dimension.split('.') || '';
      let tertiaryDataType = tertiaryDimensionSplit[0];
      let tertiaryDataKey = tertiaryDimensionSplit[1]; // CTODO - handle further nested keys
      //let tertiaryDataTreatment = seriesData?.ref?.axises?.tertiary?.treatment;
      // CTODO - can there be a treatment on tertiary or not?
      if (tertiaryDataType && tertiaryDataType !== '') {
        dataTypes.add(tertiaryDataType);
      }

      if (!primaryDataType || !secondaryDataType) {
        return; // CTODO
      }

      for (const filter of seriesData?.ref?.filters) {
        let filterType = filter.dimension?.split('.')[0];
        if (!filters[filterType]) {
          filters[filterType] = [];
        }
        filters[filterType].push(filter);
        filtersCount++;
        dataTypes.add(filterType);
      }

      let finalData = {};
      // Deterimine relationship heirarchy for dimensions AND filters - we only want to pull
      //   data that has matching relationship data

      dataTypes = [...dataTypes];
      let transaction = db.transaction([...dataTypes], 'readonly');
      let store;
      if (dataTypes.length === 1) {
        let type = dataTypes[0];
        store = transaction.objectStore(type);
        let results = await store.getAll();
        for (const record of results) {
          // ### CTODO - apply filters function
          if (applyFilters(record, (filters[type] || []))) {
            finalData[record.uid] = {
              y_result: [record[primaryDataKey]],
              x_result: [record[secondaryDataKey]],
              filters: [],
            };
            for (let f = 0; f < filtersCount; f++) {
              finalData[record.uid] = finalData[record.uid] ?? 0;
              finalData[record.uid].filters++;
            }
            if (tertiaryDataKey) {
              finalData[record.uid].z_result = [record[tertiaryDataKey]];
            }
          }
        }
      } else {
        // Not enough sleep - may god have mercy on your soul if you're debugging this.
        let type = primaryDataType;
        store = transaction.objectStore(type);
        let results = await store.getAll();
        let primaryTable = type;
        // CTODO - Does this cover the special case above where we only deal
        //         with one data type?
        for (let i = results.length - 1; i > -1; i--) {
          const value = results[i];
          if (applyFilters(value, (filters[primaryTable] || []))) {
            if (primaryDataType === primaryTable) { // CTODO - is this ever _not_ true? see above
              finalData[value.uid] = { // CTODO - no hardcoding 'uid'
                y_result: value[primaryDataKey],
              };
            }
            if (secondaryDataType === primaryTable) {
              finalData[value.uid].x_result = [value[secondaryDataKey]];
            } // CTODO - no hardcoding 'uid'
            if (tertiaryDataType === primaryTable) {
              finalData[value.uid].z_result = [value[tertiaryDataKey]];
            } // CTODO - no hardcoding 'uid'
            if (primaryDataType !== primaryTable && // CTODO - is this ever true?  see above
              secondaryDataType !== primaryTable &&
              tertiaryDataType !== primaryTable) {
              if (!finalData[value.uid].filters) { // CTODO - don't hardcode 'uid'
                finalData[value.uid].filters = 0; // CTODO - move this outside "if" so it always happens?
              }
              finalData[value.uid].filters++;
            }
          }
        }

        let indexes = [...store.indexNames]; // CTODO - is this better than passing them?
        for (const index of indexes) {
          // First pull all of the indexes so we can then compare
          let indexParts = index.split('__');
          const direction = indexParts[0];
          const primaryTable = type;
          const primaryKey = indexParts[1];
          const secondaryTable = indexParts[2];
          const secondaryKey = indexParts[3];

          // ### Change the naming here - this isn't just secondary tables, but also tertiary and
          // ### filters
          if (dataTypes.includes(secondaryTable)) {
            let secondStore = transaction.objectStore(secondaryTable);
            let reversedDirection = (direction === "in" ? "out" : "in")
            let indexName = reversedDirection // TODO - Use function to generate
              + '__' + secondaryKey
              + '__' + primaryTable
              + '__' + primaryKey
              + '__idx';

            for await (const cursor of store.index(index)) {
              // CTODO - we are parsing here because external_id is an int - handle better (v)
              let secondResults = await secondStore.index(indexName).getAll(parseInt(cursor.key));
              for await (const secondResult of secondResults) {
                if (secondResult) {
                  if (applyFilters(secondResult, (filters[secondaryTable] || []))) {

                    // Direclty associate x and z values with primary keys in cursor.result
                    if (!finalData[cursor.primaryKey]) {
                      finalData[cursor.primaryKey] = {};
                    }
                    if (secondaryDataType === secondaryTable) {
                      if (!finalData[cursor.primaryKey].x_result) {
                        finalData[cursor.primaryKey].x_result = [];
                      }
                      finalData[cursor.primaryKey].x_result.push(secondResult[secondaryDataKey]);
                    }
                    if (tertiaryDataType === secondaryTable) {
                      if (!finalData[cursor.primaryKey].z_result) {
                        finalData[cursor.primaryKey].z_result = [];
                      }
                      finalData[cursor.primaryKey].z_result.push(secondResult[tertiaryDataKey]);
                    }
                    if (secondaryDataType !== secondaryTable &&
                      tertiaryDataType !== secondaryTable) {
                      // This is just a filter then.
                      if (!finalData[cursor.primaryKey].filters) {
                        finalData[cursor.primaryKey].filters = 0;
                      }
                      finalData[cursor.primaryKey].filters++;
                    }
                  }
                }
              }
            }
          }
        }
      }
      return applyTreatment(
        finalData,
        primaryDataTreatment,
        secondaryDataTreatment,
        filtersCount,
      );
    },
    saveDashboard: function (name, body, org, uid) {
      let nUID = uid || '';
      return api.patch(`dashboards/${nUID}?org=${org}`, { body, name });
    },
    getDashboardList: function (org) {
      return api.get(`orgs/${org}/dashboards`);
    },
    getDashboard: function (handle) {
      return api.get(`/${handle}/dashboard`);
    },
    makeDefault: function (data) {
      return api.patch(`preferences`, {
        preferences: {
          [data.org]: {
            default_dashboard: data.uid
          }
        }
      });
    },
    getSeriesTypes: function (org) {
      return api.get(`orgs/${org}/dashboards/types`);
    },
    getTypeData: async function (db, org, types, period, force) {
      let requestTypes = [];
      if (force) {
        requestTypes = types;
      } else {
        // Unless we force a refresh, only pull types that aren't already in the iDB.
        for (const type of types) {
          if (db.objectStoreNames.contains(type)) {
            let records = await db.transaction(type).objectStore(type).getAll();
            if (records.length < 1) {
              requestTypes.push(type);
            }
          } else {
            // If the type doesn't even exist in IndexedDB, then we should definitely pull
            //   the records.
            requestTypes.push(type);
          }
        }
      }
      if (requestTypes.length > 0) {
        let response = await api.get(`org/${org}/dashboards/data?types=${[...types]}&period=${period}`);
        for (const [key, value] of Object.entries(response.data)) {
          db = this.addData(db, key, value);
        }

        return {
          db,
          response
        };
      }
      return {
        db,
        response: { data: {} }
      };
    },
  };
}
