<template>
  <div class="pa-1 mr-1">
    <v-row align-content="start">
      <v-col
        cols="12"
        align-self="start"
        class="pb-0"
      >
        <v-toolbar
          v-if="isCollapsed"
          flat
          class="toolbar-bg"
        >
          <v-toolbar-title>
            <span class="fs-18px font-weight-medium text-theme-base">{{ $t('testFolders') }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="writeEntity"
                icon
                :disabled="!selectedProjectByKey"
                :loading="isFolderLoadingState"
                :class="{ 'disabled-action': isProjectArchived }"
                @click="addTestFolder"
                v-on="on"
              >
                <folderPlusIcon />
              </v-btn>
            </template>
            <span v-if="selectedProjectByKey">{{ $t('info.addFolder') }}</span>
            <span v-else>{{ $t('info.selectProject') }}</span>
          </v-tooltip>
        </v-toolbar>
        <v-toolbar
          v-else
          flat
          class="toolbar-bg item-center"
        >
          <v-btn
            icon
            :disabled="!selectedProjectByKey"
            :loading="isFolderLoadingState"
            :class="{ 'disabled-action': isProjectArchived }"
            @click="addTestFolder"
          >
            <v-icon color="#0C2FF3 !important">
              mdi-folder-plus-outline
            </v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
      <v-col
        v-if="isCollapsed"
        cols="12"
        align-self="start"
        class="v-padding-0"
      >
        <v-treeview
          v-if="editedItems.length"
          :items="editedItems"
          activatable
          item-key="uid"
          :open.sync="open"
          transition
          :active.sync="selectedItemsArray"
          rounded
          expand-icon="mdi-chevron-down"
          class="text-left treeview-theme cursor-pointer scrollable-div"
          :class="{
            'has-child': isEditedItemsHasChildren,
            'has-no-child': !isEditedItemsHasChildren,
            'is-scrolled': isScrolled,
            'is-unscroll': !isScrolled,
          }"
          :dense="false"
          @update:active="setActive"
        >
          <template v-slot:prepend="{ item }">
            <div
              :id="item.uid"
              class="d-flex flex-row justify-start align-center cursor-move pr-0"
            >
              <folderActiveIcon v-if="item.active" />
              <folderInActiveIcon v-else />
            </div>
          </template>
          <template v-slot:label="{ item, active }">
            <div
              v-if="item != currentEditableItem || item === editingFolder"
              :id="item.uid"
              class="d-flex flex-row justify-start align-center no-select pl-0 w-full"
              @contextmenu.prevent="showContextMenu($event, item)"
            >
              <div
                v-if="item === editingFolder"
                class="pl-0"
              >
                <v-text-field
                  v-model="editingFolder.name"
                  :value="editingFolder.name"
                  type="text"
                  background-color="transparent"
                  class="new-folder-input mt-0 pt-0 pl-0"
                  hide-details
                  autofocus
                  solo
                  flat
                  dense
                />
              </div>
              <div
                v-else
                class="pl-2"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      class="font-weight-regular"
                      :class="{ 'text-theme-primary ': active, 'text-theme-label': !active }"
                      v-on="on"
                    >
                      {{ item && item.name ? item.name : $t('unnamedFolder') }}
                    </span>
                  </template>
                  <span>{{ item && item.name ? item.name : $t('unnamedFolder') }}</span>
                </v-tooltip>
              </div>
              <span
                v-if="hasChildren(item)"
                class="pl-1"
              >({{ item.children.length }})</span>
            </div>
            <div
              v-else-if="item.editable && item !== editingFolder"
              class="d-flex flex-row justify-start align-center pl-0 pr-0 w-full"
            >
              <v-text-field
                v-model="item.name"
                :value="item.name"
                type="text"
                background-color="transparent"
                class="new-folder-input mt-0 pt-0 pl-2"
                hide-details
                autofocus
                solo
                flat
                dense
                @keyup.enter="updateFolder(item)"
                @blur="updateFolder(item)"
                @click.stop
              />
            </div>
          </template>
        </v-treeview>
      </v-col>
    </v-row>
    <FolderDeleteDialog
      v-if="folderDeleteDialog"
      :value="folderDeleteDialog"
      :folder_name="folderDeleteName"
      @handleConfirmClick="deleteFolder"
      @close="handleCloseDialog"
    />
    <v-menu
      v-model="showMenu"
      :position-x="menuX"
      :position-y="menuY"
      absolute
      offset-y
    >
      <v-list dense>
        <v-list-item
          :key="1"
          class="action-btn actions-item"
          @click="handleMenuEdit"
        >
          <div class="d-flex align-center">
            <EditIcon />
          </div>
          <v-list-item-title class="pl-3">
            {{ $t('edit') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :key="2"
          class="action-btn actions-item"
          @click="handleMenuDelete"
        >
          <div class="d-flex align-center">
            <DeleteIcon />
          </div>
          <v-list-item-title class="pl-3 text-theme-danger">
            {{ $t('delete') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import EditIcon from '@/assets/svg/edit.svg';
import DeleteIcon from '@/assets/svg/delete.svg';
import makeFoldersService from '@/services/api/folder';
import makeCasesService from '@/services/api/case';
import FolderDeleteDialog from '@/components/TestCases/FolderDeleteDialog.vue';
import folderPlusIcon from '@/assets/svg/folder-plus.svg';
import folderInActiveIcon from '@/assets/svg/folder-yellow.svg';
import folderActiveIcon from '@/assets/svg/folder-blue.svg';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import projectStatus from '@/mixins/projectStatus';
import uid from 'tiny-uid';
import cloneDeep from 'lodash/cloneDeep';

let folderService;
let caseService;

export default {
  components: {
    FolderDeleteDialog,
    EditIcon,
    DeleteIcon,
    folderPlusIcon,
    folderInActiveIcon,
    folderActiveIcon,
    // draggable
  },
  mixins: [projectStatus],
  props: {
    collapsed: Number,
    items: {
      required: true,
      type: Array,
    },
    selectedFolderUid: {
      type: [String, Number],
      required: false,
      default: null,
    },
    executionFolders: {
      type: Array,
    },
    writeEntity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      folderDeleteDialog: false,
      folderDeleteName: '',
      folderDeleteItem: null,
      openedFolders: [],
      tree: [],
      editingFolder: null,
      selectedItem: null,
      createdFoldersCache: {},
      currentEditableItem: null,
      treeviewKey: 0,
      selectedItemsArray: [],
      rootFolderUID: '',
      isFolderLoadingState: false,
      originalFolderName: null,
      isScrolled: false,
      showMenu: false,
      menuX: 0,
      menuY: 0,
      selectedContextItem: null,
      dragFromFolder: null,
      dragToFolder: null,
    };
  },
  computed: {
    open: {
      get() {
        return this.openedFolders.length
          ? this.openedFolders
          : this.getParentFolderKeys(this.items, { uid: parseInt(this.$route.params.folderUid) });
      },
      set(value) {
        this.openedFolders = value;
      },
    },
    isOpenedFoldersNotEmpty() {
      return this.openedFolders.length > 0;
    },
    isCollapsed() {
      // TODO - passing this as a number instead of a boolean is a huge hack
      //        to get around:
      // Property or method "on"/"attrs" is not defined on the instance...
      return this.collapsed ? true : false;
    },
    selectedProjectByKey() {
      return this.$route.params.key;
    },
    selectedOrganization() {
      return this.$store.state.currentAccount;
    },
    rootFolder() {
      return this.items.find((folder) => folder.parentUid === null);
    },
    sortedFolders() {
      return this.sortFoldersByUpdatedAt(this.items);
    },
    editedItems() {
      if (this.executionFolders?.length && this.items.length) {
        const filterFolders = (folder, executionFolders) => {
          const processFolder = (folder) => {
            if (folder.children && Array.isArray(folder.children))
              folder.children = folder.children.map((child) => processFolder(child)).filter(Boolean);

            if (executionFolders.includes(folder.uid) || (folder.children && folder.children.length > 0))
              return { ...folder, children: folder.children };

            return null;
          };
          return [processFolder(folder)];
        };
        filterFolders(this.items[0], this.executionFolders);
      }

      return this.addDepth(this.sortedFolders);
    },
    isEditedItemsHasChildren() {
      return this.editedItems.some((item) => item.children.length > 0);
    },
  },
  watch: {
    editingFolder: {
      immediate: true,
      handler(newVal) {
        this.open.push(this.selectedItem?.uid);
      },
    },
    selectedItem: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (this.selectedItem) {
            this.$emit('folder-selected', this.selectedItem);
            this.updateSelected(this.selectedItem.uid);
          } else if (this.active && this.active.tempUid) {
            // In the edge case that we deselect and reselect a newly created
            // folder before we've refreshed the folder list from the backend,
            // the real values for that folder live in this cache.
            this.$emit('folder-selected', this.createdFoldersCache[this.active.tempUid]);
            this.updateSelected(this.createdFoldersCache[this.active.tempUid].uid);
          }
        }
      },
    },
    selectedFolderUid: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.findSelectedItem();
        }
      },
    },
  },

  mounted() {
    folderService = makeFoldersService(this.$api);
    caseService = makeCasesService(this.$api);
    this.items.map((item) => {
      item.editable = false;
    });
    this.open = this.flattenFolders(this.editedItems);
    document.addEventListener('click', this.handleClickOutside);
    window.addEventListener('scroll', this.onScroll);
    this.initializeDraggable();
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
    window.removeEventListener('scroll', this.onScroll);
    if (this.treeObserver) {
      this.treeObserver.disconnect();
    }
  },
  methods: {
    getFolderByEvent(event) {
      const folders = this.editedItems;
      const folderUid = event.target.childNodes[event.target.childNodes.length - 1].childNodes[1].childNodes[0].id;

      const searchFolder = (items) => {
        for (let item of items) {
          if (item.uid === Number(folderUid)) {
            return item;
          }

          // If item has children, search recursively
          if (item.children && item.children.length > 0) {
            const found = searchFolder(item.children);
            if (found) {
              return found;
            }
          }
        }
        return null;
      };
      return searchFolder(folders);
    },
    onScroll() {
      this.isScrolled = window.pageYOffset > 180;
    },
    flattenFolders(folders) {
      const result = [];

      function traverse(folders) {
        if (!Array.isArray(folders) || folders.length === 0) {
          return;
        }

        folders.forEach((folder) => {
          result.push(folder.uid);
          folder.children = Array.isArray(folder.children) ? folder.children : [];

          if (folder.children.length > 0) {
            traverse(folder.children);
          }
        });
      }

      traverse(folders);
      return result;
    },
    sortFoldersByUpdatedAt(folders) {
      return folders.map((folder) => {
        const folderCopy = cloneDeep(folder);

        if (folderCopy.children && folderCopy.children.length > 0) {
          folderCopy.children = this.sortFoldersByUpdatedAt(folderCopy.children);
          folderCopy.children.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        }

        return folderCopy;
      });
    },

    addDepth(folders, depth = 0) {
      return folders.map((folder) => {
        folder.depth = depth;
        if (folder.children && Array.isArray(folder.children)) {
          folder.children = this.addDepth(folder.children, depth + 1);
        }
        return folder;
      });
    },
    getDepth(folders, uid) {
      const findDepth = (folders, uid, depth = 0) => {
        for (const folder of folders) {
          if (folder.uid === uid) {
            return depth;
          }
          if (folder.children && folder.children.length > 0) {
            const childDepth = findDepth(folder.children, uid, depth + 1);
            if (childDepth !== -1) {
              return childDepth;
            }
          }
        }
        return -1;
      };

      return findDepth(folders, uid);
    },
    updateSelected(uid) {
      this.selectedItem = this.items.find((item) => item.uid === uid);
    },
    makeEditable(selecteditem) {
      if (!this.writeEntity) {
        this.unauthorizedToast;
        return;
      }
      if (this.isProjectArchived) return;
      this.originalFolderName = selecteditem.name;
      if (this.currentEditableItem && this.currentEditableItem !== selecteditem) {
        this.currentEditableItem.editable = false;
      }
      selecteditem.editable = true;
      this.currentEditableItem = selecteditem;
    },
    handleBlur(item) {
      item.editable = false;
      this.currentEditableItem = null;
    },
    handleCloseDialog() {
      this.folderDeleteDialog = false;
      this.folderDeleteItem = null;
    },
    handleOpenDialog(item) {
      this.folderDeleteName = item.name;
      this.folderDeleteDialog = true;
      this.folderDeleteItem = item;
    },
    setActive(activeItems) {
      if (!activeItems.length) {
        this.selectedItem = null;
        return;
      }

      let currentItem = activeItems.length ? activeItems[0] : null;
      this.selectedItem = currentItem;

      this.$router
        .replace({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            folderUid: currentItem,
          },
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
    },
    async addTestFolder() {
      if (!this.isProjectArchived) {
        // Check depth of selected folder
        const MAX_DEPTH = 5; // Max depth of 5 allows for 6 levels (0-5)

        if (this.$route.params.folderUid) {
          const currentDepth = this.getDepth(this.editedItems, this.$route.params.folderUid);
          if (currentDepth >= MAX_DEPTH) {
            showErrorToast(this.$swal, this.$t('error.maxFolderDepth', { max: MAX_DEPTH + 1 }));
            return;
          }
        }

        this.editingFolder = {
          tempUid: uid(),
          name: `${this.$t('newFolder')}_${uid(3)}`,
          count: 0,
          children: [],
          parent: this.selectedItem || null,
        };

        this.isFolderLoadingState = true;
        await this.saveNewFolder();
        this.isFolderLoadingState = false;
      }
    },

    async saveNewFolder() {
      const newFolder = {
        parentId: this.$route.params.folderUid || this.rootFolderUID,
        externalId: 'testfiesta',
        customFields: {},
        source: 'testfiesta',
        name: this.editingFolder.name,
      };
      try {
        const response = await folderService.createFolder(this.$route.params.handle, this.$route.params.key, newFolder);
        this.createdFoldersCache[this.editingFolder.tempUid] = response.data;
        this.editingFolder = null;
        this.$parent.$emit('refresh-folders');
        showSuccessToast(this.$swal, 'createSuccess', { item: 'Folder' });
      } catch (error) {
        showErrorToast(this.$swal, 'createError', { item: 'Folder' });
      }
    },
    async updateFolder(item) {
      let newFolder = {
        ...item,
      };
      if (!newFolder.name || this.folderDeleteDialog) {
        return;
      }

      try {
        const response = await folderService.updateFolder(
          this.$route.params.handle,
          this.$route.params.key,
          item.uid,
          newFolder
        );
        this.selectedItem = response.data;
        this.editingFolder = null;
        showSuccessToast(this.$swal, 'updateSuccess', { item: 'Folder' });
      } catch (error) {
        item.name = this.originalFolderName;
        if (error.status === 409) {
          showErrorToast(this.$swal, 'duplicateNameError', { item: 'Folder' });
        } else {
          showErrorToast(this.$swal, 'updateError', { item: 'Folder' });
        }
      }
      item.editable = false;
      this.currentEditableItem = null;
    },

    async deleteFolder() {
      try {
        await folderService.deleteFolder(this.$route.params.handle, this.$route.params.key, this.folderDeleteItem.uid);
        this.handleCloseDialog();
        this.$parent.$emit('refresh-folders');
        this.$emit('folder-delete');
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Folder' });
      } catch (error) {
        showErrorToast(this.$swal, 'deleteError', { item: 'Folder' });
      }
    },

    findParent(folders, childUid) {
      for (let folder of folders) {
        if (folder.children && folder.children.length > 0) {
          for (let child of folder.children) {
            if (child.uid == childUid) {
              return folder;
            }
          }

          const foundParent = this.findParent(folder.children, childUid);
          if (foundParent) {
            return foundParent;
          }
        }
      }

      return null;
    },
    findFolderByUid(folders, uid) {
      for (const folder of folders) {
        if (folder.uid === uid) {
          return folder;
        }
        if (folder.children) {
          const foundInChildren = this.findFolderByUid(folder.children, uid);
          if (foundInChildren) {
            return foundInChildren;
          }
        }
      }
      return null;
    },

    getParentFolderKeys(allFolders, targetFolder) {
      const parentFolderKeys = [];

      const findParent = (folders, target) => {
        for (const folder of folders) {
          if (!folder.children || !folder.children.length) continue;

          if (folder.uid == target.uid) return true;

          if (folder.children.some((child) => child.uid === target.uid)) {
            parentFolderKeys.unshift(folder.uid);
            return true;
          }

          if (findParent(folder.children, target)) {
            parentFolderKeys.unshift(folder.uid);
            return true;
          }
        }
        return false;
      };

      if (findParent(allFolders, targetFolder)) parentFolderKeys.push(targetFolder.uid);

      return parentFolderKeys;
    },
    hasChildren(item) {
      return item && item.children && item.children.length > 0;
    },
    findSelectedItem() {
      this.selectedItemsArray.push(this.selectedFolderUid);
    },
    showContextMenu(event, item) {
      event.preventDefault();
      this.menuX = event.clientX;
      this.menuY = event.clientY;
      this.selectedContextItem = item;
      this.showMenu = true;
    },
    handleMenuEdit() {
      if (this.selectedContextItem) {
        this.makeEditable(this.selectedContextItem);
      }
      this.showMenu = false;
    },
    handleMenuDelete() {
      if (this.selectedContextItem) {
        this.handleOpenDialog(this.selectedContextItem);
      }
      this.showMenu = false;
    },
    initializeDraggable() {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeType === 1) {
              const treeNodes = node.classList.contains('v-treeview-node__root')
                ? [node]
                : node.querySelectorAll('.v-treeview-node__root');

              treeNodes.forEach((treeNode) => {
                this.applyDragFunctionality(treeNode);
              });
            }
          });
        });
      });

      const checkTreeNodes = setInterval(() => {
        const treeNodes = document.querySelectorAll('.v-treeview-node__root');

        if (treeNodes && treeNodes.length > 0) {
          clearInterval(checkTreeNodes);

          treeNodes[0].classList.add('first-node');

          treeNodes.forEach((node) =>{
            this.applyDragFunctionality(node);
          });

          const treeviewContainer = document.querySelector('.v-treeview');
          if (treeviewContainer) {
            observer.observe(treeviewContainer, {
              childList: true,
              subtree: true,
            });
          }
        }
      }, 100);

      setTimeout(() => {
        clearInterval(checkTreeNodes);
      }, 10000);

      this.treeObserver = observer;
    },

    async handleFolderMove() {
      const from = window.isFileDragging ? window.caseUid : this.dragFromFolder;
      const to = this.dragToFolder;
      if (!from || !to) return;

      if (from.uid === to.uid) return;

      const isChild = (parent, child) => {
        if (!parent.children) return false;
        return parent.children.some((item) => item.uid === child.uid || isChild(item, child));
      };
      if (!window.isFileDragging && isChild(from, to)) return;

      try {
        if (window.isFileDragging) {
          const response = await caseService.updateTestCase(
            this.$route.params.handle,
            this.$route.params.key,
            window.caseUid,
            {
              parentId: to.uid,
            }
          );
          this.$parent.$emit('reload-cases');
          window.isFileDragging = false;
          window.caseUid = null;
        } else {
          const response = await folderService.updateFolder(
            this.$route.params.handle,
            this.$route.params.key,
            from.uid,
            {
              parentId: to.uid,
            }
          );

          if (this.selectedItem && this.selectedItem.uid === from.uid) {
            this.selectedItem = response.data;
          }
          this.$parent.$emit('refresh-folders');
        }

        this.dragFromFolder = null;
        this.dragToFolder = null;

        showSuccessToast(this.$swal, 'updateSuccess', { item: 'Folder' });
      } catch (e) {
        showErrorToast(this.$swal, 'updateError', { item: 'Folder' });
      }
    },
    applyDragFunctionality(node) {
      // Make the root node and all its children draggable
      const makeNodeDraggable = (element) => {
        if (element.classList.contains('first-node')) {
          element.draggable = false;
          return;
        }
        
        element.draggable = true;
        
        // Add drag event listeners
        element.addEventListener('dragstart', (e) => {
          // Find the closest root node
          const rootNode = e.target.closest('.v-treeview-node__root');
          if (rootNode) {
            rootNode.classList.add('dragging');
            this.dragFromFolder = this.getFolderByEvent({ target: rootNode });
          }
          e.stopPropagation(); // Prevent event bubbling
        });

        element.addEventListener('dragend', (e) => {
          const rootNode = e.target.closest('.v-treeview-node__root');
          if (rootNode) {
            rootNode.classList.remove('dragging');
          }
          e.stopPropagation();
        });

        element.addEventListener('dragover', (e) => {
          e.preventDefault();
          const rootNode = e.target.closest('.v-treeview-node__root');
          if (rootNode) {
            rootNode.classList.add('drag-over');
          }
          e.stopPropagation();
        });

        element.addEventListener('dragleave', (e) => {
          const rootNode = e.target.closest('.v-treeview-node__root');
          if (rootNode) {
            rootNode.classList.remove('drag-over');
          }
          e.stopPropagation();
        });

        element.addEventListener('drop', (e) => {
          e.preventDefault();
          const rootNode = e.target.closest('.v-treeview-node__root');
          if (rootNode) {
            this.dragToFolder = this.getFolderByEvent({ target: rootNode });
            const fromEntity = window.isFileDragging ? window.caseUid : this.dragFromFolder;
            if (fromEntity && this.dragToFolder) {
              this.handleFolderMove();
            }
            rootNode.classList.remove('drag-over');
          }
          e.stopPropagation();
        });
      };

      // Apply drag functionality to the root node and all its descendants
      makeNodeDraggable(node);
      node.querySelectorAll('*').forEach(makeNodeDraggable);
    },
  },
};
</script>

<style>
.is-scrolled {
  max-height: calc(100vh - 10rem);
}
.is-unscroll {
  max-height: calc(100vh - 22rem);
}
.scrollable-div {
  overflow: auto;
  scrollbar-width: none;
  display: flex;
  min-height: calc(100vh - 95vh);
}
.scrollable-div:hover {
  scrollbar-width: thin;
}
.item-center {
  display: flex;
  justify-content: center;
}
.toolbar-bg {
  background-color: transparent !important;
}
.height-parent {
  height: 100%;
}
.float-bottom {
  position: absolute;
  bottom: 0;
}
.position-relative {
  position: relative;
}
.card {
  border-radius: 5px;
}
.v-treeview-node__root {
  position: relative;
}

.v-treeview-node__children {
  margin-left: 10px;
}

.v-treeview-node__append {
  margin-left: 0px;
  padding-left: 0px;
}

.v-treeview-node {
  margin-left: 10px;
}

.v-padding-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.v-treeview-node__children {
  border-left: 1px solid rgb(247, 248, 249);
}
.treeview-theme .v-treeview-node__root {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 0 !important;
  height: 25px !important;
  min-height: 25px !important;
}

.treeview-theme.has-child .v-treeview-node__root .v-treeview-node__level {
  width: 20px !important;
  left: -12px;
  top: 9px;
  background: url('/icon/node-line.svg');
  background-size: contain;
  height: 100% !important;
  background-repeat: no-repeat;
  position: absolute;
  background-color: transparent !important;
}
.treeview-theme.has-no-child .v-treeview-node__level {
  width: 0px;
}
.no-select {
  user-select: none;
}
.v-menu {
  display: block;
}
.context-menu-item {
  cursor: pointer;
}
.action-btn .v-list-item__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.v-treeview-node__root {
  /* cursor: move; */
  user-select: none;
}

.v-treeview-node__root.dragging {
  opacity: 0.5;
}

.v-treeview-node__root.drag-over {
  border: 2px dashed #0c2ff3;
}
</style>
