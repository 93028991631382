<template>
  <v-sheet
    class="project-item"
    rounded="lg"
    p="4"
    color="#f9fafb"
    @click="cardClick"
  >
    <div class="d-flex align-center justify-space-between py-4 px-2">
      <div class="d-flex gap-4">
        <div class="d-flex">
          <v-btn
            icon
            class="mr-2"
            @click="toggleStar(item, $event)"
          >
            <StarFillIcon v-if="item.customFields.star" />
            <StarIcon v-else />
          </v-btn>
          <v-avatar
            class="mr-2"
            size="40"
          >
            <v-img
              :src="item.avatarUrl"
              :lazy-src="require('@/assets/png/project.png')"
              width="100%"
              alt="logo"
            />
          </v-avatar>
        </div>
        <div class="text-start">
          <div class="fw-semibold fs-12px">
            {{ item.name }}
          </div>
          <div class="text-theme-secondary fs-12px text-truncate mw-100px">
            {{ item.customFields.description }}
          </div>
        </div>
      </div>
      <div class="d-flex align-center">
        <div
          v-if="filterItems.length > 0 && filterItems[filterItems.length - 2].checked"
          class="mr-2 text-start"
        >
          <div class="text-theme-secondary fs-12px font-weight-medium">
            {{ $t('lastChanges') }}:
          </div>
          <div class="fw-semibold fs-14px text-end">
            {{ formattedDate(item.updatedAt) }}
          </div>
        </div>
        <v-menu
          content-class="custom_ele elevation-0"
          nudge-bottom="35"
          left
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon color="gray-ish">
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-if="!isArchived"
              class="pointer"
              @click="onEdit(item)"
            >
              <EditIcon />
              <v-list-item-content class="ml-2">
                {{ $t('edit') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="!isArchived"
              class="pointer"
              @click="onArchive(item)"
            >
              <ArchieveIcon />
              <v-list-item-content class="ml-2">
                {{ $t('archive') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else-if="isArchived"
              class="pointer"
              @click="onUnArchive(item)"
            >
              <ArchieveIcon />
              <v-list-item-content class="ml-2">
                {{ $t('unarchive') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="pointer"
              @click="onDelete(item)"
            >
              <DeleteIcon />
              <v-list-item-content class="ml-2 error--text">
                {{ $t('delete') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-sheet
      color="#f3f4f7"
      rounded="lg"
      class="py-2 px-5 mx-4 text-start align-center d-flex justify-space-between"
    >
      <div>
        <span class="fw-semibold fs-14px">{{ item.testRunsCount ?? 0 }}</span> <span class="text-theme-secondary fs-12px font-weight-medium">{{ $t('testRuns') }}</span>
      </div>
      <div v-if="filterItems.length > 0 && filterItems[filterItems.length - 6].checked">
        <span class="fw-semibold fs-14px">{{ item.testCasesCount ?? 0 }}</span> <span class="text-theme-secondary fs-12px font-weight-medium">{{ $t('testCases') }}</span>
      </div>
      <div v-if="filterItems.length > 0 && filterItems[filterItems.length - 5].checked">
        <span class="fw-semibold fs-14px">{{ item.defects ?? 0 }}</span> <span class="text-theme-secondary fs-12px font-weight-medium">{{ $t('defects') }}</span>
      </div>
    </v-sheet>
    <div class="d-flex align-center justify-space-between px-5 w-full py-5">
      <div>
        <v-row class="pl-3">
          <div
            v-for="(pic, imgIndex) in item.images"
            :key="imgIndex"
          >
            <v-avatar
              class="ml-n2 custom_border"
              size="30"
            >
              <img :src="pic">
            </v-avatar>
          </div>
          <v-avatar
            v-if="item.showCount"
            class="font-weight-bold custom_color ml-n2"
            color="#ebecf0"
            size="30"
          >
            +{{ item.count }}
          </v-avatar>
        </v-row>
      </div>
      <div v-if="filterItems.length > 0 && filterItems[filterItems.length - 4].checked">
        <div class="text-theme-secondary fs-12px font-weight-medium">
          {{ $t('projectAdmin') }}:
        </div>
        <div class="font-weight-bold">
          {{ item.projadmin ?? "" }}
        </div>
      </div>
      <div v-if="filterItems.length > 0 && filterItems[filterItems.length - 3].checked">
        <div class="text-theme-secondary fs-12px font-weight-medium">
          {{ $t('creationDate') }}:
        </div>
        <div class="fw-semibold fs-14px">
          {{ formattedDate(item.createdAt) }}
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import DeleteIcon from '@/assets/svg/delete.svg';
import EditIcon from '@/assets/svg/edit.svg';
import ArchieveIcon from '@/assets/svg/archived.svg';
import { formattedDate } from '@/utils/util';
import StarFillIcon from '@/assets/svg/star-fill.svg';
import StarIcon from '@/assets/svg/star.svg';

export default {
  components: {
    DeleteIcon,
    EditIcon,
    ArchieveIcon,
    StarFillIcon,
    StarIcon,
  },
  props: {
    item: Object,
    filterItems: Array
  },
  computed: {
    isArchived() {
      return this.item.archivedAt != null;
    },
  },
  methods: {
    formattedDate,
    cardClick() {
      this.$emit('select-item', this.item);
    },
    toggleStar(item, event) {
      event.stopPropagation();
      this.$emit('toggle-star', item);
    },
    onEdit(item) {
      this.$emit('edit-item', item);
    },
    onArchive(item) {
      this.$emit('archive-item', item);
    },
    onUnArchive(item) {
      this.$emit('unarchive-item', item);
    },
    onDelete(item) {
      this.$emit('delete-item', item);
    },
  },
};
</script>

<style scoped>
  .project-item:hover {
    cursor: pointer;
  }
</style>
