<template>
  <v-card
    :color="plan.cardColor"
    flat
    :style="{ borderRadius: '8px', color: plan.cardTextColor }"
    class="text-center flex-grow-1 v-card d-flex flex-column"
    height="100%"
    min-height="512px"
  >
    <h3>{{ plan.tier }}</h3>
    <h2>
      {{ plan.name }}<span
        v-if="plan.name !== 'FREE'"
        style="font-size: 0.5em"
      >/user</span>
    </h2>
    <h3
      class="mb-8"
      style="min-height: 0.25em"
    >
      {{ plan.period }}
    </h3>
    <ul class=" text-start ma-0 pa-0">
      <li
        v-for="feature in plan.features"
        :key="feature.text"
      >
        <v-icon
          :style="{ color: plan.cardTextColor }"
          size="16"
        >
          mdi-dots-vertical
        </v-icon>
        {{ feature.text }}
      </li>
    </ul>
    <v-btn

      class=" text-capitalize btn-theme mt-auto"
      depressed
      height="38px"
      elevation="0"
      :disabled="plan.isSelected"
      @click="$emit('select', plan)"
    >
      {{ plan.buttonText }}
    </v-btn>
  </v-card>
</template>

<script>
export default {
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.v-card {
  height: 100%;
  min-height: 512px;
  padding: 40px;

  border-radius: 8px;
}
h3 {
  font-size: 20px;
  font-weight: 600;
}
h2 {
  font-size: 48px;
  font-weight: 800;
  margin-top: 37px;
  margin-bottom: 34px;
}
ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
li {
  list-style: none;

}
</style>
