<template>
  <v-app-bar
    color="white"
    class="px-4 app-navbar"
    height="90px"
    elevation="0"
    rounded="lg"
  >
    <router-link to="/">
      <img
        src="@/assets/png/logo.png"
        alt="logo"
        class="mr-6"
      >
    </router-link>
    <v-btn
      v-for="link in links"
      :key="link.text"
      :to="link.to"
      text
      class="text-capitalize mr-2 topbar text-theme-secondary"
      :class="{ 'active-link': link.isActive }"
      @click="setActive(link.text)"
    >
      <span>{{ $t(link.text) }}</span>
    </v-btn>
    <v-spacer />
    <v-btn 
      text
      link
      href="https://docs.testfiesta.com"
      target="_blank"
      class="text-capitalize mr-2 text-theme-label"
    >
      {{ $t('needHelp') }}
    </v-btn>
    <!-- <v-badge
      class="mx-6"
      overlap
      color="#F2284E"
      dot
    >
      <img src="/icon/bellicon.svg">
    </v-badge> -->
    <div>
      <v-avatar
        v-if="displayUserAccount.avatar_url"
        size="40px"
      >
        <img
          :src="displayUserAccount.avatar_url"
          :alt="displayUserAccount.name"
        >
      </v-avatar>
      <v-avatar
        v-else
        color="blue"
        size="40px"
      >
        <span class="white--text">
          {{ displayUserAccount.initials }}
        </span>
      </v-avatar>
      <v-avatar
        v-if="currentAccount?.name != displayUserAccount.name && currentAccount?.avatar_url"
        size="20px"
        class="org-avatar"
      >
        <img
          :src="currentAccount?.avatar_url"
          :alt="currentAccount?.name"
        >
      </v-avatar>
      <v-avatar
        v-else-if="currentAccount?.name != displayUserAccount.name"
        color="blue"
        size="20px"
        class="org-avatar"
      >
        <span class="white--text">
          {{ initials }}
        </span>
      </v-avatar>
    </div>

    <v-menu
      v-model="menuOpen"
      offset-y
      min-width="200"
      content-class="shadow-theme rounded-lg"
      class="account-menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center pl-4"
          v-bind="attrs"
          v-on="on"
        >
          <div class="d-flex flex-column align-start ml-2">
            <span class="black--text font-weight-medium mr-2">{{
              displayUserAccount.name
            }}</span>
            <span
              v-if="currentAccount?.name != displayUserAccount.name"
              class="caption grey--text lighten-1"
            >
              {{ currentAccount?.name }}</span>
          </div>
          <v-icon>{{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </div>
      </template>
      <v-list>
        <v-subheader class="text-theme-secondary">
          {{ $t('account_menu.personal_account') }}
        </v-subheader>

        <v-list-item @click="selectHandle(displayUserAccount)">
          <v-list-item-title class="d-flex align-center">
            <v-avatar
              v-if="displayUserAccount.avatar_url"
              size="32px"
            >
              <img
                :src="displayUserAccount.avatar_url"
                :alt="displayUserAccount.name"
              >
            </v-avatar>
            <v-avatar
              v-else
              color="blue"
              size="32px"
            >
              <span class="white--text">
                {{ displayUserAccount.initials }}
              </span>
            </v-avatar>
            <div class="d-flex flex-column align-start ml-2 text-theme-table-text">
              <span>{{ displayUserAccount.name }}</span>
            </div>
          </v-list-item-title>
        </v-list-item>

        <v-divider class="divider-theme" />
        <v-subheader v-if="displayableAccounts.length > 0">
          {{ $t('account_menu.switch_workspace') }}
        </v-subheader>
        <v-list
          v-if="displayableAccounts.length > 0"
          max-height="200px"
          class="overflow-auto custom-scrollbar"
        >
          <v-list-item
            v-for="account in displayableAccounts"
            :key="account.handle"
            @click="selectHandle(account)"
          >
            <v-list-item-title class="d-flex align-center">
              <v-avatar
                v-if="account.avatar_url"
                size="32px"
              >
                <img
                  :src="account.avatar_url"
                  :alt="account.name"
                >
              </v-avatar>
              <v-avatar
                v-else
                color="blue"
                size="32px"
              >
                <span class="white--text">
                  {{ account.initials }}
                </span>
              </v-avatar>
              <div class="d-flex flex-column align-start ml-2">
                <span>{{ account.name }}</span>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-list-item :to="{ name: 'Organizations' }">
          <v-list-item-title class="d-flex align-center">
            <PlusIcon class="mr-2" />
            <span class="text-theme-table-text">{{ $t('account_menu.add_workspace') }}</span>
          </v-list-item-title>
        </v-list-item>

        <v-divider class="divider-theme" />

        <v-list-item @click="onClickSetting">
          <v-list-item-title class="d-flex align-center">
            <GearIcon class="mr-2" />
            <span class="text-theme-table-text">{{ $t('settings') }}</span>
          </v-list-item-title>
        </v-list-item>

        <v-divider class="divider-theme" />

        <v-list-item
          link
          @click="logout"
        >
          <v-list-item-title class="d-flex align-center">
            <LogoutIcon class="mr-2" />
            <span class="text-theme-danger">{{ $t('logout') }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { createNamespacedHelpers, mapActions as projectMapActions } from "vuex";
import SettingsService from "@/services/local/settings";
import makeAuthService from "@/services/api/auth";
import makeUserService from "@/services/api/user";

import GearIcon from "@/assets/svg/gear24px.svg";
import PlusIcon from "@/assets/svg/plus24px.svg";
import LogoutIcon from "@/assets/svg/logout24px.svg";
//import BellIcon from '@/assets/svg/bell.svg';
import colorPreferencesMixin from '@/mixins/colorPreferences';

const { mapActions, mapGetters, mapState, mapMutations } = createNamespacedHelpers("user");

import { DB_NAME } from "@/constants/dashboardConstants";

let authService;
let userService;

export default {
  name: 'Appbar',
  components: {
    GearIcon,
    PlusIcon,
    LogoutIcon,
  },
  mixins: [colorPreferencesMixin],
  computed: {
    ...mapGetters(['orgs', 'isAuthenticated', 'userName']),
    ...mapState(['user', 'currentAccount']),
    handle() {
      if (this.$route.params?.handle) {
        return this.$route.params.handle;
      } else if (this.currentAccount?.handle) {
        return this.currentAccount?.handle;
      }
      return this.$store.state.user.user.handle;
    },
    initials() {
      if (this.currentAccount?.handle === this.user?.handle) {
        return `${this.user?.firstName[0]}${this.user?.lastName[0]}`;
      }
      return `${this.currentAccount?.name?.[0] || ''}${this.currentAccount?.name?.at(-1) || ''}`;
    },
    displayableAccounts() {
      const accounts = [];

      if (!this.currentAccount) {
        return accounts;
      }

      const orgsToDisplay = this.orgs
        .filter((org) => org.handle !== this.currentAccount?.handle)
        .map((org) => ({
          name: org.name,
          handle: org.handle,
          avatar_url: org.avatarUrl,
          type: org.type,
          roleName: org.roleName,
          uid : org.uid,
          initials: `${org.name[0]}${org.name.at(-1)}`,
        }));

      accounts.push(...orgsToDisplay);

      return accounts;
    },

    displayUserAccount() {
      const userAccount = {
        name: `${this.user?.firstName} ${this.user?.lastName}`,
        handle: this.user?.handle,
        avatar_url: this.user?.avatarUrl,
        type: "user",
        roleName: "owner",
        initials: `${this.user?.firstName[0]}${this.user?.lastName[0]}`
      };
      return userAccount;
    },
  },
  data() {
    return {
      links: [],
      menuOpen: false,
    };
  },
  mounted() {
    this.updateLinks(this.handle);
  },
  created() {
    authService = makeAuthService(this.$api);
    userService = makeUserService(this.$api);
  },
  methods: {
    ...mapActions(["initSession"]),
    ...mapMutations(["setUser", "setOrgs", "setCurrentAccount"]),
    ...projectMapActions("headers", ['clearAllHeaders']),
    updateLinks(handle, key) {
      let handleName = handle || this.handle;

      const currentPage = this.$router.history.current;
      if (key) {
        currentPage.params.key = key;
      }
      this.links = [
        {
          text: 'projectsMenu',
          to: { name: 'ProjectsView', params: { handle: handleName } },
          isActive: true,
        },
        {
          text: 'myWorkspace',
          to: { name: 'Workspace', params: { handle: handleName } },
          isActive: false,
        },
        // {
        //   text: 'dashboard',
        //   to: { name: 'DashboardMain', params: { ...currentPage.params, handle: handle } },
        //   isActive: false,
        // },
        //{
        //  text: 'reports',
        //  to: { name: 'Reports', params: { ...currentPage.params, handle: handle } },
        //  isActive: false
        //},
        {
          text: 'administration',
          to: { name: 'Tags', params: { ...currentPage.params, handle: handleName } },
          isActive: false,
        },
      ];
      if (this.currentAccount && this.currentAccount.type === 'org') {
        this.links.push({
          text: 'integrations.name',
          to: { name: 'integrations', params: { handle: handleName } },
          isActive: false,
        });
      }
    },
    setActive(selectedLink) {
      this.links.forEach((link) => {
        link.isActive = link.text === selectedLink;
      });
    },
    goToSettings() {
      this.$router.push({ name: 'Settings' });
    },
    selectHandle(account) {
      let selectedHandle = {
        handle: account.handle,
        name: account.name,
        type: account.type,
        roleName: account.roleName,
        avatar_url: account.avatarUrl,
        uid: account.uid,
      };

      const currentPage = this.$router.history.current;
      // Handle settings pages differently
      if (
        currentPage.path.indexOf('settings') >= 0 &&
        (!currentPage.params.handle || selectedHandle.handle === this.$store.state.user.user.handle)
      ) {
        // If we're going to the user settings page or the org page
        if (selectedHandle.handle === this.$store.state.user.user.handle) {
          this.$router.push({
            name: 'Account',
          });
        } else {
          this.$router.push({
            name: 'OrgAccount',
            params: { handle: selectedHandle.handle },
          });
        }
      } else {
        if (currentPage.params?.handle === this.currentAccount.handle) {
          this.$router.push({
            name: 'ProjectsView',
            params: { ...currentPage.params, handle: selectedHandle.handle },
          });
        }
      }
      this.setCurrentAccount(selectedHandle);
      SettingsService.setCurrentAccount(selectedHandle);



      this.updatePreferences(selectedHandle);
      
    },
    onClickSetting(){
      if(this.currentAccount.type == 'org' || this.currentAccount.isOrg){
        this.$router.push({
          name: "OrgAccount",
          params: { handle: this.currentAccount.handle },
        });
      } else {
        this.$router.push({
          name: "Account",
        });
      }
    },
    logout() {
      this.setCurrentAccount(null);
      this.setUser(null);
      this.setOrgs(null);
      this.clearAllHeaders();
      this.$router.push('/login').catch((e) => {});
      indexedDB.deleteDatabase(DB_NAME);
    },

    getOrgInitials(org) {
      return `${org.name.at(0)}${org.name.at(-1)}`;
    },
  },
  watch: {
    $route(to, from) {
      this.setActive(to.name);
      this.updateLinks(to.params.handle, to.params.key);
    },
  },
};
</script>

<style>
.org-avatar {
  height: 1.2rem;
  min-width: 1.2rem;
  position: absolute !important;
  transform: translate(-15px, 21px) !important;
  font-size: 11px;
}

/* Custom scrollbar styles for the specific container */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f9f9fb;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #eaecf0;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #eaecf0;
}

.topbar.v-btn--active {
  color: blue !important;
}
.app-navbar .v-btn--active::before {
  opacity: 0 !important;
}
.app-navbar .v-btn--active {
  font-weight: 600 !important;
}
.account-menu {
  z-index: 19;
}
</style>
