<template>
  <div
    style="width: 100%; height: 100%"
    class="apex_line-chart"
  >
    <div
      v-if="data.id"
      class="d-flex justify-space-between align-center mb-5"
    >
      <div class="chart-filters d-flex align-center flex-wrap">
        <h3>Split</h3>
        <!-- <PriorityStatusFilter
          :statuses="data.status"
          :priorites="data.priority"
          :splitBy="data.split"
          :chartId="data.id"
        /> -->
        <EntitySelector
          :entity="data.entityType"
          :chart-id="data.id"
          @updateEntity="updateEntity"
        />
        <!-- <ChartViewFilter
          :split="data.split" 
          :chartId="data.id"
        /> -->
      </div>
      <div class="chart-settings">
        <v-menu
          left
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list
            dense
            class="text-left"
          >
            <v-list-item
              @click="$emit('deleteChart', data.id)"
            >
              <DeleteIcon />
              <v-list-item-content class="ml-2 error--text">
                {{ $t('delete') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="$emit('expandChart', data.id)"
            >
              <ExpandIcon />
              <v-list-item-content class="ml-2">
                {{ $t('expand') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <vue-apex-charts
      type="line"
      height="280"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import EntitySelector from '@/components/Dashboard/Filters/EntitySelector'
import ExpandIcon from '@/assets/svg/expand.svg';
import DeleteIcon from '@/assets/svg/delete.svg';

export default{
  components:{
    VueApexCharts,
    EntitySelector,
    DeleteIcon,
    ExpandIcon
  },
  props:{
    data:{
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data(){
    return {
      series: [{
          name: this.data?.name,
          data: Object.values(this.data?.chartData)
      }],
      chartOptions: {
        chart: {
          type: 'line',
          zoom: {
            enabled: false
          },
          
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 2,
          colors: '#0C2FF3'
        },
        markers: {
          size: 4,
          colors: '#0C2FF3',
          strokeColors: '#0C2FF3',
        },
        grid: {
          show: true,
          borderColor: '#90A4AE',
          strokeDashArray: 5,
          position: 'back',
          row: {
            colors: ['#FFF'],
            opacity: 0.5
          },
        },
        xaxis: {
          categories: Object.keys(this.data?.chartData),
        }
      },        
    }
  },
  methods:{
    updateEntity(entity, chartId){
      this.$emit('updateEntity', entity, chartId)
    }
  }
}
</script>
<style>
.apex_line-chart .apexcharts-canvas,.apex_line-chart .apexcharts-canvas .apexcharts-svg{
  width: 100% !important;
}
</style>
<style scoped>
.apex_line-chart .chart-filters{
  gap: 8px;
}
</style>
<style scoped>
.expand-content{
  color: #344054 !important;
}
</style>