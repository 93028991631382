import axios from 'axios'

const state = {
  uploadedFiles: []
}

const mutations = {
  addUploadedFile: (state, uploadedFile) => {
    state.uploadedFiles.push(uploadedFile)
  },
  removeUploadedFile: (state, file) => {
    const { key}  = file;
    const fileIndex = state.uploadedFiles.findIndex(element => element.key == key);
    state.uploadedFiles.splice(fileIndex,1)
  },
  updateFileProgress: (state, {uploadingFile , progress}) => {
    const { key } = uploadingFile;
    const fileIndex = state.uploadedFiles.findIndex(element => element.key == key)
    state.uploadedFiles[fileIndex].progress = progress
  },
  resetUploadedFiles: (state) => {
    state.uploadedFiles = []
  },
  setUploadFailed: (state, file) => {
    const { key}  = file;
    const fileIndex = state.uploadedFiles.findIndex(element => element.key == key);
    state.uploadedFiles[fileIndex].failed = true;
  },
}

const getters = {
  uploadedFiles: (state) => state.uploadedFiles
}
const makeActions = () => {
  return {
    removeUploadedFile({commit}, file){
      commit('removeUploadedFile', file)
    },
    // TODO Attachment APIS
    async deleteUploadedFile(_, {id, apiService, params}){
      await apiService.cleanupAttachments({id, params})
    },
    async retryUpload({commit,dispatch}, {handle, attachment}){

      commit('removeUploadedFile', attachment);

      await dispatch('uploadToServer', { handle, mediaType: attachment.mediaType, file: attachment.file, apiService: attachment.apiService, params: attachment.params})
    },
    async uploadToServer({commit, dispatch, getters},{mediaType, file, apiService, signedUrl, params}) {

      const key = getters['uploadedFiles'].length
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      const attachment = {
        key,  
        fileName: file.name,
        size: file.size,
        fileType: file.type,
        mediaType,
        sizeInKB: file.size/1000,
        progress: 0,
        file,
        source,
        params,
        apiService,
        extension: file.type.substr(file.type.indexOf('/')+1).toUpperCase(),
        failed: false,
      };
      commit('addUploadedFile', attachment);
      
      const signedUrlPayload = {
        fileType: attachment.fileType,
        size: attachment.size,
        fileName: attachment.fileName,
      }

      const { data } = signedUrl ?? await apiService.getSignedAttachmentUrl({payload: signedUrlPayload, ...(params? {params} : undefined)}).catch(() => commit('setUploadFailed', attachment));

      return await dispatch('uploadFile',{attachment, signedUrl: data.signedUrl, headers: data.clientHeaders}).then(() => {
        commit('removeUploadedFile', attachment)
        if(mediaType == 'profile-picture')
          return data.objectUrl;
        return data.uid
      }).catch(async (error) => {
        await dispatch('deleteUploadedFile', {id: data.uid, apiService, params})
        
        if(axios.isCancel(error))
          commit('removeUploadedFile', attachment)
        else
          commit('setUploadFailed', attachment);
       
      });
    },
    async uploadFile({commit},{attachment, signedUrl, headers}) {      

      return await axios.put(signedUrl, attachment.file, {
        cancelToken: attachment.source.token,
        headers,
        onUploadProgress: (progressEvent) => {
          const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          commit('updateFileProgress', {uploadingFile: attachment, progress})
        },
      })
      .then(response => response)
      .catch(err => {
          throw err
      }); 
    }

  }
}

export default function makeAttachment(api) {
  return {
    namespaced: true,
    state,
    mutations,
    getters,
    actions: makeActions(api),
  };
}
