export default function makeIntegrationsService(api) {
  return {
    getAvailableIntegrations: async function (handle) {
      return api.get(`/${handle}/integrations/available`,{
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
      });
    },
    getIntegrations: async function (handle, params) {
      return api.get(`/${handle}/integrations?${params}`,{
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
      });
    },
    getOrganizations: async function (handle, integrationUid) {
      return api.get(`/${handle}/integrations/${integrationUid}/orgs`,{
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
      });
    },
    getIntegrationProjects: async function (handle, integrationUid, params) {
      return api.get(`/${handle}/integrations/${integrationUid}/projects?${params}`,{
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
      });
    },
    createIntegration: async function (handle, body) {
      return api.post(`/${handle}/integrations`, body,{
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
      });
    },
    updateIntegration: async function (handle, integrationUid, body) {
      return api.patch(`/${handle}/integrations/${integrationUid}`, body,{
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
      });
    },
    getRedirectUrl: async function (handle, integration, params) {
      return api.get(`/${handle}/integrations/${integration}/redirect?${params}`,{
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
      });
    },
    // soft delete
    deleteIntegration: async function (handle,integrationUid) {
      return api.delete(`/${handle}/integrations/${integrationUid}`,{
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
      });
    },
    // remove integration if user wants to cancel the setup
    removeIntegration: async function (handle,integrationUid) {
      return api.delete(`/${handle}/integrations/${integrationUid}?operation=delete`,{
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
      });
    },
    getSignedAttachmentUrl: function({params, payload}) {
      return api.post(
        `/${params.handle}/integrations/${params.integrationUid}/attachments`,payload,
        {
          baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
        } 
      );
    },
    deleteAttachment: function({id, params}) {
      return api.delete(
        `/${params.handle}/integrations/${params.integrationUid}/attachments/${id}`,
        {
          baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
        }
      );
    },
    cleanupAttachments: function({id, params}) {
      return api.delete(
        `/${params.handle}/integrations/${params.integrationUid}/attachments/${id}/failed`,
        {
          baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
        }
      );
    }
  };
}
