<template>
  <v-container
    class="pa-6 white rounded-lg align-start card app-height-global"
    fluid
  >  
    <v-row
      v-if="!isBlankTable"
      justify="start"
      align="start"
    >
      <v-col
        cols="7"
        sm="7"
        class="search-bar-style"
      >
        <v-responsive
          class="ma-0"
          max-width="344"
        >
          <v-text-field
            v-model="searchTerm"
            class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
            :placeholder="$t('placeHolder.searchByColumn')"
            height="40"
            background-color="#F9F9FB"
          >
            <template v-slot:prepend-inner>
              <SearchIcon />
            </template>
          </v-text-field>
        </v-responsive>
        <TestCasesFilter @filters="applyFilters" />
      </v-col>
      <v-col
        cols="5"
        sm="5"
        class="search-bar-style setting-btn-style"
      >
        <div class="btn-selector">
          <template>
            <div class="text-center">
              <SettingsMenu 
                table-type="testExecution" 
              />
            </div>
          </template>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        class="breadcrumb-container mt-4"
      >
        <v-breadcrumbs :items="breadCrumbs">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item v-if="isLastItem(item)">
              <b>{{ item.text }}</b>
            </v-breadcrumbs-item>
            <v-breadcrumbs-item v-else>
              <span style="color: #667085">{{ item.text }}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col
        cols="12"
        sm="12"
      >
        <v-data-table
          v-model="selectedRows"
          :headers="filteredHeaders"
          :items="isSelectedData ? selectedData : displayTableData"
          item-key="uid"
          show-select
          class="data-table-style table-fixed"
          :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
          @input="handleSelectTestCases"
          @click:row="handleClick"
        >
          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <div class="d-flex justify-center align-center">
              <v-checkbox
                id="remember-me-checkbox"
                class="field-theme"
                :ripple="false"
                off-icon="icon-checkbox-off"
                on-icon="icon-checkbox-on"
                indeterminate-icon="icon-indeterminate"
                :input-value="props.value"
                :indeterminate="props.indeterminate"
                @change="on.input"
              /> 
            </div>
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <div class="d-flex justify-center align-center">
              <v-checkbox
                id="remember-me-checkbox"
                class="field-theme"
                :ripple="false"
                off-icon="icon-checkbox-off"
                on-icon="icon-checkbox-on"
                :input-value="isSelected"
                @change="select"
                @click.stop
              />
            </div>
          </template>

          <template v-slot:[`header.actions`]="{ header }">
            <div class="d-none">
              {{ header.text }}
            </div>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <span class="custom-attribute font-weight-regular text-theme-table-text">{{ item.externalId }}</span>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="custom-attribute text-truncate fw-semibold text-theme-table-text">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:[`item.assignees`]="{ item: assignee }">
            <div class="d-flex align-center">
              <v-select 
                v-model="assignee.assigneeObject"
                :items="filteredAssignees"   
                :placeholder="$t('unassign') + '...'"
                :item-text="item => `${item.firstName} ${item.lastName}`"
                :item-value="item => `${item.uid}`"
                return-object
                append-icon="mdi-chevron-down"
                :menu-props="{ offsetY: true, left: true, maxWidth: '215px !important' }"
                height="20px"
                hide-details
                flat
                class="rounded-lg  pt-0 mt-0 select-assignee font-weight-regular"
                :class="{'disabled-action': isProjectArchived }"
                @change="updateExecution('assignedTo', assignee.assigneeObject, assignee)"
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field 
                        v-model="searchAssignee" 
                        class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
                        :placeholder="$t('search')"
                        height="40"
                        background-color="#F9F9FB"
                        hide-details
                      >
                        <template v-slot:prepend-inner>
                          <SearchIcon />
                        </template>
                      </v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                
                <template v-slot:no-data>
                  <span class="font-weight-regular fs-14px text-theme-label">{{ $t('noMatchingAssignees') }}</span>
                </template>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item
                    class="mh-36px cursor-pointer"
                    v-bind="attrs" 
                    @click="on.click" 
                  >
                    <v-list-item-content class="py-0">
                      <v-list-item-title>
                        <span
                          v-bind="attrs"
                          class="font-weight-regular fs-14px text-theme-label"
                        >{{ item.firstName }} {{ item.lastName }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </div>
          </template>

          <template v-slot:[`item.priority`]="{ item: priorityItem }">
            <div class="d-flex align-center">
              <v-select
                v-model="priorityItem.priority"
                :items="priorities"
                item-text="name"
                item-value="id"
                :placeholder="$t('choosePriority') + '...'"
                append-icon="mdi-chevron-down"
                :menu-props="{ offsetY: true, left: true, maxWidth: '215px !important' }"
                height="20px"
                hide-details
                flat
                class="rounded-lg pt-0 mt-0 select-assignee font-weight-regular"
                :class="{'disabled-action': isProjectArchived }"
                @change="(item) => updateExecution('priority', item, priorityItem.priority)"
              >
                <template v-slot:selection="{ item }">
                  <span
                    class="fs-14px fw-semibold"
                    :style="{ color: item.color }"
                  >
                    {{ item.name }}
                  </span>
                </template>

                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item 
                    class="mh-36px cursor-pointer"
                    v-bind="attrs" 
                    @click="on.click" 
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        class="fs-14px fw-semibold"
                        :style="{ color: item.color }"
                      >
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </div>
          </template>


          <template v-slot:[`item.status`]="{ item: statusItem }">
            <div class="d-flex align-center">
              <v-select 
                v-model="statusItem.status"
                :items="statuses" 
                :placeholder="$t('chooseStatus') + '...'"
                item-text="name"
                item-value="id"
                append-icon="mdi-chevron-down"
                :menu-props="{ offsetY: true, left: true, maxWidth: '215px !important' }"
                height="20px"
                hide-details
                flat
                class="rounded-lg  pt-0 mt-0 select-assignee font-weight-regular"
                :class="{'disabled-action': isProjectArchived }"
                @change="(item) => updateExecution('status', item, statusItem.status)"
              >
                <template v-slot:selection="{ item }">
                  <span
                    class="fs-14px fw-semibold"
                    :style="{ color: item.color }"
                  >
                    {{ item.name }}
                  </span>
                </template>

                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item 
                    class="mh-36px cursor-pointer"
                    v-bind="attrs" 
                    @click="on.click" 
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        class="fs-14px fw-semibold"
                        :style="{ color: item.color }"
                      >
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </div>
          </template>


          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex flex-row justify-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="deleteActivity || writeEntity"
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list
                  dense
                  class="text-left"
                >
                  <v-list-item
                    v-if="deleteActivity"
                    :class="{'disabled-action': isProjectArchived }"
                    @click="$parent.$emit('deleteExecution', item.uid)"
                  >
                    <DeleteIcon />
                    <v-list-item-content class="ml-2 fs-14px text-theme-label">
                      {{ $t('delete') }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    :class="{'disabled-action': isProjectArchived }"
                    @click="handleRowEdit(item.testCaseUid, item.uid)"
                  >
                    <EditIcon />
                    <v-list-item-content class="ml-2 fs-14px text-theme-label">
                      {{ $t('edit') }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-col
      v-if="actionBtnShow"
      cols="12"
      sm="12"
      class="round-8 action-btn-wrapper px-6 py-4"
    >
      <v-btn 
        width="141px"
        color="#F2F4F7"
        full-width
        height="40"
        depressed
        class="text-capitalize btn-theme mr-3"
        elevation="0"
        @click="onCancelAction"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-menu
        v-model="menuOpen"
        class="rounded-lg"
        offset-y
        top
        left
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            class="text-capitalize btn-theme"
            height="40"
            width="141px"
            v-bind="attrs"
            :depressed="true"
            v-on="on"
          >
            {{ $t('actions') }}
            <v-icon size="20">
              {{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
        </template>
        <v-list class="actions-list font-inter text-left">
          <v-list-item
            :key="1"
            class="action-btn actions-item"
            :class="{'action-btn actions-item': true, 'disabled-action': isProjectArchived }"
          >
            <div class="d-flex align-center">
              <PinataIcon />
            </div>
            <v-list-item-title class="pl-3">
              {{ $t('runWithPinata') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            :key="2"
            :class="{'action-btn actions-item': true, 'disabled-action': isProjectArchived }"
            @click="handleEditClick()"
          >
            <div class="d-flex align-center">
              <EditIcon />
            </div>
            <v-list-item-title class="pl-3">
              {{ $t('edit') }}
            </v-list-item-title>
          </v-list-item>

          <v-menu
            :nudge-width="200"
            offset-x
            left
            :close-on-content-click="false"
            max-width="240px !important"
            class="rounded-lg"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                :key="3"
                :class="{'action-btn actions-item': true, 'disabled-action': isProjectArchived }"
                v-bind="attrs"
                :close-on-content-click="false"
                dense
                v-on="on"
              >
                <div class="d-flex align-center">
                  <ChevronLeftIcon />
                </div>
                <div class="d-flex align-center pl-3">
                  <AssignIcon />
                  <v-list-item-title class="pl-3">
                    {{ $t('assignTo') }}
                  </v-list-item-title>
                </div>
              </v-list-item>
            </template>

            <v-list>
              <v-subheader>
                <v-text-field 
                  v-model="searchAssignee" 
                  class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                  :placeholder="$t('search')"
                  height="40"
                  background-color="#F9F9FB"
                  hide-details
                >
                  <template v-slot:prepend-inner>
                    <SearchIcon />
                  </template>
                </v-text-field>
              </v-subheader>
              <v-list-item
                v-for="(assignee, index) in filteredAssignees"
                :key="index"
                class="mh-36px"
              >
                <span class="font-weight-regular fs-14px text-theme-label">{{ assignee.firstName }} {{ assignee.lastName }}</span>
              </v-list-item>
            </v-list>
          </v-menu>
            
          <v-list-item
            :key="4"
            :class="{'action-btn actions-item': true, 'disabled-action': isProjectArchived }"
          >
            <div class="d-flex align-center">
              <DeleteIcon />
            </div>
            <v-list-item-title class="pl-3 error--text">
              {{ $t('remove') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <EditRunDialog
      :is-open="isOpenEditRunDialog"
      :assignees="filteredAssignees"
      @closeDialog="handleCloseEditDialog"
    />
    <ExportDialog
      :is-open="isOpenExportDialog"
      @closeDialog="handleCloseExportDialog"
    />
    <ConfirmDialog
      :is-open="isOpenConfirmDialog"
      @closeDialog="handleCloseConfirmDialog"
      @confirm="handleConfirm"
    />
    <ConfirmBulkDeleteDialog
      :title="$t('testruns.test_case.bulk_remove.title', { count: selectedRows.length })"
      :is-open="isOpenConfirmBulkDeleteDialog"
      @closeDialog="handleCloseConfirmBulkDeleteDialog"
      @confirm="confirmBulkRemove"
    />
  </v-container>
</template>

<script>
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import TestCasesFilter from '@/views/Tests/Case/Components/Filter.vue';
import EditRunDialog from '@/components/TestRuns/EditRunDialog.vue';
import ExportDialog from '@/views/Tests/Case/Components/ExportDialog.vue';
import ConfirmDialog from '@/views/Tests/Case/Components/ConfirmDialog.vue';
import ConfirmBulkDeleteDialog from '@/views/Tests/Case/Components/ConfirmBulkDeleteDialog.vue';
import SearchIcon from '@/assets/svg/search-icon.svg';
import makeCasesService from '@/services/api/case';
import { showSuccessToast, showAlertToast, showErrorToast } from '@/utils/toast';
import DeleteIcon from '@/assets/svg/delete.svg';
import EditIcon from '@/assets/svg/edit.svg';
import PinataIcon from '@/assets/svg/pinata.svg';
import AssignIcon from '@/assets/svg/assign.svg';
import ChevronLeftIcon from '@/assets/svg/chevron-left.svg';
import { createNamespacedHelpers, mapGetters, mapActions } from 'vuex';
import projectStatus from '@/mixins/projectStatus';
import colorPreferencesMixin from '@/mixins/colorPreferences';

const { mapState, mapGetters : mapUserGetters } = createNamespacedHelpers('user');
let caseService;

export default {
  components: {
    TestCasesFilter,
    ExportDialog,
    EditRunDialog,
    ConfirmDialog,
    SearchIcon,
    SettingsMenu,
    ConfirmBulkDeleteDialog,
    DeleteIcon,
    EditIcon,
    PinataIcon,
    AssignIcon,
    ChevronLeftIcon
  },
  mixins: [projectStatus, colorPreferencesMixin],
  props: {
    isRepository: Boolean,
    caseItems: Array,
    breadCrumbs: [],
    allowAction: {
      type: Boolean,
      default: true
    },
    assignees:{
      type: Array,
    },
    execution: {
      type: Object
    },
    isDetailCollapsed:{
      type: Boolean,
      default: false
    },
    deleteActivity: {
      type: Boolean,
      default: false
    },
    writeEntity: {
      type: Boolean,
      default: false
    }
  },
  data()
  {
    return {
      // test state for blank page
      isBlankTable: false,
      // test state for blank page
      menuOpen: false,
      isOpenEditRunDialog: false,
      isOpenExportDialog: false,
      isOpenConfirmDialog: false,
      isOpenConfirmBulkDeleteDialog: false,
      isSelectedData: false,
      isAddedTable: false,
      openFilterDrawer: true,
      toggleSelect: false,
      testName: '',
      testTemplate: '',
      selectedRows: [],
      headers: [],
      statuses: [],
      priorities: [],
      searchTerm: '',
      searchAssignee: '',
      filters: null,
      actionBtnShow: false,
      selectedData: [],
      states: [{ text: 'High' }, { text: 'Medium' }, { text: 'Low' }],
      tags: [
        { value: 'test', text: '#test' },
        { value: 'billing', text: '#billing' },
        { value: 'performance', text: '#performance' },
        { value: 'navigation', text: '#navigation' },
      ],
      testTemplates: [
        { text: 'App', value: 1, externalId: 'HM-1', source: 'Test create data for App' },
        { text: 'Web', value: 2, externalId: 'HM-2', source: 'Test create data for Web' },
      ],
    };
  },
  computed: {
    ...mapState([ 'currentAccount','user']),
    ...mapGetters({
       currentAccount: 'user/currentAccount',
       dynamicHeaders:'headers/dynamicHeaders'
    }),
    filteredAssignees() {
      if (!this.searchAssignee) {
        return this.assignees;
      }
      const searchTermLower = this.searchAssignee.toLowerCase();
      return this.assignees.filter(assignee =>
        assignee.firstName.toLowerCase().includes(searchTermLower) ||
        assignee.lastName.toLowerCase().includes(searchTermLower)
      )
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.value != 'actions');
      return filtered;
    },
    filteredHeaders() {
      const filtered = this.headers.filter((header) => header.checked);
      return filtered;
    },
    displayTableData() {
      return this.caseItems.filter((item) => {

        if(this.searchTerm.length && item.name.toLowerCase().indexOf(this.searchTerm.toLocaleLowerCase()))
          return false

        if(this.filters && (this.filters.priorities.length || this.filters.tags.length)){
            if(this.filters.priorities && this.filters.priorities.length && !this.filters.priorities.includes(item.priority))
              return false

            if(this.filters.tags && this.filters.tags.length){
              let tagExists = false;
              for(const tag of item.caseFields.tags)
                if(this.filters.tags.includes(tag))
                  tagExists = true;

                return tagExists
              }
          }
          
          item.assigneeObject = { 
            firstName: item?.assignedTo?.firstName || '', 
            lastName: item?.assignedTo?.lastName || '', 
            uid: item?.assignedTo?.uid || '' 
          };
          return item            
        })
    },
  },
  created() {
    if(!this.dynamicHeaders.testExecution) {
      this.initializeHeaders({ type: 'testExecution' });
    }
    this.headers = this.dynamicHeaders.testExecution;
    caseService = makeCasesService(this.$api);

    this.priorities = this.getPriorities("testCase");
    this.statuses = this.getStatuses("testCase");

  },
  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    updateExecution(property, value, item) {
      const updatedItem = {
      ...item,
      uid: item?.uid || item?.assignedTo?.uid,
      value: 
        property === 'assignedTo' 
        ? item?.assignedTo?.uid 
        : value,
      };
      const payload = {
        property,
        value,
        item: updatedItem,
      };
      this.$emit('updateExecution', payload);
    },
    handleCloseConfirmBulkDeleteDialog()
    {
      this.isOpenConfirmBulkDeleteDialog = false;
    },
    handleCloseConfirmDialog()
    {
      this.isOpenConfirmDialog = false;
    },
    handleConfirm()
    {
      this.isOpenConfirmDialog = false;
    },
    handleEditClick()
    {
      this.isOpenEditRunDialog = true;
    },
    handleCloseEditDialog()
    {
      this.isOpenEditRunDialog = false;
    },
    handleExportClick()
    {
      this.isOpenExportDialog = true;
    },
    handleCloseExportDialog()
    {
      this.isOpenExportDialog = false;
    },
    isLastItem(item)
    {
      return item.text == this.breadCrumbs[this.breadCrumbs.length - 1].text;
    },
    getColor(priority)
    {
      if (priority == 'High') {
        return 'text-red';
      } else if (priority == 'Medium') {
        return 'text-yellow';
      } else {
        return 'text-green';
      }
    },
    onCancelAction() {
      this.selectedRows = [];
    },
    applyFilters(filters){
      this.filters = filters
    },
    handleSelectTestCases()
    {
      if (this.selectedRows.length > 0) {
        this.actionBtnShow = true;
      } else {
        this.actionBtnShow = false;
      }
      this.$emit('selectedExecutions', this.selectedRows)
    },
    handleClick(row)
    {
      this.$emit('expandDetail', row);
    },
    addSelectedCases()
    {
      this.selectedData = this.selectedRows;
      this.isSelectedData = true;
      this.isAddedTable = true;
    },
    removeSelectedCases()
    {
      this.isAddedTable = false;
    },
    // quick create test case function
    async quickCreate()
    {
      const item = this.testTemplates[parseInt(this.testTemplate) - 1];
      const payload = {
        name: this.testName,
        externalId: '',
        source: item.source,
        parentId: this.$route.params.folderUid || null,
        customFields: {
          tags: [],
          priority: this.priorities[this.priorities.length - 1].value,
        },
      };
      this.$emit('createCase', payload);
      this.testName = '';
      this.testTemplate = '';
    },
    // delete Test case from uid
    async deleteTestCase(uid)
    {
      this.$parent.$emit('caseRemove', uid)
    },
    async handleBulkRemove()
    {
      if (this.selectedRows.length > 0) {
        this.isOpenConfirmBulkDeleteDialog = true;
      }
    },

    async confirmBulkRemove()
    {
      const itemUIDs = this.selectedRows.map((row) => row.uid);
      const payload = {
        ids: itemUIDs,
      };
      this.confirmDialogOpen = false;
      this.$parent.$emit('bulkRemove', payload);
      this.isOpenConfirmBulkDeleteDialog = false;
    },

    async updateCases(selectedPriority, selectedTag)
    {
      const handle = this.currentAccount.handle;
      try {
        const payload = {
          name: this.selectedRows[0].name,
          externalId: this.selectedRows[0].externalId,
          source: this.selectedRows[0].source,
          custom_fields: {
            tags: selectedTag,
            priority: selectedPriority,
          },
        };
        const response = await caseService.updateTestCase(
          handle,
          this.$route.params.key,
          this.selectedRows[0].uid,
          payload
        );
        if (response.status == 200) {
          console.log('Case item updated successfully!');
        } else {
          console.error('Error occured in backend!');
        }
      } catch (err) {
        console.log('Error: ', err);
      }
    },
    handleRowEdit(caseId,ExecutionId)
    {
      this.$router.push({ name: 'EditTestCases', params: { uid: caseId }, query: {redirectTo: 'Runs', id: ExecutionId, isExecution: true}});
      // TODO - pass this item via state to avoid extra API calls
      //let caseItem;
      //for (let item in this.caseItems) {
      //  if (this.caseItems[item].uid == uid) {
      //    caseItem = this.caseItems[item]
      //  }
      //}
      //this.$router.push({ name: 'EditTestCases', params: { uid: caseItem } })
    },
  },
};
</script>
<style scoped>
.color-red {
  color: #f2284e !important;
}

.f-color-red {
  color: #f2284e !important;
}

.round-8 {
  border-radius: 8px;
}

.round-6 {
  border-radius: 6px;
}

.h-40 {
  height: 40px !important;
}

.btn-selector {
  position: relative;
}

.modal-main-area {
  height: 100%;
  padding: 32px 32px 32px 32px;
}

.dialog-title {
  font-weight: 900 !important;
}

.filter-dialog {
  padding-top: 15px;
}

.dialog-action {
  width: 90%;
  display: flex;
  position: absolute;
  bottom: 25px;
}

.btn-selector .selector-wrapper {
  position: relative;
}

.selector-style {
  position: absolute;
  right: 0;
  left: unset;
  top: 30px;
  min-width: 240px;
}

.modal-btn {
  width: 45%;
}

.f-color-white {
  color: white !important;
}

.text-red {
  color: #ef5350;
}

.text-green {
  color: #66bb6a;
}

.text-yellow {
  color: #ffa726;
}

.align-start {
  align-items: baseline !important;
  font-family: Inter !important;
}

.search-box-style {
  padding-top: 0;
  border-radius: 8px;
}

.search-bar-style {
  display: flex;
  padding-bottom: 0;
  justify-content: flex-start
}

.setting-btn-style {
  display: flex;
  justify-content: flex-end;
}

.setting-btn {
  position: absolute;
  right: 10px;
  width: 40px !important;
  min-width: 40px !important;
}

.breadcrumb-container {
  padding: 0;
}

.breadcrumb-container ul {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
}

.create-btn {
  display: flex;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
}

.create-btn button {
  color: white !important;
  margin-top: 10px;
}

.bottom-input-style {
  margin-top: 0;
  border-radius: 5px;
  margin-right: 10px;
}

.bottom-input-style .v-text-field__slot {
  padding-left: 10px;
}

.bottom-input-style .v-select__selections {
  padding-left: 10px;
}

.data-table-style {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.data-table-style tbody tr {
  height: 50px !important;
  line-height: 0% !important;
  min-height: 50px;
  cursor: pointer;
}

.data-table-style tbody tr:hover,
.data-table-style tbody tr:hover:not(.v-data-table__expanded__content) {
  background-color: #d0d5dd !important;
}

.v-input__prepend-inner {
  padding-left: 10px;
}

.v-list-item__content {
  text-align: start;
}

.v-breadcrumbs__item .normal-font-color {
  color: rgb(93, 101, 121) !important;
  color: red;
}

.search-field .v-input__slot {
  display: flex;
  align-items: center !important;
}

.search-field .v-input__prepend-inner {
  align-self: center;
  margin-top: 0 !important;
  padding-left: 0px;
  padding-right: 8px !important;
}

.text-field .v-input__slot {
  background-color: #f9f9fb !important;
}

.btn-restore {
  width: 100%;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  cursor: pointer;
}

.menu-header {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.absolute {
  position: absolute;
}

.bottom-right {
  display: flex;
  justify-content: right;
  right: 24px;
  bottom: 16px;
}

.f-color-blue {
  color: #0c2ff3;
}

.action-btn .v-list-item__title {
  display: flex;
  justify-content: flex-start;
}

.h-100 {
  height: 100%;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block {
  display: block;
}

h2.blank-title {
  text-align: center;
}

p.blank-description {
  max-width: 500px;
  text-align: center;
}

.none {
  display: none;
}

.custom-attribute {
  white-space: nowrap;
}

.action-btn-wrapper {
  position: sticky;
    bottom: 0;
    background-color: white;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    z-index: 8;
}
</style>
