<template>
  <v-card
    class="h-default d-flex justify-center align-center mt-3 app-height-global"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <v-form
      ref="form"
      v-model="validForm"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
          md="6"
          offset-sm="2"
          offset-md="3"
        >
          <v-row>
            <v-col
              cols="12"
              class="text-center"
            >
              <template v-slot:badge>
                <upload-avatar
                  is-org
                  @uploaded="updatedAvatar"
                />
              </template>
              <v-avatar
                class="mb-4"
                size="9rem"
                color="secondary"
              >
                <img
                  v-if="org.avatarUrl"
                  :src="org.avatarUrl"
                  alt="avatar"
                >
                <v-icon
                  v-else
                  size="6rem"
                  color="white"
                >
                  mdi-account
                </v-icon>
              </v-avatar>
            </v-col>

            <v-col
              cols="12"
              class="pb-0"
            >
              <v-text-field
                class="font-weight-medium body-2 rounded-lg text-left mb-1"
                :value="org.name"
                :label="$t('organization.organization_name')"
                filled
                readonly
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                class="font-weight-medium body-2 rounded-lg text-left mb-1"
                :value="formattedDate(org.createdAt)"
                :label="$t('organization.join_date')"
                filled
                readonly
                hide-details
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-4 mt-5 text-left"
            >
              <v-btn
                :color="'#F2284E'"
                width="200px"
                height="40"
                elevation="0"
                class="custom-btn white--text text-capitalize btn-theme rounded-lg"
                @click="onDeleteOrganization()"
              >
                {{ $t('organization.leaveOrganization') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <LeaveOrganizationConfirmDialog
      v-model="showDeleteConfirmDialog"
      @leave="leaveOrganization"
    />
  </v-card>
</template>

<script>
import UploadAvatar from '@/components/Profile/UploadAvatar.vue';
import LeaveOrganizationConfirmDialog from '@/components/Settings/Organization/LeaveOrganizationConfirmDialog.vue';
import makeUserService from '@/services/api/user';
import makeOrgService from '@/services/api/org';
import { handleDuplicateMixin } from '@/mixins/handleDuplicate';
import { formattedDate } from '@/utils/util';
import { showSuccessToast,showAlertToast, showErrorToast } from '@/utils/toast';

import { createNamespacedHelpers ,mapGetters ,mapActions} from 'vuex';
const { mapState, mapMutations } = createNamespacedHelpers('user');
// const { mapActions } = createNamespacedHelpers('org');

let userService;
let orgService;

export default {
  name: 'CreateUpdateOrganization',

  components: {
    UploadAvatar,
    LeaveOrganizationConfirmDialog,
  },

  mixins: [handleDuplicateMixin],
  data() {
    return {
      orgNameValidation: [
        value => !!value || this.$t('error.requiredField'),
        value => (value.length >= 2 && value.length <= 50) || this.$t('min2max50Chars')
      ],
      validForm: false,
      org: {
        uid: '',
        avatarUrl: '',
        name: '',
        handle: '',
        createdBy: '',
        createdAt: null,
      },
      showDeleteConfirmDialog: false,
    }
  },
  watch: {
    'org.handle': {
      handler: 'usernameInUse',
      immediate: true,
    },
  },

  computed: {
    ...mapState(['orgs', 'user', 'currentAccount','userName']),
    ...mapGetters({
      isOrgAdmin: 'user/isOrgAdmin',
      getOrg: 'user/getOrg',
    }),

    isEditMode() {
      return !!this.org.uid
    },
    orgHandleHint() {
      if (this.org.handle === '') {
        return this.$t('orgAccountNameLabel')
      }
      if (!this.handleError && this.handleAvailable) {
        return "handle is available"
      }
      return ''
    },
    accountNameValidation() {
      const defaultRules = [
        value => !!value || this.$t('error.requiredField'),
        value => /^(?=.{3,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/.test(value) || this.$t('invalidUsername'),

      ]
      if (this.handleError) {
        return [
          ...defaultRules,
          value => this.handleError,
        ]
      }
      return defaultRules
    }
  },

  mounted() {
    this.org = this.getOrg(this.$route.params.handle);
  },

  created() {
    orgService = makeOrgService(this.$api);
    userService = makeUserService(this.$api);
  },

  methods: {
    ...mapActions({
      setOrgs: 'user/setOrgs'
    }),
    formattedDate,
    updatedAvatar(avatarURL) {
      this.org.avatarUrl = avatarURL;
    },

    onUpdateOrganization() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      this.$emit(this.isEditMode ? 'update' : 'create', this.org)
    },

    onDeleteOrganization() {
      this.showDeleteConfirmDialog = true
    },

    async leaveOrganization() {
      this.showDeleteConfirmDialog = false;
      
      try {
        await orgService.leaveOrg(
          this.$store.state.user.user.handle,
          this.$route.params.handle
        );

        showSuccessToast(
          this.$swal,
          this.$t('memberDeleted', {
            orgname: this.org.name,
            username: `${this.$store.state.user.user.firstName} ${this.$store.state.user.user.lastName}`,
          })
        );

        // Refresh orgs list
        const { data } = await userService.getOrgs();
        this.setOrgs(data.orgs);
        
        // Navigate back to organizations page
        this.$router.replace({
          name: 'Organizations',
        });

      } catch (error) {
        showErrorToast(
          this.$swal,
          this.$t('memberNotDeleted', {
            orgname: this.org.name,
            username: `${this.$store.state.user.user.firstName} ${this.$store.state.user.user.lastName}`,
          })
        );
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.h-default {
  height: calc(100vh - 100px);
}

.custom-btn {
  transition: background-color .4s ease-in-out;
}

</style>
