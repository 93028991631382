<template>
  <v-container class="pa-0">
    <!-- Member Info -->
    <v-row
      align="center"
      no-gutters
    >
      <v-col cols="6">
        <v-list-item class="d-flex align-self-center border-0 px-0 mx-0">
          <v-avatar
            size="3rem"
            class="mr-4 bg-secondary"
          >
            <v-icon v-if="!invite.avatar_url">
              mdi-account
            </v-icon>
            <v-img
              v-else
              width="2rem"
              height="2rem"
              class="rounded-circle justify-center"
              :src="invite.avatar_url"
            />
          </v-avatar>

          <v-list-item-content
            v-if="existingMember"
            class="text-body d-flex py-0"
          >
            <div class="text-capitalize font-weight-bold text-left">
              {{ invite.first_name }} {{ invite.last_name }}
            </div>
            <div class="text-lowercase text-left text--lighten-3">
              <i>{{ invite.email }}</i>
            </div>
          </v-list-item-content>
          <v-list-item-content
            v-else
            class="text-body font-weight-bold text-lowercase"
          >
            {{ invite.email }}
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <!-- Member Actions -->
      <v-col cols="6">
        <v-row align="center">
          <!-- Role Display -->
          <v-col
            sm="4"
            offset="5"
            class="px-0"
          >
            <span class="d-block text-body text-right font-weight-bold grey--text text--darken-1 text-capitalize">
              {{ invite.role }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
  </v-container>
</template>

<script>

export default {
  name: 'PendingMemberItem',
  props: {
    invite: { type: Object, required: true },
  },
  computed: {
    existingMember() {
      return this.invite.username && this.invite.email;
    },
  },
};
</script>

