<template>
  <div
    class="pl-3 pt-0"
  >
    <MilestoneTestActivitiesView
      v-if="showTestActivites"
      v-model="testActivities"
      :write-entity="_writeEntity"
    >
      <template v-slot:backButton>
        <div
          class="back-to-projects"
          @click="handleRevert"
        >
          <v-icon color="blue">
            mdi-chevron-left
          </v-icon>
          <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
            {{ $t('Back to Milestone Info') }}
          </p>
        </div>
      </template>
      <template v-slot:actionButton>
        <v-btn
          depressed
          color="primary"
          height="40px"
          class="text-capitalize fw-semibold rounded-lg white--text mt-2"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
          @click="saveAdded"
        >
          {{ $t('save') }}
        </v-btn>
      </template>
    </MilestoneTestActivitiesView>
    <v-card
      v-else
      class="py-6 px-6 mt-3 font-inter app-height-global"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-row>
        <v-col flex>
          <div
            class="back-to-projects"
            @click="handleBackClick"
          >
            <v-icon color="blue">
              mdi-chevron-left
            </v-icon>
            <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
              {{ $t('milestone.create_milestone.back_to_milestones') }}
            </p>
          </div>
        </v-col>
      </v-row>
      <template>
        <v-row justify="center">
          <v-col
            md="5"
            lg="3"
            class="pa-0 pb-12"
          >
            <h2 class="text-start">
              {{ $t('milestone.create_milestone.title') }}
            </h2>
          </v-col>
        </v-row>
        <v-form
          ref="milestoneForm"
          role="createMilestone"
          @submit.prevent="handleSubmit(updateProfile)"
        >
          <v-row justify="center">
            <v-col
              md="5"
              lg="3"
              pa="0"
              class="pa-0"
            >
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('milestone.create_milestone.milestone_name') }}
                <strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-text-field
                v-model="form.name"
                type="text"
                dense
                single-line
                filled
                placeholder="Name"
                :rules="milestoneNameRule"
                class="rounded-lg"
                clear-icon="body-2"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              md="5"
              lg="3"
              class="pa-0"
            >
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('milestone.create_milestone.startDate') }}
                <strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-menu
                v-model="form.startDate"
                :close-on-content-click="false"
                max-width="290"
              >
                <template 
                  v-slot:activator="{ on }" 
                >
                  <div class="calendar-textbox-container">
                    <v-text-field
                      dense
                      single-line
                      filled
                      color="transparent"
                      class="mr-0 custom_input rounded-lg calendar-textbox"
                      :value="startDate"
                      :rules="requiredRule"
                      placeholder="MM/DD/YY"
                      readonly
                      v-on="on"
                    />
                    <calendarBlueIcon 
                      class="calendar-icon"  
                      v-on="on"   
                    />
                  </div>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @change="form.startDate = null"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              md="5"
              lg="3"
              class="pa-0"
            >
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('milestone.create_milestone.dueDate') }}
                <strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-menu
                v-model="form.dueDate"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <div class="calendar-textbox-container">
                    <v-text-field
                      dense
                      single-line
                      filled
                      color="blue"
                      class="mr-0 custom_input rounded-lg calendar-textbox"
                      :value="dueDate"
                      placeholder="MM/DD/YY"
                      readonly
                      :rules="[...requiredRule,...dueDateRule]"
                      v-on="on"
                    />
                    <calendarBlueIcon 
                      class="calendar-icon"  
                      v-on="on"   
                    />
                  </div>
                </template>
                <v-date-picker
                  v-model="dueDate"
                  @change="form.dueDate = null"
                />
              </v-menu>
            </v-col>
          </v-row>
                 
          <v-row justify="center">
            <v-col
              md="5"
              lg="3"
              class="pa-0"
            >
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('milestone.create_milestone.status') }}
              </p>
              <v-select
                v-model="form.status"
                type="text"
                dense
                single-line
                height="38px"
                :items="statuses"
                clear-icon="body-2"
                append-icon="mdi-chevron-down"
                class="rounded-lg field-theme custom-prepend"
                background-color="#F9F9FB"
                :placeholder="$t('chooseStatus')"
                :menu-props="{ offsetY: true }"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              md="5"
              lg="3"
              class="pa-0"
            >
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('milestone.create_milestone.description') }}
              </p>
              <v-text-field
                v-model="form.description"
                type="text"
                dense
                single-line
                filled
                placeholder="Description"
                hide-details
                class="rounded-lg"
                clear-icon="body-2"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              md="5"
              lg="3"
              class="pa-0"
            >
              <p class="d-flex ma-0 mt-4 font-weight-medium body-2">
                {{ $t('tags') }}
              </p>
              <v-select
                v-model="form.tags"
                type="text"
                dense
                single-line
                filled
                :label="$t('tags')"
                hide-details
                :items="tags"
                item-text="name"
                item-value="name"
                class="rounded-lg"
                clear-icon="body-2"
                background-color="#F9F9FB"
                :menu-props="{ offsetY: true }"
                append-icon="mdi-chevron-down"
                multiple
              >
                <template v-slot:selection="{ item }">
                  <div class="d-flex align-center custom-chip-theme mr-1 mb-1">
                    <div class="text-theme-label label text-truncate mr-1">
                      {{ item.name }}
                    </div>
                    <v-icon
                      size="16px"
                      @click="onRemoveSelectedTags(item.name)"
                    >
                      mdi-close
                    </v-icon>
                  </div>
                </template>

                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-action>
                      <v-checkbox
                        hide-details
                        :input-value="tagsSelection(item.name)"
                        class="field-theme mt-0 pt-0"
                        :ripple="false"
                        off-icon="icon-checkbox-off"
                        on-icon="icon-checkbox-on"
                      >
                        <template v-slot:label>
                          <span class="fs-14px text-theme-label">{{ `${item.name}` }}</span>
                        </template>
                      </v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <v-row
        justify="end"
        class="mt-4"
      >
        <v-col cols="12">
          <v-flex class="mt-6 d-sm-flex justify-end">
            <v-btn
              color="gray-100"
              class="text-capitalize fw-semibold black--text rounded-lg mr-4 mt-2 box-shadow-none"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
              height="40px"
              @click="addTestActivities"
            >
              <v-icon>{{ 'mdi-plus' }}</v-icon> {{ $t('milestone.create_milestone.addTestActivities') }} 
            </v-btn>
            <v-btn
              depressed
              height="40px"
              color="blue"
              class="text-capitalize fw-semibold white--text rounded-lg mt-2 box-shadow-none"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
              :loading="isCreateDisabled"
              :class="{ 'disabled-action': isCreateDisabled }"
              @click="handleCreateMilestone"
            >
              {{ $t('save') }}
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-card>
    <MilestoneDiscardDialog
      v-model="showConfirmBackDialog"
      :title="$t('milestone.create_milestone.close_dialog.title')"
      :content="$t('milestone.create_milestone.close_dialog.content')"
      :btn_label="$t('milestone.create_milestone.close_dialog.confirm_button')"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmClick"
    />
    <RevertChangesDialog
      v-model="showRevertChangesDialog"
      :title="$t('milestone.revert_changes.title')"
      :content="$t('milestone.revert_changes.description')"
      :btn_label="$t('milestone.create_milestone.close_dialog.confirm_button')"
      @close="handleCloseRevert"
      @handleConfirmClick="handleConfirmRevert"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import MilestoneDiscardDialog from '@/components/Milestone/MilestoneDiscardDialog.vue';
import { showSuccessToast, showAlertToast, showErrorToast } from '@/utils/toast';
import calendarBlueIcon from '@/assets/svg/calendar-blue.svg';
import { dateMixin } from '@/mixins/date.js';
import makeTagsService from '@/services/api/tag';
import MilestoneTestActivitiesView from '@/views/Tests/Milestones/MilestoneTestActivitiesView'
import RevertChangesDialog from '@/views/Tests/Milestones/RevertChanges'
import { requiredAndMax255FieldValidationRules } from "@/utils/validation";
import makeMilestonesService from '@/services/api/milestone';

const { mapState: mapUserState } = createNamespacedHelpers('user');

let tagService;
let milestoneService;

export default {
  name: 'MilestoneCreate',
  components: {
    MilestoneDiscardDialog,
    calendarBlueIcon,
    MilestoneTestActivitiesView,
    RevertChangesDialog
  },
  mixins: [dateMixin],
  data() {
    return {
      milestoneNameRule: requiredAndMax255FieldValidationRules(this),
      requiredRule: [v => !!v || this.$t('thisFieldIsRequired')],
      dueDateRule: [ v => new Date(v) > new Date(this.startDate) || this.$t('milestone.create_milestone.dueDateError')],
      isCreateDisabled: false,
      showRevertChangesDialog: false,
      form: {
        name: '',
        description: '',
        status: '',
        users: [],
        startDate: null,
        dueDate: null,
        tags: [],
      },
      testActivities: {
        testPlans: [],
        testRuns: []
      },
      startDate: '',
      dueDate: '',
      showTestActivites: false,
      showConfirmBackDialog: false,
      showAddUsersView: false,
      toBeSelectedUser: [],
      selectedUser: [],
      tableFilter: 'all',
      search: '',
      appliedFilters: {
        roles: [],
        projects: [],
        tags: [],
      },
      tags: [],
      statuses: ['Active', 'Upcoming', 'Blocked'],
    };
  },
  created() {
    milestoneService = makeMilestonesService(this.$api);
  },
  computed: {
    ...mapUserState(['currentAccount']),
    ...mapGetters({ currentOrg: 'user/currentAccount', }),
    imageStyle() {
      return this.imageSrc ? { backgroundImage: `url(${this.imageSrc})` } : {};
    },
    _writeEntity(){
      return this.authorityTo('write_entity');
    },
    toBeSelectedUserLength() {
      return this.users?.filter((user) => user.toBeSelected).length;
    },
    selectedUserLength() {
      return this.users?.filter((user) => user.selected).length;
    },
    isAnyFilterApplied() {
      return this.appliedFilters.roles.length || this.appliedFilters.projects.length || this.appliedFilters.tags.length;
    },
  },
  async mounted() {
		tagService = makeTagsService(this.$api);
    this.users?.forEach((user) => {
      this.$set(user, 'toBeSelected', false);
    });
    this.getAllTags();
  },
  methods: {
    handleCloseRevert(){
      this.showRevertChangesDialog = false;
    },
    handleConfirmRevert(){
      this.testActivities = {
        testPlans: [],
        testRuns: []
      }
      this.showTestActivites = false;
      this.showRevertChangesDialog = false;
    },
    handleRevert(){
      this.showRevertChangesDialog = true;
    },
    saveAdded(){
      this.showTestActivites = false;
    },
    async handleCreateMilestone() {
      if (!this.$refs.milestoneForm.validate()) return;
      this.isCreateDisabled = true;
      const payload = {
        name: this.form.name,
        dueDate: new Date(this.dueDate),
        runIds: this.testActivities.testRuns.map(item => item.uid),
        planIds: this.testActivities.testPlans.map(item => item.uid),
        customFields: {
          startDate: new Date(this.startDate),
          status: this.form.status,
          description: this.form.description,
          runstatus: 'open',
          progress: 0,
          tags: this.form.tags,
        },
        externalId: 'testfiesta',
        source: 'testfiesta-web',
      };

      try {
        await milestoneService.createMilestone(this.$route.params.handle, this.$route.params.key, payload);
        showSuccessToast(this.$swal, this.$t('milestone.create_milestone_success'));
        this.$router.push({
          name: 'Milestones',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key
          },
        });
      } catch (error) {
        this.isCreateDisabled = false;
        showErrorToast(this.$t('error.milestoneCreateFailed'));
        console.error('Failed to create milestone:', error);
      }

    },

    addTestActivities(){
      this.showTestActivites = true;
    },

    handleBackClick() {
      if (this.form.name || this.form.startDate || this.form.dueDate || this.form.status || this.form.description) {
        this.showConfirmBackDialog = true;
        return;
      }

      this.$router.replace({
        name: 'Milestones',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      });
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;

      this.$router.replace({
        name: 'Milestones',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      });
    },
    async getAllTags() {
      try {
        const response = await tagService.getTags(
          this.$route.params.handle,
          'milestones'
        );
        if (response.status === 200) {
          this.tags = response.data;
        }
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'Tag' });
      }
    },

    onRemoveSelectedTags(name) {
      const index = this.form.tags.findIndex((item) => item === name);
      if (index !== -1) {
        this.form.tags.splice(index, 1);
      }
    },

    tagsSelection(name) {
      return this.form.tags.some((item) => item === name);
    },
  },
};
</script>

<style lang="scss" scoped>

.calendar-icon {
  position: absolute;
  right: 12px;
  top: 9px;
}

.box-shadow-none {
  box-shadow: none;
}

.calendar-textbox-container {
  position: relative;
}

.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}

.search_input {
  width: 100%;

  @media screen and (min-width: 600px) {
    width: 300px;
  }
}

.project-logo {
  border-radius: 50%;
  border: 2px dashed grey;
  width: 150px;
  height: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
}

.project-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}

.edit-icon,
.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.delete-icon {
  margin-left: 60px;
}

.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}
</style>
