<template>
  <div
    id="case-index-container"
    fluid
    class="pl-3 pt-3"
  >
    <StatusesDialog
      v-model="statusDialog"
      @completed="handleCompleteClick"
    />
    <v-card
      class="py-6 px-6"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <div class="d-flex flex-row align-center justify-space-between">
        <div class="d-flex flex-row align-center">
          <button
            class="d-flex flex-row align-center pointer mr-3"
            @click="handleBackClick"
          >
            <v-icon color="black">
              mdi-arrow-left
            </v-icon>
          </button>
          <v-tooltip
            bottom
            max-width="430px"
            :disabled="testPlanName.length < 61"
            content-class="tooltip-theme"
          >
            <template v-slot:activator="{ on, attrs }">
              <h2 
                class="custom__tooltip__title"
                v-bind="attrs"
                v-on="on"
              >
                {{ testPlanName }}
              </h2>
            </template>
            <span>{{ testPlanName }}</span>
          </v-tooltip>
          <div
            class="d-flex flex-row align-center justify-space-between ml-6"
          >
            <ProgressBar
              :executions="testExecutionsProgress"
              :percentage="testPercentage"
              :case-count="testPlanExecutionsCount"
            />
          </div>
        </div>
        <div
          v-if="_writeEntity"
          class="d-flex flex-row justify-end"
        >
          <v-btn
            color="#F2F4F7"
            :class="{'text-capitalize btn-theme rounded-lg mr-3': true, 'disabled-action': isProjectArchived }"
            height="40px"
            depressed
            :loading="btnRerunLoading"
            @click="handleRerunClick"
          >
            {{ $t('rerun') }}
          </v-btn>
          <v-btn
            depressed
            color="primary"  
            height="40px"
            :class="{'text-capitalize btn-theme rounded-lg mr-3': true, 'disabled-action': isProjectArchived }"
            :loading="btnCompleteLoading"
            @click="statusDialog = true"
          >
            {{ $t('Complete') }}
          </v-btn>
        </div>
      </div>
    </v-card>
    <div>
      <v-card
        class="py-6 px-6 mt-3 app-height-global"
        rounded="lg"
        elevation="0"
        width="100%"
      >
        <div class="d-flex justify-space-between">
          <div class="d-flex justify-start">
            <search-field
              v-model="searchFilter"
              class="search-input mr-2"
              :placeholder="$t('searchByName')"
            />
            <PlanFilter 
              :configurations="configurations" 
              :milestones="activeMilestones"  
              :tags="tags"
              @applyFilters="applyFilters" 
            />
          </div>

          <SettingsMenu 
            table-type="testPlanRerun" 
          />
        </div>
        
        <PlansList
          :write-entity="_writeEntity"
          :delete-entity="_deleteEntity"
          :filtered-headers="filteredHeaders"
          :filtered-items="filteredRuns"
          :item-key="itemKey"
          :row-class="rowClass"
          :clear-selection="clearSelection"
          @edit-item="editItem"
          @select-row="handleRowClick"
          @select-item="setselected"
          @delete-item="confirmDeleteRun"
        />

        <select-test-run-status-dialog
          v-model="showRerunDialog"
          @close="handleCloseRerunDialog"
          @rerun="handleRerunPlan"
        />
      </v-card>
    </div>

    <PlansListFilter
      :open="isOpenFilter"
      @filters="applyFilters"
      @close="isOpenFilter = false"
    />

    <PlanDiscardDialog
      v-model="showConfirmDeleteDialog"
      :title="$t('plans.deleteDialog.title')"
      :content="$t('plans.deleteDialog.content')"
      :run_name="selectedRunName"
      :btn_label="$t('delete')"
      color="danger"
      @close="handleCloseClick"
      @handleConfirmClick="handleDeleteRun"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, createNamespacedHelpers, mapState } from 'vuex';
import StatusesDialog from '@/components/TestPlans/StatusesDialog'
import SearchField from '@/components/Form/SearchField';
import PlansList from '@/views/Tests/Plans/Components/List/PlansList';
import SelectTestRunStatusDialog from '@/views/Tests/Plans/Components/SelectTestRunStatusDialog';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import PlanFilter from '@/components/TestPlans/PlanFilter.vue';
import PlansListFilter from '@/views/Tests/Plans/Components/List/PlansListFilter';
import PlanDiscardDialog from '@/components/TestPlans/PlanDiscardDialog.vue';

import makeMilestonesService from '@/services/api/milestone'
import makePlanService from '@/services/api/plan'
import makeConfigurationService from '@/services/api/configuration' 
import makeTagService from '@/services/api/tag';
import makeRunService from '@/services/api/run';

import { showErrorToast, showSuccessToast } from '@/utils/toast';
import { sleep } from '@/utils/util';
import projectStatus from '@/mixins/projectStatus';
import ProgressBar from '@/components/base/ProgressBar.vue';

let planService;
let runsService;

export default {
  components: {
    SelectTestRunStatusDialog,
    SearchField,
    SettingsMenu,
    PlanFilter,
    PlansListFilter,
    PlansList,
    PlanDiscardDialog,
    ProgressBar,
    StatusesDialog,
  },
  mixins: [projectStatus],
  data() {
    return {
      showRerunDialog: false,
      menuOpen: false,
      headers: [],
      isOpenFilter: false,
      appliedFilters: null,
      itemKey: 'uid',
      clearSelection: false,
      rowClass: () => 'project-item',
      milestones: [],
      configurations: [],
      tags: [],
      isFilter: false,
      searchFilter: '',
      selecteditems: [],
      selectedRun: {},
      btnCompleteLoading: false,
      btnRerunLoading: false,
      showConfirmDeleteDialog: false,
      statusDialog: false,
      testPlan: {
        customFields: {}
      },
    };
  },
  computed: {
    ...mapGetters({
      currentOrg: 'user/currentAccount',
      dynamicHeaders:'headers/dynamicHeaders'
    }),
    _writeEntity(){
      return this.authorityTo('write_entity')
    },
    _deleteEntity(){
      return this.authorityTo('delete_entity')
    },
    activeMilestones() {
      return this.milestones.filter((milestone) => milestone.archivedAt === null && milestone.deletedAt === null);
    },
    filteredHeaders() {
      const filtered = this.headers.filter((header) => header.checked);
      return filtered
    },
    runs() {
      return this.testPlan?.runs || [];
    },
    isRunsHasData() {
      return this.runs.length > 0;
    },
    selectedRunName() {
      return this.selectedRun?.name || '';
    },
    testPlanName() {
      return this.testPlan?.name || '';
    },
    testExecutionsProgress(){
      return this.testPlan?.executionsProgress || {};
    },
    testPercentage(){
      return this.testPlan?.percentage || 0;
    },
    testPlanExecutionsCount(){
      return Array.isArray(this.testPlan?.runs) ? this.testPlan.runs.reduce((total, run) => total + (Array.isArray(run.testExecutions) ? run.testExecutions.length : 0), 0) : 0;

    },
    filteredRuns() {
      let filtered = this.runs;
      if (this.searchFilter) {
        filtered = filtered.filter((item) => this.matchesFilter(item));
      }
      if (this.isFilter && this.appliedFilters) {
        filtered = filtered.filter((item) => this.matchApplyFilter(item));
      }
      return filtered;
    },
    listHeaders() {
      const filtered = this.headers.filter((header) => header.checked);
      if (filtered.length < this.headers.length)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false
      return filtered;
    },
  },
  created() {
    if(!this.dynamicHeaders.testPlanRerun) {
      this.initializeHeaders({ type: 'testPlanRerun' });
    }
    this.headers = this.dynamicHeaders.testPlanRerun;
    planService = makePlanService(this.$api);
    runsService = makeRunService(this.$api);
  },
  mounted() {
    this.init();  
  },
  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    handleTab(value) {
      this.tab = value;
    },
    handleCloseClick() {
      this.showConfirmDeleteDialog = false;
      this.selectedRun = {};
      this.clearSelection = true;
    },
    handleRerunClick() {
      this.showRerunDialog = true;
    },
    handleCloseRerunDialog() {
      this.showRerunDialog = false;
    },
    handleEditClick() {
      this.isEditPlan = true;
    },
    handleBackClick() {
      this.$router.replace({ name: 'TestPlans' });
    },
    async init() {
      this.loaderState = false;
      this.tableLoadingState = true;
      let loaderStateTimeout = null;

      loaderStateTimeout = setTimeout(() => {
        this.loaderState = true;
      }, 1000);
      try {
        await Promise.all([
          this.findTestPlan(),
          this.getMilestones(),
          this.getConfigurations(),
          this.getTags()
        ]);
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'plans' });
      } finally {
        clearTimeout(loaderStateTimeout); 
        const hideLoader = async () => {

          await sleep(1000); 
          this.loaderState = false; 
          this.tableLoadingState = false;
        };
        hideLoader(); 
      }
    },
    async duplicateTestPlan(){
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const planId = Number(this.$route.params.planId);
      let selectedItemsUids  = this.selecteditems.map(item => item.uid)
      let data = {
        planUid: planId,
        runUids: selectedItemsUids
      }
      try {
        const response = await planService.duplicateTestPlan(handle, projectKey, data);
        return response;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'milestones' });
        return [];
      }
    },  
    editItem(item) {
      this.selectedRun = item;
      this.$router.push({
        name: 'TestRunEdit',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          id: item.uid,
        },
      });
    },
    async refreshData() {
      await this.findTestPlan()
    },
    async getMilestones() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const milestoneService = makeMilestonesService(this.$api);
      try {
        const response = await milestoneService.getMilestones(handle, projectKey);
        this.milestones = response.data?.milestones;
        return response.data?.milestones;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'milestones' });
        return [];
      }
    },
    async findTestPlan(){
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const planId = this.$route.params.planId;
      try {
        const response = await planService.findTestPlan(handle, projectKey, planId);
        this.testPlan = response.data;
        return response.data;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'plans' });
        return [];
      }
    },
    async getConfigurations() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const configurationService = makeConfigurationService(this.$api);
      try {
        const response = await configurationService.getConfigurations(handle, projectKey, 10, 0);
        this.configurations = response.data?.configurations;
        return response.data?.configurations;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'configurations' });
        return [];
      }
    },
    async getTags() {
      const handle = this.$route.params.handle;
      const tagService = makeTagService(this.$api);
      try {
          const response = await tagService.getTags(handle);
          this.tags = response.data
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'tags' });
        return [];
      }
    },
    async handleRowClick(item) {
      this.$router.push({
          name: 'TestRunCaseEdit',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
            id: item.uid,
          }
      });
    },
    confirmDeleteRun(item) {
      this.selectedRun = item;
      this.showConfirmDeleteDialog = true;
    },
    async handleDeleteRun(){
      try {
        await runsService.deleteTestRun(this.$route.params.handle, this.$route.params.key, this.selectedRun.uid);
        await this.refreshData()
        showSuccessToast(this.$swal, this.$t('toast.deleteSuccess', { item: this.$t('plans.title_single') }));
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'deleteError', { item: 'Test run' });
      } finally {
        this.handleCloseClick();
      }

    },
    async handleRerunPlan(){
      try {
        this.btnRerunLoading = true;
        this.showRerunDialog = false;

        if (!this.isRunsHasData) {
          showErrorToast(
            this.$swal,
            this.$t('toast.planRunsRerunError', { item: this.$t('plans.title_single') })
          );
          return; 
        }

        const response = await this.duplicateTestPlan();
        if(response.status === 200){
          showSuccessToast(this.$swal, `${this.$t('rerunned')} "${ this.testPlanName }" ${this.$t('testPlan') }`);
          // this.$router.replace({ name: 'TestPlans' });
        }
      } catch (error) {
        showErrorToast(this.$swal, this.$t('toast.fetchError', { item: 'plans' }));
      } finally {
        this.btnRerunLoading = false;
      }
    },
    matchApplyFilter(item) {
      // Check if appliedFilters are not empty before checking each filter
      if (this.appliedFilters?.panel_priority.length > 0 && 
          !this.appliedFilters.panel_priority.includes(item.priority)) {
        return false;
      }
      if (this.appliedFilters?.panel_status.length > 0 && 
          !this.appliedFilters.panel_status.includes(item.status)) {
        return false;
      }
      if (this.appliedFilters?.panel_milestone.length > 0 && 
          !this.appliedFilters.panel_milestone.includes(item.customFields.milestone) && 
          !this.appliedFilters.panel_milestone.includes('None')) {
        return false;
      }

      if (this.appliedFilters?.panel_tag.length > 0 && 
          !this.appliedFilters.panel_tag.includes(item.customFields.tags) && 
          !this.appliedFilters.panel_tag.includes('none')) {
        return false;
      }
      if (this.appliedFilters?.testcases_range[0] > item.customFields.caseCount || 
          this.appliedFilters?.testcases_range[1] < item.customFields.caseCount) {
        return false;
      }
      if (this.appliedFilters?.progress_range[0] > item.customFields.progress || 
          this.appliedFilters?.progress_range[1] < item.customFields.progress) {
        return false;
      }
      if (this.appliedFilters?.dateRange.start && this.appliedFilters?.dateRange.start > item.createdAt || 
          this.appliedFilters?.dateRange.end && this.appliedFilters?.dateRange.end < item.createdAt) {
        return false;
      }
      return true;
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      return item.name.toLowerCase().includes(lowerCaseFilter)
    },
    applyFilters(filters) {
      if (filters) {
        this.appliedFilters = filters;
        this.isFilter = true;
      } else {
        this.isFilter = false;
      }
      this.refreshData()
    },
    setselected(selecteditems) {
      this.clearSelection = false
      this.selecteditems = selecteditems
    },
    async handleCompleteClick(status){
      const payload = {
        finalStatus: status
      }
      try {
        this.btnCompleteLoading = true;
        this.statusDialog = false;
        const response = await planService.updateTestPlan(this.currentAccount.handle, this.$route.params.key, this.$route.params.planId, payload);
        if(response.status === 200){
          showSuccessToast(this.$swal, this.$t('toast.updateSuccess', { item: this.$t('plans.title_single') }));
          this.refreshData();
          this.$router.replace({ name: 'TestPlans' });
        }
      } catch (error) {
        showErrorToast(this.$swal, this.$t('toast.fetchError', { item: this.$t('plans.title_single') }));
      } finally {
        this.btnCompleteLoading = false;
      }

    },
    
  },
};
</script>

<style lang="scss">
.test-plans-detail {
  height: 100%;
  max-height: calc(100vh - 90px);
  background: #f2f4f7;

  display: flex;
  flex-direction: column;
}

.test-plans-detail-wrapper {
  height: 100%;
  max-height: calc(100% - 116px - 8px);
  background: #ffffff;
  border-radius: 8px;
}

.edit-inputs{
  max-width: 476px;
}

.v-input {
  .v-input__control {
    .v-input__slot {
      textarea{
        line-height: 14px;
      }
    }
  }
}

.none{
  display: none;
}

.btn-show{
  display: block !important;
}

.action-button{
  width: 140px;
}
</style>