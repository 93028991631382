<template>
  <div>
    <v-data-table
      v-if="filteredItems && filteredItems.length > 0"
      hide-default-footer
      :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
      class="custom-table data-table-style mt-6"
      :headers="filteredHeaders || []"
      :items="filteredItems"
      :item-key="itemKey"
      :item-class="() => 'integration-row'"
      @click:row="onRowClick"
    >
      <!-- Integration Name Header -->
      <template v-slot:[`header.name`]="{ header }">
        <span class="header_text ml-3">
          {{ header.text }}
        </span>
      </template>

      <!-- Projects Header -->
      <template v-slot:[`header.projects`]="{ header }">
        <div class="text-center w-100">
          <span class="header_text">
            {{ header.text }}
          </span>
        </div>
      </template>

      <!-- Integration Name Slot -->
      <template v-slot:[`item.name`]="{ item }">
        <div class="d-flex align-center">
          <v-avatar
            class="mr-2"
            size="40"
          >
            <v-img
              :src="item.avatarUrl"
              :lazy-src="require('@/assets/png/project.png')"
              width="40px"
              alt="logo"
            />
          </v-avatar>
          <div class="text-start">
            <div class="fw-semibold fs-14px">
              {{ item.name }}
            </div>
            <div class="text-theme-secondary fs-12px text-truncate mw-100px font-weight-regular">
              {{ item.service }}
            </div>
          </div>
        </div>
      </template>

      <!-- Project Avatars Slot -->
      <template v-slot:[`item.projects`]="{ item }">
        <div class="d-flex justify-center w-100">
          <div class="avatar-group">
            <div
              v-for="(logo, index) in item.projectLogos.slice(0, 4)"
              :key="index"
            >
              <v-avatar
                class="ml-n2 custom_border"
                size="40"
              >
                <v-img
                  :src="logo || require('@/assets/png/project.png')"
                  :lazy-src="require('@/assets/png/project.png')"
                />
              </v-avatar>
            </div>
            <v-avatar
              v-if="item.projectUids?.length > 4"
              size="30"
              class="extra-avatar ml-n2"
            >
              <small>+{{ item.projectUids.length - 4 }}</small>
            </v-avatar>
          </div>
        </div>
      </template>

      <!-- Actions Column -->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-end">
          <v-btn
            icon
            @click.stop="onEdit(item)"
          >
            <v-sheet
              color="#F2F4F7"
              class="d-flex align-center justify-center pointer"
              height="40px"
              rounded="lg"
            >
              <EditIcon />
            </v-sheet>
          </v-btn>
          <v-btn
            icon
            @click.stop="onDelete(item)"
          >
            <DeleteIcon />
          </v-btn>
        </div>
      </template>
    </v-data-table>
    
    <!-- Empty state -->
    <div 
      v-else 
      class="d-flex align-center justify-center py-8"
    >
      <p class="text-subtitle-1">
        {{ $t('integrations.no_data') }}
      </p>
    </div>

    <!-- Edit Integration Component -->
    <EditIntegration
      v-if="showEditDialog"
      :edited-data="editedData"
      @integration-updated="$emit('integration-updated')"
      @refresh="$emit('refresh')"
      @close-dialog="showEditDialog = false"
    />
  </div>
</template>

<script>
import DeleteIcon from '../../assets/svg/delete.svg';
import EditIcon from '../../assets/svg/edit.svg';
import EditIntegration from './EditIntegration.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    DeleteIcon,
    EditIcon,
    EditIntegration,
  },
  props: {
    filteredHeaders: {
      type: Array,
      default: () => []
    },
    filteredItems: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    rowClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editedData: null,
      showEditDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      dynamicHeaders: 'headers/dynamicHeaders'
    }),
  },
  watch: {
    'dynamicHeaders.integration': {
      handler(newHeaders) {
        if (newHeaders) {
          this.$emit('headers-updated', newHeaders.filter(header => header.checked));
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('headers', ['initializeHeaders']),
    onRowClick(item) {
      this.$emit('select-item', item);
    },
    onEdit(item) {      
      this.editedData = {
        uid: item.uid,
        name: item.name,
        description: item.description,
        avatarUrl: item.avatarUrl,
        service: item.service,
        configurations: item.configuration.projectConfigurations,
      };
      this.showEditDialog = true;
    },
    onDelete(item) {
      this.$emit('delete-item', item.uid);
    },
  }
};
</script>

<style scoped>
.avatar-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-group v-avatar:first-child {
  margin-left: 0;
}

.extra-avatar {
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #555;
}
.v-data-table .v-data-table__wrapper tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.v-data-table-header__icon {
  opacity: 1 !important;
}

.v-data-table .v-data-table__wrapper tbody tr:nth-child(even) {
  background-color: #f9fafb;
}

.pointer {
  cursor: pointer;
}

.header_text {
  color: #475467;
  font-weight: 700;
}

.custom_border {
  border: 2px solid #ffffff;
}

.v-data-table table {
  border-collapse: collapse;
}

.v-data-table th {
  border: none !important;
}

.v-data-table td {
  border: none !important;
}

.v-data-table .v-data-table__wrapper tbody tr {
  height: 80px;
}

.v-data-table .v-data-table__wrapper tbody tr td {
  height: 80px !important;
  padding-top: 0;
}

.v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background-color: transparent !important;
}

.w-100 {
  width: 100%;
}
</style>
