import { Axios } from 'axios';

/**
 *
 * @param {Axios} api
 * @returns
 */

export default function makeRoleService(api) {
  return {
    getPermissions: async function(handle){
      return api.get(`/${handle}/permissions`, {
        baseURL: process.env.VUE_APP_SERVER_INTERNALURL,
      })
    },
    getRoles: async function (handle) {
      return api.get(`/${handle}/roles`);
    },
    getRoleMembers: async function (handle, userId) {
      return api.get(`/${handle}/members/${userId}/roles`);
    },
    getRoleDetails: async function (handle, roleId) {
      return api.get(`/${handle}/roles/${roleId}`);
    },
    createRole: function (handle, payload) {
      return api.post(`/${handle}/roles`, payload);
    },
    updateRole: function (handle, payload, roleId) {
      return api.patch(`/${handle}/roles/${roleId}`, payload);
    },
    deleteRole: function (handle, roleId) {
      return api.delete(`/${handle}/roles/${roleId}`);
    },
    deleteRoles: function (handle, payload) {
      return api.delete(`/${handle}/roles`, { data: payload });
    },
    reAssignRole: function (handle, roleId, payload) {
      return api.post(`/${handle}/roles/${roleId}/members/reassign`, payload);
    },
    deleteMembersFromRole: function (handle, roleId, payload) {
      return api.delete(`/${handle}/roles/${roleId}/members`, { data: payload });
    },
  };
}
