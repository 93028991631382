<template>
  <div style="width: 100%">
    <div
      v-if="data.id"
      class="d-flex justify-space-between align-center mb-5"
    >
      <div class="apex_chart-filters d-flex align-center flex-wrap">
        <h3>Split</h3>
        <PriorityStatusFilter
          :statuses="data.status"
          :priorites="data.priority"
          :split-by="data.split"
          :chart-id="data.id"
        />
        <EntitySelector
          :entity="data.entityType"
          :chart-id="data.id"
          @updateEntity="updateEntity"
        />
        <ChartViewFilter
          v-if="data.entityType !== 'milestone'"
          :split="data.split"
          @changeSplit="changeSplit"
        />
      </div>
      <div class="chart-settings">
        <v-menu
          left
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list
            dense
            class="text-left"
          >
            <v-list-item
              @click="$emit('deleteChart', data.id)"
            >
              <DeleteIcon />
              <v-list-item-content class="ml-2 error--text">
                {{ $t('delete') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <vue-apex-charts
      class="d-flex justify-center"
      height="250"
      width="550"
      :options="options"
      :series="series"
    />
  </div>
</template>
<script>
import PriorityStatusFilter from '@/components/Dashboard/Filters/PriorityStatusFilter'
import EntitySelector from '@/components/Dashboard/Filters/EntitySelector'
import ChartViewFilter from '@/components/Dashboard/Filters/ChartViewFilter'
import DeleteIcon from '@/assets/svg/delete.svg';
import VueApexCharts from 'vue-apexcharts'
export default{
components:{
  VueApexCharts,
  PriorityStatusFilter,
  EntitySelector,
  ChartViewFilter,
  DeleteIcon
},
props:{
  data:{
    type: Object,
    required: true
  },
},
data(){
  return {
    options: {
      chart:{
        type: 'donut',

      },
      responsive: [
        {
          breakpoint: 1780,
          options:{
            legend: {
              position: 'bottom',
              itemMargin: {
                horizontal: 10,
                vertical: 0,
              },
              floating: false,
            }
          }
        },
      ],
      legend: {
        floating: false,
        show: true,
        position: 'left',
        horizontalAlign: 'center',
        fontWeight: 400,
        fontSize: '16px',
        itemMargin: {
          horizontal: 24,
          vertical: 10,
        },
      },
      plotOptions: {
      pie: {
        expandOnClick: false,
          donut: {
            size: "85%",
              labels: {
                  show: true,
                  total: {
                      show: true,
                      label: this.data.name,
                      fontSize: '22px',

                  }
              }
          },
        }
      },
      labels: this.data[this.data.split].map((item) => {
        if(item.selected)
          return `${item.name} (${item.value})`
      }).filter(Boolean),
      colors: this.data[this.data.split].map((item) => {
        if(item.selected)
          return item.color
        }).filter(Boolean),
      dataLabels: {
        formatter: (val, { seriesIndex, w }) => w.config.series[seriesIndex],
        enabled: false,
      }
    },
  }
},
computed:{
  series(){
    return this.data[this.data.split].map((item) => {
      if(item.selected)
        return item.value
    }).filter(Boolean)
  },
},
methods:{
  changeSplit(split){
    this.data.split = split
  },
  updateEntity(entity, chartId){
    this.$emit('updateEntity', entity, chartId)
  }
}
}
</script>
<style>
.apexcharts-legend {
text-align: left !important;
justify-content: center !important;
}
.apexcharts-legend .apexcharts-legend-marker{
margin-right: 10px;
}
</style>