<template>
  <v-container fluid>
    <section-header
      :title="$t('settingsPage.apiKeys')"
      :action-text="$t('settingsPage.createApiKey')"
      @create="onCreateApiKey"
    />

    <v-card
      class="py-6 px-6 mt-3"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-row
        justify="space-between"
        class="align-center"
      >
        <v-col
          cols="12"
          md="auto"
          class="d-flex align-center"
        >
          <SearchComponent
            :search="filter.name"
            :placeholder="$t('placeHolder.searchByName')"
            @update:search="filter.name = $event"
          />
        </v-col>
      </v-row>

      <ApiKeyTable
        :headers="headers"
        :items="filteredApiKeys"
        item-key="uid"
        @edit="onEditApiKey"
        @delete="onDeleteApiKey"
      />
    </v-card>

    <CreateUpdateDialog
      v-model="showCreateUpdateDialog"
      :data="selectedApiKey"
      @create-api-key="createApiKey"
      @update-api-key="updateApiKey"
      @close-dialog="showCreateUpdateDialog = false"
    />

    <NewApiKeyCopyDialog
      v-model="showNewApiKeyCopyDialog"
      :new-api-key="newApiKey"
      @close-dialog="showNewApiKeyCopyDialog = false"
    />

    <DeleteConfirmDialog
      v-model="showDeleteConfirmDialog"
      :api-key="selectedApiKey"
      @delete="deleteApiKey"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import * as _ from 'lodash'

import makeOrgService from '@/services/api/org';
import { showSuccessToast, showErrorToast } from '@/utils/toast';

import SectionHeader from '@/components/Form/SectionHeader.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import ApiKeyTable from '@/components/Settings/ApiKey/ApiKeyTable.vue';
import CreateUpdateDialog from '@/components/Settings/ApiKey/CreateUpdateDialog.vue';
import NewApiKeyCopyDialog from '@/components/Settings/ApiKey/NewApiKeyCopyDialog.vue';
import DeleteConfirmDialog from '@/components/Settings/ApiKey/DeleteConfirmDialog.vue';

export default {
  name: 'ApiKeys',

  components: {
    SectionHeader,
    ApiKeyTable,
    CreateUpdateDialog,
    SearchComponent,
    NewApiKeyCopyDialog,
    DeleteConfirmDialog,
  },

  data() {
    return {
      filter: {
        name: '',
      },
      tokens: [],
      originalApiKeys: [],
      filteredApiKeys: [],
      headers: [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: true,
          value: 'name',
          class: 'elevation-0 rounded-l-lg',
        },
        {
          text: this.$t('common.createdBy'),
          value: 'createdBy',
          sortable: true,
        },
        {
          text: this.$t('expiration'),
          value: 'expiresAt',
          sortable: true,
        },
        {
          text: this.$t('settingsPage.apiKey'),
          value: 'accessToken',
          sortable: true,
        },
        {
          text: this.$t('settingsPage.lastUsedAt'),
          value: 'lastUsedAt',
          sortable: true,
        },
        {
          text: '',
          value: 'uid',
          sortable: false,
          class: 'rounded-r-lg',
          width: 130,
        },
      ],
      showCreateUpdateDialog: false,
      selectedApiKey: {
        uid: '',
        name: '',
        expiresAt: null,
      },
      isLoading: false,
      errorMessage: '',
      showNewApiKeyCopyDialog: false,
      newApiKey: '',
      showDeleteConfirmDialog: false,
    }
  },

  computed: {
    ...mapGetters({
      currentAccount: 'user/currentAccount',
    }),
  },

  watch: {
    'filter.name': {
      handler: _.debounce(function () {
        this.filterApiKeys()
      }, 500),
    },
  },

  mounted() {
    this.loadAccessTokens()
  },

  methods: {
    async loadAccessTokens() {
      try {
        const orgService = makeOrgService(this.$api);
        const response = await orgService.getAccessTokens(this.currentAccount.handle);
        this.tokens = response.data.accessTokens;
      } catch (err) {
        showErrorToast(this.$swal, err.response.data[0].msg)
      }

      this.filterApiKeys()
    },

    onCreateApiKey() {
      this.selectedApiKey = {
        uid: '',
        name: '',
        expiresAt: null,
      }

      this.showCreateUpdateDialog = true
    },

    async createApiKey(apiKey) {
      this.showCreateUpdateDialog = false

      const orgService = makeOrgService(this.$api);

      try {
        const response = await orgService.createAccessToken(this.currentAccount.handle, this.currentAccount.uid, apiKey)

        this.newApiKey = response.data.accessToken
        this.showNewApiKeyCopyDialog = true

        await this.loadAccessTokens()
      } catch (err) {
        showErrorToast(this.$swal, err.response.data[0].msg)
      } finally {
        this.isLoading = false
      }
    },

    onEditApiKey(item) {
      this.selectedApiKey = {
        uid: item.uid,
        name: item.name,
        expiresAt: item.expiresAt,
      }

      this.showCreateUpdateDialog = true
    },

    async updateApiKey(apiKey) {
      this.showCreateUpdateDialog = false

      const orgService = makeOrgService(this.$api);

      try {
        const response = await orgService.updateAccessToken(this.currentAccount.handle, this.currentAccount.uid, apiKey)

        this.newApiKey = response.data.accessToken
        this.showNewApiKeyCopyDialog = true

        await this.loadAccessTokens()
      } catch (err) {
        showErrorToast(this.$swal, err.response.data[0].msg)
      } finally {
        this.isLoading = false
      }
    },

    onDeleteApiKey(item) {
      this.selectedApiKey = {
        uid: item.uid,
        name: item.name,
        expiresAt: item.expiresAt,
      }

      this.showDeleteConfirmDialog = true
    },

    async deleteApiKey() {
      this.showDeleteConfirmDialog = false

      const orgService = makeOrgService(this.$api);

      try {
        await orgService.deleteAccessToken(this.currentAccount.handle, this.currentAccount.uid, {
          accessTokenId: this.selectedApiKey.uid,
        })

        showSuccessToast(this.$swal, 'API Key is deleted')

        this.originalApiKeys = this.originalApiKeys.filter(item => item.uid !== this.selectedApiKey.uid)
      } catch (err) {
        showErrorToast(this.$swal, err.response.data[0].msg)
      } finally {
        this.isLoading = false
      }

      this.filterApiKeys()
    },

    filterApiKeys() {
      let filteredApiKeys = _.cloneDeep(this.originalApiKeys)

      if (this.filter.name) {
        filteredApiKeys = filteredApiKeys.filter(item => item.name.includes(this.filter.name))
      }

      this.filteredApiKeys = filteredApiKeys
    }
  }
}
</script>