<template>
  <v-menu
    left
    :close-on-content-click="false"
    offset-y
    origin="bottom right"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        class="rounded-lg text-none"
        height="40"
        min-width="40"
        elevation="0"
        v-on="on"
      >
        <span class="mr-1">{{ $t('filters') }}</span>
        <v-icon size="16px">
          mdi-filter-variant
        </v-icon>
      </v-btn>
    </template>
    <div class="d-flex">
      <div
        v-if="statuses && statuses.length"
        class="statuses-list"
      >
        <v-list class="d-flex flex-column justify-space-between rounded-xl">
          <v-list-item>{{ $t('status') }}</v-list-item>
          <v-list-item
            v-for="(status, index) in statuses"
            :key="index"
            class="custom-menu-item"
            :disabled="splitBy && splitBy != 'status'"
          >
            <v-checkbox 
              v-model="status.selected"
              item-value="selected"
              :disabled="splitBy && splitBy != 'status'"
              off-icon="mdi-checkbox-blank"
              class="ma-0 pa-0" 
              color="blue"
              small
              :ripple="false"
              hide-details
              @change="updateSelected('status',statuses)"
            >
              <template v-slot:label>
                <div class="custom-color-0c111d font-inter custom-text-14">
                  {{ status.name }}
                </div>
              </template>
            </v-checkbox>
          </v-list-item>
        </v-list>
      </div>
      <div
        v-if="priorites && priorites.length"
        class="priorities-list"
      >
        <v-list class="d-flex flex-column justify-space-between rounded-xl">
          <v-list-item>{{ $t('priority') }}</v-list-item>
          <v-list-item
            v-for="(priority, index) in priorites"
            :key="index"
            class="custom-menu-item"
            :disabled="splitBy && splitBy != 'priority'"
          >
            <v-checkbox 
              v-model="priority.selected" 
              :value="priority.selected" 
              :true-value="true"
              :false-value="false"
              :disabled="splitBy && splitBy != 'priority'" 
              off-icon="mdi-checkbox-blank"
              class="ma-0 pa-0" 
              color="blue"
              small
              :ripple="false"
              hide-details
              @change="updateSelected('priority',priorites)"
            >
              <template v-slot:label>
                <div class="custom-color-0c111d font-inter custom-text-14">
                  {{ priority.name }}
                </div>
              </template>
            </v-checkbox>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </v-menu>
</template>
  <script>
  export default{
    props:{
      statuses:{
        type: Array
      },
      priorites: {
        type: Array
      },
      splitBy: {
        type: String,
      },
      chartId: {
        type: Number,
      }
    },
    methods:{
      updateSelected(type, values){
        this.$parent.$emit('updatedSelected' ,type, values, this.chartId)
      }
    }
  }
  </script>
  <style scoped>
  .custom-menu-item{
    min-height: 35px;
  }
  </style>