<template>
  <v-card
    class="white py-4 px-7"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex align-center justify-space-between">
      <h2 class="text-theme-base">
        {{ title }}
      </h2>
      <div v-if="writeEntity">
        <v-btn
          class="tray-btn-margin tray-btn-outline mr-3"
          depressed
          :disabled="isProjectArchived" 
          :to="{ name: 'ImportCSV', query:{folderUid} }"
        >
          <v-icon size="18">
            mdi-tray-arrow-down
          </v-icon>
        </v-btn>
        <v-btn
          v-if="hasAction"
          :class="{
            'text-capitalize tray-btn-outline btn-theme': true,
            'disabled-action': isProjectArchived
          }"
          color="primary"
          :depressed="true"
          height="40"
          @click="navigateToCreateTestCases(folderUid)" 
        >
          {{ actionText }}
          <v-icon
            class="ml-3"
            size="20"
          >
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
    </div>
    <slot name="additional-actions" />
  </v-card>
</template>

<script>
import projectStatus from '@/mixins/projectStatus';

export default {
  name: 'SectionHeader',
  mixins: [projectStatus],
  props: {
    title: String,
    actionText: String,
    hasAction: {
      type: Boolean,
      default: true,
    },
    folderUid: [String, Number],
    writeEntity: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localCaseItems: [],
    };
  },

  methods: {
    navigateToCreateTestCases(folderUid) {
    if (!this.isProjectArchived) {
      this.$router.push({ name: 'CreateTestCases', query:{folderUid}});
    }
  }
}
};
</script>

<style scoped>
h2 {
  font-weight: 700;
}
.v-list-item:hover {
  background-color: #F9FAFB;
}
.v-list a {
  text-decoration: none;
}
.v-menu__content {
  text-align: left !important;
}
.horizontal-margin {
  margin: 0px 10px;
}
.tray-btn-margin {
  min-width: 40px !important;
  width: 40px !important;
  padding: 10px 0px !important;
}
.tray-btn-outline {
  border-radius: 8px;
  height: 40px !important;
}
</style>
