<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table data-table-style mt-6"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
    :total-items-length="totalItems"
    :page.sync="page"
    :items-per-page.sync="itemsPerPage"
    @update:options="$emit('update:options', $event)"
    @click:row="$emit('view', $event)"
  >
    <template v-slot:[`item.priority`]="{ item }">
      <span
        :style="{ color: getPriorityColor(item.priority) }"
        class="font-weight-bold text--lighten-1"
      >{{ getPriorityName(item.priority) }}</span>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <span
        :style="{ color: getStatusColor(item.status) }"
        class="font-weight-bold text--lighten-1 text-capitalize"
      >{{ getStatusName(item.status) }}</span>
    </template>

    <template v-slot:[`item.integration`]="{ item }">
      <span class="text-capitalize">{{ item.integration }}</span>
    </template>

    <template v-slot:[`item.updated_at`]="{ item }">
      <span>{{ formatUpdateddAt(item.updatedAt) }}</span>
    </template>

    <template v-slot:[`item.uid`]="{ item }">
      <div class="d-flex justify-space-between">
        <v-btn
          icon
          color="primary"
          @click="$emit('edit', item)"
        >
          <EditIcon />
        </v-btn>
      </div>
    </template>
    
    <template v-slot:[`item.labels`]="{ item }">
      <v-menu
        v-if="item.customFields?.labels?.length"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            width="auto"
            class="rounded-lg px-2"
            v-on="on"
          >
            <div class="d-flex flex-row align-center justify-space-between w-full">
              <div class="d-flex align-center">
                <span 
                  v-if="item.customFields?.labels?.length > 0"
                  :style="{ color: item.customFields.labels[0].color }"
                  class="text-theme-table-text fs-14px"
                >
                  {{ item.customFields.labels[0].name }}
                  <span
                    v-if="item.customFields?.labels?.length > 1"
                    class="text-theme-table-text fs-14px ml-1"
                  >
                    +{{ item.customFields.labels.length - 1 }}
                  </span>
                </span>
              </div>
              <v-icon
                small
                class="ml-2"
              >
                mdi-chevron-down
              </v-icon>
            </div>
          </v-btn>
        </template>
        <v-list
          dense
          class="text-left"
        >
          <v-list-item
            v-for="label in item.customFields?.labels || []"
            :key="label.id"
          >
            <v-list-item-title :style="{ color: label.color }">
              {{ label.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { formatDate } from '@/utils/util';
import EditIcon from '@/assets/svg/edit.svg';

export default {

  components: {
    EditIcon,
  },
  props: {
    headers: Array,
    itemKey: String,
    items: Array,
    totalItems: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    priorities: {
      type: Array,
      required: true
    },
    statuses: {
      type: Array,
      required: true
    }
  },
  methods: {
    formatUpdateddAt(updatedAt) {
      return formatDate(updatedAt, 'MM/dd/yy')
    },
    getPriorityColor(priorityId) {
      const priority = this.priorities.find(p => Number(p.id) === Number(priorityId));
      return priority?.color || '#0c111d';
    },

    getPriorityName(priorityId) {
      const priority = this.priorities.find(p => Number(p.id) === Number(priorityId));
      return priority?.name || '';
    },

    getStatusColor(statusId) {
      const status = this.statuses.find(s => Number(s.id) === Number(statusId));
      return status?.color || '#0c111d';
    },

    getStatusName(statusId) {
      const status = this.statuses.find(s => Number(s.id) === Number(statusId));
      return status?.name || '';
    },

    getDefaultPriority() {
      return this.priorities.find(p => p.isDefault)?.id;
    },

    getCompletedStatuses() {
      return this.statuses.filter(s => s.isCompleted).map(s => s.id);
    },

    getPriorityByName(name) {
      const normalizedName = name?.toLowerCase()?.replace(/\s+/g, '');
      return this.priorities.find(
        p => p.name?.toLowerCase()?.replace(/\s+/g, '') === normalizedName
      );
    },

    getStatusByName(name) {
      const normalizedName = name?.toLowerCase()?.replace(/\s+/g, '');
      return this.statuses.find(
        s => s.name?.toLowerCase()?.replace(/\s+/g, '') === normalizedName
      );
    }
  }
}
</script>
