<template>
  <div class="mt-5 iteam-area">
    <div class="ml-10 mb-1 d-flex justify-space-between align-center">
      <v-label class="text-left fs-14px text-theme-label font-weight-medium">
        {{ field.name }}
      </v-label>
      <div class="right-menu">
        <v-menu
          offset-y
          bottom
          class="rounded-lg"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              rounded
              depressed
              class="property"
              v-bind="attrs"
              :plain="true"
              icon
              v-on="on"
            >
              <v-icon size="20px">
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-card
            rounded="8"
            elevation="0"
          >
            <v-list>
              <v-list-item
                @click="$emit('delete')"
              >
                <div class="d-flex align-center">
                  <DeleteIcon />
                </div>
                <v-list-item-title class="pl-3">
                  {{ $t('remove') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div
      :key="field.uid"
      class="item-area position-relative"
    >
      <icon-dots />
      <div class="item-area align-center">
        <!-- Text Field -->
        <v-textarea
          v-if="getFieldType === 'text'"
          ref="customField"
          :key="`customField-${customIndex}`"
          v-model="field.value"
          :placeholder="field.name"
          background-color="#F9F9FB"
          class="ml-5 pt-1 mt-0 rounded-lg field-theme customField"
          auto-grow
        >
          <template v-slot:append>
            <v-btn
              icon
              :fab="false"
              :value="field.value"
              :name="field.name"
              @click="openAssist(`customField-${customIndex}`, field.name)"
            >
              <v-icon>mdi-shimmer</v-icon>
            </v-btn>
          </template>
        </v-textarea>

        <!-- Multi Select -->
        <v-select
          v-else-if="getFieldType === 'multi'"
          v-model="field.value"
          :items="field.options"
          multiple
          chips
          :placeholder="field.name"
          background-color="#F9F9FB"
          class="ml-5 pt-1 mt-0 rounded-lg field-theme"
          append-icon="mdi-chevron-down"
        />

        <!-- Radio Buttons -->
        <v-radio-group
          v-else-if="getFieldType === 'radio'"
          v-model="field.value"
          class="ml-5 pt-1 mt-0"
        >
          <v-radio
            v-for="option in field.options"
            :key="option"
            :label="option"
            :value="option"
          />
        </v-radio-group>

        <!-- Link Field -->
        <v-text-field
          v-else-if="getFieldType === 'link'"
          v-model="field.value"
          :placeholder="$t('customFieldPage.enterLink')"
          background-color="#F9F9FB"
          class="ml-5 pt-1 mt-0 rounded-lg field-theme"
          :rules="urlRules"
        />

        <!-- Checkbox -->
        <div
          v-else-if="getFieldType === 'checkbox'"
        >
          <v-checkbox
            v-for="(item, index) in field.options"
            :key="index"
            v-model="field.value"
            multiple
            :value="item"
            :label="item"
            :ripple="false"
            off-icon="icon-checkbox-off"
            on-icon="icon-checkbox-on"
            class="field-theme ml-5"
            :hide-details="true"
          >
            <template v-slot:label>
              <span class="fs-14px text-theme-label">{{ item }}</span>
            </template>
          </v-checkbox>
        </div>

        <!-- Date Picker -->
        <v-menu
          v-else-if="getFieldType === 'date'"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formattedDate"
              :placeholder="$t('customFieldPage.selectDate')"
              readonly
              v-bind="attrs"
              background-color="#F9F9FB"
              class="ml-5 pt-1 mt-0 rounded-lg field-theme"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="field.value"
            no-title
            @input="dateMenu = false"
          />
        </v-menu>

        <!-- File Attachment -->
        <v-file-input
          v-else-if="getFieldType === 'file'"
          v-model="field.value"
          :placeholder="$t('customFieldPage.chooseFile')"
          prepend-icon=""
          background-color="#F9F9FB"
          class="ml-5 pt-1 mt-0 rounded-lg field-theme"
          @change="handleFileChange"
        />

        <!-- Dropdown Field -->
        <v-select
          v-else-if="getFieldType === 'dropdown'"
          v-model="field.value"
          :items="field.options"
          :placeholder="field.name"
          background-color="#F9F9FB"
          append-icon="mdi-chevron-down"
          class="ml-5 mt-0 pt-1 rounded-lg field-theme custom-prepend"
          height="38px"
          :menu-props="{ offsetY: true }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import iconDots from '@/assets/svg/dots-20x20-gray.svg';
import DeleteIcon from '@/assets/svg/delete.svg';
import { dateMixin } from '@/mixins/date.js';
import validator from 'validator';
import { showErrorToast } from '@/utils/toast';

export default {
  components: {
    iconDots,
    DeleteIcon,
  },
  mixins: [dateMixin],
  
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    canDelete: {
      type: Boolean,
      default: () => true,
    },
    canAssist: {
      type: Boolean,
      default: () => true,
    },
    customIndex:{
      type: Number
    }
  },

  data() {
    return {
      dateMenu: false,
      urlRules: [
        v => !v || validator.isURL(v) || this.$t('error.invalidUrl')
      ],
    };
  },

  computed: {
    ...mapGetters(['isTextAssistOpen']),
    getFieldType() {
      return this.field.type || this.field.dataType;
    },

    formattedDate: {
      get() {
        return this.field.value ? this.formatDate(this.field.value) : '';
      },
      set(value) {
        this.field.value = value;
      }
    }
  },

  watch: {
    field: {
      immediate: true,
      handler(newField) {
        if (!newField.value) {
          if (newField.defaultValue) {
            this.$set(this.field, 'value', newField.defaultValue);
          } else if (newField.default_date && this.getFieldType === 'date') {
            this.$set(this.field, 'value', newField.default_date);
          }
        }
      }
    }
  },

  methods: {
    ...mapMutations(['toggleTextAssist', 'setTextAssistData']),
    async openAssist(fieldKey, fieldName) {
      this.$emit('openAssist', fieldKey, 'customField', fieldName);
    },
    handleFileChange(file) {
      if (file) {
        // Emit event to parent component to handle file upload
        this.$emit('file-selected', {
          fieldId: this.field.uid,
          file: file
        });
      }
    },
  }
};
</script>

<style scoped>
.item-area {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.case-contents {
  display: block;
  max-width: 438px;
  width: 438px;
}

/* Custom styling for multi-select chips */
::v-deep .v-select.v-select--chips .v-chip {
  margin: 2px;
}

/* Custom styling for radio buttons */
::v-deep .v-radio {
  margin-top: 0;
}

/* Custom styling for checkbox */
::v-deep .v-input--checkbox {
  margin-top: 0;
  padding-top: 0;
}

/* Custom styling for file input */
::v-deep .v-file-input .v-file-input__text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-space-between {
  justify-content: space-between;
}
.delete{
  margin-top: -1rem;
  position: absolute;
  right: -2.3rem;
}
</style>
<style>
.customField.v-textarea .v-input__control .v-input__slot .v-input__append-inner{
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>