export default function makeCasesService(api) {
  return {
    createTestCase: async function (handle, projectKey, data) {
      return api.post(`/${handle}/projects/${projectKey}/cases`, data);
    },
    createTestCases: async function (handle, projectKey, data) {
      return api.post(`/${handle}/projects/${projectKey}/cases/import`, { cases: data });
    },
    getCase: async function (handle, projectKey, caseId) {
      return api.get(`/${handle}/projects/${projectKey}/cases/${caseId}`);
    },
    getCases: async function (handle, projectKey, perPage = 1000, currentPage = 1) {
      return api.get(`/${handle}/projects/${projectKey}/cases?per_page=${perPage}&current_page=${currentPage}`);
    },
    searchCases: async function (text, handle, projectKey, perPage = 1000, currentPage = 1) {
      return api.get(
        `/${handle}/projects/${projectKey}/cases/search?query=${text}&per_page=${perPage}&current_page=${currentPage}`
      );
    },
    deleteCase: async function (handle, projectKey, item) {
      return api.delete(`/${handle}/projects/${projectKey}/cases/${item}`);
    },
    deleteCases: async function (handle, projectKey, data) {
      return api.delete(`/${handle}/projects/${projectKey}/cases`, { data });
    },
    updateTestCase: async function (handle, projectKey, item, payload) {
      return api.patch(`/${handle}/projects/${projectKey}/cases/${item}`, payload);
    },
    getCaseExecutions: async function (handle, projectKey, item, perPage = 1000, currentPage = 1) {
      return api.get(`/${handle}/projects/${projectKey}/cases/${item}/executions?per_page=${perPage}&current_page=${currentPage}`);
    },
    getFolderCases: async function (handle, projectKey, folderId, page = 1, limit = 1000) {
      return api.get(
        `/${handle}/projects/${projectKey}/cases?page=${page}&limit=${limit}&groupByFolder=true&folderId=${folderId}`
      );
    },
    getProjectCases: async function (handle, projectKey, page = 1, limit = 1000) {
      return api.get(`/${handle}/projects/${projectKey}/cases?page=${page}&limit=${limit}`);
    },
    updateBulkTestCases: async function (handle, projectKey, payload) {
      return api.patch(`/${handle}/projects/${projectKey}/cases`, payload);
    },
    getSignedAttachmentUrl: function({params,payload}){
      return api.post(`/${params.handle}/projects/${params.projectKey}/cases/${params.caseId}/attachments`, payload)
    },
    cleanupAttachments: function({id, params}){
      return api.delete(`/${params.handle}/projects/${params.projectKey}/cases/attachments/${id}/failed`)
    },
    deleteAttachments: function({id, params}){
      return api.delete(`/${params.handle}/projects/${params.projectKey}/cases/attachments/${id}`)
    }
  };
}
