<template>
  <v-menu
    left
    :nudge-bottom="4"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        class="rounded-lg text-none"
        height="40"
        min-width="40"
        elevation="0"
        v-on="on"
      >
        <AnalyticsIcon />
      </v-btn>
    </template>
    <div class="__viewedBy">
      <p>{{ $t('view') }}:</p>
      <p>{{ $t('SplitBy') }}:</p>
      <v-radio-group
        v-model="splitBy"
        column
      >
        <v-radio
          color="primary"
          label="Status"
          value="status"
        />
        <v-radio
          color="primary"
          label="Priority"
          value="priority"
        />
      </v-radio-group>
    </div>
  </v-menu>
</template>
  <script>
  import AnalyticsIcon from '@/assets/svg/analytics-icon.svg';
  export default{
    components:{
      AnalyticsIcon
    },
    props:{
      split: String,
      chartId: Number,
    },
    data(){
      return {
        setSplit: null
      }
    },
    computed:{
      splitBy:{
        get(){
          return this.setSplit ?? this.split;
        },
        set(val){
          this.setSplit = val;
          this.$emit('changeSplit', val)
        }
      }
    }
  }
  </script>
  <style scoped>
  .custom-menu-item{
    min-height: 35px;
  }
  .__viewedBy{
    background-color: #FFF;
    padding: 4px 12px;
  }
  .__viewedBy > p:first-child{
    font-size: 17px;
    font-weight: 500;
    color: #18181A;
    font-size: 'Inter';
    margin-bottom: 4px;
  }
  </style>
  <style>
  .__viewedBy .v-input--radio-group__input{
    display: flex !important;
    gap: 8px !important;
    margin-top: 0px;
  }
  .__viewedBy > div{
    margin: 0px;
  }
  </style>