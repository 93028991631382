export default function makeResultsService(api) {
    return {
        addTestResult: async function (handle,projectKey,executionUid,payload) {
            return api.post(`/${handle}/projects/${projectKey}/executions/${executionUid}/results`, payload);
        },
        getTestResults : async function (handle, projectKey, executionUid) {
            return api.get(`/${handle}/projects/${projectKey}/executions/${executionUid}/results`);
        },
        updateTestResult: async function(handle, projectKey, resultUid, payload){
            return api.patch(`/${handle}/projects/${projectKey}/results/${resultUid}`, payload)
        },
        deleteTestResult: async function(handle, projectKey, resultUid, payload){
            return api.delete(`/${handle}/projects/${projectKey}/results/${resultUid}`, payload)
        },
        getSignedAttachmentUrl: function({params,payload}){
            return api.post(`/${params.handle}/projects/${params.projectKey}/results/${params.resultUid}/attachments`, payload)
        },
        cleanupAttachments: function({id, params}){
            return api.delete(`/${params.handle}/projects/${params.projectKey}/results/attachments/${id}/failed`)
        },
        deleteAttachments: function({id, params}){
            return api.delete(`/${params.handle}/projects/${params.projectKey}/results/attachments/${id}`)
        }
    };
}
