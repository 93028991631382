const state = {
  show404: false,
};

const mutations = {
  SET_SHOW_404(state, value) {
    state.show404 = value;
  },
};

const makeActions = () => ({
  show404({ commit }, value) {
    commit('SET_SHOW_404', value);
  },
  hide404({ commit }) {
    commit('SET_SHOW_404', false);
  }
});

const getters = {
  show404: (state) => state.show404,
};

export default function makeError404() {
  return {
    namespaced: true,
    state,
    mutations,
    actions: makeActions(),
    getters
  };
}
