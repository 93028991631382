<template>
  <div
    class="pl-3"
  >
    <Loader v-if="loaderState" />
    <v-card
      v-if="!loaderState"
      class="py-4 px-6 my-3"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <div class="d-flex flex-column">
        <div class="d-flex flex-row align-center justify-space-between">
          <div class="flex align-center">
            <button
              class="d-flex flex-row align-center pointer mr-3"
              @click="backToMilestones"
            > 
              <v-icon color="black">
                mdi-arrow-left
              </v-icon>
            </button>
            <v-tooltip
              bottom
              max-width="430px"
              :disabled="value?.name?.length < 30"
              content-class="tooltip-theme"
            >
              <template v-slot:activator="{ on, attrs }">
                <h2 
                  class="custom__tooltip__title"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ value?.name }}
                </h2>
              </template>
              <span>{{ value?.name }}</span>
            </v-tooltip>
            <div
              class="d-flex flex-row align-center justify-space-between ml-6"
            >
              <ProgressBar
                :executions="value?.executionsProgress"
                :percentage="value?.executionsPercentage"
                :case-count="testCasesCount"
              />
            </div>
          </div>
          <div>
            <v-btn
              depressed
              color="primary"  
              height="40px"
              class="text-capitalize btn-theme rounded-lg"
              @click="handleCloseClick"
            >
              {{ $t('close') }}
            </v-btn>
          </div>
        </div>
        <div class="mt-5 d-flex">
          <v-chip
            :class="{ 'blue--text': tableTestType === 'plans' }"
            width="200px"
            :color="tableTestType === 'plans' ? 'blue-light' : 'gray-light'"
            label
            @click="changeTestType('plans')"
          >
            <div
              class="px-2"
              :class="{ 'fw-semibold': tableTestType === 'plans', 'font-weight-medium': tableTestType !== 'plans'}"
            >
              {{ $t('testPlans') }} <span class="ml-1">{{ getActivePlanItemsCount }}</span>
            </div>
          </v-chip>
          <div class="ml-2">
            <v-chip
              :class="{ 'blue--text': tableTestType === 'runs' }"
              width="200px"
              :color="tableTestType === 'runs' ? 'blue-light' : 'gray-light'"
              label
              @click="changeTestType('runs')"
            >
              <div 
                class="px-2"
                :class="{ 'fw-semibold': tableTestType === 'runs', 'font-weight-medium': tableTestType !== 'runs'}"
              >
                {{ $t('testRuns') }} <span class="ml-1">{{ getActiveRunItemsCount }}</span>
              </div>
            </v-chip>
          </div>
        </div>
      </div>
    </v-card>

    <v-container
      v-if="!loaderState"
      class="d-flex flex-column align-self-stretch"
      fluid
    >
      <div class="row">
        <div
          v-if="!isTableTypePlans"
          class="pa-0"
          :style="{ width: isProjectMenuCollapsed ? '5%' : '15%' }"
        >
          <TestPlanMiniSection
            :is-collapsed.sync="isProjectMenuCollapsed"
            @onPlanActiveId="onPlanActiveId"
          />
        </div>
        <div
          class="pa-0"
          :style="{ width: !isTableTypePlans ? isProjectMenuCollapsed ? '95%' : '85%' : '100%' }"
        >
          <div
            class="container pa-6 white align-start card rounded-lg container--fluid app-height-global"
          >
            <v-row class="align-center">
              <v-col
                cols="6"
                sm="6"
                class="py-0"
              >
                <div class="d-flex flex-row justify-start align-center">
                  <v-responsive
                    class="ma-0"
                    max-width="344"
                  >
                    <v-text-field
                      v-model="searchFilter"
                      class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
                      :placeholder="$t('searchByName')"
                      height="40"
                      background-color="#F9F9FB"
                      hide-details
                    >
                      <template v-slot:prepend-inner>
                        <SearchIcon />
                      </template>
                    </v-text-field>
                  </v-responsive>
                  
                  <PlanFilter 
                    v-if="singularTestType == 'plan'"
                    :configurations="configurations" 
                    :milestones="activeMilestones"  
                    :tags="tags"
                    @applyFilters="applyPlanFilters"
                  />
                  <RunFilter
                    v-else
                    :configurations="configurations" 
                    :milestones="activeMilestones"  
                    :tags="tags"
                    @applyFilters="applyRunFilters"
                  />
                </div>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                class="d-flex justify-end"
              >
                <SettingsMenu 
                  :key="singularTestType"
                  :table-type="singularTestType" 
                />
              </v-col>
            </v-row>
            <template>
              <MilestoneTestPlanTable
                v-if="tableTestType === 'plans'"
                v-model="value.testPlans"
                :value="value.testPlans"
                :plans-data="filteredTestPlans"
                :table-filter="tableFilter"
                :filtered-headers="filteredPlanHeaders"
                :filtered-items="filteredTestPlans"
                @input="handleTestPlans"
              />

              <MilestoneTestRunTable
                v-if="tableTestType === 'runs'"
                :value="value.testRuns"
                :runs-data="filteredTestRuns"
                :table-filter="tableFilter"
                :filtered-headers="filteredRunHeaders"
                :filtered-items="filteredTestRuns"
                @input="handleTestRuns"
              />
            </template>
            <v-row
              v-if="!hideActionBtn"
              justify="end"
              class="mt-4"
            >
              <v-col cols="12">
                <v-flex class="mt-6 d-sm-flex justify-end">
                  <v-btn
                    v-if="writeEntity"
                    background-color="#F2F4F7"
                    height="40px"
                    depressed
                    :class="{'text-capitalize fw-semibold rounded-lg black--text mr-4 mt-2': true, 'disabled-action': isProjectArchived }"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
                    @click="openCreateTest" 
                  >
                    <v-icon
                      class="mr-1"
                      size="16"
                    >
                      mdi-plus
                    </v-icon> {{ isTableTypePlans ? $t('Create new test plan') : $t('Create new test run') }}
                  </v-btn>
                  <slot name="actionButton" />
                </v-flex>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>  

      <MilestoneArchiveDialog
        v-model="showConfirmOpenDialog"
        :title="$t('milestone.close_dialog.title')"
        :content="$t('milestone.close_dialog.content_part1')"
        :content_part2="$t('milestone.close_dialog.content_part2')"
        :btn_label="$t('milestone.close_dialog.btn_label')"
        :milestone_name="value.name"
        color="primary"
        @close="handleCloseClick"
        @handleConfirmClick="handleConfirmOpenClick('close')"
      />
    </v-container>
  </div>
</template>
  
  <script>
  import MilestoneTestRunTable from '@/components/Milestone/MilestoneTestRunTable.vue';
  import MilestoneTestPlanTable from '@/components/Milestone/MilestoneTestPlanTable.vue';
  import MilestoneArchiveDialog from '@/components/Milestone/MilestoneArchiveDialog.vue';
  import SettingsMenu from '@/components/Project/SettingsMenu.vue';
  import RunFilter from '@/components/TestRuns/RunFilter.vue';  
  import PlanFilter from '@/components/TestPlans/PlanFilter'
  import { createNamespacedHelpers, mapGetters, mapActions } from 'vuex';
  import SearchIcon from '@/assets/svg/search-icon.svg';
  import handleLoading from '@/mixins/loader.js'
  import Loader from '@/components/base/Loader'
  import makeRunService from '@/services/api/run'
  import makePlanService from '@/services/api/plan'
  import makeConfigurationService from '@/services/api/configuration' 
  import makeTagService from '@/services/api/tag';
  import makeMilestonesService from '@/services/api/milestone'
  import projectStatus from '@/mixins/projectStatus';
  import ProgressBar from '@/components/base/ProgressBar.vue';
  import TestPlanMiniSection from '@/components/base/TestPlanMiniSection.vue';
  import MilestoneService from '@/services/api/milestone';
  import { showSuccessToast, showErrorToast } from '@/utils/toast';

  let makeMilestoneService;

  export default {
    name: 'MilestoneTestActivitiesView',
    components: {
      MilestoneTestRunTable,
      MilestoneTestPlanTable,
      SearchIcon,
      SettingsMenu,
      RunFilter,
      PlanFilter,
      Loader,
      ProgressBar,
      TestPlanMiniSection,
      MilestoneArchiveDialog
    },    
    mixins:[handleLoading, projectStatus],
    props: {
      value: {
        type: Object,
      },
      hideActionBtn: {
        type: Boolean,
        default: false
      },
      writeEntity:{
        type: Boolean,
        default: false
      }
    },
    emits: ['input'],
    data() {
      return {
        isCreateDisabled: false,
        tableTestType: 'plans',
        tableFilter: 'all',
        testRuns: [],
        testPlans: [],
        searchFilter: '',
        appliedRunFilters: null,
        appliedPlanFilters: null,
        runHeaders: [],
        planHeaders: [],
        milestones: [],
        configurations: [],
        runItems: [],
        planItems: [],
        tags: [],
        showConfirmBackDialog: true,
        isProjectMenuCollapsed: false,
        selectedPlanId: null,
        showConfirmOpenDialog: false,
      };
    },
    computed: {
      ...mapGetters({
        currentAccount: 'user/currentAccount',
        dynamicHeaders:'headers/dynamicHeaders'
      }),
    getActivePlanItems() {
      return this.planItems?.filter((plan) => !plan.archivedAt);
    },
    getActiveRunItems() {
      return this.runItems?.filter((run) => !run.archivedAt);
    },
    getActivePlanItemsCount() {
      return this.getActivePlanItems.length;
    },
    getActiveRunItemsCount() {
      return this.getActiveRunItems.length;
    },
    getMilestoneProgress() {
      return this.value.progress;
    },
    singularTestType(){
      return this.tableTestType.substring(0,this.tableTestType.length-1)
    },
    selectedTestType() {
      const testType = {
        plans: this.$t('testPlans'),
        runs: this.$t('testRuns')
      }
      return testType[this.tableTestType];
    },
    selectedRunLength() {
      return this.value.testRuns.length;
    },
    selectedPlanLength() {
      return this.value.testPlans.length;
    },
    filteredRunHeaders() {
      return this.runHeaders?.filter((header) => header.checked && header.value !== 'actions');
    },
    filteredPlanHeaders() {
      return this.planHeaders?.filter((header) => header.checked);
    },
    isTableTypePlans() {
      return this.tableTestType === 'plans';
    },
    filteredTestRuns() {
      const data = this.filteredItems(this.testRuns);
      let filtered = data.filter((item) => {
        if(this.selectedPlanId == 'unlinked' && item.testPlanUid) {
          return false;
        }
        if(Number.isInteger(this.selectedPlanId) && item.testPlanUid !== this.selectedPlanId) {
          return false;
        }
        return true;
      });
      return filtered;
    },

    filteredTestPlans() {
      return this.filteredItems(this.testPlans);
    },
    filteredItems() {
      return (items) => {
        if (this.searchFilter && !this.appliedRunFilters && !this.appliedPlanFilters) {
          return items.filter(item => this.matchesFilter(item));
        } else if (this.singularTestType == 'run' && this.appliedRunFilters && !this.searchFilter) {
          return items.filter(item => this.matchRunApplyFilter(item));
        } else if (this.singularTestType == 'run' && this.appliedRunFilters && this.searchFilter) {
          const mainFiltered = items.filter(item => this.matchRunApplyFilter(item))
          return mainFiltered.filter(item => this.matchesFilter(item));
        }
        else if(this.singularTestType == 'plan' && this.appliedPlanFilters && !this.searchFilter){
          return items.filter(item => this.matchPlanApplyFilter(item))
        }
        else if(this.singularTestType == 'plan' && this.appliedPlanFilters && this.searchFilter){
          const mainFiltered = items.filter(item => this.matchPlanApplyFilter(item))
          return mainFiltered.filter(item => this.matchesFilter(item))
        }
        return items;
      };
    },
    testCasesCount() {
      return this.runItems.reduce((total, item) => total + (item.testcases || 0), 0)
    },
    activeMilestones() {
      return this.milestones.filter((milestone) => milestone.archivedAt === null && milestone.deletedAt === null);
    },
    },
    async created() {
      if(!this.dynamicHeaders.run) {
        this.initializeHeaders({ type: 'run' });
      }
      if(!this.dynamicHeaders.plan) {
        this.initializeHeaders({ type: 'plan' });
      }

      makeMilestoneService = MilestoneService(this.$api);

      this.runHeaders = this.dynamicHeaders.run;
      this.planHeaders = this.dynamicHeaders.plan;
      const handle = this.$route.params.handle;

     await this.init([
        this.getAllTestRuns({ handle, projectKey: this.$route.params.key, perPage: 99999, currentPage: 0 }), 
        this.getAllTestPlans({ handle, projectKey: this.$route.params.key, perPage: 99999, currentPage: 0 })
      ])

      this.testRuns = this.getActiveRunItems;
      this.testPlans = this.getActivePlanItems; 

      this.getMilestones();
      this.getConfigurations();
      this.getTags();

    },
    methods: {
      ...mapActions({
        initializeHeaders: 'headers/initializeHeaders'
      }),
      backToMilestones() {
      this.$router.replace({ name: 'Milestones' });
      },
      handleCloseClick() {
        this.showConfirmOpenDialog = !this.showConfirmOpenDialog;
      },
      async handleConfirmOpenClick() {
      try {
        await makeMilestoneService.updateMilestone(
          this.$route.params.handle,
          this.$route.params.key,
          this.value.uid,
          {
            archived: true,
            externalId: this.value.externalId,
            source: this.value.source,
            name: this.value.name,
          }
        );
        showSuccessToast(this.$swal, 'closeSuccess', { item: 'Milestone' });
        this.$router.replace({ 
          name: 'Milestones',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
          },
          query: {
            activeClosed: 'true'
          }
        });
      } catch (error) {
        showErrorToast(this.$swal, 'closeError', { item: 'Milestone' });
      }
    },
      async getAllTestRuns({handle, projectKey, perPage, currentPage}){
        const runService = makeRunService(this.$api);
        await runService.getTestRuns(handle, projectKey, perPage, currentPage).then(response => {
          this.runItems = response.data.runs;
        })
      },
      async getAllTestPlans({handle, projectKey, perPage, currentPage}){
        const planService = makePlanService(this.$api);
        await planService.getAllTestPlans(handle, projectKey, perPage, currentPage).then(response => {
          this.planItems = response.data.plans;
        })
      },
      applyRunFilters(filters){
        this.appliedRunFilters = filters ?? null;
      },
      applyPlanFilters(filters){
        this.appliedPlanFilters = filters ?? null;
      },
      matchRunApplyFilter(item) {
        if (this.appliedRunFilters?.panel_priority.length > 0 && 
          !this.appliedRunFilters.panel_priority.includes(item.priority)) {
          return false;
        }
        if (this.appliedRunFilters?.panel_status.length > 0 && 
          !this.appliedRunFilters.panel_status.includes(item.status)) {
          return false;
        }
        if (this.appliedRunFilters?.panel_milestone.length > 0 && 
          !this.appliedRunFilters.panel_milestone.includes(item.customFields.milestone) && 
          !this.appliedRunFilters.panel_milestone.includes('None')) {
          return false;
        }
        if (this.appliedRunFilters?.panel_tag.length > 0 && 
          !this.appliedRunFilters.panel_tag.includes(item.customFields.tags) && 
          !this.appliedRunFilters.panel_tag.includes('none')) {
          return false;
        }
        if (this.appliedRunFilters?.testcases_range[0] > item.testcases || 
          this.appliedRunFilters?.testcases_range[1] < item.testcases) {
          return false;
        }
        if (this.appliedRunFilters?.progress_range[0] > item.percentage || 
          this.appliedRunFilters?.progress_range[1] < item.percentage) {
          return false;
        }
        if (this.appliedRunFilters?.dateRange.start && this.appliedRunFilters?.dateRange.start > item.createdAt || 
          this.appliedRunFilters?.dateRange.end && this.appliedRunFilters?.dateRange.end < item.createdAt) {
          return false;
        }
        return true;
      },
      matchPlanApplyFilter(item){
        if (this.appliedPlanFilters?.panel_priority.length > 0 && 
          !this.appliedPlanFilters.panel_priority.includes(item.priority)) {
          return false;
        }
        if (this.appliedPlanFilters?.panel_status.length > 0 && 
          !this.appliedPlanFilters.panel_status.includes(item.status)) {
          return false;
        }
        if (this.appliedPlanFilters?.panel_tag.length > 0) {
          let tagExists = false
          for(const tag of this.appliedPlanFilters.panel_tag){
            if(item.customFields.tags.includes(tag)){
              tagExists = true
              break;
            }
          }
          if(!tagExists)
            return false
        }
        if (this.appliedPlanFilters?.dateRange.start && this.appliedPlanFilters?.dateRange.start > item.createdAt && 
          this.appliedPlanFilters?.dateRange.end && this.appliedPlanFilters?.dateRange.end < item.createdAt) {
          return false;
        }
        if (this.appliedPlanFilters?.testruns_range[0] > item.runs.length || 
          this.appliedPlanFilters?.testruns_range[1] < item.runs.length) {
          return false;
        }
        return true
      },
      onPlanActiveId(planId) {
        this.selectedPlanId = planId;
      },
      handleTestPlans(value){
        this.value.testPlans = value;
        this.$emit('input', this.value)
      },
      handleTestRuns(value){
        this.value.testRuns = value;
        this.$emit('input', this.value)
      },
      async getTags() {
      const handle = this.$route.params.handle;
      const tagService = makeTagService(this.$api);
      try {
        const response = await tagService.getTags(handle, 'runs');
        this.tags = response.data
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'tags' });
        return [];
      }
    }, 
    async getMilestones() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const milestoneService = makeMilestonesService(this.$api);
      try {
        const response = await milestoneService.getMilestones(handle, projectKey);
        this.milestones = response.data?.milestones;
        return response.data?.milestones;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'milestones' });
        return [];
      }
    },
    async getConfigurations() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const configurationService = makeConfigurationService(this.$api);
      try {
        const response = await configurationService.getConfigurations(handle, projectKey, 10, 0);
        this.configurations = response.data?.configurations;
        return response.data?.configurations;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'configurations' });
        return [];
      }
    },
    openCreateTest() {

        if(this.isTableTypePlans) {
          this.$router.push({
            name: 'TestPlanCreate',
            params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key
            },
            query: {
              createMilestone: 'true'
            },
          });

        } else {
          this.$router.push({
            name: 'TestRunCreate',
            params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key
            },
            query: {
              createMilestone: 'true'
            },
          });

        }
      },
      handleConfirmClick() {
        this.showConfirmBackDialog = false;
        this.$router.replace({
          name: 'MilestoneCreate',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key
          },
          query: {
            activeEdit: 'true'
          }
        });
      },
      changeFilter(filter) {
        this.tableFilter = filter;
      },
      changeTestType(type) {
        this.tableTestType = type;
      },
      matchesFilter(item) {
        const lowerCaseFilter = this.searchFilter.toLowerCase();
  
        const nameMatch = item.name.toLowerCase().includes(lowerCaseFilter);
  
        return nameMatch;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .back-to-projects {
    display: flex;
    cursor: pointer;
    width: max-content;
  }
  
  .search_input {
    width: 100%;
  
    @media screen and (min-width: 600px) {
      width: 300px;
    }
  }
  
  .project-logo {
    border-radius: 50%;
    border: 2px dashed grey;
    width: 150px;
    height: 150px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    cursor: pointer;
    transition: border-color 0.3s;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  .project-logo:hover {
    border-color: #2196f3;
  }
  
  .hovering .edit-icon,
  .hovering .delete-icon {
    display: block;
  }
  
  .edit-icon,
  .delete-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateX(-30px);
    display: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
  }
  
  .delete-icon {
    margin-left: 60px;
  }
  
  .project-logo:hover .edit-icon,
  .project-logo:hover .delete-icon {
    display: block;
  }

  </style>
  
