<template>
  <v-dialog
    v-model="showDialog"
    max-width="800"
    persistent
  >
    <v-card class="pa-2">
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ $t('billing.availablePlans') }}
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <div
          class="d-flex justify-space-between mt-3"
          style="gap: 16px"
        >
          <PlanCardItem
            v-for="plan in planList"
            :key="plan.name"
            :plan="plan"
            @select="selectPlan"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PlanCardItem from './PlanCardItem.vue';

import { userPlans, orgPlans } from '@/constants/plans';

export default {
  components: {
    PlanCardItem,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    plans: {
      type: Array,
      default: () => [],
    },
    currentPlan: {
      type: Object,
      default: () => {},
    },
    isOrg: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },

    planList() {
      return this.plans.map((plan) => ({
        id: plan.stripePriceId,
        tier: plan.name,
        name: plan.amount === '0.00' ? 'FREE' : `$${plan.amount/100}`,
        price: parseFloat(plan.amount),
        period: plan.amount === '0.00' ? null : 'Monthly',
        isSelected: plan.stripePriceId === this.currentPlan.stripePriceId,
        features: this.getPlanFeatures(plan),
        buttonText: plan.stripePriceId === this.currentPlan.stripePriceId ? 'Selected plan' : 'Change plan',
        buttonVariant: plan.stripePriceId === this.currentPlan.stripePriceId ? 'ghost' : 'default',
        cardColor: plan.amount === '0.00' ? '#F9FAFB' : '#0C2FF3',
        cardTextColor: plan.amount === '0.00' ? '#0C111D' : '#FFFFFF',
      }));
    },
  },

  methods: {
    selectPlan(plan) {
      this.$emit('choose-plan', plan);
      this.showDialog = false;
    },

    getPlanFeatures(plan) {
      return [
        { text: `${plan.features.users} users`, enabled: true },
        { text: `${plan.features.testCases} testcases`, enabled: true },
        { text: `${plan.features.gbStorage} GB storage`, enabled: true },
      ];
    },
  },
};
</script>
