<template>
  <div>
    <v-sheet
      color="gray-100"
      class="d-flex align-center justify-center"
      height="50px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-4"> {{ $t('filters') }} <v-icon>mdi-filter-variant</v-icon></span>
    </v-sheet>
    <v-dialog
      v-model="showDialog"
      fullscreen
      max-width="400"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>

          <v-expansion-panels
            v-model="panel"
            flat
            multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('priority') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-for="(item,index) in priorities"
                  :key="index"
                  v-model="selectedPriorities"
                  :value="item.name"
                  class="field-theme"
                  :ripple="false"
                  off-icon="icon-checkbox-off"
                  on-icon="icon-checkbox-on"
                  :hide-details="true"
                >
                  <template v-slot:label>
                    <span class="fs-14px text-theme-label">{{ item.name }}</span>
                  </template>
                </v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('tag') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  color="blue"
                  dense
                  class="mt-2"
                  placeholder="Search"
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="#test"
                  color="blue"
                  dense
                  label="#test"
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="#performance"
                  color="blue"
                  dense
                  label="#performance"
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="#billing"
                  color="blue"
                  dense
                  label="#billing"
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="#navigation"
                  color="blue"
                  dense
                  label="#navigation"
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="#remotetest"
                  color="blue"
                  dense
                  label="#remotetest"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div class="d-flex justify-end mt-5">
            <v-btn
              width="10rem"
              color="gray-100"
              full-width
              class="mr-4 text-capitalize"
              elevation="0"
              @click="cancel"
            >
              {{ $t('clearAll') }}
            </v-btn>
            <v-btn
              width="10rem"
              color="blue"
              dark
              full-width
              elevation="0"
              class="text-capitalize"
              @click="apply"
            >
              {{ $t('apply') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import colorPreferencesMixin from '@/mixins/colorPreferences';
export default {
  name: "FilterComponent",
  mixins: [colorPreferencesMixin],
  data() {
    return {
      selectedPriorities: [],
      selectedTags: [],
      showDialog: false,
      panel: [0,1],
      priorities: []
    }
  },
  created() {
    this.priorities = this.getPriorities("testCase");
  },
  methods: {
    apply() {
      this.$emit('update:filters', { priorities: this.selectedPriorities, tags: this.selectedTags });
      this.showDialog = false;
    },
    cancel() {
      this.selectedPriorities = [];
      this.selectedTags = [];
    }
  }
}
</script>

