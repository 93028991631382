import DefaultLayout from '@/Layouts/DefaultLayout.vue';

const routes = [
  {
    path: '/:handle/integrations',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'integrations',
        component: () => import('@/views/Integration/Index.vue'),
        props: true,
        meta: {
          requiredAuthz: ['read_integration'],
        },
      },
      {
        path: 'new',
        name: 'create-integration',
        component: () => import('@/views/Integration/AddIntegration.vue'),
        props: true,
        meta: {
          requiredAuthz: ['write_integration'],
        },
      },
    ],
  },
];

export default routes.map((route) => {
  const meta = {
    ...route.meta,
    requiresAuth: true,
    authzScope: 'org',
  }
  return { ...route, meta };
});
