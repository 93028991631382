<template>
  <v-card
    class="white py-6 px-6 mt-3"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex align-center justify-space-between">
      <h2>{{ $t('integrations.name') }}</h2>
      <!-- TODO: Uncomment this when we have the custom app feature -->
      <!-- <v-btn
        color="#0C2FF3"
        depressed
        height="40px"
        class="text-capitalize rounded-lg btn-theme white--text"
        @click="() => goToAddApp('Custom App','Some description')"
      >
        {{ $t('integrations.header.add_app') }} <v-icon
          class="ml-1"
          size="16px"
        >
          mdi-plus
        </v-icon>
      </v-btn> -->
    </div>
    <div class="mt-4 d-flex">
      <v-chip
        :class="{
          'blue--text': filter === 'added',
          'fw-semibold': filter === 'added',
          'font-weight-medium': filter != 'added',
        }"
        width="200px"
        :color="filter === 'added' ? '#e6ecff' : '#f9fafb'"
        label
        @click="updateFilter('added')"
      >
        <div class="px-2">
          {{ $t('integrations.header.added') }} <span class="ml-2">{{ addedItemCount }}</span>
        </div>
      </v-chip>
      <div class="ml-2">
        <v-chip
          :class="{
            'blue--text': filter === 'all',
            'fw-semibold': filter === 'all',
            'font-weight-medium': filter != 'all',
          }"
          width="200px"
          :color="filter === 'all' ? '#e6ecff' : '#f9fafb'"
          label
          @click="updateFilter('all')"
        >
          <div class="px-2">
            {{ $t('integrations.header.all') }} <span class="ml-2">{{ allItemCount }}</span>
          </div>
        </v-chip>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    filter: {
      type: String,
      default: ''
    },
    addedItemCount: {
      type: Number,
      default: 0
    },
    allItemCount: {
      type: Number,
      default: 0
    }
  },
  methods: {
    updateFilter(filter) {
      this.$emit('update-filter', filter);
    },
    goToAddApp(name, description) {
      this.$emit('select-integration', { name, description });
    }
  },
};
</script>

<style scoped>
.v-btn {
  background-color: #0C2FF3 !important;
  opacity: 1 !important;
}
</style>
