<template>
  <v-dialog
    v-model="dialog"
    content-class="confirm-dialog"
    :max-width="480"
  >
    <v-card class="font-inter bg-white">
      <v-card-text class="py-8 px-sm-10">
        <v-flex class="d-flex flex-row justify-space-between">
          <div class="mt-4 d-flex flex-column">
            <p class="ma-0 mb-1 font-weight-bold text-h6 text-sm-h5 text-start black--text">
              {{ $t('editTestCaseConfirmation.title') }}
            </p>
            <p class="text-start">
              {{ $t('editTestCaseConfirmation.description_part1') }}
            </p>
            <p>{{ $t('editTestCaseConfirmation.description_part2') }}</p>
          </div>
          <button
            depressed
            class="d-flex mt-1 ml-4"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </button>
        </v-flex>
        <v-row class="mt-2">
          <v-col cols="6">
            <v-btn
              depressed
              color="#F2F4F7"
              height="40"
              class="text-capitalize btn-theme"
              elevation="0"
              width="100%"
              @click="dialog = false"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              class="btn-theme"
              height="40"
              color="primary"
              depressed
              elevation="0"
              width="100%"
              @click="$emit('edit')"
            >
              {{ $t('edit') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmCaseEdit',
  props: {
    value: Boolean,
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.dialog = newVal;
    },
    dialog(newVal) {
      this.$emit('input', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  height: unset;
}

.title {
  max-width: 366px;
  font-family: Inter;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: start;
  color: #0c111d;
}

.description {
  color: #667085;
}

.v-btn.primary {
  background: #f2284e;
  border-radius: 6px;
  color: #ffffff !important;
  text-transform: none;
}
</style>
    