<template>
  <v-container
    fluid
    style="padding: 0"
  >
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
      />
      <p class="text-body-1 mt-3">
        {{ loadingText }}...
      </p>
    </v-overlay>
    <error404 v-if="show404" />
    <v-row v-else>
      <v-col :cols="isMenuCollapsed ? 1 : 2">
        <left-menu :menu-items="menuItems" />
      </v-col>

      <v-col
        :cols="isMenuCollapsed ? 11 : 10"
        style="padding-left: 0 !important"
      >
        <router-view />
      </v-col>
    </v-row>
    <span id="versionSpan">
      {{ versionString }}
    </span>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import LeftMenu from '@/components/Admin/LeftMenu';
import OrganizationsIcon from '@/assets/svg/settings-left-menu/org.svg';
import AuthenticationIcon from '@/assets/svg/settings-left-menu/authentication.svg';
import KeyIcon from '@/assets/svg/settings-left-menu/key.svg';
import DropperIcon from '@/assets/svg/settings-left-menu/dropper.svg';
import InfoIcon from '@/assets/svg/settings-left-menu/about.svg';
import BillingIcon from '@/assets/svg/settings-left-menu/billing.svg';
import error404 from "@/components/base/error404.vue";

export default {
  name: 'SettingsLayout',

  components: {
    LeftMenu,
    error404
  },

  computed: {
    ...mapGetters({ currentAccount: 'user/currentAccount', currentUser: 'user/user', orgs: 'user/orgs', isMenuCollapsed: 'isMenuCollapsed', isLoading: 'isLoading', loadingText: 'loadingText' }),
    ...mapGetters("error404", ["show404"]),
    versionString() {
      if (
        process.env.VUE_APP_VERSION &&
        process.env.VUE_APP_STRIPE_PUBLIC_KEY.indexOf('live') < 0
      ) {
        return `FRONTEND VERSION: ${process.env.VUE_APP_VERSION}`;
      }
      return '';
    },

    menuItems() {
      const handle = this.currentAccount.handle

      return [
        { title: this.$t('settingsPage.organization'), icon: OrganizationsIcon, to: { name: 'OrgAccount', params: { handle } }, isActive: true },
        { title: this.$t('settingsPage.apiKeys'), icon: KeyIcon, to: { name: 'OrgAPIKeys', params: { handle } }, isActive: false },
        // { title: this.$t('settingsPage.authentication'), icon: AuthenticationIcon, to: { name: 'OrgAuthentication', params: { handle } }, isActive: false },
        { title: this.$t('settingsPage.billing'), icon: BillingIcon, to: { name: 'OrgBilling', params: { handle } }, isActive: false },
        { title: this.$t('settingsPage.personalization'), icon: DropperIcon, to: { name: 'OrgPersonalization', params: { handle } }, isActive: false },
        { title: this.$t('settingsPage.about'), icon: InfoIcon, to: { name: 'OrgAbout', params: { handle } }, isActive: false },
      ]
    }
  },

  methods: {
    ...mapActions({ setCurrentAccount: 'user/setCurrentAccount' }),
  },

  created() {
    const handle = this.$route.params.handle;
    const selectedAccount = this.orgs.filter(org => org.handle == handle)[0];
    this.setCurrentAccount({ isOrg: true, ...selectedAccount });
  },
};
</script>
<style>
  #versionSpan {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 0 1em;
    color: white;
    background: black
  }
</style>
