import ProjectLayout from '@/Layouts/ProjectLayout.vue';

const routes = [
  {
    path: '/:handle/:key/milestones',
    component: ProjectLayout,
    meta: {
      requiredAuthz: ['read_entity'],
    },
    children: [
      {
        path: '',
        name: 'Milestones',
        component: () => import('@/views/Tests/Milestones/Index'),
      },
      {
        path: 'create',
        name: 'MilestoneCreate',
        component: () => import('@/views/Tests/Milestones/MilestoneCreateView'),
        meta: {
          requiredAuthz: ['write_entity'], 
        },
      },
      {
        path: ':id',
        name: 'MilestoneView',
        component: () => import('@/views/Tests/Milestones/MilestoneView'),
      },
      {
        path: 'testactivities',
        name: 'MilestoneTestActivities',
        component: () => import('@/views/Tests/Milestones/MilestoneTestActivitiesView'),
      },
      {
        path: 'edit/:id',
        name: 'MilestoneEdit',
        component: () => import('@/views/Tests/Milestones/MilestoneEditView'),
        meta: {
          requiredAuthz: ['write_entity'],    
        },
      },
    ],
  },
];

export default routes.map(route => {
  const meta = {
    ...route.meta,
    requiresAuth: true,
    authzScope: 'project',
  }
  return { ...route, meta }
});
