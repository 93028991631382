<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="table-fixed data-table-style custom-table mt-6"
    :headers="filteredHeaders"
    :items="filteredItems"
    :item-key="itemKey"
    :item-class="rowClass"
    :value="selectedItems"
    height="100%"
    show-select
    :single-select="singleSelect"
    @input="onRowClick"
    @click:row="handleRowClick"
  >
    <template v-slot:[`header.data-table-select`]="{ props, on }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          v-if="!singleSelect"
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          indeterminate-icon="icon-indeterminate"
          :input-value="props.value"
          :indeterminate="props.indeterminate"
          @change="on.input"
        />
      </div>
    </template>

    <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          :input-value="isSelected"
          @change="select"
          @click.stop
        />
      </div>
    </template>

    <template v-slot:[`header.actions`]="{header}">
      <div class="d-none">
        {{ header.text }}
      </div>
    </template>
    <template v-slot:[`item.name`]="{item}">
      <v-tooltip
        bottom
        left
        max-width="485px"
        :disabled="item.name.length < 61"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <div 
            class="custom-attribute text-truncate font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            {{ item.name }}
          </div>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.priority`]="{item}">
      <span
        :style="{ color: getPriorityColor(item.priority, priorities) }"
        class="font-weight-bold"
      >{{ getPriorityName(item.priority, priorities) }}</span>
    </template>
    <template v-slot:[`item.status`]="{item}">
      <span
        :style="{ color: getStatusColor(item.status, statuses) }"
        class="font-weight-bold"
      >{{ getStatusName(item.status, statuses) }}</span>
    </template>
    <template v-slot:[`item.configurations`]="{item}">
      <span
        :class="getColor(item.customFields?.configurations)"
        class="text-capitalize"
      >{{ item.customFields?.configurations }}</span>
    </template>
    <template v-slot:[`item.testruns`]="{item}">
      <span class="">{{ item.runs.length }} test runs</span>
    </template>
    <template v-slot:[`item.creationdate`]="{item}">
      <span class="">{{ formatCreationDate(item.createdAt) }}</span>
    </template>
    <template v-slot:[`item.milestones`]="{ item }">
      {{ item.milestones.map(milestone => milestone.name).join(', ') || 'No Milestones' }}
    </template>
    <template v-slot:[`item.tags`]="{item}">
      <v-tooltip
        bottom
        left
        max-width="485px"
        :disabled="!Array.isArray(item.customFields?.tags) || item.customFields.tags.length < 3"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            class="custom-attribute font-weight-regular text-theme-table-text"
            v-bind="attrs"
            v-on="on"
          >
            <template>
              <div class="text-truncate">
                <span v-if="Array.isArray(item.customFields?.tags) && item.customFields.tags.length > 0">
                  {{ item.customFields.tags.map(tag => `${tag}`).join(', ') }}
                </span>
                <span v-else-if="typeof item.customFields?.tags === 'string' && item.customFields.tags.trim() !== ''">
                  {{ item.customFields.tags }}
                </span>
                <v-icon v-else>mdi-minus</v-icon>
              </div>
            </template>
          </span>
        </template>
        <span>
          {{
            Array.isArray(item.customFields?.tags)
              ? item.customFields.tags.map(tag => `${tag}`).join(', ')
              : ''
          }}
        </span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.progress`]="{item}">
      <ProgressBar
        :executions="item.executionsProgress"
        :percentage="item.percentage"
        :case-count="item.runs[0]?.customFields?.caseCount"
      />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex flex-row justify-center">
        <v-menu
          left
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="writeEntity || deleteEntity"
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list
            dense
            class="text-left"
          >
            <v-list-item 
              v-if="item.archivedAt === null && writeEntity"
              :class="{ 'disabled-action': isProjectArchived }"
            >
              <div class="d-flex align-center">
                <DuplicateIcon />
              </div>
              <v-list-item-title 
                class="pl-3"
                @click="handleDuplicatePlan(item)"
              >
                {{ $t('duplicate') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="item.archivedAt === null && writeEntity"
              :class="{
                'pointer': true,
                'disabled-action': isProjectArchived
              }"
              @click="onAddToMilestone(item)"
            >
              <MilestoneIcon />
              <v-list-item-content class="ml-2">
                {{ $t('plans.addToMilestone') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="item.archivedAt === null && writeEntity"
              :class="{
                'pointer': true,
                'disabled-action': isProjectArchived
              }"
              @click="onEdit(item)"
            >
              <EditIcon />
              <v-list-item-content class="ml-2">
                {{ $t('edit') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="item.archivedAt === null && writeEntity"
              :class="{
                'pointer': true,
                'disabled-action': isProjectArchived
              }"
              @click="onArchive(item)"
            >
              <ArchieveIcon />
              <v-list-item-content class="ml-2">
                {{ $t('archive') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else-if="item.archivedAt !== null && writeEntity"
              :class="{
                'pointer': true,
                'disabled-action': isProjectArchived
              }"
              @click="onUnArchive(item)"
            >
              <ArchieveIcon />
              <v-list-item-content class="ml-2">
                {{ $t('unarchive') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="deleteEntity"
              :class="{
                'pointer': true,
                'disabled-action': isProjectArchived
              }"
              @click="onDelete(item)"
            >
              <DeleteIcon />
              <v-list-item-content class="ml-2 error--text">
                {{ $t('delete') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template v-slot:[`item.users`]="{ item }">
      <td class="d-flex align-center">
        <v-row>
          <div
            v-for="(pic, imgIndex) in item.images"
            :key="imgIndex"
          >
            <v-avatar
              class="ml-n2 custom_border"
              size="30"
            >
              <img
                :src="pic"
                alt=""
              >
            </v-avatar>
          </div>
          <v-avatar
            v-if="item.showCount"
            class="font-weight-bold gray-ish--text ml-n2"
            color="#ebecf0"
            size="30"
          >
            +{{ item.count }}
          </v-avatar>
        </v-row>
        <div>
          <v-menu
            content-class="custom_ele elevation-0"
            nudge-bottom="35"
            left
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon color="gray-ish">
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                :class="{
                  'pointer': true,
                  'disabled-action': isProjectArchived
                }"
                @click="onAddToMilestone(item)"
              >
                <MilestoneIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('addToMilestone') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :class="{
                  'pointer': true,
                  'disabled-action': isProjectArchived
                }"
                @click="onEdit(item)"
              >
                <EditIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('edit') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="item.customFields?.status==='Active'"
                :class="{
                  'pointer': true,
                  'disabled-action': isProjectArchived
                }"
                @click="onArchive(item)"
              >
                <ArchieveIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('archive') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else-if="item.customFields?.status==='Archived'"
                :class="{
                  'pointer': true,
                  'disabled-action': isProjectArchived
                }"
                @click="onUnArchive(item)"
              >
                <ArchieveIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('unarchive') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :class="{
                  'pointer': true,
                  'disabled-action': isProjectArchived
                }"
                @click="onDelete(item)"
              >
                <DeleteIcon />
                <v-list-item-content class="ml-2 error--text">
                  {{ $t('delete') }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import DeleteIcon from '@/assets/svg/delete.svg';
import EditIcon from '@/assets/svg/edit.svg';
import MilestoneIcon from '@/assets/svg/milestone.svg';
import ArchieveIcon from '@/assets/svg/archived.svg';
import DuplicateIcon from '@/assets/svg/duplicate.svg';

import { formattedDate } from '@/utils/util';
import { dateMixin } from '@/mixins/date.js';
import projectStatus from '@/mixins/projectStatus';
import colorPreferencesMixin from '@/mixins/colorPreferences';
import ProgressBar from '@/components/base/ProgressBar'


export default {
  components: {
    DeleteIcon,
    EditIcon,
    MilestoneIcon,
    ArchieveIcon,
    DuplicateIcon,
    ProgressBar
  },
  mixins: [dateMixin, projectStatus, colorPreferencesMixin],
  props: {
    filteredHeaders: Array,
    filteredItems: Array,
    itemKey: String,
    rowClass: Function,
    clearSelection: Boolean,
    singleSelect:{
      type: Boolean,
      default: false
    },
    writeEntity:{
      type: Boolean,
      default: true,
    },
    deleteEntity:{
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      selectedItems: [],
      statuses: [],
      priorities: []
    };
  },
  watch: {
    clearSelection(newVal) {
      if (newVal) {
        this.selectedItems = [];
        this.$emit('select-item', this.selectedItems);
      }
    },
  },
  created() {
    this.priorities = this.getPriorities("testPlan");
    this.statuses = this.getStatuses("testPlan");
  },
  methods: {
    formattedDate,
    onRowClick(item) {
      this.selectedItems = item;
      this.$emit('select-item', this.selectedItems);
    },
    handleRowClick(item) {
      this.$emit('select-row', item);
    },
    handleDuplicatePlan(item) { 
      this.$emit('duplicate-plan', item);
    },
    onEdit(item) {
      if (!this.isProjectArchived) {
      this.$emit('edit-item', item);
      }
    },
    onAddToMilestone(item) {
      if (!this.isProjectArchived) {
      this.$emit('add-to-milestone', item);
      }
    },
    onArchive(item) {
      if (!this.isProjectArchived) {
      this.$emit('archive-item', item);
      }
    },
    onUnArchive(item) {
      if (!this.isProjectArchived) {
      this.$emit('unarchive-item', item);
      }
    },
    onDelete(item) {
      if (!this.isProjectArchived) {
      this.$emit('delete-item', item);
      }
    },
    formatCreationDate(createdAt) {
      return this.formatDate(createdAt)
    },
    getColor(priority) {
      switch(priority?.toLowerCase()){
        case "high":
        case "failed":
          return "font-weight-bold red--text text--lighten-1";
        case "medium":
        case "rerun":
          return "font-weight-bold orange--text text--lighten-1";
        case "low":
        case "active":
          return "font-weight-bold green--text text--lighten-1";
        case "passed":
          return "font-weight-bold deep-purple--text text--lighten-1";
        case "in progress":
          return "font-weight-bold grey--text text--lighten-1";
      }
    },
  },
};
</script>

<style scoped>
  .v-data-table .v-data-table__wrapper tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }
  .v-data-table .v-data-table__wrapper tbody tr:nth-child(even) {
    background-color: #F9FAFB;
  }
  .pointer {
    cursor: pointer;
  }
  .custom_border {
    border: 2px solid #ffffff;
  }
  .v-data-table table {
    border-collapse: collapse;
  }
  .v-data-table th {
    border: none !important;
  }
  .v-data-table td {
    border: none !important;
    cursor: pointer;
  }
  .v-data-table tr.project-item:hover {
    border: 1px solid #d1e1ff !important;
  }
  .v-data-table .v-data-table__wrapper tbody tr {
    height: 80px;
  }
  .v-data-table .v-data-table__wrapper tbody tr td {
    height: 80px !important;
    padding-top: 0;
  }
  .v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
    background-color: transparent !important;
  }
</style>
