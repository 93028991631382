<template>
  <div>
    <v-sheet
      class="integration-item"
      rounded="lg"
      p="4"
      color="#f9fafb"
    >
      <div class="d-flex align-center justify-space-between py-4 px-2">
        <div
          v-if="isHeaderVisible('name')"
          class="d-flex gap-4"
        >
          <v-avatar
            class="mr-2"
            size="40"
          >
            <v-img
              :src="item.picUrl"
              :lazy-src="require('@/assets/png/project.png')"
              width="100%"
              alt="logo"
            />
          </v-avatar>
          <div class="text-start">
            <div class="fw-semibold fs-12px">
              {{ item.name || $t('integrations.integration_card.name') }}
            </div>
            <div class="text-theme-secondary fs-12px text-truncate mw-100px font-weight-regular">
              {{ item.service || $t('integrations.integration_card.description') }}
            </div>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="d-flex align-center justify-end">
            <v-btn
              icon
              @click.stop="onEdit(item)"
            >
              <v-sheet
                color="#F2F4F7"
                class="d-flex align-center justify-center pointer"
                height="40px"
                rounded="lg"
              >
                <EditIcon />
              </v-sheet>
            </v-btn>
            <v-btn
              icon
              @click.stop="onDelete(item)"
            >
              <DeleteIcon />
            </v-btn>
          </div>
        </div>
      </div>

      <div
        v-if="isHeaderVisible('projects')"
        class="pl-3 pb-5 d-flex justify-start"
      >
        <div>
          <div class="d-flex">
            <span class="font-weight-regular fs-12px">{{ $t('projectsName') }}:</span>
          </div>
          <div class="avatar-group">
            <div
              v-for="(logo, index) in item.projectLogos"
              :key="index"
            >
              <v-avatar
                class="ml-n2 custom_border"
                size="40"
              >
                <v-img
                  :src="logo || require('@/assets/png/project.png')"
                  :lazy-src="require('@/assets/png/project.png')"
                />
              </v-avatar>
            </div>
            <v-avatar
              v-if="item.projectUids?.length > 4"
              size="40"
              class="extra-avatar ml-n2"
            >
              <small>+{{ item.projectUids.length - 4 }}</small>
            </v-avatar>
          </div>
        </div>
      </div>
    </v-sheet>

    <EditIntegration
      v-if="showEditDialog"
      :edited-data="editedData"
      @integration-updated="$emit('integration-updated')"
      @refresh="$emit('refresh')"
      @close-dialog="showEditDialog = false"
    />
  </div>
</template>

<script>
import DeleteIcon from '../../assets/svg/delete.svg';
import EditIcon from '../../assets/svg/edit.svg';
import EditIntegration from './EditIntegration.vue';

export default {
  components: {
    DeleteIcon,
    EditIcon,
    EditIntegration,
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    filteredHeaders: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      editedData: null,
      showEditDialog: false,
    };
  },
  methods: {
    onEdit(item) {      
      this.editedData = {
        uid: item.uid,
        name: item.name,
        description: item.description,
        avatarUrl: item.avatarUrl,
        service: item.service,
        configurations: item.configuration.projectConfigurations,
      };
      this.showEditDialog = true;
    },
    onDelete(item) {
      this.$emit('delete-item', item.uid);
    },
    isHeaderVisible(headerValue) {
      return this.filteredHeaders.some(header => header.value === headerValue && header.checked);
    },
    handleEditSuccess() {
      this.$emit('refresh');
    }
  },
};
</script>

<style scoped>
.integration-item:hover {
  cursor: pointer;
}
.custom_border {
  border: 2px solid #ffffff;
}
.custom_color {
  color: #667085;
}
.avatar-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-group v-avatar:first-child {
  margin-left: 0;
}

.extra-avatar {
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #555;
}
</style>
