import { Axios } from 'axios';

/**
 *
 * @param {Axios} api
 * @returns
*/

export default function makeBillingService(api) {
  return {
    getCurrentSubscription: async function ({ model, uid }) {
      return api.get(`/subscriptions`, { 
        params: { model, uid } 
      });
    },

    getSubscriptionPlans: async function ({ model, uid }) {
      return api.get(`/subscriptions/plans`, { 
        params: { model, uid } 
      });
    },

    getBillingHistory: async function ({ model, uid }) {
      return api.get(`/subscriptions/billing-history`, { 
        params: { model, uid } 
      });
    },

    getPaymentMethods: async function ({ model, uid }) {
      return api.get(`/subscriptions/payment-methods`, { 
        params: { model, uid } 
      });
    },

    switchPlan: async function ({ model, uid, planId }) {
      return api.post(`/checkouts`, 
        { price_id:planId },
        { params: { model, uid } }
      );
    },

    addPaymentMethod: async function ({ model, uid, paymentMethodData }) {
      return api.post(`/subscriptions/payment-methods`,
        paymentMethodData,
        { params: { model, uid } }
      );
    },

    setDefaultPaymentMethod: async function ({ model, uid, paymentMethodId }) {
      return api.patch(
        `/subscriptions/payment-methods/default`, 
        { paymentMethodId },
        { params: { model, uid } }
      );
    },

    deletePaymentMethod: async function ({ model, uid, paymentMethodId }) {
      return api.delete(
        `/subscriptions/payment-methods/${paymentMethodId}`,
        { params: { model, uid } }
      );
    }
  };
}
