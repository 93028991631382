<template>
  <v-main class="dashboard__header">
    <div class="d-flex align-center justify-between mb-6">
      <div class="dashboard__header-title">
        <h3>{{ selectedProject }}</h3>
      </div>
      <div class="dashboard__header-actions">
        <v-btn
          class="text-capitalize __main-btn"
          large
          depressed
          @click="$emit('customize')"
        >
          {{ $t('customize') }}
        </v-btn>
      </div>
    </div>
    <div class="d-flex align-center justify-between">
      <div class="dashboard__header-filters d-flex">
        <v-select 
          v-model="selectedPeriod"
          placeholder="Period" 
          class="rounded-lg" 
          :items="periods" 
          :hide-details="true" 
          dense 
          filled  
          :menu-props="{'offset-y': true,}"
        />
        <v-select 
          placeholder="All milestones" 
          class="rounded-lg" 
          :disabled="true"
          :items="milestones" 
          :hide-details="true" 
          dense 
          filled  
          :menu-props="{'offset-y': true}"
        />
        <v-select 
          placeholder="All test programs" 
          class="rounded-lg" 
          :hide-details="true" 
          :disabled="true"
          dense 
          filled  
          :menu-props="{'offset-y': true}"
        />
        <v-select 
          placeholder="All test plans" 
          class="rounded-lg" 
          :hide-details="true" 
          :disabled="true"
          dense 
          filled  
          :menu-props="{'offset-y': true}"
        />
      </div>
      
      <div class="dashboard__header-tags">
        <v-text-field 
          class="rounded-lg pa-0"
          prepend-inner-icon="mdi-magnify" 
          :placeholder="$t('placeHolder.searchByTag')"
          dense
          filled
          hide-details
        />
      </div>
    </div>
  </v-main>
</template>
<script>
import { mapGetters } from 'vuex';
export default{
  data(){
    return{
      selectedPeriod: 'Period',
      periods:[
        'Last 7 days',
        'Last 14 days',
        'Last month',
        // 'Custom'
        /**
         * This will require a specific implementation, especially for the line chart, to generate average series data from the given period
         */
      ],
      milestones:[],
    }
  },
  computed:{
    ...mapGetters({
      projects: 'project/projects'
    }),
    selectedProject(){
      return this.projects.find(element => element.key == this.$route.params.key).name
    },
  },
}
</script>
<style>

  .__main-btn{
    font-family: 'Inter' !important;
    font-size: 14px;
    border-radius: 8px !important;
    padding: 10px 24px !important;
    background-color: #0C2FF3 !important;
    color: #FFF !important;
  }
  .__secondary-btn{
    font-family: 'Inter' !important;
    font-size: 14px;
    border-radius: 8px !important;
    padding: 10px 24px !important;
    background-color: #F2F4F7 !important;
    color: #0C111D !important;
  }
  .dashboard__header{
    background-color: #FFF;
    border-radius: 8px;
    padding: 24px !important;
  }
  .dashboard__header-title h3{
    font-size: 24px;
    font-family: 'Inter';
    font-weight: 700 !important;
    color: #101828;
  }
  .dashboard__header-tags{
    width: 380px;
  }
  .dashboard__header-actions{
    display: flex;
    gap: 12px;
    width: 380px;
  }
  .dashboard__header-filters{
    gap: 12px;
  }
  .dashboard__header-filters .v-select{
    width: min-content;
    font-size: 14px;
    min-width: 120px;
  }
  .dashboard__header-filters .v-select .v-select__selection{
    overflow: visible;
  }
</style>