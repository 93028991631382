<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="data-table-style table-fixed mt-6"
    :headers="filteredHeaders"
    :items="filteredItems"
    :item-key="itemKey"
    :item-class="rowClass"
    :value="selectedItems"
    height="100%"
    show-select
    @input="onRowClick"
    @click:row="handleRowClick"
  >
    <template v-slot:[`header.data-table-select`]="{ props, on }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          indeterminate-icon="icon-indeterminate"
          :input-value="props.value"
          :indeterminate="props.indeterminate"
          @change="on.input"
        />
      </div>
    </template>

    <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          :input-value="isSelected"
          @change="select"
          @click.stop
        />
      </div>
    </template>

    <template v-slot:[`header.actions`]="{header}">
      <div class="d-none">
        {{ header.text }}
      </div>
    </template>
    <template v-slot:[`item.name`]="{item}">
      <v-tooltip
        bottom
        left
        max-width="485px"
        :disabled="item.name.length < 61"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <div 
            class="custom-attribute text-truncate font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            {{ item.name }}
          </div>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.priority`]="{item}">
      <span
        class="font-weight-bold text--lighten-1"
        :style="{ color: getPriorityColor(item.status, priorities) }"
      >{{ getPriorityName(item.priority, priorities) }}</span>
    </template>
    <template v-slot:[`item.status`]="{item}">
      <span
        class="font-weight-bold text--lighten-1"
        :style="{ color: getStatusColor(item.status, statuses) }"
      >{{ getStatusName(item.status, statuses) }}</span>
    </template>
    <template v-slot:[`item.milestone`]="{item}">
      <v-tooltip
        top
        left
        max-width="485px"
        :disabled="!Array.isArray(item.testMilestones) || item.testMilestones.length < 2"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            class="custom-attribute font-weight-regular text-theme-table-text"
            v-bind="attrs"
            v-on="on"
          >
            <template>
              <div class="text-truncate">
                <span v-if="Array.isArray(item.testMilestones) && item.testMilestones.length > 0">
                  {{ item.testMilestones.map(milestone => `${milestone.name}`).join(', ') }}
                </span>
                <span v-else-if="typeof item.testMilestones === 'string' && item.testMilestones.trim() !== ''">
                  {{ item.testMilestones }}
                </span>
                <v-icon v-else>mdi-minus</v-icon>
              </div>
            </template>
          </span>
        </template>
        <span>
          {{
            Array.isArray(item.testMilestones)
              ? item.testMilestones.map(milestone => `${milestone.name}`).join(', ')
              : ''
          }}
        </span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.tags`]="{item}">
      <v-tooltip
        bottom
        left
        max-width="485px"
        :disabled="!Array.isArray(item.customFields?.tags) || item.customFields.tags.length < 2"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            class="custom-attribute font-weight-regular text-theme-table-text"
            v-bind="attrs"
            v-on="on"
          >
            <template>
              <div class="text-truncate">
                {{
                  Array.isArray(item.customFields?.tags)
                    ? item.customFields.tags.length > 0
                      ? item.customFields.tags.map(tag => `${tag}`).join(', ')
                      : $t('no_tags')
                    : typeof item.customFields?.tags === 'string' && item.customFields.tags.trim() !== ''
                      ? item.customFields.tags
                      : $t('no_tags')
                }}
              </div>
            </template>
          </span>
        </template>
        <span>
          {{
            Array.isArray(item.customFields?.tags)
              ? item.customFields.tags.map(tag => `${tag}`).join(', ')
              : ''
          }}
        </span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.testcases`]="{item}">
      <span class="">{{ item.customFields?.caseCount ?? 0 }} test cases</span>
    </template>
    <template v-slot:[`item.creationdate`]="{item}">
      <span class="">{{ formatCreationDate(item.createdAt) }}</span>
    </template>
    <template v-slot:[`item.progress`]="{item}">
      <ProgressBar
        :executions="item.executionsProgress"
        :percentage="item.percentage"
        :case-count="item.customFields.caseCount"
      />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex flex-row justify-center">
        <v-menu
          left
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="writeEntity || deleteEntity"
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list
            dense
            class="text-left"
          >
            <v-list-item
              v-if="item.archivedAt === null && writeEntity"
              class="pointer"
              @click="onEdit(item)"
            >
              <EditIcon />
              <v-list-item-content class="ml-2">
                {{ $t('edit') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="deleteEntity"
              class="pointer"
              @click="onDelete(item)"
            >
              <DeleteIcon />
              <v-list-item-content class="ml-2 error--text">
                {{ $t('delete') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template v-slot:[`item.users`]="{ item }">
      <td class="d-flex align-center">
        <v-row>
          <div
            v-for="(pic, imgIndex) in item.images"
            :key="imgIndex"
          >
            <v-avatar
              class="ml-n2 custom_border"
              size="30"
            >
              <img :src="pic">
            </v-avatar>
          </div>
          <v-avatar
            v-if="item.showCount"
            class="font-weight-bold gray-ish--text ml-n2"
            color="#ebecf0"
            size="30"
          >
            +{{ item.count }}
          </v-avatar>
        </v-row>
        <div class="d-flex flex-row justify-center">
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon color="gray-ish">
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                class="pointer"
                @click="onEdit(item)"
              >
                <EditIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('edit') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="item.status==='active'"
                class="pointer"
                @click="onArchive(item)"
              >
                <ArchieveIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('archive') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else-if="item.status==='archived'"
                class="pointer"
                @click="onUnArchive(item)"
              >
                <UnarchivedIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('unarchive') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="pointer"
                @click="onDelete(item)"
              >
                <DeleteIcon />
                <v-list-item-content class="ml-2 error--text">
                  {{ $t('delete') }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import DeleteIcon from '@/assets/svg/delete.svg';
import EditIcon from '@/assets/svg/edit.svg';
import ArchieveIcon from '@/assets/svg/archived.svg';
import UnarchivedIcon from '@/assets/svg/unarchive24px.svg';
import { formattedDate } from '@/utils/util';
import { dateMixin } from '@/mixins/date.js';
import colorPreferencesMixin from '@/mixins/colorPreferences';
import ProgressBar from '@/components/base/ProgressBar'

export default {
  components: {
    DeleteIcon,
    EditIcon,
    ArchieveIcon,
    UnarchivedIcon,
    ProgressBar,
  },
  mixins: [dateMixin, colorPreferencesMixin],
  props: {
    filteredHeaders: Array,
    filteredItems: Array,
    itemKey: String,
    rowClass: Function,
    clearSelection: Boolean,
    writeEntity: {
      type: Boolean,
      default: false
    },
    deleteEntity: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      debounce: false,
      selectedItems: [],
      statuses: [],
      priorities: []
    };
  },
  watch: {
    clearSelection(newVal) {
      if (newVal) {
        this.selectedItems = [];
        this.$emit('select-item', this.selectedItems);
      }
    },
  },
  created() {
    this.priorities = this.getPriorities("testRun");
    this.statuses = this.getStatuses("testRun");
  },
  methods: {
    formattedDate,
    onRowClick(item) {
      this.selectedItems = item;
      this.$emit('select-item', this.selectedItems);
    },
    safePercentage(value) {
      const percentage = value;
      return (typeof percentage === 'string' && percentage !== 'NaN') ? percentage : '0';
    },
    handleRowClick(item) {
      this.$emit('select-row', item);
    },
    onEdit(item) {
      this.$emit('edit-item', item);
    },
    onArchive(item) {
      this.$emit('archive-item', item);
    },
    onUnArchive(item) {
      this.$emit('unarchive-item', item);
    },
    onDelete(item) {
      this.$emit('delete-item', item);
    },
    toggleStar(item) {
      this.debounce = true;
      this.$emit('toggle-star', item);
    },
    formatCreationDate(createdAt) {
      return this.formatDate(createdAt)
    },
  },
};
</script>
<style scoped>
  .v-data-table .v-data-table__wrapper tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }
  .v-data-table-header__icon {
    opacity: 1 !important;
  }
  .v-data-table .v-data-table__wrapper tbody tr:nth-child(even) {
    background-color: #F9FAFB;
  }
  .pointer {
    cursor: pointer;
  }
  .header_text {
    color: #475467;
    font-weight: 700;
  }
  .custom_color {
    color: #667085;
  }
  .custom_border {
    border: 2px solid #ffffff;
  }
  .v-data-table table {
    border-collapse: collapse;
  }
  .v-data-table th {
    border: none !important;
  }
  .v-data-table td {
    border: none !important;
    cursor: pointer;
  }
  .v-data-table .v-data-table__wrapper tbody tr {
    height: 80px;
  }
  .v-data-table .v-data-table__wrapper tbody tr td {
    height: 80px !important;
    padding-top: 0;
  }
  .v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
    background-color: transparent !important;
  }
</style>