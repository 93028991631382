<template>
  <div class="configuration-wrapper">
    <!-- Organization Selection - Only show if coming from OAuth redirect -->
    <div
      v-if="showOrgSelection"
      class="mb-4"
    >
      <p class="field-label">
        {{ $t('integrations.success.orgs', { serviceName }) }}
      </p>
      <v-select
        v-model="selectedResource"
        :items="resources"
        item-text="resourceName"
        item-value="resourceId"
        background-color="#F9F9FB"
        class="rounded-lg field-theme"
        height="38px"
        :placeholder="$t('integrations.success.select_org', { serviceName })"
        @change="handleResourceSelection"
      />
    </div>

    <!-- Configuration Name -->
    <div class="mb-4">
      <p class="field-label">
        {{ $t('integrations.create_intergrations.configuration_name') }}
      </p>
      <v-text-field
        v-model="configName"
        class="rounded-lg field-theme pt-0"
        background-color="#F9F9FB"
        height="38px"
        :placeholder="$t('enterName')"
        @input="emitConfigurationUpdate"
      />
    </div>

    <!-- Testfiesta Projects Dropdown -->
    <div class="mb-4">
      <p class="field-label">
        {{ $t('integrations.create_intergrations.testfiesta_projects') }}
      </p>

      <v-select
        v-model="selectedTestfiestaProjects"
        :items="filteredTestfiestaProjects"
        item-text="name"
        item-value="uid"
        multiple
        chips
        deletable-chips
        background-color="#F9F9FB"
        class="rounded-lg field-theme"
        height="38px"
        :placeholder="$t('integrations.create_intergrations.select_testfiesta_projects')"
      >
        <template v-slot:prepend-item>
          <v-list-item
            class="px-4"
            @click.stop="toggleSelectAllTestfiesta"
          >
            <v-checkbox
              :input-value="isAllTestfiestaSelected"
              :indeterminate="isIndeterminateTestfiesta"
              class="field-theme"
              :ripple="false"
              off-icon="icon-checkbox-off"
              on-icon="icon-checkbox-on"
              :hide-details="true"
              dense
            >
              <template v-slot:label>
                <span class="select-text">{{ $t('selectAll') }}</span>
              </template>
            </v-checkbox>
          </v-list-item>
          <v-divider />
          <v-text-field
            v-model="searchTestfiesta"
            class="mx-4 mt-2"
            dense
            clearable
            hide-details
            :placeholder="$t('search')"
            prepend-icon="mdi-magnify"
          />
        </template>

        <template v-slot:item="{ item, on }">
          <v-list-item
            class="px-4"
            v-on="on"
          >
            <v-checkbox
              :input-value="isItemSelected(item)"
              class="field-theme"
              :ripple="false"
              off-icon="icon-checkbox-off"
              on-icon="icon-checkbox-on"
              :hide-details="true"
              dense
            >
              <template v-slot:label>
                <span class="select-text">{{ item.name }}</span>
              </template>
            </v-checkbox>
          </v-list-item>
        </template>
      </v-select>
    </div>

    <!-- Service Projects Dropdown -->
    <div class="mb-4">
      <p class="field-label">
        {{ getServiceEntityLabel }}
      </p>
      <v-select
        v-model="selectedServiceProjects"
        :items="filteredServiceProjects"
        :loading="isLoadingProjects"
        item-text="projectName"
        item-value="projectId"
        multiple
        chips
        deletable-chips
        background-color="#F9F9FB"
        class="rounded-lg field-theme"
        height="38px"
        :placeholder="$t('integrations.create_intergrations.select_service_entity', { 
          serviceName, 
          entityName: getServiceEntityName 
        })"
      >
        <template v-slot:prepend-item>
          <v-list-item
            class="px-4"
            @click.stop="toggleSelectAllService"
          >
            <v-checkbox
              :input-value="isAllServiceSelected"
              :indeterminate="isIndeterminateService"
              class="field-theme"
              :ripple="false"
              off-icon="icon-checkbox-off"
              on-icon="icon-checkbox-on"
              :hide-details="true"
              dense
            >
              <template v-slot:label>
                <span class="select-text">{{ $t('selectAll') }}</span>
              </template>
            </v-checkbox>
          </v-list-item>
          <v-divider />
          <v-text-field
            v-model="searchService"
            class="mx-4 mt-2"
            dense
            clearable
            hide-details
            :placeholder="$t('search')"
            prepend-icon="mdi-magnify"
          />
        </template>

        <template v-slot:item="{ item, on }">
          <v-list-item
            class="px-4"
            v-on="on"
          >
            <v-checkbox
              :input-value="isServiceItemSelected(item)"
              class="field-theme"
              :ripple="false"
              off-icon="icon-checkbox-off"
              on-icon="icon-checkbox-on"
              :hide-details="true"
              dense
            >
              <template v-slot:label>
                <span class="select-text">{{ item.projectName }}</span>
              </template>
            </v-checkbox>
          </v-list-item>
        </template>
      </v-select>
    </div>
    <!-- Add this after the Service Projects Dropdown -->
    <div class="d-flex justify-end mt-4">
      <v-btn
        v-if="Object.keys(editConfigData).length == 0"
        :disabled="!canAdd"
        class="add-config-button"
        @click="handleConfiguration"
      >
        {{ $t('integrations.create_intergrations.add_configuration') }}
        <v-icon
          right
          size="20"
        >
          mdi-plus
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        :disabled="!canAdd"
        class="add-config-button"
        @click="handleConfiguration"
      >
        {{ $t('integrations.create_intergrations.save_configuration') }}
        <v-icon
          right
          size="20"
        >
          mdi-content-save
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import makeIntegrationsService from '@/services/api/integrations';
import { showErrorToast, showSuccessToast } from '@/utils/toast';

export default {
  name: 'IntegrationConfigurationDropdown',
  props: {
    index: {
      type: Number,
      default: null
    },
    resources: {
      type: Array,
      default: () => []
    },
    testfiestaProjects: {
      type: Array,
      default: () => []
    },
    integrationUid: {
      type: String,
      default: ''
    },
    serviceName: {
      type: String,
      default: ''
    },
    editConfigData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedResource: null,
      serviceProjects: [],
      selectedTestfiestaProjects: [],
      selectedServiceProjects: [],
      configName: '',
      searchTestfiesta: '',
      searchService: '',
      selectAllTestfiesta: false,
      selectAllService: false,
      serviceEntities: {
        'jira': 'projects',
        'github': 'repositories',
      },
      isLoadingProjects: false,
    };
  },

  computed: {
    filteredTestfiestaProjects() {
      if (!this.searchTestfiesta) return this.testfiestaProjects;
      const searchTerm = this.searchTestfiesta.toLowerCase();
      return this.testfiestaProjects.filter((project) => project.name.toLowerCase().includes(searchTerm));
    },

    filteredServiceProjects() {
      if (!this.searchService) return this.serviceProjects;
      const searchTerm = this.searchService.toLowerCase();
      return this.serviceProjects.filter((project) => project.projectName.toLowerCase().includes(searchTerm));
    },

    isAllTestfiestaSelected() {
      return this.selectedTestfiestaProjects.length === this.filteredTestfiestaProjects.length;
    },
    isIndeterminateTestfiesta() {
      return (
        this.selectedTestfiestaProjects.length > 0 &&
        this.selectedTestfiestaProjects.length < this.testfiestaProjects.length
      );
    },
    isAllServiceSelected() {
      return this.selectedServiceProjects.length === this.serviceProjects.length;
    },
    isIndeterminateService() {
      return (
        this.selectedServiceProjects.length > 0 && this.selectedServiceProjects.length < this.serviceProjects.length
      );
    },
    canAdd() {
      const baseConditions =
        this.configName.trim() !== '' &&
        this.selectedTestfiestaProjects.length > 0 &&
        this.selectedServiceProjects.length > 0;

      // Only check for selectedResource if it's a Jira redirect
      if (this.showOrgSelection) {
        return baseConditions && this.selectedResource;
      }

      return baseConditions;
    },
    showOrgSelection() {
      const { name } = this.$route.query;
      if (name) {
        return !!name && name.toLowerCase() === 'jira';
      }
      else if (this.serviceName) {
        return this.serviceName.toLowerCase() === 'jira';
      }
      return false;
    },
    getServiceEntityName() {
      const serviceEntity = this.serviceEntities[this.serviceName.toLowerCase()];
      return serviceEntity || 'projects';
    },
    getServiceEntityLabel() {
      return this.$t('integrations.create_intergrations.service_entity', {
        serviceName: this.serviceName,
        entityName: this.getServiceEntityName
      });
    }
  },

  watch: {
    selectedResource(newVal) {
      if (newVal) {
        this.handleResourceSelection();
      }
    },
    selectedTestfiestaProjects(newVal) {
      this.selectAllTestfiesta = newVal.length === this.filteredTestfiestaProjects.length;
      this.emitConfigurationUpdate();
    },
    selectedServiceProjects(newVal) {
      this.selectAllService = newVal.length === this.filteredServiceProjects.length;
      this.emitConfigurationUpdate();
    },
    editConfigData(newVal) {
      if (Object.keys(this.editConfigData).length != 0) {
        this.configName = newVal.name;
        this.selectedTestfiestaProjects = Object.keys(newVal.projects).map((key) => parseInt(key, 10));
        this.selectedResource = newVal.resourceId;
        this.selectedServiceProjects = [
          ...new Set(
            Object.values(newVal.projects).flatMap((projectArray) => projectArray.map((project) => project.projectId))
          ),
        ];
      } else {
        this.reset();
      }
    },
  },

  async mounted() {
    // For non-Jira cases, fetch service projects directly
    if (!this.showOrgSelection) {
      await this.fetchServiceProjects();
    }
  },

  methods: {
    async fetchServiceProjects() {
      this.isLoadingProjects = true;
      try {
        const integrationsService = makeIntegrationsService(this.$api);
        const res = await integrationsService.getIntegrationProjects(
          this.$route.params.handle,
          this.integrationUid,
          ''
        );
        this.serviceProjects = res.data;
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || 'Failed to fetch service projects');
      } finally {
        this.isLoadingProjects = false;
      }
    },

    async handleResourceSelection() {
      if (!this.selectedResource) return;

      this.isLoadingProjects = true;
      try {
        const integrationsService = makeIntegrationsService(this.$api);
        const isJiraRedirect = this.showOrgSelection;

        if (isJiraRedirect) {
          const params = `resourceId=${this.selectedResource}`;
          const res = await integrationsService.getIntegrationProjects(
            this.$route.params.handle,
            this.integrationUid,
            params
          );
          this.serviceProjects = res.data;
        }

        this.emitConfigurationUpdate();
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || 'Failed to fetch service projects');
      } finally {
        this.isLoadingProjects = false;
      }
    },

    emitConfigurationUpdate() {
      this.$emit('configuration-update', {
        index: this.index,
        config: {
          resourceId: this.selectedResource,
          testfiestaProjects: this.selectedTestfiestaProjects,
          serviceProjects: this.selectedServiceProjects,
          name: this.configName,
        },
      });
    },

    toggleSelectAllTestfiesta() {
      if (this.isAllTestfiestaSelected) {
        this.selectedTestfiestaProjects = [];
      } else {
        this.selectedTestfiestaProjects = this.filteredTestfiestaProjects.map((p) => p.uid);
      }
      this.emitConfigurationUpdate();
    },

    handleTestfiestaItemClick(item) {
      const index = this.selectedTestfiestaProjects.indexOf(item.uid);
      if (index === -1) {
        this.selectedTestfiestaProjects.push(item.uid);
      } else {
        this.selectedTestfiestaProjects.splice(index, 1);
      }
      this.emitConfigurationUpdate();
    },

    toggleSelectAllService() {
      if (this.isAllServiceSelected) {
        this.selectedServiceProjects = [];
      } else {
        this.selectedServiceProjects = this.filteredServiceProjects.map((p) => p.projectId);
      }
      this.emitConfigurationUpdate();
    },

    handleServiceItemClick(item) {
      const index = this.selectedServiceProjects.indexOf(item.projectId);
      if (index === -1) {
        this.selectedServiceProjects.push(item.projectId);
      } else {
        this.selectedServiceProjects.splice(index, 1);
      }
      this.emitConfigurationUpdate();
    },

    isItemSelected(item) {
      return this.selectedTestfiestaProjects.includes(item.uid);
    },
    isServiceItemSelected(item) {
      return this.selectedServiceProjects.includes(item.projectId);
    },

    handleConfiguration() {
      if (!this.canAdd) return;

      const projects = {};
      this.selectedTestfiestaProjects.forEach((testfiestaId) => {
        projects[testfiestaId] = this.selectedServiceProjects.map((serviceId) => ({
          projectId: serviceId,
          projectName: this.serviceProjects.find((p) => p.projectId === serviceId).projectName,
        }));
      });
      const configurationData = {
        resourceId: this.selectedResource,
        name: this.configName,
        projects,
      };
      if (Object.keys(this.editConfigData).length == 0) {
        this.$emit('add-configuration', configurationData);
      } else {
        this.$emit('edit-configuration', configurationData);
      }
      this.reset();
    },

    reset() {
      this.selectedResource = null;
      this.configName = '';
      this.selectedTestfiestaProjects = [];
      this.selectedServiceProjects = [];
      this.searchTestfiesta = '';
      this.searchService = '';
      this.selectAllTestfiesta = false;
      this.selectAllService = false;
    },
  },
};
</script>

<style scoped>
.field-label {
  font-size: 14px;
  font-weight: 500;
  color: #344054;
  margin-bottom: 6px;
}

.field-theme ::v-deep .v-input__slot {
  border: none !important;
}

.select-text {
  font-size: 14px;
  color: #344054;
}

::v-deep .v-list-item {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.add-config-button {
  height: 40px;
  border-radius: 8px;
  text-transform: none;
  font-weight: 500;
  font-size: 14px;
  padding: 0 16px;
  background-color: #f2f4f7;
}

.add-config-button:not(:disabled) {
  color: #344054;
  background-color: #f9f9fb;
}

.add-config-button:hover:not(:disabled) {
  background-color: #f9fafb !important;
}
</style>