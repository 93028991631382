import ProjectLayout from '@/Layouts/ProjectLayout.vue';
import DefaultLayout from '@/Layouts/DefaultLayout.vue';

const routes = [
  {
    path: '/:handle/:key/plans',
    component: ProjectLayout,
    children: [
      {
        path: '',
        name: 'TestPlans',
        component: () => import('@/views/Tests/Plans/Index'),
      },
    ],
  },
  {
    path: '/:handle/:key/plans/create',
    component: DefaultLayout,
    meta: {
      requiredAuthz: ['write_entity'],
    },
    children: [
      {
        path: '',
        name: 'TestPlanCreate',
        component: () => import('@/views/Tests/Plans/CreatePlanView'),
      },
      {
        path: 'duplicate',
        name: 'TestPlanDuplicate',  
        component: () => import('@/views/Tests/Plans/Duplicate/Index'),
      },
    ],
  },
  {
    path: '/:handle/:key/plans/:planId',
    component: ProjectLayout,
    children: [
      {
        path: 'rerun',
        name: 'TestPlanRerun',
        component: () => import('@/views/Tests/Plans/RerunView'),
      },
      {
        path: '',
        name: 'TestPlanDetail',
        component: () => import('@/views/Tests/Plans/DetailView'),
        meta: {
          requiredAuthz: ['write_entity'],
        },
      },
    ],
  },
];

export default routes.map(route => {
  const meta = {
    ...route.meta,
    requiresAuth: true,
    authzScope: 'project',
    requiredAuthz: ['read_entity'],
  }
  return { ...route, meta }
});
