<template>
  <div
    class="d-flex align-center app-height-global"
  >
    <div class="not-found">
      <div class="position-relative img-placeholder">
        <Loader class="pt-0 position-absolute" />
        <img
          src="@/assets/png/setup-logo.png"
          alt="Setup Illustration"
          class="error-image"
        >
      </div>
      <div class="mt-4">
        <h1>{{ $t('setup.databaseCreation') }}</h1>
        <p class="mb-0">
          {{ $t('setup.redirectNotice') }}
        </p>
      </div>
    </div>
  </div>
</template>



<script>
import { mapState } from 'vuex';
import ProjectsService from '@/services/api/project';
import Loader from '@/components/base/Loader';
import colorPreferencesMixin from '@/mixins/colorPreferences';

export default {
  name: "Setup",
  components: {
    Loader,
  },
  mixins: [colorPreferencesMixin],
  data()
  {
    return {
      pollInterval: null,
    };
  },
  computed: {
    ...mapState({
      currentAccount: state => state.user.currentAccount,
    }),
  },
  mounted()
  {
    this.startPolling();
  },
  beforeDestroy()
  {
    this.stopPolling();
  },
  methods: {
    startPolling()
    {
      this.pollInterval = setInterval(() =>
      {
        this.pollProjects();
      }, 10000); // 10 seconds
    },
    stopPolling()
    {
      clearInterval(this.pollInterval);
    },
    async pollProjects()
    {
      if (!this.currentAccount || !this.currentAccount.handle) {
        console.error('Current account or handle is not available');
        return;
      }

      const projectService = ProjectsService(this.$api);
      const searchParams = new URLSearchParams();
      searchParams.set('status', 'active');
      searchParams.set('includeCount', true);

      try {
        const response = await projectService.getProjects(this.currentAccount.handle, searchParams.toString());
        if (Array.isArray(response.data.projects)) {
          this.updatePreferences(this.currentAccount.handle);
          const comingFromAuth = ["/login", "/signup", "/setup", "/"].includes(this.$router.history._startLocation.split('?')[0]);
          const dest = comingFromAuth
            ? { name: "ProjectsView", params: { handle: this.currentAccount.handle } }
            : this.$router.history._startLocation;

          this.$router.push(dest);
        }
      } catch (error) {
        console.error('Error polling projects:', error);
      }
    },
  },
};
</script>

<style scoped>
.not-found {
  text-align: center;
  padding: 48px;
  max-width: 600px;
  margin: 0 auto;
}

.error-image {
  max-width: 100%;
  height: auto;
}
.img-placeholder {
  width: 100%;
  height: 100%;
    min-width: 504px;
    min-height: 388px;
    display: flex;
    justify-content: center;
    align-items: center;
}
h1 {
  font-size: 24px;
  margin-bottom: 16px;
}

p {
  margin-bottom: 24px;
  text-align: center;
}
</style>
