export default function makePlanService(api) {
  return {
    createTestPlan: async function (handle, projectKey, data) {
      return api.post(`/${handle}/projects/${projectKey}/plans`, data);
    },
    duplicateTestPlan: async function(handle, projectKey, data){
      return api.post(`/${handle}/projects/${projectKey}/plans/duplicate`, data)
    },
    getAllTestPlans: async function (handle, projectKey, perPage, currentPage) {
      return api.get(`/${handle}/projects/${projectKey}/plans?per_page=${perPage}&current_page=${currentPage}`);
    },
    searchTestPlans: async function (handle, projectKey, text, perPage, currentPage) {
      return api.get(`/${handle}/projects/${projectKey}/plans/search?query=${text}&per_page=${perPage}&current_page=${currentPage}`);
    },
    deleteTestPlan: async function (handle, projectKey, uid) {
      return api.delete(`/${handle}/projects/${projectKey}/plans/${uid}`);
    },
    deleteTestPlans: async function (handle, projectKey, data) {
      return api.delete(`/${handle}/projects/${projectKey}/plans`, { data: data });
    },
    updateTestPlan: async function (handle, projectKey, uid, data) {
      return api.patch(`/${handle}/projects/${projectKey}/plans/${uid}`, data);
    },
    findTestPlan: async function (handle, projectKey, uid) {
      return api.get(`/${handle}/projects/${projectKey}/plans/${uid}`);
    },
    updateTestPlans: async function (handle, projectKey, data) {
      return api.patch(`/${handle}/projects/${projectKey}/plans/`, data);
    },
    addRunsToTestPlan: async function(handle, projectKey, uid, payload){
      return api.post(`/${handle}/projects/${projectKey}/plans/${uid}/runs`, payload)
    },

  };
}
