<template>
  <Loader
    v-if="loaderState"
    class="pt-0"
  />
  <v-card
    v-else
    flat
    max-width="500"
    width="100%"
    class="pb-5 h-screen"
  >
    <v-row class="d-flex flex-column pa-8 gap-4">
      <h3 class="font-weight-semibold mb-6">
        {{ $t('billing.yourCurrentPlan') }}
      </h3>
      <div class="d-flex justify-space-between align-center">
        <span class="uppercase fw-bold">{{ subscriptionInfo?.subscription?.currentPlan?.name || $t('billing.basic') }}</span>

        <v-btn
          width="138px"
          color="#F2F4F7"
          full-width
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme font-weight-semibold"
          elevation="0"
          @click="onUpgradePlan"
        >
          {{ $t('changePlan') }}
        </v-btn>
      </div>

      <div class="d-flex justify-space-between align-center bg-grey rounded-lg pa-4 ">
        <span>{{ $t('billing.nextPayment') }}</span>
        <span>{{ this.formatDate(subscriptionInfo?.subscription?.nextPaymentDate) }}</span>
      </div>
      <div class="d-flex justify-space-between align-center mt-2">
        <span class="font-weight-bold">{{ $t('billing.paymentMethod') }}</span>
        <button
          class=" d-flex justify-space-between align-center gap-2 text-primary"
          @click="onAddPaymentMethod"
        >
          {{ $t('billing.addPaymentMethod') }}
          <v-icon
            size="20"
            color="primary"
            class="ml-2"
          >
            mdi-plus
          </v-icon>
        </button>
      </div>
      <div
        v-for="paymentMethod in this.paymentMethods"
        :key="paymentMethod.id"
        class="d-flex justify-space-between align-center mt-2"
      >
        <span class="color-grey uppercase">{{ paymentMethod.brand }} ****{{ paymentMethod.last4 }}</span> 
        <div class="d-flex align-center gap-2">
          <span
            v-if="paymentMethod.isDefault"
            class="primary-badge"
          >Primary</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list
              dense
              flat
              class="text-left"
            >
              <v-list-item @click="onSetAsPrimary(paymentMethod.id)">
                <FlagIcon />
                <v-list-item-content class="ml-2 fs-14px text-theme-label">
                  <span class=""> {{ $t('billing.setAsPrimary') }}</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="onDeletePaymentMethod(paymentMethod.id)">
                <DeleteIcon />
                <v-list-item-content class="ml-2 fs-14px text-theme-label">
                  <span class=" text-danger"> {{ $t('delete') }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-row>
    <choose-plan-dialog
      v-model="showChoosePlanDialog"
      :plans="subscriptionPlans"
      :current-plan="subscriptionInfo?.subscription?.currentPlan"
      @choose-plan="choosePlan"
    />

    <add-payment-method
      v-model="showAddPaymentMethodDialog"
      :model="isOrg ? 'org' : 'user'"
      :uid="isOrg ? currentAccount.uid : user.uid"
      @payment-method-added="addPaymentMethod"
      @close-dialog="showAddPaymentMethodDialog = false"
    />
  </v-card>
</template>

<script>
import {  createNamespacedHelpers } from 'vuex';
import makeOrgService from '@/services/api/org';
import { showErrorToast, showSuccessToast } from '@/utils/toast';
import DeleteIcon from '@/assets/svg/delete.svg';
import FlagIcon from '@/assets/svg/milestone.svg';
import { userPlans, orgPlans } from '@/constants/plans';
import ChoosePlanDialog from './ChoosePlanDialog.vue';
import AddPaymentMethod from './AddPaymentMethod.vue';
import makeBillingService from '@/services/api/billing';
import Loader from '@/components/base/Loader.vue';
import { formatDate } from '@/utils/util';

const {  mapState } = createNamespacedHelpers('user');
export default {
  name: 'Subscription',
  components: {
    DeleteIcon,
    FlagIcon,
    ChoosePlanDialog,
    AddPaymentMethod,
    Loader
  },

  props: {
    isOrg: {
      type: Boolean,
      default: false,
    }
  },

  data()
  {
    return {
      loaderState: true,
      userService: null,
      orgService: null,
      billingService: null,
      showChoosePlanDialog: false,
      showAddPaymentMethodDialog: false,
      showPaymentForm: false,
      paymentRef: null,
      subscriptionInfo: null,
      paymentMethods: [],
      subscriptionPlans: [],
    }
  },

  computed: {
    ...mapState(['user', 'currentAccount']),
  },

  async mounted()
  {
    this.planList = this.isOrg ? orgPlans : userPlans
    this.orgService = makeOrgService(this.$api);
    this.billingService = makeBillingService(this.$api);

    await this.getSubscriptionInfo();
    await this.getPaymentMethods();
    this.getSubscriptionPlans();
    this.loaderState = false
  },

  methods: {
    async onSetAsPrimary(paymentMethodId){
      try {
        await this.billingService.setDefaultPaymentMethod({
          model: this.isOrg ? 'org' : 'user',
          uid: this.isOrg ? this.currentAccount.uid : this.user.uid,
          paymentMethodId
        });
        await this.getPaymentMethods();
        showSuccessToast(this.$swal, 'updateSuccess', { item: 'set as primary' });
      } catch (error) {
        showErrorToast(this.$swal, 'updateError', { item: 'set as primary' });
      }
    },
    async onDeletePaymentMethod(paymentMethodId) {
      try {
        await this.billingService.deletePaymentMethod({
          model: this.isOrg ? 'org' : 'user',
          uid: this.isOrg ? this.currentAccount.uid : this.user.uid,
          paymentMethodId
        });
        await this.getPaymentMethods();
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Payment method' });
      } catch (error) {
        showErrorToast(this.$swal, 'deleteError', { item: 'Payment method' });
      }
    },
    formatDate(date) {
      return formatDate(date, 'MM/dd/yy');
    },
    async getSubscriptionInfo(){
      try {
        const response = await this.billingService.getCurrentSubscription({
          model: this.isOrg ? 'org' : 'user',
          uid: this.isOrg ? this.currentAccount.uid : this.user.uid
        });
        this.subscriptionInfo = response.data;
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'subscription info' });
      }
    },

    async getPaymentMethods() {
      try {
        const response = await this.billingService.getPaymentMethods({
          model: this.isOrg ? 'org' : 'user',
            uid: this.isOrg ? this.currentAccount.uid : this.user.uid
        });
        this.paymentMethods = response.data;
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'payment methods' });
      }
    },

    onUpgradePlan()
    {
      this.showChoosePlanDialog = true
    },

    async choosePlan(plan)
    {
      try {
        await this.billingService.switchPlan({
          model: this.isOrg ? 'org' : 'user',
          uid: this.isOrg ? this.currentAccount.uid : this.user.uid,
          planId: plan.id
        });
        this.showChoosePlanDialog = false;
        await this.getSubscriptionInfo();
        await this.getSubscriptionPlans();
        showSuccessToast(this.$swal, 'updateSuccess', { item: 'Plan' });
      } catch (error) {
        showErrorToast(this.$swal, 'updateError', { item: 'change plan' });
      }
    },

    closePaymentForm()
    {
      this.showPaymentForm = false;
    },

    onAddPaymentMethod()
    {
      this.showAddPaymentMethodDialog = true
    },

    async addPaymentMethod(paymentMethodData) {
      try {
        await this.billingService.addPaymentMethod({
          model: this.isOrg ? 'org' : 'user',
            uid: this.isOrg ? this.currentAccount.uid : this.user.uid,
          paymentMethodData
        });
        this.showAddPaymentMethodDialog = false;
        await this.getPaymentMethods();
      } catch (error) {
        showErrorToast(this.$swal, 'createError', { item: 'add payment method' });
      }
    },

    async getSubscriptionPlans() {
      try {
        const response = await this.billingService.getSubscriptionPlans({
          model: this.isOrg ? 'org' : 'user',
            uid: this.isOrg ? this.currentAccount.uid : this.user.uid
        });
        this.subscriptionPlans = response.data;
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'subscription plans' });
      }
    }


  }
}
</script>

<style scoped>
.primary-badge{
  color: #66BB6A;
  font-weight: 600;
  font-size: 15px;
  background-color: transparent !important;
}
.uppercase{
  text-transform: uppercase;
}
.bg-grey {
  background-color: #F9FAFB;
}

.gap-4 {
  gap: 16px;
}

.h-screen {
  min-height: 70vh;
  height: 100%;
}

.text-primary {
  color: #0C2FF3;
  font-weight: 600;
  font-size: 15px;
}

.text-danger {
  color: #FF5656;
}
</style>
