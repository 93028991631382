<template>
  <div class="customize_header d-flex">
    <div class="customize-text d-flex">
      <a
        class="back-to-dashboard d-flex"
        text
        @click="$parent.$emit('customize')"
      >
        <v-icon color="blue">
          mdi-chevron-left
        </v-icon>
        <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
          {{ $t('dashboard.backToDashboard') }}
        </p>
      </a>
      <h3>Charts library</h3>
      <v-text-field
        v-model="searchFilter"
        :loading="loading"
        prepend-inner-icon="mdi-magnify"
        :placeholder="$t('search_by_name')"
        class="text-field mt-0 pa-0 mr-3 rounded-lg field-theme custom-prepend pa-0"
        height="40"
        background-color="#F9F9FB"
        hide-details
      >
        <template v-slot:prepend-inner>
          <SearchIcon />
        </template>
      </v-text-field>
    </div>
    <div class="customize-action">
      <v-btn
        class="text-capitalize __main-btn"
        large
        depressed
        @click="handleNavigation"
      >
        {{ $t('changePersonalization') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import SearchIcon from '@/assets/svg/search-icon.svg';
import { mapGetters } from "vuex";

export default{
  computed:{
    ...mapGetters({
      currentAccount: 'user/currentAccount'
    }),
  },
  components:{
    SearchIcon
  },
  data(){
    return{
      loading: false,
      searchFilter: null
    }
  },
  methods:{
    handleNavigation(){
      const routeName = this.currentAccount && this.currentAccount.type == 'org' ? 'OrgPersonalization' : 'Personalization';
      const route = this.$router.resolve({name: routeName})
      if(routeName)
        window.open(route.href, '_blank')
    }
  }
}
</script>

<style scoped>
  .customize_header{
    background-color: #FFF;
    padding: 16px 24px;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
  }
  .text-field{
    min-width: 300px;
  }
  .customize-text{
    flex-direction: column;
    gap: 16px;
  }
  .customize-text h3{
    font-size: 24px;
    font-weight: 700;
    color: #101828;
  }
</style>