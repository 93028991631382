import Vue from 'vue'
import { mapGetters } from 'vuex'
import { showErrorToast } from '@/utils/toast';
export default Vue.mixin({
  computed: {
    ...mapGetters({
      currentPermissions: 'user/currentPermissions',
      _user: 'user/user'
    }),
    unauthorizedToast(){
      return showErrorToast(this.$swal, this.$t('unauthorizedAccess'));
    }
  },
  methods:{
    authorityTo(permission) {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const permissions = this.currentPermissions(handle, projectKey)

      return this.$route.params.handle != this._user.handle ?
      permissions?.includes(permission) :
      true;
    }
  }
});