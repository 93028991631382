<template>
  <div>
    <v-data-table
      v-model="selectedRows"
      class="custom-table data-table-style table-fixed font-inter mt-6"
      show-select
      :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
      :headers="headers"
      :items="itemsPerView"
      :item-key="itemKey"
    >
      <template v-slot:[`header.data-table-select`]="{ props, on }">
        <div class="d-flex justify-center align-center">
          <v-checkbox
            id="remember-me-checkbox"
            class="field-theme"
            :ripple="false"
            off-icon="icon-checkbox-off"
            on-icon="icon-checkbox-on"
            indeterminate-icon="icon-indeterminate"
            :input-value="props.value"
            :indeterminate="props.indeterminate"
            @change="on.input"
          />
        </div>
      </template>

      <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
        <div class="d-flex justify-center align-center">
          <v-checkbox
            id="remember-me-checkbox"
            class="field-theme"
            :ripple="false"
            off-icon="icon-checkbox-off"
            on-icon="icon-checkbox-on"
            :input-value="isSelected"
            @change="select"
            @click.stop
          />
        </div>
      </template>

      <template v-slot:[`headers.name`]="{ header }">
        <span class="header_text">{{ header.text }}</span>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-tooltip
          bottom
          left
          max-width="485px"
          :disabled="item.name.length < 61"
          content-class="tooltip-theme"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="custom-attribute text-truncate font-weight-bold"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.name }}
            </div>
          </template>
          <span>{{ item.name }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.testcases`]="{ item }">
        <td class="d-flex align-center">
          <div class="text-start">
            <div class="text-subtitle-3">
              {{ item.customFields.caseCount }} {{ $t('plans.testRuns.testCases') }}
            </div>
          </div>
        </td>
      </template>
      <template v-slot:[`item.testruns`]="{ item }">
        <td class="d-flex align-center">
          <div class="text-start">
            <div class="text-subtitle-2 font-weight-bold">
              {{ item.testruns }} {{ $t('plans.testRuns.testRuns') }}
            </div>
          </div>
        </td>
      </template>
      <template v-slot:[`item.configuration`]="{ item }">
        <div class="d-flex flex-row align-center justify-start">
          <v-btn
            v-if="!item.configurations || item.configurations.length <= 0"
            color="primary"
            text
            class="fw-semibold text-capitalize"
            @click="handleAddConfigurations(item)"
          >
            <v-icon
              color="primary"
            >
              mdi-plus
            </v-icon> {{ $t('add') }}
          </v-btn>
          <v-menu
            v-else
            left
            offset-y
            class="font-inter"
          >
            <template v-slot:activator="{ on }">
              <button
                class="btn-configuration font-inter text-none"
                color="primary"
                v-on="on"
              >
                {{ item.configurations[0].title }} <v-icon color="#344054">
                  mdi-chevron-down
                </v-icon>
              </button>
            </template>
            <v-list
              dense
              class="configurations-menu text-left font-inter"
            >
              <v-subheader class="font-inter pt-2 px-4">
                {{ $t('configurations') }}
              </v-subheader>
              <v-list-item
                v-for="(group, index) of item.configurations"
                :key="index"
                class="pt-2 px-4 d-flex align-center"
              >
                <span>{{ group.title }}</span>
                <button
                  class="btn-edit d-flex ml-1"
                  @click="$emit('edit', item)"
                >
                  <iconEdit />
                </button>
                <button
                  class="btn-delete d-flex ml-1"
                  @click="$emit('delete', item)"
                >
                  <iconDelete />
                </button>
              </v-list-item>
              <v-list-item>
                <v-btn
                  class="btn-add-configuration"
                  @click="handleAddConfigurations(item)"
                >
                  <v-icon>mdi-plus</v-icon> {{ $t('add') }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:[`item.priority`]="{item}">
        <span
          class="font-weight-bold text--lighten-1"
          :style="{ color: getPriorityColor(item.priority, priorities) }"
        >{{ getPriorityName(item.priority, priorities) }}</span>
      </template>
      <template v-slot:[`item.status`]="{item}">
        <span
          class="font-weight-bold text--lighten-1"
          :style="{ color: getStatusColor(item.status, statuses) }"
        >{{ getStatusName(item.status, statuses) }}</span>
      </template>
      <template v-slot:[`item.milestone`]="{ item }">
        <v-tooltip
          top
          left
          max-width="485px"
          :disabled="!Array.isArray(item.testMilestones) || item.testMilestones.length < 2"
          content-class="tooltip-theme"
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              class="custom-attribute font-weight-regular text-theme-table-text"
              v-bind="attrs"
              v-on="on"
            >
              <template>
                <div class="text-truncate">
                  <span v-if="Array.isArray(item.testMilestones) && item.testMilestones.length > 0">
                    {{ item.testMilestones.map(milestone => `${milestone.name}`).join(', ') }}
                  </span>
                  <span v-else-if="typeof item.testMilestones === 'string' && item.testMilestones.trim() !== ''">
                    {{ item.testMilestones }}
                  </span>
                  <v-icon v-else>mdi-minus</v-icon>
                </div>
              </template>
            </span>
          </template>
          <span>
            {{
              Array.isArray(item.testMilestones)
                ? item.testMilestones.map(milestone => `${milestone.name}`).join(', ')
                : ''
            }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.tags`]="{ item }">
        <v-tooltip
          bottom
          left
          max-width="485px"
          :disabled="!Array.isArray(item.customFields?.tags) || item.customFields.tags.length < 2"
          content-class="tooltip-theme"
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              class="custom-attribute font-weight-regular text-theme-table-text"
              v-bind="attrs"
              v-on="on"
            >
              <template>
                <div class="text-truncate">
                  {{
                    Array.isArray(item.customFields?.tags)
                      ? item.customFields.tags.length > 0
                        ? item.customFields.tags.map(tag => `${tag}`).join(', ')
                        : $t('no_tags')
                      : typeof item.customFields?.tags === 'string' && item.customFields.tags.trim() !== ''
                        ? item.customFields.tags
                        : $t('no_tags')
                  }}
                </div>
              </template>
            </span>
          </template>
          <span>
            {{
              Array.isArray(item.customFields?.tags)
                ? item.customFields.tags.map(tag => `${tag}`).join(', ')
                : ''
            }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.creationdate`]="{ item }">
        <span class="">{{ formatCreationDate(item.createdAt) }}</span>
      </template>
      <template v-slot:[`item.progress`]="{ item }">
        <ProgressBar
          :executions="item.executionsProgress"
          :percentage="item.percentage"
          :case-count="item.customFields.caseCount"
        />
      </template>
    </v-data-table>
    <slot name="action" />
  </div>
</template>

<script>
import iconEdit from '@/assets/svg/edit-16x16.svg';
import { dateMixin } from '@/mixins/date.js';
import colorPreferencesMixin from '@/mixins/colorPreferences';
import iconDelete from '@/assets/svg/delete-16x16.svg';
import ProgressBar from '@/components/base/ProgressBar'

export default {
  name: 'TestRunsList',

  components: {
    iconEdit,
    iconDelete,
    ProgressBar
  },
  mixins: [dateMixin, colorPreferencesMixin],
  props: {
    tab: {
      type: String,
      default: 'all',
    },
    data: {
      type: Array,
    },
    selectedRuns: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    filteredItems: {
      type: Array,
    },
    rowClass: {
      type: Function,
    },
  },
  data() {
    return {
      itemKey: 'uid',
      selected: this.selectedRuns,
      isAllTestRunChecked: false,
      isSelectedTestRunChecked: false,
      isOpenAddConfigurations: false,
      statuses: [],
      priorities: []
    };
  },
  computed: {
    selectedRows:{
      get(){
        return this.selectedRuns
      },
      set(value){
        this.selected = value;
        this.$emit('selectTestRun', this.selected)
      }
    },
    itemsPerView() {
      if (this.tab === 'all') {
        return this.data
      } else {
        return this.selectedRuns
      }
    },
    
  },
  watch: {
    tab(newValue) {
      this.toggleTestRuns();
    },
  },
  created() {
    this.priorities = this.getPriorities("testRun");
    this.statuses = this.getStatuses("testRun");
  },
  methods: {
    selectedItems(items) {
      this.$emit('selectTestRun', items);
    },
    toggleTestRuns() {
      const isSelected = this.tab === 'all' ? this.isAllTestRunChecked : this.isSelectedTestRunChecked;
      this.data.forEach((run) => {
        const condition = this.tab === 'all' ? !run.selected : run.selected;
        if (condition) {
          this.$set(run, 'toBeSelected', isSelected);
        }
      });
    },
    toBeSelectedHandler() {
      const filteredRuns =
        this.tab === 'all' ? this.data.filter((run) => !run.selected) : this.data.filter((run) => run.selected);
      const allTrue = filteredRuns.every((run) => run.toBeSelected);

      if (this.tab === 'all') {
        this.isAllTestRunChecked = allTrue;
      } else {
        this.isSelectedTestRunChecked = allTrue;
      }
    },
    handleAddConfigurations(item) {
      this.$emit('addConfiguration', item);
    },
    formatCreationDate(createdAt) {
      return this.formatDate(createdAt)
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-add-configuration {
  padding: 0;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #667085;

  display: inline-flex;
  align-items: center;
  gap: 8px;

  &::before {
    display: none;
  }

  .v-icon {
    color: #667085;
  }
}

.btn-add-configuration:hover {
  color: #0c2ff3;

  .v-icon {
    color: #0c2ff3;
  }
}

.configurations-menu {
  .v-subheader {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0c111d;
  }

  .btn-edit {
    color: #667085;
  }

  .btn-delete {
    color: #f4284e;
  }

  .btn-add-configuration {
    color: #0c2ff3;
  }
}
</style>