<template>
  <v-main>
    <div
      v-if="loaderState"
      class="project-placeholder-height d-flex align-center justify-center"
    >
      <Loader />
    </div>
    <template v-else>
      <div
        v-if="!selectedExecutions.length"
        class="view-page"
      >
        <WorkspaceHeader 
          v-if="filterData"
          :filter="filterView"
          :todo-count="todoCount"
          :progress-calculation="progressCalculation"
          :completed-count="completedCount"
          :filter-data="filterData"
          @updateView="updateView" 
          @update-filters="setFilters"
        />
        <div
          class="mt-3 d-flex justify-center project-placeholder-height rounded-lg"
          :class="{ white: !executions.length,
                    'align-start': executions.length,
                    'align-center': !executions.length
          }"
        >
          <div
            v-if="!executions.length"
            class="white"
          >            
            <ActiveEmptyState
              image-src="/image/empty-workspace.svg"
              image-max-width="322px"
            >
              <template #description>
                <h2 class="text-center">
                  {{ $t('noCasesYet') }}<span class="d-block">{{ $t('itsVibeHere') }}</span>
                </h2>
                <p class="text-center">
                  {{ $t('waitingforcases') }}
                </p>
              </template>
            </ActiveEmptyState>
          </div>
          <v-row
            v-else
            align-content="start"
            justify="start"
            dense
          >
            <v-col :cols="isDetailViewCollapsed ? 9 : 12">
              <List 
                :case-items="displayedExecutions"
                :projects="projects"
                :tags="tags"
                :selected-execution.sync="selectedExecution"
                @expandDetail="openDetailView"
                @selectedExecutions="viewExecutions"
                @updateRows="updateRows"
              />
            </v-col>
        
            <v-col
              v-if="isDetailViewCollapsed && selectedExecution"
              cols="3"
            >
              <div class="sticky-div">
                <detail-view
                  class="app-height-global overflow-auto h-100vh"
                  :execution="selectedExecution"
                  :test-results="testResults"
                  :write-activity="_writeActivity"
                  :is-selected-execution-first-index="isCurrentSelectedExecutionFirstIndex"
                  :is-selected-execution-last-index="isCurrentSelectedExecutionLastIndex"
                  @closeDetail="closeDetailView"
                  @updateExecution="updateTestExecution"
                  @addResult="addTestResult"
                  @updateResult="updateTestResult"
                  @deleteResult="deleteTestResult"
                  @moveItem="moveSelectedItem"
                >
                  <template v-slot:attachment>
                    <fileInputWithSlider
                      :files.sync="attachments"
                    />
                  </template>
                </detail-view>
              </div>
            </v-col>
            <v-col
              v-if="selectedRows.length > 1"
              cols="12"
              class="d-flex justify-end"
            >
              <v-menu
                top
                offset-y
                :nudge-top="4"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    large
                    color="blue darken-3"
                    class="text-capitalize font-weight-bold white--text mr-4 mt-2"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                    v-on="on"
                  >
                    {{ $t('actions') }}<v-icon>
                      {{ menuOpen ? 'mdi-chevron-up' :
                        'mdi-chevron-down' }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list
                  dense
                  class="text-left"
                >
                  <v-list-item
                    @click="multiAddResults"
                  >
                    <v-icon
                      class="pr-2"
                    >
                      mdi-plus
                    </v-icon>{{ $t('addResult') }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </div>
      </div>
      <div
        v-else
        class="execution-page mt-6"
      >
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div class="rounded-lg white pa-6 d-flex align-center justify-between">
              <div class="d-flex">
                <div
                  class="d-flex align-center cursor-pointer"
                  @click="resetExecutions"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  <h2 class="__execution_name ml-2">
                    {{ selectedExecution.name }}
                  </h2>
                </div>
              </div>
              <div
                v-if="selectedExecutions.length > 1"
                class="__top-header-right d-flex align-center"
              >
                <v-btn
                  v-if="currentExecution > 1"
                  :elevation="0"
                  small
                  @click="moveExecution('previous')"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  <span>{{ $t('previous') }}</span>
                </v-btn>
                <p class="mb-0 mx-2">
                  {{ currentExecution }} of {{ selectedExecutions.length }}
                </p>
                <v-btn
                  v-if="currentExecution < selectedExecutions.length"
                  :elevation="0"
                  small
                  @click="moveExecution('next')"
                >
                  <span>{{ $t('next') }}</span>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <result-detail-view
              class="app-height-global"
              :execution="selectedExecution"
              :test-results="testResults"
              :show-actions="false"
              :show-name="false"
              @closeDetail="closeDetailView"
              @updateExecution="updateTestExecution"
              @addResult="addTestResult"
              @updateResult="updateTestResult"
              @deleteResult="deleteTestResult"
              @moveItem="moveSelectedItem"
            />
          </v-col>
          <v-col
            cols="6"
            class="px-0"
          >
            <v-card
              :elevation="0"
              class="pa-6 rounded-lg app-height-global"
            >
              <h2 class="fs-24px text-theme-base fw-semibold mb-4">
                {{ selectedExecution.name }}
              </h2>
              <v-form
                ref="form"
                class="text-left"
              >
                <label class="font-weight-medium d-block result-form-label mb-1">
                  {{ $t('status') }}<strong class="danger--text">*</strong>
                </label>

                <v-select 
                  v-model="selectedExecution.resultState" 
                  :placeholder="$t('selectStatus')" 
                  class="rounded-lg mb-4 field-theme custom-prepend" 
                  :rules="requiredRule"
                  :items="resultStatuses"
                  item-text="name"
                  item-value="id"
                  dense  
                  height="38px"
                  :menu-props="{'offset-y': true}"
                  background-color="#F9F9FB"
                  append-icon="mdi-chevron-down"
                />
                <label class="font-weight-medium d-block result-form-label mb-1">
                  {{ $t('addAttachment') }}
                </label>
                <fileInput
                  :files.sync="selectedExecution.files"
                  class="w-full mb-4"
                />
                <label class="font-weight-medium d-block result-form-label mb-1">{{ $t('addComment') }}</label>
                <tiptap-editor
                  v-model="selectedExecution.resultComment"
                  class="mb-8"
                  @files-added="updateFiles"
                />
                <div class="d-flex justify-end">
                  <v-btn
                    height="40px"
                    width="140px"
                    :elevation="0"
                    color="#f2f4f7"
                    class="text-capitalize black--text mr-4 mt-2 rounded-lg"
                    @click="clearResult"
                  >
                    {{ $t('clear') }}
                  </v-btn>
                  <v-btn
                    height="40px"
                    width="140px"
                    :elevation="0"
                    color="blue"
                    class="text-capitalize white--text mt-2 rounded-lg"
                    @click="addResult"
                  >
                    {{ $t('addResult') }}
                  </v-btn>
                </div>
              </v-form>
            </v-card>
          </v-col>
          <v-col
            cols="3"
          >
            <v-card
              :elevation="0"
              class="pa-6 rounded-lg app-height-global"
            >
              <v-text-field 
                v-model="search" 
                class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                :placeholder="$t('search')"
                height="40"
                background-color="#F9F9FB"
                hide-details
              >
                <template v-slot:prepend-inner>
                  <SearchIcon />
                </template>
              </v-text-field>
              
              <div class="mt-4 d-flex">
                <v-chip 
                  :class="{ 'blue--text': filter === 'history',
                            'fw-semibold': filter === 'history',
                            'font-weight-medium': filter != 'history'
                  }"
                  width="200px" 
                  :color="filter === 'history' ? '#e6ecff' : '#f9fafb'"
                  label 
                  @click="updateFilter('history')"
                >
                  <div class="px-2">
                    {{ $t('resultHistory') }} <span class="ml-2">{{ filterResults.length }}</span>
                  </div>
                </v-chip>
                <!-- 
                uncomment this once the comment API is ready
                <div class="ml-2">
                  <v-chip 
                    :class="{ 'blue--text': filter === 'comment',
                              'fw-semibold': filter === 'comment',
                              'font-weight-medium': filter != 'comment'
                    }" 
                    width="200px" 
                    :color="filter === 'comment' ? '#e6ecff' : '#f9fafb'"
                    label 
                    @click="updateFilter('comment')"
                  >
                    <div class="px-2">
                      {{ $t('comments') }} <span class="ml-2">0</span>
                    </div>
                  </v-chip>
                </div> -->
              </div>

              <!-- 
              uncomment this once the comment API is ready
              <div v-if="filter ==  'comment'">
                <tiptap-editor
                  class="mt-4 mb-3"
                  v-model="comments"
                  @files-added="updateCommentsFiles"
                >
                  <template #action>
                    <SendIcon/>
                  </template>
                </tiptap-editor>  
                <div class="bg-gray-theme rounded-lg mb-3">
                  <div class="pa-4">
                    <div class="d-flex justify-space-between mb-3">
                      <div class="d-flex align-center">
                        <v-avatar
                        size="32"
                        class="mr-2"
                        >
                          <img
                            src="https://cdn.vuetifyjs.com/images/john.jpg"
                            alt="John"
                          >
                        </v-avatar>
                        <div class="lh-normal">
                          <p class="mb-0 text-theme-label fs-14px font-weight-regular">John Smith</p>
                          <span class="fs-12px text-theme-secondary">Test run 21.21</span>
                        </div>
                      </div>
                      <div>
                        <span class="fs-12px text-theme-secondary vertical-align-super">03/15/24</span>
                      </div>
                    </div>
                    <p class="mb-0 text-theme-label fs-14px font-weight-regular">Executed thorough testing of the new user registration flow, ensuring seamless functionality across various browsers and devices. All scenarios passed successfully, and the feature is ready for deployment.</p>
                  </div>
                </div>
                <div class="bg-gray-theme rounded-lg mb-3">
                  <div class="pa-4">
                    <div class="d-flex justify-space-between mb-3">
                      <div class="d-flex align-center">
                        <v-avatar
                        size="32"
                        class="mr-2"
                        >
                          <img
                            src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
                            alt="John"
                          >
                        </v-avatar>
                        <div class="lh-normal">
                          <p class="mb-0 text-theme-label fs-14px font-weight-regular">James Watson has changed test case status.</p>
                        </div>
                      </div>
                      <div>
                        <span class="fs-12px text-theme-secondary vertical-align-super">03/15/24</span>
                      </div>
                    </div>
                    <div class="d-flex align-center">
                      <span class="text-status-new font-weight-medium fs-14px">New</span>
                      <ArrowRightIcon class="mx-2"/>
                      <span class="text-status-passed font-weight-medium fs-14px">Passed</span>
                    </div>
                  </div>
                </div>
              </div> -->

              <div v-if="filter === 'history'">
                <v-timeline
                  v-if="filterResults && filterResults.length"
                  dense
                  align-top
                  class="timeline-history"
                >
                  <div 
                    v-for="(result, index) in filterResults"
                    :key="index"
                    class="result-content"
                  >
                    <v-timeline-item
                      right
                      medium
                      class="timeline-item"
                      color="grey lighten-2"
                    >
                      <template v-slot:icon>
                        <span class="grey--text text--darken-1 caption font-weight-medium">{{ index + 1 }}</span>
                      </template>

                      <div
                        v-if="result.reporter"
                        class="d-flex justify-space-between mb-3"
                      >
                        <div class="d-flex align-center">
                          <v-avatar
                            size="32"
                            class="mr-2"
                            color="#f2f4f7"
                          >
                            <img
                              v-if="result.reporter?.avatarUrl"
                              :src="result.reporter?.avatarUrl"
                              alt="Profile"
                            >
                          </v-avatar>
                          <div class="lh-normal">
                            <p class="mb-0 text-theme-label fs-14px font-weight-regular">
                              {{ result.reporter.firstName }} {{ result.reporter.lastName }}
                            </p>
                          </div>
                        </div>
                        <div class="d-flex align-center">
                          <span class="fs-12px text-theme-secondary">{{ result.resultCreatedAt }}</span>
                        </div>
                      </div>
                      <div class="step-item">
                        <div class="d-flex justify-space-between mb-3">
                          <p class="mb-0 text-theme-label fs-14px fw-semibold">
                            {{ result.title }}
                          </p>
                          <div class="step-item-status">
                            <h5
                              class="fw-semibold text-capitalize"
                              :style="{color: getStatusColor(result.status, resultStatuses)}"
                            >
                              {{ getStatusName(result.status, resultStatuses) }}
                            </h5>
                          </div>
                        </div>
                        <div class="step-item-comment">
                          <div
                            class="fs-14px text-theme-label align-start"
                            v-html="result.comment"
                          />
                        </div>
                        
                        <SliderGroup
                          :files="result.files"
                          is-preview
                        />
                      </div>
                    </v-timeline-item>
                  </div>
                </v-timeline>
                <div
                  v-else
                  class="mt-3"
                >
                  {{ $t('noResultFound') }}
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-main>
</template>
<script>

import WorkspaceHeader from '@/components/Workspace/WorkspaceHeader'
import workspaceService from '@/services/api/workspace'
import List from '@/components/Workspace/List'
import makeResultService from '@/services/api/result'
import makeExecutionService from '@/services/api/execution'
import ResultDetailView from '@/components/Workspace/ResultDetailView.vue'
import DetailView from '@/components/Execution/Index.vue'
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import { mapActions } from 'vuex'
import Loader from '@/components/base/Loader'
import ActiveEmptyState from '@/components/base/ActiveEmptyState.vue';
import TagService from '@/services/api/tag';
import ProjectsService from '@/services/api/project';
import fileInputWithSlider from "@/components/base/FileInputWithSlider.vue";
import fileInput from "@/components/base/FileInput.vue";
import TiptapEditor from '@/components/base/TipTapEditor.vue';
import SearchIcon from '@/assets/svg/search-icon.svg';
// import SendIcon from '@/assets/svg/tiptap/send.svg';
// import ArrowRightIcon from '@/assets/svg/arrow-right.svg';
import SliderGroup from "@/components/base/SliderGroup.vue";
import { formattedDate } from '@/utils/util';
import handleLoading from '@/mixins/loader.js'
import colorPreferencesMixin from '@/mixins/colorPreferences';

let makeProjectService;
let makeTagService;

export default{
  components: {
    WorkspaceHeader,
    ActiveEmptyState,
    List,
    Loader,
    ResultDetailView,
    DetailView,
    fileInputWithSlider,
    fileInput,
    TiptapEditor,
    SearchIcon,
    // SendIcon,
    // ArrowRightIcon,
    SliderGroup,
  },
  mixins: [handleLoading, colorPreferencesMixin],
  data(){
    return {
      attachments: [],
      filter: 'history',
      loading: false,
      filterData: null,
      filterView: 'todo',
      menuOpen: false,
      selectedFilters: null,
      executions: [],
      selectedExecution: {},
      isDetailViewCollapsed: false,
      testResults: [],
      selectedExecutions: [],
      selectedRows: [],
      customToolbar: [ '#bold', '#italic', '#strike', '|', '#bulletList', '#orderedList' ],
      editorContent: '',
      comments: '',
      tiptapFiles: [],
      commentsFiles: [],
      resultStatuses: [],
      runStatuses: [],
      tableLoadingState: false,
      loaderState: false,
      percentage: 0,
      statusExecutions: [],
      search: '',
      requiredRule: [
        v => !!v || 'This is required',
      ],
      tags: [],
      projects: [],
    }
  },
  computed:{
    _writeActivity(){
      return this.authorityTo('write_activity')
    },
    progressStatus(){
      return this.runStatuses.reduce((acc, item) => {
          acc[item.name] = 0;
          return acc;
      }, {});
    },
    progressCalculation(){
      return {
        progress: this.statusExecutions,
        percentage: this.percentage,
        length: this.executions.length
      }
    },
    currentExecution(){
      return this.selectedExecutions.findIndex(element => element.uid == this.selectedExecution.uid) + 1
    },
    // TODO Remove Hardcoding here
    todoCount(){
      return this.executions?.filter(element => !this.getEntityCompletedStatuses('testCase').includes(element.status))?.length ?? 0
    },
    completedCount(){
      return this.executions?.filter(element => this.getEntityCompletedStatuses('testCase').includes(element.status))?.length ?? 0
    },
    displayedExecutions(){
      const executions = this.executions?.filter((element) => {
      const completedExecution = this.getEntityCompletedStatuses('testCase').includes(element.status);
        if (this.filterView === 'completed')
          return completedExecution && !element.toWait;
        else (this.filterView === 'todo') 
          return !completedExecution || (!completedExecution && element.toWait == 1);
      });
      return executions
    },
    currentSelectedExecutionIndex(){
      return this.displayedExecutions.indexOf(this.selectedExecution)
    },   
    isCurrentSelectedExecutionFirstIndex(){
      return this.currentSelectedExecutionIndex == 0
    },
    isCurrentSelectedExecutionLastIndex(){
      return this.currentSelectedExecutionIndex == this.displayedExecutions.length - 1
    },
    nextExecution(){
      return this.displayedExecutions[this.currentSelectedExecutionIndex + 1]
    },
    previousExecution(){
      return this.displayedExecutions[this.currentSelectedExecutionIndex - 1]
    },
    filterResults() {
      return this.testResults.map(item => {
        const files = [];
        const createdAt = item.resultCreatedAt ? formattedDate(item.resultCreatedAt) : null;

        if (item.attachments?.length) {
          item.attachments.forEach(element => {
            const { url, name, fileType, uid } = element;
            files.push({
              previewUrl: url,
              name,
              type: fileType,
              uid
            });
          });
        }

        return {
          ...item,
          resultCreatedAt: createdAt,
          files
        };
      });
    }
  },
  mounted(){
    try {
      const promises = [
        this.getOverview(),
        this.getExecutions(),
        this.getProjects(),
        this.getTags(),
      ];
      this.init(promises); 
    } catch (error) {
      showErrorToast(this.$swal, 'fetchError', { item: 'workspace' });
    }
  },
  
  created(){
    makeProjectService = ProjectsService(this.$api);
    makeTagService = TagService(this.$api);
    this.runStatuses = this.getStatuses("testRun");
    this.resultStatuses = this.getStatuses('testCase');
  },
  methods:{
    ...mapActions({
      uploadToServer: 'attachment/uploadToServer',
    }),
    clearResult(){
      this.selectedExecution.files = [];
      this.selectedExecution.resultComment = null;
      this.selectedExecution.resultState = null;
    },
    updateFiles(files) {
      this.tiptapFiles = files;
    },
    updateCommentsFiles(files) {
      this.commentsFiles = files;
    },
    updateFilter(filter){
      this.filter = filter
    },
    moveExecution(direction){
      const currentIndex = this.selectedExecutions.findIndex(element => element.uid == this.selectedExecution.uid);
      if(direction == 'next')
        this.selectedExecution = this.selectedExecutions[currentIndex + 1]
      else if(direction == 'previous')
        this.selectedExecution = this.selectedExecutions[currentIndex - 1]
      
      const projectKey = this.selectedExecution.projectKey;
      const uid = this.selectedExecution.uid;
      this.getExecutionResults(projectKey, uid)
    },
    resetExecutions(){
      this.selectedExecutions = [];
      this.selectedExecution = null;
      this.isDetailViewCollapsed = false;
    },
    addResult(){
      if(this.$refs.form.validate()){
        const payload = {
          files: this.selectedExecution.files,
          status: this.selectedExecution.resultState,
          comment: this.selectedExecution.resultComment
        }
        this.addTestResult(payload)
        this.getExecutionResults(this.selectedExecution.projectKey, this.selectedExecution.uid)

        this.selectedExecution.files = [];
        this.selectedExecution.resultComment = null
        this.selectedExecution.resultState = null;
        this.$refs.form.reset();
      }
    },
    updateRows(rows){
      this.selectedRows = rows
    },
    multiAddResults(){
      this.viewExecutions(this.selectedRows)
    },
    async getExecutions(){
      const makeWorkspaceService = workspaceService(this.$api);
      const handle = this.$route.params.handle;
      await makeWorkspaceService.getWorkspaceExecutions(handle, this.selectedFilters).then(response => {
        this.executions = response.data?.executions;
        this.statusExecutions = response.data?.executionsProgress;
        this.percentage = response.data?.percentage;
      });
    },
    updateView(data){
      this.filterView = data
    },
    async viewExecutions(selectedExecutions){
      this.selectedExecutions.push(...selectedExecutions)
      this.selectedExecution = this.selectedExecutions[0];
      await this.getExecutionResults(this.selectedExecution.projectKey, this.selectedExecution.uid)
    },
    async getOverview(){
      const makeWorkspaceService = workspaceService(this.$api);
      const handle = this.$route.params.handle;
      await makeWorkspaceService.getWorkspaceOverview(handle).then(response => {
        this.filterData = response.data
      });
    },
    async setFilters(filters){
      this.selectedFilters = filters
      await this.getExecutions();  
    },
    async getExecutionResults(projectKey, executionUid){
      const handle = this.$route.params.handle;
      const resultService = makeResultService(this.$api);
      this.testResults = await resultService.getTestResults(handle, projectKey, executionUid).then(async response => {
        return response.data   
      }) 
    },
    closeDetailView() {
      this.selectedExecution = {};
      this.isDetailViewCollapsed = false;
    },
    async openDetailView(item) {
      this.selectedExecution = item;
      this.isDetailViewCollapsed = true;
      await this.getExecutionResults(item.projectKey, item.uid)
    },
    async moveSelectedItem(direction){
      let itemIndex = this.displayedExecutions.findIndex(element => element.uid == this.selectedExecution.uid)
      if(itemIndex == -1){
        this.selectedExecution = null;
        this.isDetailViewCollapsed = false;
      }
      if(direction == 'next' && itemIndex < this.displayedExecutions.length-1)
        this.selectedExecution = this.displayedExecutions[itemIndex + 1]
      else if(direction == 'previous' && itemIndex > 0)
        this.selectedExecution = this.displayedExecutions[itemIndex - 1]

      await this.getExecutionResults(this.selectedExecution.projectKey,this.selectedExecution.uid)
    },
    async addTestResult(data){
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.selectedExecution.projectKey;
      const selectedExecution = this.selectedExecution.uid;
      const mediaType = 'attachment';
      const payload = {
        status: data.status,
        comment: data.comment
      };

      await resultService.addTestResult(handle, projectKey, selectedExecution, payload).then(async (response) => {
        const result = response.data;
        const params = {
          handle,
          projectKey,
          resultUid: result.uid
        }
        if(data.files?.length)
         await Promise.all(data.files.map(async (file) => {
          await this.uploadToServer( {handle, mediaType, file, apiService: resultService, params});
        })).then(() => {
          showSuccessToast(this.$swal, this.$t('success.testResultAdded'))
        }).catch(() => {
          showErrorToast(this.$swal, this.$t('error.failedToAddTestResult'))
        })
        else
          showSuccessToast(this.$swal, this.$t('success.testResultAdded'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.failedToAddTestResult'))
      })
      
      await this.getExecutionResults(projectKey,selectedExecution)
    },
    async updateTestResult(resultUid,data){
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.selectedExecution.projectKey;

      const payload = {
        status: data.status,
        comment: data.comment
      }
      
      await resultService.updateTestResult(handle, projectKey, resultUid, payload).then(async () => {
        const resultIndex = this.testResults.findIndex(element => element.resultUid == resultUid)
        this.testResults[resultIndex].status = payload.status[0].toUpperCase()+payload.status.slice(1)
        this.testResults[resultIndex].comment = payload.comment;
        const mediaType = "attachment";
        const params = {
          handle,
          projectKey,
          resultUid
        }
        if(data.files.length)
          await Promise.all(data.files.map(async (file) => {
            await this.uploadToServer({handle, mediaType, file, apiService: resultService, params});
          })).then(() => {
            showSuccessToast(this.$swal, this.$t('success.testResultUpdated'))
          }).catch(() => {
            showErrorToast(this.$swal, this.$t('success.testResultUpdated'))
          })
        else
          showSuccessToast(this.$swal, this.$t('success.testResultUpdated'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('success.testResultUpdated'))
      })
    },
    async deleteTestResult(resultUid){
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.selectedExecution.projectKey;
      
      await resultService.deleteTestResult(handle, projectKey, resultUid).then(() => {
        showSuccessToast(this.$swal, this.$t('success.testResultDeleted'));
        const resultIndex = this.testResults.findIndex(element => element.resultUid == resultUid)
        this.testResults.splice(resultIndex,1)
      }).catch( () => {
        showErrorToast(this.$swal, this.$t('error.failedToDeleteTestResult'))
      } )
    },
    async updateTestExecution(updatedData){
      const executionService = makeExecutionService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.selectedExecution.projectKey;
      const selectedExecution = this.selectedExecution.uid;
      let payload = {};
      
      if(typeof(updatedData.value) == 'object')
        Object.assign(payload, {
          [updatedData.property] : updatedData.value.uid
        })
      else
        Object.assign(payload, {
          [updatedData.property] : updatedData.value
        })
      
      const findIndex = this.executions.findIndex(element => element.uid == this.selectedExecution.uid);    

      await executionService.updateExecution(handle,projectKey,selectedExecution,payload).then(async (response) => {
        const execution = response.data;
        if(execution.uid)
          this.executions.splice(findIndex, 1, {...this.executions[findIndex], ...execution}) 

        showSuccessToast(this.$swal, this.$t('success.executionUpdated'))

        if(this.getEntityCompletedStatuses('testCase').includes(execution.status)){
          await new Promise((resolve) => setTimeout(() => {
              this.executions[findIndex].toWait = undefined
              this.selectedExecution = this.displayedExecutions[0]
              return resolve
            }, 1500))
        }else{
          // this.filterView = 'todo'
          // this.selectedExecution = null;
          // this.isDetailViewCollapsed = false;
        }
          

      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.executionUpdateFailed'))
      })
    },
    async getProjects(additionalParams = {}, handle) {
      if (!handle) handle = this.handle;
      try {
        const response = await makeProjectService.getProjects(handle);
        this.projects = response.data?.projects;
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
      } 
    },
    async getTags() {
      const handle = this.$route.params.handle;
      try {
          const response = await makeTagService.getTags(handle);
          this.tags = response.data;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'tags' });
        return [];
      }
    },
  },
}
</script>
<style scoped>

#input-file-browser{
  display: block;
  height: 120px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #D0D5DD;
  background-color: #F9FAFB;
  color: #0C2FF3;
  font-weight: bold;
  cursor: pointer;
}
#input-file-browser:hover{
  background-color: #eeeeee;
  transition: all 0.5s;
}
h2.__execution_name{
  color: #101828;
  font-weight: 500;
  font-size: 24px;
  font-weight: 600;
}
h2.result-execution{
  font-size: 20px;
}
</style>