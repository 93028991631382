<template>
  <div class="ai-popup text-left">
    <div class="text-left">
      <v-label class="text-left fs-14px text-theme-label font-weight-medium">
        {{ $t('prompt') }}
      </v-label>
      <v-text-field
        v-model="userPrompt"
        :placeholder="$t('aiAssist.whatDoYouWantToImprove')"
        class="round-8 field-theme"
        background-color="#F9F9FB"
        hide-details="false"
        dense
        height="38px"
        required
      />
    </div>
    <div class="ai-assist-panel text-left mt-6">
      <v-label class="text-left fs-14px text-theme-label font-weight-medium mb-3 d-block">
        {{ $t('aiAssist.suggestedPrompts') }}
      </v-label>
      <div class="panel-list custom-scrollbar">
        <transition-group
          name="ai-list-item"
          tag="ul"
        >
          <li
            v-for="(item, index) in prompts"
            :key="item"
            class="ai-assist-item"
            :style="{'transition-delay': `${index * 250}ms`}"
            @click="setPrompt(item)"
          >
            <span>{{ item }}</span>
          </li>
        </transition-group>
      </div>
    </div>
    <div class="ai-assist-action d-flex justify-space-between">
      <v-btn
        v-if="!regenerate"
        depressed
        background-color="#F2F4F7"
        class="text-capitalize btn-theme"
        height="38px"
        @click="$emit('closeAssist')"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        v-if="regenerate"
        depressed
        background-color="#F2F4F7"
        class="text-capitalize btn-theme"
        height="38px"
        @click="$emit('closeAssist')"
      >
        {{ $t('aiAssist.accept') }}
      </v-btn>
      <v-btn
        v-if="regenerate"
        depressed
        background-color="#F2F4F7"
        class="text-capitalize btn-theme"
        height="38px"
        @click="$emit('reject')"
      >
        {{ $t('aiAssist.reject') }}
      </v-btn>
      <v-btn
        depressed
        class="f-color-white btn-theme"
        color="primary"
        :disabled="!userPrompt"
        @click="generateResponse"
      >
        {{ regenerate ? $t('regenerate') : $t('generate') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
export default{
  props:{
    regenerate:{
      type: Number,
      default: 0
    }
  },
  data(){
    return{
      prompts: [],
      userPrompt: null,
    }
  },
  mounted() {
    this.suggestedPrompts()
  },
  methods:{
    async suggestedPrompts(){
    // API Retrieve user prompt here
      this.prompts = [
        "Improve clarity and conciseness",
        "Optimize flow and coherence.",
        "Strengthen vocabulary choices",
        "Eliminate redundant phrases",
        "Simplify complex sentence structures"
      ];
    },
    generateResponse(){
      this.$emit('generateResponse', this.userPrompt); 
      this.userPrompt = null
    },
    setPrompt(val){
      this.userPrompt = val 
    }
  }
}
</script>
<style scoped lang="scss">
.ai-popup{
  z-index: 201;
  width: 396px;
  background-color: #FFF;
  border-radius: 8px;
  box-shadow: 0px 16px 40px 0px #0000000F;
  padding: 24px;
  position: absolute;
  .ai-assist-panel{
  width: 100%;
  height: 200px !important;
  min-height: 200px !important;
  .panel-list{
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    height: 138px !important;
    min-height: 138px !important;
    overflow: hidden;
    overflow-y: scroll;
    ul{
      list-style: none;
      padding: 0px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    li.ai-assist-item{
      background-color: #F3F4F6;
      width: 100%;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid transparent;
      span{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        font-size: 14px;
      }
      &:hover{
        cursor: pointer;
        border: 1px solid rgb(112, 112, 112);
        }
      }
    }
  }
  .ai-assist-action{
    gap: 10px;
    flex-wrap: wrap;
    button{
      width: calc(32% - 10px) !important;
      flex-grow: 1;
    }
  }
}
</style>