<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      class="fill-height"
      align="center"
      justify="center"
    >
      <v-col align="center">
        <h1 class="invite_title">
          {{ $t('unlockTheFun') }} - {{ $t('join') }} {{ orgName }}!
        </h1>
        <p class="invite_subtitle">
          {{ $t('acceptInvite', { name: sender }) }}
        </p>

        <v-row class="invite_org">
          <div
            class="flex flex-column align-start rounded-lg py-2 px-3"
            style="background-color: #f9f9fb"
          >
            <label
              for="organization"
              class="text-caption"
            >{{ $t('org') }}</label>
            <div class="flex-grow-1" />
            <span class="font-weight-bold">{{ orgName }}</span>
          </div>
        </v-row>

        <v-row class="invite_org">
          <v-btn
            id="signup"
            block
            color="primary"
            type="submit"
            :depressed="true"
            class="btn-theme"
            height="40"
            :loading="isAccepting"
            :disabled="isAccepting"
            @click="acceptInvite"
          >
            {{ $t('joinOrganization') }}
          </v-btn>

          <v-btn
            block
            class="btn-theme"
            color="error"
            bg-color="error"
            :depressed="true"
            @click="declineInvite"
          >
            {{ $t('decline') }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('user');
import makeOrgService from '@/services/api/org';
import { showSuccessToast, showErrorToast } from '@/utils/toast';

let orgService;

export default {
  data() {
    return {
      orgName: '',
      valid: false,
      sender: '',
      isAccepting: false,
    };
  },
  computed: {
    ...mapGetters(['currentAccount']),
    currentUserEmail() {
      return this.$store.state.user.user?.email;
    },
  },
  created() {
    const handle = this.$route.params.handle;
    const token = this.$route.params.token;
    orgService = makeOrgService(this.$api);
    orgService
      .validateInvite({ handle, token })
      .then((response) => {
        if (this.currentUserEmail && response.data.email !== this.currentUserEmail) {
          this.$store.commit('error404/SET_SHOW_404', true);
          return this.$router.replace('/404');
        }
        
        this.valid = true;
        this.orgName = response.data.name;
        this.sender = response.data.sender.firstName + ' ' + response.data.sender.lastName;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          return this.$router.push({ name: 'Login' });
        }
        showErrorToast(this.$swal, 'genericError', { message: error.response?.data?.message ?? error.message });
        this.$router.replace('/');
      });
  },

  methods: {
    ...mapActions(['setCurrentAccount']),
    acceptInvite() {
      this.isAccepting = true;
      orgService
        .acceptInvite({
          handle: this.$route.params.handle,
          token: this.$route.params.token,
        })
        .then((response) => {
          if (response.status === 200) {
            showSuccessToast(this.$swal, 'invitationUpdate', { action: 'Accepted' });

            let selectedHandle = {
              handle: this.$route.params.handle,
              name: this.orgName,
              type: 'org',
              roleName: 'member',
              avatar_url: null,
            };

            this.setCurrentAccount(selectedHandle);

            this.$router.replace(`/${this.$route.params.handle}/projects`);
          }
        })
        .catch((error) => {

          showErrorToast(this.$swal, 'genericError', { message: error.response?.data?.message ?? error.message });
        })
        .finally(() => {
          this.isAccepting = false;
        });
    },
    declineInvite() {
      orgService
        .declineInvite({
          handle: this.$route.params.handle,
          token: this.$route.params.token,
        })
        .then((response) => {
          if (response.status === 200) {
            showSuccessToast(this.$swal, 'invitationUpdate', { action: 'Declined' });
            const userHandle = this.currentAccount ? this.currentAccount.handle : '';
            this.$router.replace(userHandle ? `/${userHandle}/projects` : '/');
          }
        })
        .catch((error) => {
          showErrorToast(this.$swal, 'genericError', { message: error.response?.data?.message ?? error.message });
        });
    },
  },
};
</script>

<style scoped>
.invite_title {
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: center;
}
.invite_subtitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  max-width: 438px;
  margin-top: 16px;
}
.invite_org {
  max-width: 438px;
  margin: 36px auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
