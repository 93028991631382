<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table table-fixed mt-6 data-table-style"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
  >
    <template v-slot:[`item.name`]="{ item }">
      <v-tooltip
        bottom
        left
        max-width="485px"
        :disabled="item.name.length < 61"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <div 
            class="custom-attribute text-truncate font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            {{ item.name }}
          </div>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.type`]="{ item }">
      <span>{{ getTypeLabel(item.type) }}</span>
    </template>

    <template v-slot:[`item.createdAt`]="{ item }">
      <span>{{ formatCreatedAt(item.createdAt) }}</span>
    </template>

    <template v-slot:[`item.uid`]="{ item }">
      <div class="d-flex justify-end">
        <v-btn
          v-if="writeCustomField"
          icon
          color="primary"
          :class="{ 'disabled-action': isProjectArchived }"
          @click="!isProjectArchived && $emit('edit', item)"
        >
          <EditIcon />
        </v-btn>

        <v-btn
          v-if="deleteCustomField"
          icon
          color="primary"
          :class="{ 'disabled-action': isProjectArchived }"
          @click="!isProjectArchived && $emit('delete', item)"
        >
          <DeleteIcon />
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { formatDate } from '@/utils/util';

import EditIcon from '@/assets/svg/edit.svg';
import DeleteIcon from '@/assets/svg/delete.svg';
import { dataTypes } from '@/constants/customField.js';
import { dateMixin } from '@/mixins/date.js';
import projectStatus from '@/mixins/projectStatus';

export default {
  components: {
    EditIcon,
    DeleteIcon,
  },
  mixins: [dateMixin, projectStatus],
  props: {
    headers: Array,
    itemKey: String,
    items: Array,
    writeCustomField: {
      type: Boolean,
      default: false
    },
    deleteCustomField: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getTypeLabel(type) {
      return dataTypes.find(item => item.type === type)?.name || ''
    },

    formatCreatedAt(createdAt) {
      return this.formatDate(createdAt)
    }
  }
}
</script>
