<template>
  <div>
    <v-dialog
      v-model="createDefectDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <!-- Show loader when loading -->
      <template v-if="isLoading">
        <v-card>
          <v-overlay
            :value="true"
            absolute
          >
            <v-progress-circular
              indeterminate
              size="64"
            />
          </v-overlay>
        </v-card>
      </template>

      <!-- Show empty state when no integrations -->
      <template v-else-if="totalIntegrations === 0">
        <v-card class="d-flex flex-column fill-height">
          <v-card-text class="d-flex flex-column align-center justify-center flex-grow-1">
            <h3 class="mb-4">
              {{ $t('defect.createNewDefectDialog.no_integrations') }}
            </h3>
            <p class="mb-6 text-grey">
              {{ $t('defect.createNewDefectDialog.add_integration_message') }}
            </p>
          </v-card-text>
        </v-card>
      </template>

      <!-- Show normal form when integrations exist -->
      <template v-else>
        <v-card class="d-flex flex-column fill-height">
          <v-card-text class="flex-grow-1 pa-0">
            <!-- Header -->
            <div class="px-4 pt-6 pb-4">
              <div class="d-flex align-center justify-space-between">
                <h2 class="black--text">
                  {{ actionSelected === 'Create defects' 
                    ? $t('defect.createNewDefectDialog.title') 
                    : $t('defect.createNewDefectDialog.link_title') 
                  }}
                </h2>
                <v-btn
                  icon
                  @click="closeDrawer"
                >
                  <v-icon color="black">
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
            </div>

            <!-- Scrollable Form Content -->
            <div class="form-wrapper">
              <v-form class="px-4">
                <div v-if="showBasicFields">
                  <!-- DEFECT NAME (only for creation) -->
                  <div
                    v-if="actionSelected === 'Create defects'"
                    class="d-flex flex-column mb-6"
                  >
                    <div class="text-left">
                      <v-label class="fs-14px text-theme-label font-weight-medium">
                        {{ $t('defect.createNewDefectDialog.name_label') }}
                      </v-label>
                    </div>
                    <v-text-field
                      v-model="defectName"
                      type="text"
                      dense
                      background-color="#F9F9FB"
                      :placeholder="$t('defect.createNewDefectDialog.enter_name')"
                      class="rounded-lg field-theme custom-prepend mh-38px"
                      hide-details
                    />
                  </div>

                  <!-- DEFECT DESCRIPTION (only for creation) -->
                  <div
                    v-if="actionSelected === 'Create defects'"
                    class="d-flex flex-column mb-6"
                  >
                    <div class="text-left">
                      <v-label class="fs-14px text-theme-label font-weight-medium">
                        {{ $t('defect.createNewDefectDialog.description_label') }}
                      </v-label>
                    </div>
                    <v-textarea
                      v-model="defectDescription"
                      auto-grow
                      rows="3"
                      background-color="#F9F9FB"
                      :placeholder="$t('defect.createNewDefectDialog.enter_description')"
                      class="rounded-lg field-theme custom-prepend"
                      hide-details
                    />
                  </div>

                  <!-- INTEGRATION SELECT -->
                  <div class="d-flex flex-column mb-6">
                    <div class="text-left">
                      <v-label class="fs-14px text-theme-label font-weight-medium">
                        {{ $t('defect.createNewDefectDialog.integration_label') }}
                      </v-label>
                    </div>
                    <v-select
                      v-model="selectedIntegration.integrationUid"
                      type="text"
                      dense
                      background-color="#F9F9FB"
                      :placeholder="$t('defect.createNewDefectDialog.select_integration')"
                      class="rounded-lg field-theme custom-prepend mh-38px"
                      hide-details
                      append-icon="mdi-chevron-down"
                      :items="integrationsForSelect"
                      item-text="title"
                      item-value="value"
                      :menu-props="{ offsetY: true }"
                      @change="onIntegrationSelected"
                    />
                  </div>


                
                  <!-- JIRA ORG SELECT -->
                  <div
                    v-if="showJiraOrgs && actionSelected === 'Create defects'"
                    class="d-flex flex-column mb-6"
                  >
                    <div class="text-left">
                      <v-label class="fs-14px text-theme-label font-weight-medium">
                        {{ $t('defect.createNewDefectDialog.jira_org_label') }}
                      </v-label>
                    </div>
                    <v-select
                      v-model="selectedIntegration.resourceId"
                      :items="jiraOrganizations"
                      type="text"
                      dense
                      background-color="#F9F9FB"
                      :placeholder="$t('defect.createNewDefectDialog.select_jira_org')"
                      class="rounded-lg field-theme custom-prepend mh-38px"
                      hide-details
                      append-icon="mdi-chevron-down"
                      item-text="resourceName"
                      item-value="resourceId"
                      :menu-props="{ offsetY: true }"
                      :loading="isLoadingResources"
                      :disabled="isLoadingResources"
                      @change="onJiraOrgSelected"
                    />
                  </div>

                  <!-- PROJECT/REPO SELECT -->
                  <div
                    v-if="actionSelected === 'Create defects'"
                    class="d-flex flex-column mb-6"
                  >
                    <div class="text-left">
                      <v-label class="fs-14px text-theme-label font-weight-medium">
                        {{ $t('defect.createNewDefectDialog.item_label', { item: currentServiceEntity }) }}
                      </v-label>
                    </div>
                    <v-select
                      v-model="selectedIntegration.projectId"
                      :items="serviceProjects"
                      type="text"
                      dense
                      background-color="#F9F9FB"
                      :placeholder="$t('defect.createNewDefectDialog.item_label', { item: currentServiceEntity })"
                      class="rounded-lg field-theme custom-prepend mh-38px"
                      hide-details
                      append-icon="mdi-chevron-down"
                      item-text="title"
                      item-value="value"
                      :menu-props="{ offsetY: true }"
                      :loading="isLoadingMetadata"
                      :disabled="isLoadingMetadata"
                      @change="onProjectSelect"
                    />
                  </div>
                  
                  <!-- DEFECT SELECTION (only for linking) -->
                  <div
                    v-if="actionSelected === 'Select defects'"
                    class="d-flex flex-column mb-6"
                  >
                    <div class="text-left">
                      <v-label class="fs-14px text-theme-label font-weight-medium">
                        {{ $t('defect.createNewDefectDialog.library_label', { service: selectedIntegration.service ? selectedIntegration.service : 'Service' }) }}
                      </v-label>
                    </div>

                    <v-select
                      v-model="selectedDefect"
                      :items="filteredDefects"
                      :loading="isLoadingDefects"
                      :disabled="isLoadingDefects"
                      item-text="name"
                      item-value="id"
                      dense
                      background-color="#F9F9FB"
                      :placeholder="$t('defect.createNewDefectDialog.select_defect')"
                      class="rounded-lg field-theme custom-prepend mh-38px"
                      hide-details
                      append-icon="mdi-chevron-down"
                      :menu-props="{ offsetY: true }"
                    >
                      <template v-slot:prepend-item>
                        <v-text-field
                          v-model="searchQuery"
                          dense
                          background-color="#F9F9FB"
                          :placeholder="$t('defect.createNewDefectDialog.search_placeholder')"
                          class="mx-2 mt-2"
                          hide-details
                          clearable
                          @input="filterDefects"
                        >
                          <template v-slot:prepend-inner>
                            <v-icon size="18">
                              mdi-magnify
                            </v-icon>
                          </template>
                        </v-text-field>
                      </template>
                    </v-select>
                  </div>
                  
                  <!-- TYPE -->
                  <div
                    v-if="showJiraOrgs && actionSelected === 'Create defects'"
                    class="d-flex flex-column mb-6"
                  >
                    <div class="text-left">
                      <v-label class="fs-14px text-theme-label font-weight-medium">
                        {{ $t('defect.createNewDefectDialog.type_label') }}
                      </v-label>
                    </div>
                    <v-select
                      v-model="selectedIntegration.typeId"
                      type="text"
                      dense
                      background-color="#F9F9FB"
                      :placeholder="$t('defect.createNewDefectDialog.select_type')"
                      class="rounded-lg field-theme custom-prepend mh-38px"
                      hide-details
                      append-icon="mdi-chevron-down"
                      :items="issueTypes"
                      item-text="title"
                      item-value="value"
                      :menu-props="{ offsetY: true }"
                      :loading="isLoadingTypes"
                      :disabled="isLoadingTypes"
                    />
                  </div>

                  <!-- GITHUB LABELS -->
                  <div
                    v-if="selectedIntegration.service === 'github' && actionSelected === 'Create defects'"
                    class="d-flex flex-column mb-6"
                  >
                    <div class="text-left">
                      <v-label class="fs-14px text-theme-label font-weight-medium">
                        {{ $t('defect.createNewDefectDialog.tags_label') }}
                      </v-label>
                    </div>
                    <v-select
                      v-model="selectedIntegration.tags"
                      :items="githubTags"
                      type="text"
                      dense
                      background-color="#F9F9FB"
                      :placeholder="$t('defect.createNewDefectDialog.select_tags')"
                      class="rounded-lg field-theme custom-prepend mh-38px"
                      hide-details
                      append-icon="mdi-chevron-down"
                      item-text="name"
                      item-value="name"
                      :menu-props="{ 
                        offsetY: true,
                        closeOnClick: false,
                        closeOnContentClick: false
                      }"
                      multiple
                      chips
                      deletable-chips
                      :loading="isLoadingTags"
                      :disabled="isLoadingTags"
                    >
                      <!-- Add prepend-item slot for search and select all -->
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @click="toggleSelectAll"
                        >
                          <v-list-item-action>
                            <v-checkbox
                              :input-value="isAllSelected"
                              :indeterminate="isIndeterminate"
                              class="field-theme"
                              :ripple="false"
                              off-icon="icon-checkbox-off"
                              on-icon="icon-checkbox-on"
                              indeterminate-icon="icon-indeterminate"
                              :hide-details="true"
                              dense
                            />
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span class="fs-14px text-theme-label">{{ $t('selectAll') }}</span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                      </template>

                      <!-- Item slot for individual items -->
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="toggleTag(item)"
                        >
                          <v-list-item-action>
                            <v-checkbox
                              :input-value="isItemSelected(item)"
                              class="field-theme"
                              :ripple="false"
                              off-icon="icon-checkbox-off"
                              on-icon="icon-checkbox-on"
                              :hide-details="true"
                              dense
                            />
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span class="fs-14px text-theme-label">{{ item.name }}</span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <!-- Selection slot for chips display -->
                      <template v-slot:selection="{ item, index }">
                        <v-chip
                          v-if="index < 3"
                          small
                          class="custom-chip-theme ma-1"
                          close
                          @click:close="removeTag(item.name)"
                        >
                          <span class="text-theme-label">{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 3"
                          class="text-caption grey--text text-truncate"
                        >
                          (+{{ selectedIntegration.tags.length - 3 }} {{ $t('more') }})
                        </span>
                      </template>
                    </v-select>
                  </div>

                  <div 
                    v-if="actionSelected === 'Create defects'"
                    class="defect-attachment-section"
                  >
                    <div class="text-left mb-4">
                      <span class="section-label">
                        {{ $t('defect.createNewDefectDialog.attachments_label') }}
                      </span>
                    </div>

                    <div class="upload-container-wrapper">
                      <div 
                        class="upload-container"
                        @click="openFileDialog"
                        @drop.prevent="handleDrop"
                        @dragover.prevent
                        @dragenter.prevent
                      >
                        <input
                          ref="fileInput"
                          type="file"
                          multiple
                          style="display: none"
                          @change="handleFileChange"
                        >
                        
                        <p class="upload-text">
                          {{ $t('defect.createNewDefectDialog.upload_instructions') }}
                        </p>

                        <div class="browse-files-btn">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            class="arrow-icon"
                          >
                            <path
                              d="M3.33337 8H12.6667"
                              stroke="#0C2FF3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.66663 4L12.6666 8L8.66663 12"
                              stroke="#0C2FF3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {{ $t('defect.createNewDefectDialog.browse_files') }}
                        </div>
                      </div>
                    </div>

                    <!-- File List -->
                    <template v-if="attachments.length > 0">
                      <div class="file-list mt-4">
                        <div
                          v-for="(attachment, index) in attachments"
                          :key="index"
                          class="file-item d-flex align-center justify-space-between py-2 px-3"
                        >
                          <div class="d-flex align-center">
                            <v-icon
                              size="20"
                              class="mr-2"
                              color="grey darken-1"
                            >
                              mdi-file-outline
                            </v-icon>
                            <span class="file-name">{{ attachment.fileName }}</span>
                          </div>
                          <v-btn
                            icon
                            x-small
                            @click="removeAttachment(index)"
                          >
                            <v-icon size="16">
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div v-if="showDynamicFields">
                  <!-- Dynamic Fields Section -->
                  <div
                    v-if="fieldData.length > 0"
                    class="d-flex flex-column mb-6"
                  >
                    <div
                      v-for="field in fieldData"
                      :key="field.key"
                      class="d-flex flex-column mb-6"
                    >
                      <!-- Field Label -->
                      <div class="text-left">
                        <v-label class="fs-14px text-theme-label font-weight-medium">
                          {{ field.name }}
                          <span
                            v-if="field.required"
                            class="red--text"
                          >*</span>
                        </v-label>
                      </div>

                      <!-- String Fields -->
                      <v-text-field
                        v-if="field.schema.type === 'string'"
                        v-model="blankIssue.fields[field.key]"
                        dense
                        background-color="#F9F9FB"
                        class="rounded-lg field-theme custom-prepend mh-38px"
                        hide-details
                        :rules="field.required ? [v => !!v || $t('defect.createNewDefectDialog.field_required')] : []"
                      />

                      <!-- Free Text Array Fields (like labels) -->
                      <v-combobox
                        v-else-if="field.schema.type === 'array' && (!field.allowedValues || field.schema.items === 'string')"
                        v-model="blankIssue.fields[field.key]"
                        multiple
                        small-chips
                        deletable-chips
                        dense
                        background-color="#F9F9FB"
                        class="rounded-lg field-theme custom-prepend mh-38px"
                        hide-details
                        :rules="field.required ? [v => v.length > 0 || $t('defect.createNewDefectDialog.field_required')] : []"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip
                            small
                            close
                            @click:close="removeItem(field.key, index)"
                          >
                            {{ item }}
                          </v-chip>
                        </template>
                      </v-combobox>

                      <!-- Priority Fields -->
                      <v-select
                        v-else-if="field.schema.type === 'priority'"
                        v-model="blankIssue.fields[field.key]"
                        :items="field.allowedValues"
                        item-value="id"
                        item-text="name"
                        dense
                        background-color="#F9F9FB"
                        class="rounded-lg field-theme custom-prepend mh-38px"
                        hide-details
                        :rules="field.required ? [v => !!v || $t('defect.createNewDefectDialog.field_required')] : []"
                      >
                        <template v-slot:selection="{ item }">
                          <span :style="{ color: item.statusColor }">{{ item.name }}</span>
                        </template>
                        <template v-slot:item="{ item }">
                          <span :style="{ color: item.statusColor }">{{ item.name }}</span>
                        </template>
                      </v-select>

                      <!-- Array Fields with Allowed Values -->
                      <v-select
                        v-else-if="field.schema.type === 'array' && field.allowedValues"
                        v-model="blankIssue.fields[field.key]"
                        :items="field.allowedValues"
                        item-text="value"
                        item-value="value"
                        multiple
                        dense
                        background-color="#F9F9FB"
                        class="rounded-lg field-theme custom-prepend mh-38px"
                        hide-details
                        :rules="field.required ? [v => !!v || $t('defect.createNewDefectDialog.field_required')] : []"
                        :menu-props="{ 
                          offsetY: true,
                          closeOnClick: false,
                          closeOnContentClick: false
                        }"
                        chips
                        deletable-chips
                      >
                        <!-- Item slot for individual items -->
                        <template v-slot:item="{ item }">
                          <v-list-item @click.stop="toggleFieldItem(field.key, item.value)">
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="(blankIssue.fields[field.key] || []).includes(item.value)"
                                class="field-theme"
                                :ripple="false"
                                off-icon="icon-checkbox-off"
                                on-icon="icon-checkbox-on"
                                :hide-details="true"
                                dense
                              />
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                <span class="fs-14px text-theme-label">{{ item.value }}</span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>

                        <!-- Selection slot for chips display -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip
                            v-if="index < 3"
                            small
                            class="custom-chip-theme ma-1"
                            close
                            @click:close="removeFieldItem(field.key, item.value)"
                          >
                            <span class="text-theme-label">{{ item.value }}</span>
                          </v-chip>
                          <span
                            v-if="index === 3"
                            class="text-caption grey--text text-truncate"
                          >
                            (+{{ blankIssue.fields[field.key].length - 3 }} {{ $t('more') }})
                          </span>
                        </template>
                      </v-select>

                      <!-- Option Fields -->
                      <v-select
                        v-else-if="field.schema.type === 'option'"
                        v-model="blankIssue.fields[field.key]"
                        :items="field.allowedValues"
                        item-value="value"
                        item-text="value"
                        dense
                        background-color="#F9F9FB"
                        class="rounded-lg field-theme custom-prepend mh-38px"
                        hide-details
                        :rules="field.required ? [v => !!v || $t('defect.createNewDefectDialog.field_required')] : []"
                      />

                      <!-- Default Text Field -->
                      <v-text-field
                        v-else
                        v-model="blankIssue.fields[field.key]"
                        dense
                        background-color="#F9F9FB"
                        class="rounded-lg field-theme custom-prepend mh-38px"
                        hide-details
                        :rules="field.required ? [v => !!v || $t('defect.createNewDefectDialog.field_required')] : []"
                      />
                    </div>
                  </div>
                </div>
              </v-form>
            </div>
          </v-card-text>

          <!-- Action Buttons -->
          <div class="actions-container">
            <v-btn
              width="204.5px"
              color="#F2F4F7"
              full-width
              height="40"
              :depressed="true"
              class="text-capitalize btn-theme"
              elevation="0"
              @click="handleCancel"
            >
              {{ currentStep === 2 ? $t('back') : $t('cancel') }}
            </v-btn>
            <v-btn
              width="204.5px"
              class="btn-theme text-capitalize"
              height="40"
              color="primary"
              :depressed="true"
              full-width
              elevation="0"
              :disabled="saveLoading"
              @click="handleAction"
            >
              <v-progress-circular
                v-if="saveLoading"
                indeterminate
                size="20"
                color="white"
                class="mr-2"
              />
              <span>{{ actionButtonText }}</span>
            </v-btn>
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import makeDefectService from '@/services/api/defect';
import makeTagService from '@/services/api/tag';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import makeIntegrationsService from '@/services/api/integrations';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CreateDefect',
  props: {
    createDefectDialog: {
      type: Boolean,
      required: true,
    },
    actionSelected: {
      type: String,
      required: true,
    },
    execution: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      imageSrc: '',
      integrations: [],
      integrationsForSelect: [],
      currentIntegration: null,
      jiraOrganizations: [],
      serviceProjects: [],
      testfiestaProjectUid: null,
      selectedIntegration: {
        integrationUid: null,
        resourceId: null,
        projectId: null,
        projectName: null,
        typeId: null,
        service: null,
        tags: [],
      },
      serviceEntities: {
        jira: 'projects',
        github: 'repositories',
      },
      issueTypes: [],
      isLoadingIssueTypes: false,
      attachments: [],
      acceptedFileTypes: '*/*',
      searchQuery: '',
      totalIntegrations: 0,
      defectName: '',
      selectedDefect: null,
      existingDefects: [],
      defectDescription: '',
      isLoadingResources: false,
      isLoadingTypes: false,
      saveLoading: false,
      jiraMetadata: null,
      isLoadingMetadata: false,
      allDefects: [],
      githubTags: [],
      isLoadingTags: false,
      fieldData: [],
      blankIssue: {
        fields: {},
      },
      dateMenus: {}, // For tracking date picker menus
      fieldRules: {
        required: v => !!v || this.$t('defect.createNewDefectDialog.field_required')
      },
      currentStep: 1,
      totalSteps: 1,
      isLoadingDefects: false,
      menuOpen: false,
      searchTimeout: null,
    };
  },
  computed: {
    imageStyle() {
      return this.imageSrc
        ? {
            backgroundImage: `url(${this.imageSrc})`,
            border: 'none',
          }
        : {};
    },
    // Decide if we show Jira org field
    showJiraOrgs() {
      return this.selectedIntegration.service === 'jira';
    },
    // For the label, e.g. "projects" or "repositories"
    currentServiceEntity() {
      return (
        this.serviceEntities[this.selectedIntegration.service] || 'projects'
      );
    },
    currentProjectMetadata() {
      if (!this.jiraMetadata || !this.selectedIntegration.projectId) {
        return null;
      }
      return this.jiraMetadata[this.selectedIntegration.projectId] || null;
    },
    isJiraDefectCreation() {
      return this.selectedIntegration.service === 'jira' && 
             this.actionSelected === 'Create defects';
    },
    showBasicFields() {
      return this.currentStep === 1;
    },
    showDynamicFields() {
      return this.currentStep === 2 && this.isJiraDefectCreation;
    },
    actionButtonText() {
      if (this.currentStep === 1 && this.isJiraDefectCreation) {
        return this.$t('next');
      }
      if(this.actionSelected === 'Create defects') {
        return this.$t('defect.createNewDefectDialog.title');
      }
      return this.$t('defect.createNewDefectDialog.add_defect');
    },
    filteredDefects() {
      if (!this.searchQuery) {
        return this.existingDefects;
      }
      return this.existingDefects.filter(defect => 
        defect.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    isAllSelected() {
      return this.githubTags.length > 0 && 
             this.selectedIntegration.tags.length === this.githubTags.length;
    },
    isIndeterminate() {
      return this.selectedIntegration.tags.length > 0 && 
             this.selectedIntegration.tags.length < this.githubTags.length;
    }
  },
  watch: {
    'selectedIntegration.projectId': {
      async handler(newProjectId) {
        if (!newProjectId) {
          this.issueTypes = [];
          this.selectedIntegration.typeId = null;
          return;
        }

        // Only fetch issue types for Jira integrations with metadata
        if (
          this.selectedIntegration.service === 'jira' && 
          this.selectedIntegration.resourceId &&
          this.jiraMetadata &&
          this.jiraMetadata[newProjectId]
        ) {
          await this.fetchIssueTypes(
            this.selectedIntegration.resourceId,
            newProjectId
          );
        }
      }
    },
    actionSelected: {
      immediate: true,
      handler(newVal) {
        if (newVal === 'Select defects') {
          this.fetchExistingDefects();
        }
      }
    },
    'selectedIntegration.integrationUid': {
      handler(newIntegrationUid) {
        if (this.actionSelected === 'Select defects') {
          
          if (!newIntegrationUid) {
            this.existingDefects = [...this.existingDefects];
          } else {
            this.existingDefects = this.existingDefects.filter(
              defect => Number(defect.integrationSourceUid) === Number(newIntegrationUid)
            );
          }
        }
      }
    },
    'selectedIntegration.typeId': {
      async handler(newTypeId) {
        if (newTypeId) {
          await this.getIssueTypeDataFromMetadata();
        } else {
          // Reset field data when type is cleared
          this.fieldData = [];
          this.blankIssue.fields = {};
        }
      }
    }
  },
  created() {
    this.fetchIntegrationData();
    this.fetchDefectTags();
  },
  methods: {
    ...mapActions({
      uploadToServer: 'attachment/uploadToServer'
    }),
    closeDrawer() {
      // Reset all selections
      this.selectedIntegration = {
        integrationUid: null,
        resourceId: null,
        projectId: null,
        projectName: null,
        typeId: null,
        service: null,
        tags: [],
      };
      this.currentIntegration = null;
      this.jiraOrganizations = [];
      this.serviceProjects = [];
      this.issueTypes = [];
      this.defectDescription = '';
      this.$emit('closeDialog');
    },

    async fetchIntegrationData() {
      this.isLoading = true;
      try {
        const integrationsService = makeIntegrationsService(this.$api);
        const handle = this.$route.params.handle;
        const params = `?service=jira,github&projectKey=${this.$route.params.key}`;
        const response = await integrationsService.getIntegrations(handle, params);
        
        if (!response?.data) {
          this.totalIntegrations = 0;
          return;
        }

        this.totalIntegrations = response.data.pagination?.total || 0;

        if (this.totalIntegrations === 0) {
          return;
        }

        // Identify the testfiesta project UID from route
        const currentProjectKey = this.$route.params.key;
        const foundEntry = Object.entries(response.data.projects).find(
          ([, project]) => project.key === currentProjectKey
        );
        
        if (!foundEntry) {
          showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.project_not_found'));
          return;
        }

        this.testfiestaProjectUid = foundEntry[0];

        // Filter only integrations with at least 1 projectConfiguration
        this.integrations = response.data.integrations.filter(
          (i) => i.configuration?.projectConfigurations?.length
        );

        // Prepare for the integration dropdown
        this.integrationsForSelect = this.integrations.map((i) => ({
          title: i.name,
          value: i.uid,
          service: i.service,
        }));
      } catch (err) {
        console.error('Integration Error:', err);
        showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.failed_to_fecth_integrations'));
      } finally {
        this.isLoading = false;
      }
    },

    async onIntegrationSelected() {
      this.resetSelections(); // clear org, repo, projects

      const chosenUID = this.selectedIntegration.integrationUid;
      if (!chosenUID) {
        this.currentIntegration = null;
        this.selectedIntegration.service = null;
        return;
      }

      // Find the full integration object
      const found = this.integrations.find((i) => i.uid === chosenUID);
      if (!found) {
        this.currentIntegration = null;
        this.selectedIntegration.service = null;
        return;
      }

      // Store it
      this.currentIntegration = found;
      this.selectedIntegration.service = found.service.toLowerCase();

      // If it's Jira, fetch its organizations from the API
      if (this.selectedIntegration.service === 'jira') {
        await this.loadJiraOrganizations(found);
        // We wait for user to pick an org before showing projects
      } else {
        // If it's GitHub or something else, we can load projects directly
        this.loadNonJiraProjects(found);
      }

      // Reset step when integration changes
      this.currentStep = 1;
      this.totalSteps = this.isJiraDefectCreation ? 2 : 1;
    },

    // Clear out the org / project data
    resetSelections() {
      this.jiraOrganizations = [];
      this.serviceProjects = [];
      this.selectedIntegration.resourceId = null;
      this.selectedIntegration.projectId = null;
      
      if (this.selectedIntegration.service !== 'jira') {
        this.selectedIntegration.typeId = null;
        this.selectedIntegration.priorityId = null;
      }
    },

    async loadJiraOrganizations(integrationObj) {
      this.isLoadingResources = true;
      try {
        const integrationsService = makeIntegrationsService(this.$api);
        const handle = this.$route.params.handle;
        const resp = await integrationsService.getOrganizations(
          handle,
          integrationObj.uid
        );
        const usedResourceIds = new Set(
          integrationObj.configuration.projectConfigurations
            .map((cfg) => cfg.resourceId)
            .filter(Boolean)
        );
        const relevantOrgs =
          (resp?.data || []).filter((org) => usedResourceIds.has(org.resourceId));

        this.jiraOrganizations = relevantOrgs;
      } catch (err) {
        console.error('Failed to fetch Jira orgs for integration', integrationObj.uid, err);
        this.jiraOrganizations = [];
      } finally {
        this.isLoadingResources = false;
      }
    },

    // For GitHub (or any non-Jira), load projects from the config
    loadNonJiraProjects(integrationObj) {
      const configs = integrationObj.configuration.projectConfigurations || [];
      // Usually there's just one config for GitHub, but let's handle multiple
      const allProjects = [];

      configs.forEach((cfg) => {
        // Projects relevant to our testfiestaProjectUid
        const arr = cfg.projects?.[this.testfiestaProjectUid] || [];
        arr.forEach((p) => {
          allProjects.push({
            title: p.projectName,
            value: p.projectId,
          });
        });
      });

      this.serviceProjects = allProjects;
    },

    async onJiraOrgSelected(resourceId) {
      this.selectedIntegration.projectId = null;
      this.serviceProjects = [];
      this.issueTypes = [];
      this.jiraMetadata = null;

      if (!this.currentIntegration || !resourceId) return;

      const configs = this.currentIntegration.configuration.projectConfigurations || [];
      const finalProjects = [];

      configs.forEach((cfg) => {
        if (cfg.resourceId === resourceId) {
          const arr = cfg.projects?.[this.testfiestaProjectUid] || [];
          arr.forEach((p) => {
            finalProjects.push({
              title: p.projectName,
              value: p.projectId,
            });
          });
        }
      });

      this.serviceProjects = finalProjects;
      
      // Fetch Jira metadata for the selected organization
      try {
        this.isLoadingMetadata = true;
        const defectService = makeDefectService(this.$api);
        const handle = this.$route.params.handle;
        const projectKey = this.$route.params.key;
        
        const response = await defectService.getJiraCreateMetadata(
          handle,
          projectKey,
          this.selectedIntegration.integrationUid,
          {
            resourceId
          }
        );

        if (response?.data) {
          // Store the metadata object with project IDs as keys
          this.jiraMetadata = response.data;
        }
      } catch (err) {
        console.error('Failed to fetch Jira metadata:', err);
        showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.failed_to_fetch_metadata'));
      } finally {
        this.isLoadingMetadata = false;
      }
    },

    openFileDialog() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const files = Array.from(event.target.files);
      files.forEach(file => {
        const attachment = {
          file,
          fileName: file.name,
          extension: file.name.split('.').pop(),
          size: Math.round(file.size / 1024),
          progress: 0,
          failed: false
        };
        this.attachments.push(attachment);
      });
      this.$refs.fileInput.value = '';
    },

    handleDrop(event) {
      event.preventDefault();
      const files = Array.from(event.dataTransfer.files);
      files.forEach(file => {
        const attachment = {
          file,
          fileName: file.name,
          extension: file.name.split('.').pop(),
          size: Math.round(file.size / 1024),
          progress: 0,
          failed: false
        };
        this.attachments.push(attachment);
      });
    },

    removeAttachment(index) {
      this.attachments.splice(index, 1);
    },

    async uploadDefectAttachments(defectId) {
      if (!this.attachments.length) return;

      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const defectService = makeDefectService(this.$api);
      const mediaType = 'attachment';

      try {
        const uploadPromises = this.attachments.map(attachment => {
          const params = {
            handle,
            projectKey,
            defectId,
            relatedTo: 'defect'
          };

          return this.uploadToServer({
            handle,
            mediaType,
            file: attachment.file,
            apiService: defectService,
            params
          });
        });

        return await Promise.all(uploadPromises);
      } catch (error) {
        console.error('Failed to upload attachments:', error);
        throw error;
      }
    },

    async saveDefect() {
      // Common validation
      if (!this.selectedIntegration.integrationUid) {
        showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.integration_select_error'));
        return;
      }

      // Create defect validation
      if (this.actionSelected === 'Create defects') {
        if (!this.defectName?.trim()) {
          showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.name_error'));
          return;
        }

        if (!this.selectedIntegration.projectId) {
          showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.item_select_error', { item: this.currentServiceEntity }));
          return;
        }

        // Validate Jira-specific fields
        if (this.selectedIntegration.service === 'jira') {
          if (!this.selectedIntegration.resourceId) {
            showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.jira_org_select_error'));
            return;
          }
          if (!this.selectedIntegration.typeId) {
            showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.type_select_error'));
            return;
          }
          this.processJiraFields();
        }
      }

      // Select defect validation  
      if (this.actionSelected === 'Select defects') {
        if (!this.selectedDefect) {
          showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.defect_select_error'));
          return;
        }
      }
      
      this.saveLoading = true;
      try {
        const handle = this.$route.params.handle;
        const projectKey = this.$route.params.key;
        const defectService = makeDefectService(this.$api);
        
        // Create the defect data without files
        const defectData = {
          name: this.defectName.trim(),
          description: this.defectDescription.trim(),
          ...this.selectedIntegration,
          tags: this.selectedIntegration.service === 'github' ? this.selectedIntegration.tags : undefined,
          executionId: this.execution?.uid,
          fields: this.blankIssue.fields
        };

        let response;
        if (this.actionSelected === 'Create defects') {
          response = await defectService.createDefect(handle, projectKey, defectData);
        } else {
          response = await defectService.linkDefect(
            handle, 
            projectKey,
            this.selectedDefect,
            defectData
          );
        }

        // If we have attachments, upload them
        if (this.attachments.length > 0) {
          try {
            await this.uploadDefectAttachments(response.data.uid);
          } catch (error) {
            console.error('Failed to upload attachments:', error);
            showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.failed_to_upload_attachments'));
          }
        }

        showSuccessToast(
          this.$swal, 
          this.actionSelected === 'Create defects' 
            ? this.$t('success.defectCreated')
            : this.$t('success.defectLinked')
        );

        this.$emit('closeDialog');
      } catch (error) {
        console.error('Failed to save defect:', error);
        showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.defect_create_failed'));
      } finally {
        this.saveLoading = false;
      }
    },

    async fetchIssueTypes(resourceId, projectId) {
      if (!resourceId || !projectId) return;
      
      this.isLoadingTypes = true;
      this.issueTypes = [];
      this.selectedIntegration.typeId = null;

      try {
        // First check if we have metadata for this project
        if (this.jiraMetadata && this.jiraMetadata[projectId]) {
          const projectMetadata = this.jiraMetadata[projectId];
          const availableTypeIds = Object.keys(projectMetadata);

          // Get the full list of issue types from API
          const defectService = makeDefectService(this.$api);
          const handle = this.$route.params.handle;
          const projectKey = this.$route.params.key;

          const response = await defectService.getJiraIssueTypes(
            handle,
            projectKey,
            this.selectedIntegration.integrationUid,
            {
              resourceId,
              projectId
            }
          );
          
          if (response?.data) {
            // Filter issue types based on available type IDs from metadata
            this.issueTypes = response.data
              .filter(type => availableTypeIds.includes(type.id.toString()))
              .map(type => ({
                title: type.name,
                value: type.id
              }));

          }
        }
      } catch (err) {
        console.error('Failed to fetch issue types:', err);
        showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.failed_to_fetch_issue_types'));
      } finally {
        this.isLoadingTypes = false;
      }
    },
    goToIntegrations() {
      this.$router.push({ 
        name: 'integrations',
        query: { filter: 'all' }  // This will open the integration library view
      });
      this.closeDrawer();
    },
    async fetchExistingDefects() {
      if (this.actionSelected === 'Select defects') {
        this.isLoadingDefects = true;
        try {
          const defectService = makeDefectService(this.$api);
          const handle = this.$route.params.handle;
          const projectKey = this.$route.params.key;
          const response = await defectService.getDefects(handle, projectKey, {
            page: 1,
            limit: 99999,
          });
          
          this.existingDefects = response.data.data.map(defect => ({
            id: defect.uid,
            name: defect.name,
            integrationSourceUid: defect.integrationSourceUid
          }));
        } catch (err) {
          console.error('Failed to fetch defects:', err);
          showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.failed_to_fetch_defects'));
        } finally {
          this.isLoadingDefects = false;
        }
      }
    },
    async onProjectSelect(project) {
      if (!project) {
        this.selectedIntegration.projectId = null;
        this.selectedIntegration.projectName = null;
        this.issueTypes = [];
        this.selectedIntegration.typeId = null;
        return;
      }

      const selectedProject = this.serviceProjects.find(p => p.value === project);
      if (!selectedProject) return;

      this.selectedIntegration.projectId = selectedProject.value;
      this.selectedIntegration.projectName = selectedProject.title;

    },
    async fetchDefectTags() {
      try {
        const tagService = makeTagService(this.$api);
        const response = await tagService.getTags(
          this.$route.params.handle,
          'defects'
        );
        this.githubTags = response.data;
      } catch (err) {
        console.error('Error fetching defect tags:', err);
        showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.failed_to_fetch_tags'));
      }
    },
    async getIssueTypeDataFromMetadata() {
      try {
        if (!this.selectedIntegration.projectId || !this.selectedIntegration.typeId || !this.jiraMetadata) {
          showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.failed_to_fetch_issue_types'));
          return;
        }

        const projectData = this.jiraMetadata[this.selectedIntegration.projectId];
        if (!projectData) {
          showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.failed_to_fetch_issue_types'));
          return;
        }

        // Check if typeId exists within the project
        const issueTypeData = projectData[this.selectedIntegration.typeId];
        if (!issueTypeData) {
          showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.failed_to_fetch_issue_types'));
          return;
        }

        // Define allowed custom field names
        const allowedCustomFields = [
          'atlas goal',
          'atlas project',
          'goal',
          'roadmap',
          'teams',
          'project start',
          'project target',
          'product area',
          'linked issues',
          'category'
        ];

        // Define excluded system fields
        const excludedFields = ['issuetype', 'project', 'summary', 'description', 'assignee', 'reporter'];

        // Reset existing data
        this.fieldData = [];
        this.blankIssue.fields = {};

        // Iterate through the fields
        for (const [key, field] of Object.entries(issueTypeData)) {
          if (!field) continue;

          const isCustomField = field.key.startsWith('customfield_');
          const isAllowedCustomField = isCustomField ? 
            allowedCustomFields.includes(field.name.toLowerCase()) : true;
          
          if (
            isAllowedCustomField && 
            field.name !== "Sprint" &&
            !excludedFields.includes(field.key) &&
            field.schema.type !== "any" &&
            !["attachment", "issuelinks"].includes(field.schema.system)
          ) {
            // Transform field data if needed
            let processedField = { ...field };

            // Special handling for priority field
            if (field.key === 'priority' && field.allowedValues) {
              processedField.allowedValues = field.allowedValues.map(value => ({
                id: value.id,
                name: value.name,
                iconUrl: value.iconUrl
              }));
            }

            // Add to fieldData
            this.fieldData.push(processedField);

            // Initialize blank issue fields based on type
            if (field.key === 'labels' || field.schema.type === 'array') {
              this.blankIssue.fields[field.key] = [];
            } else if (field.key === 'priority' && field.defaultValue) {
              this.blankIssue.fields[field.key] = field.defaultValue.id;
            } else if (field.key === 'reporter' || field.key === 'assignee') {
              this.blankIssue.fields[field.key] = { id: '' };
            } else {
              this.blankIssue.fields[field.key] = '';
            }
          }
        }
      } catch (error) {
        console.error('Error fetching issue type data:', error);
        showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.failed_to_fetch_issue_types'));
      }
    },
    handleCancel() {
      if (this.currentStep === 2) {
        this.currentStep = 1;
      } else {
        this.closeDrawer();
      }
    },
    async handleAction() {
      if (this.currentStep === 1 && this.isJiraDefectCreation) {
        if (!this.validateStep1()) {
          return;
        }
        this.currentStep = 2;
      } else {
        await this.saveDefect();
      }
    },
    validateStep1() {
      if (!this.selectedIntegration.integrationUid) {
        showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.integration_select_error'));
        return false;
      }
      if (!this.selectedIntegration.projectId) {
        showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.item_select_error', { item: this.currentServiceEntity }));
        return false;
      }
      if (!this.selectedIntegration.typeId) {
        showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.type_select_error'));
        return false;
      }
      if (!this.defectName) {
        showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.name_error'));
        return false;
      }
      if (!this.defectDescription) {
        showErrorToast(this.$swal, this.$t('defect.createNewDefectDialog.description_error'));
        return false;
      }
      return true;
    },
    removeItem(fieldKey, index) {
      this.blankIssue.fields[fieldKey].splice(index, 1);
    },
    processJiraFields() {
      const processedFields = {};

      for (const [key, value] of Object.entries(this.blankIssue.fields)) {
        // Skip empty values
        if (value === null || value === undefined || value === '') continue;

        const field = this.fieldData.find(f => f.key === key);
        if (!field) continue;

        switch (field.schema.type) {
          case 'string':
          case 'text':
            // Simple text fields
            processedFields[key] = value;
            break;

          case 'array':
            if (!Array.isArray(value)) continue;
            
            if (key === 'labels' || field.schema.items === 'string') {
              // Free text arrays (like labels)
              processedFields[key] = value;
            } else if (field.allowedValues) {
              // Multi-select fields with predefined values
              processedFields[key] = value.map(v => ({ value: v }));
            }
            break;

          case 'option':
            if (field.allowedValues) {
              processedFields[key] = { value: value };
            }
            break;

          case 'priority':
            processedFields[key] = { id: value };
            break;

          default:
            processedFields[key] = value;
        }
      }

      // Update the fields with processed values
      this.blankIssue.fields = processedFields;
    },
    isItemSelected(item) {
      return this.selectedIntegration.tags.includes(item.name);
    },
    toggleSelectAll() {
      this.selectedIntegration.tags = this.isAllSelected 
        ? [] 
        : this.githubTags.map(tag => tag.name);
    },
    toggleTag(item) {
      const tagName = item.name;
      const index = this.selectedIntegration.tags.indexOf(tagName);
      if (index === -1) {
        this.selectedIntegration.tags.push(tagName);
      } else {
        this.selectedIntegration.tags.splice(index, 1);
      }
    },
    removeTag(tagName) {
      const index = this.selectedIntegration.tags.indexOf(tagName);
      if (index >= 0) {
        this.selectedIntegration.tags.splice(index, 1);
      }
    },
    toggleFieldItem(fieldKey, value) {
      if (!this.blankIssue.fields[fieldKey]) {
        this.$set(this.blankIssue.fields, fieldKey, []);
      }

      const index = this.blankIssue.fields[fieldKey].indexOf(value);
      if (index === -1) {
        this.blankIssue.fields[fieldKey].push(value);
      } else {
        this.blankIssue.fields[fieldKey].splice(index, 1);
      }
    },
    removeFieldItem(fieldKey, value) {
      if (!this.blankIssue.fields[fieldKey]) return;
      
      const index = this.blankIssue.fields[fieldKey].indexOf(value);
      if (index >= 0) {
        this.blankIssue.fields[fieldKey].splice(index, 1);
      }
    }
  },
};
</script>

<style scoped>
.dialog-theme {
  overflow: hidden !important;
}

:deep(.v-dialog--fullscreen) {
  width: 485px !important;
  right: 0 !important;
  left: auto !important;
}

:deep(.v-card) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:deep(.form-wrapper) {
  flex: 1;
  overflow-y: auto;
  background-color: white;
  padding-bottom: 80px;
}

:deep(.form-wrapper .v-form) {
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
}

:deep(.actions-container) {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 485px;
  padding: 16px 24px;
  background-color: white;
  z-index: 1;
  border-top: 1px solid #f2f4f7;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  box-sizing: border-box;
}

:deep(.actions-container .v-btn) {
  flex: 1;
  min-width: 0 !important; /* Prevent button from expanding */
}

:deep(.v-text-field.custom-prepend .v-input__prepend-inner) {
  margin-top: 10px !important;
  margin-right: 8px !important;
}

/* Add to existing styles */
.attachment-list {
  border: 1px solid #EAECF0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
}

.attachment-item {
  border-bottom: 1px solid #EAECF0;
  background-color: #F9F9FB;
}

.attachment-item:last-child {
  border-bottom: none;
}

.upload-zone {
  background-color: #F9F9FB;
  border: 1px dashed #E4E7EC;
  border-radius: 8px;
  padding: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.upload-zone:hover {
  border-color: #B2B7C2;
  background-color: #F5F5F7;
}

.upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-text {
  color: #667085;
  font-size: 14px;
  margin: 0;
}

.selected-files {
  border: 1px solid #E4E7EC;
  border-radius: 8px;
  overflow: hidden;
}

.file-item {
  background-color: #F9FAFB;
  border-bottom: 1px solid #E4E7EC;
}

.file-item:last-child {
  border-bottom: none;
}

.file-name {
  font-size: 14px;
  color: #344054;
}

.upload__attachment {
  background-color: #FFF;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #EAECF0;
  width: 100%;
  gap: 12px;
  margin-bottom: 8px;
}

.attachment_file-type {
  position: relative;
  display: flex;
  align-items: center;
}

.attachment_file-type .v-icon {
  z-index: 5;
  font-size: 55px !important;
}

.attachment_file-type span {
  position: absolute;
  font-size: 10px;
  padding: 1px 5px;
  border-radius: 2px;
  color: #FFF;
  z-index: 6;
}

.attachment_file-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
}

.attachment_file-details p {
  font-size: 14px;
  font-family: 'Inter',sans-serif;
  color: #344054;
}

.attachment_file-details #fileName {
  white-space: nowrap;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachment_file-details .attachment_progress {
  gap: 5px;
}

.toggle-btn {
  box-shadow: none !important;
}

.defect-attachment-section {
  margin-bottom: 32px;
}

.section-label {
  font-size: 14px;
  font-weight: 500;
  color: #344054;
  display: block;
}

.upload-container-wrapper {
  background-color: #F9FAFB;
  border-radius: 8px;
  padding: 10px 8px;
  width: 100%;
  max-width: 421px;
}

.upload-container {
  border: 1px dashed #D0D5DD;
  border-radius: 8px;
  padding: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background-color: #F9FAFB;
  min-height: 120px;
}

.upload-container:hover {
  border-color: #B2B7C2;
  background-color: #F5F5F7;
}

.upload-text {
  color: #667085;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 0;
  max-width: 300px;
}

.browse-files-btn {
  color: #0C2FF3;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

.arrow-icon {
  transform: rotate(-45deg);
  margin-right: 4px;
}

.file-list {
  border: 1px solid #EAECF0;
  border-radius: 8px;
  overflow: hidden;
}

.file-item {
  background-color: #F9FAFB;
  border-bottom: 1px solid #EAECF0;
}

.file-item:last-child {
  border-bottom: none;
}

.file-name {
  font-size: 14px;
  color: #344054;
}

:deep(.custom-chip-theme) {
  background-color: #F2F4F7 !important;
  color: #344054 !important;
  height: 24px !important;
}

:deep(.custom-chip-theme .v-chip__close) {
  opacity: 1;
  font-size: 16px;
}

:deep(.v-select.v-text-field input) {
  max-height: none;
  height: auto;
}

:deep(.v-select__selections) {
  padding: 0 8px;
  flex-wrap: wrap;
}
</style>
