<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="table-fixed data-table-style mt-6 font-inter"
    :headers="filteredHeaders"
    :items="filteredItems"
    :item-key="itemKey"
    :item-class="rowClass"
    :value="selectedItems"
    @click:row="viewMilestone"
    @input="onRowClick"
  >
    <template v-slot:[`header.actions`]="{ header }">
      <div class="d-none">
        {{ header.text }}  
      </div>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <v-tooltip
        bottom
        left
        max-width="485px"
        :disabled="item.name.length < 61"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <div 
            class="custom-attribute text-truncate font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            {{ item.name }}
          </div>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <span :class="getColor(item.customFields.status)">{{ item.customFields.status }}</span>
    </template>
    <template v-slot:[`item.testplans`]="{ item }">
      <span :class="getColor(item.customFields.testRuns)">{{ item.testPlansCount || 0 }} test plans</span>
    </template>
    <template v-slot:[`item.testruns`]="{ item }">
      <span :class="getColor(item.customFields.testRuns)">{{ item.testRunsCount || 0 }} test runs</span>
    </template>
    <template v-slot:[`item.testcases`]="{ item }">
      <span class="">{{ item.testCasesCount || 0 }} test cases</span>
    </template>
    <template v-slot:[`item.startdate`]="{ item }">
      <span class="">{{ formattedDate(item.customFields.startDate) }}</span>
    </template>
    <template v-slot:[`item.due_at`]="{ item }">
      <span class="">{{ item.dueAt ? formatDueDate(item.dueAt) : '' }} </span>
    </template>
    <template v-slot:[`item.tags`]="{item}">
      <v-tooltip
        bottom
        left
        max-width="485px"
        :disabled="!Array.isArray(item.customFields?.tags) || item.customFields.tags.length < 3"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            class="custom-attribute font-weight-regular text-theme-table-text"
            v-bind="attrs"
            v-on="on"
          >
            <template>
              <div class="text-truncate">
                <span v-if="Array.isArray(item.customFields?.tags) && item.customFields.tags.length > 0">
                  {{ item.customFields.tags.map(tag => `${tag}`).join(', ') }}
                </span>
                <span v-else-if="typeof item.customFields?.tags === 'string' && item.customFields.tags.trim() !== ''">
                  {{ item.customFields.tags }}
                </span>
                <v-icon v-else>mdi-minus</v-icon>
              </div>
            </template>
          </span>
        </template> 
        <span>
          {{
            Array.isArray(item.customFields?.tags)
              ? item.customFields.tags.map(tag => `${tag}`).join(', ')
              : ''
          }}
        </span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.progress`]="{ item }">
      <ProgressBar
        :executions="item.executionsProgress"
        :percentage="item.percentage"
        :case-count="item.testCasesCount"
      />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex flex-row justify-center">
        <v-menu
          left
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="writeEntity || deleteEntity"
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list
            dense
            class="text-left"
          >
            <v-list-item
              v-if="item.archivedAt == null && writeEntity"
              :class="{
                'pointer': true,
                'disabled-action': isProjectArchived
              }"
              @click="onEdit(item)"
            >
              <EditIcon />
              <v-list-item-content class="ml-2">
                {{ $t('edit') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="item.archivedAt == null && writeEntity"
              :class="{
                'pointer': true,
                'disabled-action': isProjectArchived
              }"
              @click="onClose(item)"
            >
              <CloseIcon />
              <v-list-item-content class="ml-2">
                {{ $t('close') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else-if="item.archivedAt != null && writeEntity"
              :class="{
                'pointer': true,
                'disabled-action': isProjectArchived
              }"
              @click="onReOpen(item)"
            >
              <ReOpenIcon />
              <v-list-item-content class="ml-2">
                {{ $t('re-open') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="deleteEntity"
              :class="{
                'pointer': true,
                'disabled-action': isProjectArchived
              }"
              @click="onDelete(item)"
            >
              <DeleteIcon />
              <v-list-item-content class="ml-2 error--text">
                {{ $t('delete') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template v-slot:[`item.users`]="{ item }">
      <td class="d-flex align-center">
        <v-row>
          <div
            v-for="(pic, imgIndex) in item.images"
            :key="imgIndex"
          >
            <v-avatar
              class="ml-n2 custom_border"
              size="30"
            >
              <img :src="pic">
            </v-avatar>
          </div>
          <v-avatar
            v-if="item.showCount"
            class="font-weight-bold gray-ish--text ml-n2"
            color="#ebecf0"
            size="30"
          >
            +{{ item.count }}
          </v-avatar>
        </v-row>
        <div>
          <v-menu
            content-class="custom_ele elevation-0"
            nudge-bottom="35"
            left
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon color="gray-ish">
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                class="pointer"
                @click="onEdit(item)"
              >
                <EditIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('edit') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="item.customFields.status === 'active'"
                class="pointer"
                @click="onClose(item)"
              >
                <CloseIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('close') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-else-if="item.customFields.status === 'archived'"
                class="pointer"
                @click="onReOpen(item)"
              >
                <ReOpenIcon />
                <v-list-item-content class="ml-2">
                  {{ $t('re-open') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="pointer"
                @click="onDelete(item)"
              >
                <DeleteIcon />
                <v-list-item-content class="ml-2 error--text">
                  {{ $t('delete') }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>

import DeleteIcon from '@/assets/svg/delete.svg';
import EditIcon from '@/assets/svg/edit.svg';
import CloseIcon from '@/assets/svg/close.svg';
import ReOpenIcon from '@/assets/svg/re-open.svg';
import { formattedDate } from '@/utils/util';
import { dateMixin } from '@/mixins/date.js';
import projectStatus from '@/mixins/projectStatus';
import ProgressBar from '@/components/base/ProgressBar'

export default {
  components: {
    DeleteIcon,
    EditIcon,
    CloseIcon,
    ReOpenIcon,
    ProgressBar
  },
  mixins: [dateMixin, projectStatus],
  props: {
    filteredHeaders: Array,
    filteredItems: Array,
    itemKey: String,
    rowClass: Function,
    writeEntity: {
      type: Boolean,
      default: false
    },
    deleteEntity: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      debounce: false,
      selectedItems: [],
    };
  },

  methods: {
    formattedDate,
    formatDueDate(dueDate) {
      return this.formatDate(dueDate)
    },
    onRowClick(item) {
      this.selectedItems = item;
      this.$emit('select-item', this.selectedItems);
    },
    onEdit(item) {
      if (!this.isProjectArchived) {
      this.$emit('edit-item', item);
      }
    },
    onClose(item) {
      if (!this.isProjectArchived) {
      this.$emit('close-item', item);
      }
    },
    onReOpen(item) {
      if (!this.isProjectArchived) {
      this.$emit('reopen-item', item);
      }
    },
    onDelete(item) {
      if (!this.isProjectArchived) {
      this.$emit('delete-item', item);
      }
    },
    toggleStar(item) {
      this.debounce = true;
      this.$emit('toggle-star', item);
    },
    getColor(priority) {
      switch (priority) {
        case "Past due":
          return "font-weight-bold red--text text--lighten-1"
        case "Blocked":
          return "font-weight-bold orange--text text--lighten-1"
        case "Active":
          return "font-weight-bold green--text text--lighten-1"
        case "Upcoming":
          return "font-weight-bold blue--text text--lighten-1"
      }
    },
    viewMilestone(milestone){
      if(milestone.uid)
      this.$router.push({
        name: 'MilestoneView',
        params:{
          id: milestone.uid
        }
      })
    }
  },
};
</script>

<style scoped>
.v-data-table .v-data-table__wrapper tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.v-data-table-header__icon {
  opacity: 1 !important;
}

.v-data-table .v-data-table__wrapper tbody tr:nth-child(even) {
  background-color: #F9FAFB;
}

.pointer {
  cursor: pointer;
}

.header_text {
  color: #475467;
  font-weight: 700;
}

.custom_color {
  color: #667085;
}

.custom_border {
  border: 2px solid #ffffff;
}
.my-table table {
  table-layout: fixed;
}
.v-data-table table {
  border-collapse: collapse;
}

.v-data-table th {
  border: none !important;
}

.v-data-table td {
  border: none !important;
  cursor: pointer;
}

.v-data-table tr.project-item:hover {
  border: 1px solid #d1e1ff !important;
}

.v-data-table .v-data-table__wrapper tbody tr {
  height: 80px;
}

.v-data-table .v-data-table__wrapper tbody tr td {
  height: 80px !important;
  padding-top: 0;
}

.v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background-color: transparent !important;
}
</style>