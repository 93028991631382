<template>
  <v-container
    id="case-index-container"
    class="container h-full d-flex flex-column justify-start font-inter pb-0 pr-0"
    fluid
  >
    <MilestoneHeader
      :title="$t('milestone.title')"
      :action-text="$t('milestone.create')"
      :filter="filter"
      :ongoing-item-count="openItemCount"
      :archived-item-count="closedItemCount"
      :write-entity="_writeEntity"
      @update-filter="updateFilter"
    />
    <Loader v-if="loaderState" />
    <template v-else-if="openItemCount == 0 && filter != 'closed' && !tableLoadingState">
      <div class="mb-0 white rounded-lg mx-0 project-placeholder-height d-flex justify-center align-center">   
        <ActiveEmptyState
          :image-src="require('@/assets/png/milestone-empty-state.png')"
          :title="$t('milestone.empty_state.title')"
          :button-text="$t('milestone.createMilestone')"
          :button-route="{ name: 'MilestoneCreate' }"
          button-color="primary"
          :is-project-archived="isProjectArchived"
          :write-entity="_writeEntity"
        >
          <template #description>
            <p class="mb-0 mt-3">
              {{ $t('milestone.empty_state.description_part1') }}
            </p>
            <p class="ma-0">
              {{ $t('milestone.empty_state.description_part2') }}
            </p>
            <p class="ma-0">
              {{ $t('milestone.empty_state.description_part3') }}
            </p>
          </template>
        </ActiveEmptyState>
      </div>
    </template>

    <template v-else-if="closedItemCount == 0 && filter != 'open' && !tableLoadingState">
      <div class="mb-0 white rounded-lg mx-0 project-placeholder-height d-flex justify-center align-center">
        <ArchivedEmptyState
          :image-src="require('@/assets/png/milestone-empty-state.png')"
          :title="$t('closed_empty_state.title', { name: $t('milestones') })"
        >
          <template #description>
            <p class="mb-0 mt-3">
              {{ $t('closed_empty_state.description.part1', { name: $t('milestones') }) }}
            </p>
            <p class="mb-0">
              {{ $t('projects.archived_empty_state.description.part2') }}
            </p>
          </template>
        </ArchivedEmptyState>
      </div> 
    </template>

    <template v-else>
      <template>
        <v-container
          class="pa-6 white rounded-lg h-full"
          fluid
        >
          <template>
            <v-row class="align-center">
              <v-col
                cols="6"
                sm="6"
              >
                <div class="d-flex flex-row justify-start align-center">
                  <v-responsive
                    class="ma-0"
                    max-width="344"
                  >
                    <v-text-field
                      v-model="searchFilter"
                      class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                      background-color="#F9F9FB"
                      :placeholder="$t('search_by_name')"
                      dense
                      height="38px"
                      hide-details
                    >
                      <template v-slot:prepend-inner>
                        <SearchIcon />
                      </template>
                    </v-text-field>
                  </v-responsive>
                  <v-col
                    flex
                    class="d-flex justify-space-between align-center"
                  >
                    <MilestoneFilter
                      v-if="this.filter == 'open'"
                      @applyFilters="applyFilters"
                    />
                    <MilestoneClosedFilter
                      v-if="this.filter == 'closed'"
                      @applyFilters="appplyClosedFilters"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                class="d-flex justify-end"
              >
                <SettingsMenu 
                  table-type="milestone"
                />
              </v-col>
            </v-row>
            <v-row v-if="mainFilter == true && closeFilter == false && filter == 'open'">
              <v-col>
                <div class="custom-chip-container">
                  <span class="d-flex align-center justify-center font-weight-bold">Results ({{ filteredMilestones.length
                  }})</span>
                &nbsp;&nbsp;
                  <v-chip
                    v-for="({ index, filter }, idx) in filteredFilters"
                    :key="idx"
                    label
                    class="custom-chip-font-size"
                    close
                    @click:close="updateMainFilter(index)"
                  >
                    <template v-if="index === 'selectedRoles'">
                      Status: {{ filter }}
                    </template>
                    <template v-else-if="index === 'dateRange'">
                      Start date: {{ filter.start }} - {{ filter.end }}
                    </template>
                    <template v-else-if="index === 'dueDateRange'">
                      Due date: {{ filter.start }} - {{ filter.end }}
                    </template>
                    <template v-else-if="index === 'users'">
                      Progress: {{ filter[0] }} - {{ filter[1] }}%
                    </template>
                    <template v-else-if="index === 'testCases'">
                      Test Cases: {{ filter[0] }} - {{ filter[1] }}
                    </template>
                    <template v-else-if="index === 'testRuns'">
                      Test Runs: {{ filter[0] }} - {{ filter[1] }}
                    </template>
                  </v-chip>
                  <v-sheet
                    color="gray-100"
                    class="d-flex align-center justify-center"
                    height="32px"
                    rounded="lg"
                    @click="clearFilters()"
                  >
                    <span class="px-4">
                      {{ $t('clearAll') }}
                    </span>
                  </v-sheet>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="pt-1"
              >
                <MilestoneTable
                  :filtered-headers="filteredHeaders"
                  :filtered-items="filteredMilestones"
                  :item-key="itemKey"
                  :write-entity="_writeEntity"
                  :delete-entity="_deleteEntity"
                  @select-item="setSelected"
                  @close-item="confirmCloseMilestone"
                  @reopen-item="unArchiveMilestone"
                  @edit-item="editMilestone"
                  @delete-item="showDeleteDialog"
                />
              </v-col>
            </v-row>
          </template>
        </v-container>
      </template>
    </template>
    <MilestoneArchiveDialog
      v-model="showConfirmOpenDialog"
      :title="$t('milestone.close_dialog.title')"
      :content="$t('milestone.close_dialog.content_part1')"
      :content_part2="$t('milestone.close_dialog.content_part2')"
      :btn_label="$t('milestone.close_dialog.btn_label')"
      :milestone_name="selectedMilestone.name"
      color="primary"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmOpenClick('close')"
    />
    <MilestoneDeleteDialog
      v-model="showConfirmDeleteDialog"
      :title="$t('milestone.delete_dialog.title')"
      :content="$t('milestone.delete_dialog.content')"
      :btn_label="$t('milestone.close_dialog.btn_label')"
      :milestone_name="selectedMilestone.name"
      color="primary"
      @close="handleDeleteCloseClick"
      @handleConfirmClick="handleConfirmDeleteClick('close')"
    />
  </v-container>
</template>

<script>
import MilestoneDeleteDialog from '@/components/Milestone/MilestoneDeleteDialog.vue';
import MilestoneArchiveDialog from '@/components/Milestone/MilestoneArchiveDialog.vue';
import MilestoneTable from '@/components/Milestone/MilestoneTable.vue';
import MilestoneHeader from '@/components/Milestone/MilestoneHeader.vue';
import MilestoneFilter from '@/components/Milestone/MilestoneFilter.vue';
import MilestoneClosedFilter from '@/components/Milestone/MilestoneClosedFilter.vue';
import { dateMixin } from '@/mixins/date.js';
import { runStateMap, DefaultFilter } from '@/constants/grid';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import MilestoneService from '@/services/api/milestone';
import { handleNetworkStatusError } from '@/mixins/redirect';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import Loader from '@/components/base/Loader.vue';
import ActiveEmptyState from '@/components/base/ActiveEmptyState.vue';
import { sleep } from '@/utils/util';
import ArchivedEmptyState from '@/components/base/ArchivedEmptyState.vue';
import SearchIcon from '@/assets/svg/search-icon.svg';
import { createNamespacedHelpers, mapActions, mapGetters } from "vuex";
const { mapGetters: mapHeadersGetters } = createNamespacedHelpers("headers");
import projectStatus from '@/mixins/projectStatus';
let makeMilestoneService;

export default {
  components: {
    MilestoneHeader,
    MilestoneTable,
    MilestoneArchiveDialog,
    MilestoneFilter,
    MilestoneClosedFilter,
    MilestoneDeleteDialog,
    SettingsMenu,
    Loader,
    ActiveEmptyState,
    ArchivedEmptyState,
    SearchIcon
  },
  mixins: [ handleNetworkStatusError, dateMixin, projectStatus ],
  data() {
    return {
      milestones: [],
      loaderState: false,
      tableLoadingState: false,
      selectedMilestone: {},
      setSelected: [],
      selectedStatus: Object.values(runStateMap)[0],
      savingInProgress: false,
      headers: [],
      filter: 'open',
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      expanded: [],
      itemKey: 'uid',
      searchFilter: '',
      showFormDialog: false,
      openedRow: undefined,
      loading: false,
      checked: false,
      showConfirmOpenDialog: false,
      showConfirmDeleteDialog: false,
      mainFilter: false,
      closeFilter: false,
      mainFilters: {
        selectedRoles: [],
        testRuns: [0, 100],
        testCases: [0, 100],
        users: [0, 100],
        dateRange: {
          start: "",
          end: "",
        },
        dueDateRange: {
          start: "",
          end: "",
        },
        progress: [0, 100]
      },
      closedFilters: {
        testRuns: [0, 100],
        testCases: [0, 100],
        users: [0, 100],
        dateRange: {
          start: "",
          end: "",
        },
      },
    };
  },

  computed: {
    ...mapHeadersGetters(['dynamicHeaders']),
    _writeEntity(){
      return this.authorityTo('write_entity');
    },
    _deleteEntity(){
      return this.authorityTo('delete_entity');
    },
    filteredHeaders() {
      const filtered = this.headers.filter((header) => header.checked);
      return filtered;
    },
    isColumnFilter: {
      get() {
        return this.filteredHeaders.length < 7;
      },
    },
    filteredMilestones() {
       
      let filteredMilestones = this.milestones;
      if (this.filter === 'closed') {
        filteredMilestones = filteredMilestones.filter(item => item.archivedAt != null);
      } else {
        filteredMilestones = filteredMilestones.filter(item => item.archivedAt == null);
      }
      if (this.searchFilter && this.mainFilter == false && this.closeFilter == false) {
        filteredMilestones = filteredMilestones.filter((item) => this.matchesFilter(item));
      } else if (this.mainFilter == true && this.closeFilter == false && !this.searchFilter) {
        filteredMilestones = filteredMilestones.filter(item => {
          if (this.mainFilters.selectedRoles.length > 0 && !this.mainFilters.selectedRoles.includes(item.customFields.status)) {
            return false;
          }
          if (item.testRunsCount < this.mainFilters.testRuns[0] || item.testRunsCount > this.mainFilters.testRuns[1]) {
            return false;
          }
          if (item.testCasesCount < this.mainFilters.testCases[0] || item.testCasesCount > this.mainFilters.testCases[1]) {
            return false;
          }
          if (this.mainFilters.dateRange.start && this.mainFilters.dateRange.end) {
            const itemDate = new Date(item.customFields.startDate);
            const startDate = new Date(this.mainFilters.dateRange.start);
            const endDate = new Date(this.mainFilters.dateRange.end);
            if (itemDate < startDate || itemDate > endDate) {
              return false;
            }
          }
          if (this.mainFilters.dueDateRange.start && this.mainFilters.dueDateRange.end) {
            const itemDueDate = new Date(item.dueAt);
            const dueStartDate = new Date(this.mainFilters.dueDateRange.start);
            const dueEndDate = new Date(this.mainFilters.dueDateRange.end);
            if (itemDueDate < dueStartDate || itemDueDate > dueEndDate) {
              return false;
            }
          }
          if (item.customFields.progress < this.mainFilters.users[0] || item.customFields.progress > this.mainFilters.users[1]) {
            return false;
          }
          return true;
        });
      } else if (this.mainFilter == false && this.closeFilter == true && !this.searchFilter) {
        filteredMilestones = filteredMilestones.filter(item => {
          if (item.customFields.testRuns < this.closedFilters.testRuns[0] || item.customFields.testRuns > this.closedFilters.testRuns[1]) {
            return false;
          }
          if (item.customFields.testCases < this.closedFilters.testCases[0] || item.customFields.testCases > this.closedFilters.testCases[1]) {
            return false;
          }
          if (this.mainFilters.dateRange.start && this.closedFilters.dateRange.end) {
            const itemDate = new Date(item.customFields.startDate);
            const startDate = new Date(this.closedFilters.dateRange.start);
            const endDate = new Date(this.closedFilters.dateRange.end);
            if (itemDate < startDate || itemDate > endDate) {
              return false;
            }
          }
          if (item.customFields.progress < this.closedFilters.users[0] || item.customFields.progress > this.closedFilters.users[1]) {
            return false;
          }
          return true;
        });
      } else if (this.mainFilter == true && this.searchFilter) {
        const mainFiltered = filteredMilestones.filter(item => {
          if (this.mainFilters.selectedRoles.length > 0 && !this.mainFilters.selectedRoles.includes(item.customFields.status)) {
            return false;
          }
          if (item.customFields.testRuns < this.mainFilters.testRuns[0] || item.customFields.testRuns > this.mainFilters.testRuns[1]) {
            return false;
          }
          if (item.customFields.testCases < this.mainFilters.testCases[0] || item.customFields.testCases > this.mainFilters.testCases[1]) {
            return false;
          }
          if (this.mainFilters.dateRange.start && this.mainFilters.dateRange.end) {
            const itemDate = new Date(item.customFields.startDate);
            const startDate = new Date(this.mainFilters.dateRange.start);
            const endDate = new Date(this.mainFilters.dateRange.end);
            if (itemDate < startDate || itemDate > endDate) {
              return false;
            }
          }
          if (this.mainFilters.dueDateRange.start && this.mainFilters.dueDateRange.end) {
            const itemDueDate = new Date(item.dueAt);
            const dueStartDate = new Date(this.mainFilters.dueDateRange.start);
            const dueEndDate = new Date(this.mainFilters.dueDateRange.end);
            if (itemDueDate < dueStartDate || itemDueDate > dueEndDate) {
              return false;
            }
          }
          if (item.customFields.progress < this.mainFilters.users[0] || item.customFields.progress > this.mainFilters.users[1]) {
            return false;
          }
          return true;
        });
        filteredMilestones = mainFiltered.filter((item) => this.matchesFilter(item));
      }
      return filteredMilestones;
    },
    openItemCount() {
      if (!Array.isArray(this.milestones)) {
        return 0;
      }
      return this.milestones.filter((item) => item.deletedAt == null && item.archivedAt == null).length;
    },
    closedItemCount() {
      if (!Array.isArray(this.milestones)) {
        return 0;
      }
      return this.milestones.filter((item) => item.deletedAt == null && item.archivedAt != null).length;
    },
    filteredFilters() {
      const filters = this.mainFilters;
      return Object.keys(filters).filter(index => {
        const filter = filters[index];
        switch (index) {
          case 'selectedRoles':
            return !filter.length == 0;
          case 'dateRange':
          case 'dueDateRange':
            return filter.start && filter.end;
          case 'users':
            return !(filter[0] == 0 && filter[1] == 100)
          case 'testCases':
            return !(filter[0] == 0 && filter[1] == 100)
          case 'testRuns':
            return !(filter[0] == 0 && filter[1] == 100)
          case 'tags':
            return !(filter[0] == 0 && filter[1] == 100)
          default:
            return false;
        }
      }).map(index => ({ index, filter: filters[index] }));
    }
  },
  watch: {
    filteredMilestones(newValue) {
      if (newValue === "SEARCH_BACKEND") {
        this.searchRuns();
      }
    },
    headers: {
      deep: true,
      handler() {
        this.updateFilteredHeaders();
      }
    },
    immediate: true,
  },
  mounted(){
    this.init();
    if(this.$route.query.activeClosed === 'true'){
      this.filter = 'closed';
    }
  },
  created() {
    if(!this.dynamicHeaders.milestone) {
      this.initializeHeaders({ type: 'milestone' });
    }
    this.headers = this.dynamicHeaders.milestone;
    makeMilestoneService = MilestoneService(this.$api);
  },
  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    async init() {

      this.loaderState = false;
      this.tableLoadingState = true;
      let loaderStateTimeout = null;

      loaderStateTimeout = setTimeout(() => {
        this.loaderState = true;
      }, 1000);

      try {
        await this.getMilestones(); 
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'Milestone' });
      } finally {
        clearTimeout(loaderStateTimeout); 
        const hideLoader = async () => {
          await sleep(1000); 
          this.loaderState = false; 
          this.tableLoadingState = false;
        };
        hideLoader(); 
      }
    },
    isDefaultValue(index) {
      if (this.mainFilters[index] === DefaultFilter[index]) {
        return true
      }
      else {
        return false
      }
    },
    confirmCloseMilestone(item) {
      this.selectedMilestone = item;
      this.showConfirmOpenDialog = true;
    },
    showDeleteDialog(item) {
      this.selectedMilestone = item;
      this.showConfirmDeleteDialog = true;
    },
    handleCloseClick() {
      this.showConfirmOpenDialog = false;
      this.selectedMilestone = [];
    },
    handleDeleteCloseClick() {
      this.showConfirmDeleteDialog = false;
      this.selectedMilestone = [];
    },
    async handleConfirmOpenClick() {
      try {
        await makeMilestoneService.updateMilestone(
          this.$route.params.handle,
          this.$route.params.key,
          this.selectedMilestone.uid,
          {
            archived: true,
            externalId: this.selectedMilestone.externalId,
            source: this.selectedMilestone.source,
            name: this.selectedMilestone.name,
          }
        );
        showSuccessToast(this.$swal, 'closeSuccess', { item: 'Milestone' });
        this.handleCloseClick();
        this.getMilestones();
      } catch (error) {
        showErrorToast(this.$swal, 'closeError', { item: 'Milestone' });
      }
    },
    async handleConfirmDeleteClick() {
      if(!this._deleteEntity){
        this.unauthorizedToast;
        return ;
      }
      try {
        await makeMilestoneService.deleteMilestone(
          this.$route.params.handle,
          this.$route.params.key,
          this.selectedMilestone.uid
        );
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Milestone' });
        this.handleDeleteCloseClick();
        this.getMilestones();
      } catch (error) {
        showErrorToast(this.$swal, 'deleteError', { item: 'Milestone' });
      }
    },
    applyFilters(filters) {
      this.mainFilters = filters;
      this.mainFilter = true;
      this.closeFilter = false
      this.getMilestones()
    },
    appplyClosedFilters(filters) {
      this.closedFilters = filters;
      this.mainFilter = false
      this.closeFilter = true;
      this.filter = 'closed'
    },
    async updateFilter(newFilter) {
      this.filter = newFilter;
      await this.getMilestones();
    },

    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();

      const nameMatch = item.name.toLowerCase().includes(lowerCaseFilter);

      return nameMatch;
    },
    staticValues(item, obj, defultValue) {
      return obj[item.customFields.state] || obj[defultValue];
    },

    async getMilestones() {
      this.loading = true;
      try {
        const searchParams = new URLSearchParams();
        searchParams.set('includeCount', true);
      
        const response = await makeMilestoneService.getMilestones(this.$route.params.handle, this.$route.params.key,  searchParams.toString());
        this.milestones = response.data.milestones || [];
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'milestones' });
        this.milestones = []; // Reset to empty array in case of error
      } finally {
        this.loading = false;
      }
    },
    getColor(priority) {
      switch (priority) {
        case "Past due":
          return "font-weight-bold red--text text--lighten-1"
        case "Blocked":
          return "font-weight-bold orange--text text--lighten-1"
        case "Active":
          return "font-weight-bold green--text text--lighten-1"
        case "Upcoming":
          return "font-weight-bold blue--text text--lighten-1"
      }
    },
    convertToLocal(timestamp) {
      return this.formatDate(timestamp, 'ddd, MMM DD hh:mm A');
    },
    determineType(test) {
      return test.source === 'pinata' ? 'Exploratory' : 'Manual';
    },
    updateFilteredHeaders() {
      const filtered = this.headers.filter((header) => header.isSelected);

    },
    editMilestone(item) {
      this.selectedMilestone = item;

      this.$router.push({
        name: 'MilestoneEdit',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          id: item.uid,
        },
      });
    },
    async unArchiveMilestone(item) {
      this.selectedMilestone = item;
      try {
        await makeMilestoneService.updateMilestone(
          this.$route.params.handle,
          this.$route.params.key,
          this.selectedMilestone.uid,
          {
            archived: false,
            externalId: this.selectedMilestone.externalId,
            source: this.selectedMilestone.source,
            name: this.selectedMilestone.name,
          }
        );
        showSuccessToast(this.$swal, 'reopenSuccess', { item: 'Milestone' });
        this.handleCloseClick();
        this.getMilestones();
      } catch (error) {
        showErrorToast(this.$swal, 'reopenError', { item: 'Milestone' });
      }
    },
    updateMainFilter(index) {
      this.mainFilters[index] = DefaultFilter[index]
    },
    clearFilters() {
      this.mainFilter = false
      this.mainFilters = DefaultFilter
    },
  },
};
</script>
<style scoped>
.h-full {
  height: 100%;
}

.custom-runtable {
  color: #344054 !important;
}

.custom-color-0c111d {
  color: #0C111D !important;
}

.custom-color-d0d5dd {
  color: #D0D5DD !important;
}

.custom-menu-item {
  min-height: 36px;
  height: 36px;
  max-height: 36px;
  align-items: center;
}

.custom-text-12 {
  font-size: 12px;
}

.custom-text-14 {
  font-size: 14px;
}

.custom-font-size {
  font-size: 18px;
}

.custom-chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.custom-chip-font-size {
  font-size: 16px;
}

.custom-result-title {
  font-size: 20px;
}
</style>
