
const state = {
  orgs: {},
};

const getters = {
  getOrgPreferences: (state) => (handle) => {
    const org = state.orgs[handle] || {};
    return org || {};
  },
  getOrgStatusColorsByEntityType: (state) => (handle, entityType) => {
    const org = state.orgs[handle] || {};
    const preferencesData = org || {};
    return preferencesData?.statusColors?.filter((status) => status.entityType === entityType) || [];
  },
  getOrgPriorityColorsByEntityType: (state) => (handle, entityType) => {
    const org = state.orgs[handle] || {};
    const preferencesData = org || {};
    return preferencesData?.priorityColors?.filter((priority) => priority.entityType === entityType) || [];
  },
}

const mutations = {
  setOrgPreferences(state, { handle, preferences }) {
    state.orgs = {
      ...state.orgs,
      [handle]: preferences,
    };
  },

};


export default function makeOrg() {
  return {
    namespaced: true,
    state,
    getters,
    mutations,
  };
}