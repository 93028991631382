<template>
  <v-dialog
    v-model="showDialog"
    class="test-cases-filter-drawer dialog-theme"
    transition="slide-x-transition"
    attach
    fullscreen
    width="485px"
  >
    <v-card>
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between py-6">
          <h2 class="text-theme-label">
            {{ data.name }}
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <div>
          <h4 class="fw-semibold fs-16px text-theme-label">
            {{ $t('defect.overview') }}
          </h4>
          <v-list
            class="list-theme d-flex flex-column"
          >
            <v-list-item class="px-0">
              <template>
                <div class="flex justify-between">
                  <div
                    class="block rounded-lg px-3 py-2 w-50 mr-2 mh-56px"
                    style="background-color: #F9FAFB"
                  >
                    <h5 class="align-left">
                      {{ $t('defect.creator') }}
                    </h5>
                    <div class="align-left contents fw-semibold fs-14px">
                      {{ data.creator || $t('none') }}
                    </div>
                  </div>
                  <div
                    class="block rounded-lg px-3 py-2 w-50 ml-2 mh-56px"
                    style="background-color: #f9fafb"
                  >
                    <h5 class="align-left">
                      {{ $t('testCase') }}
                    </h5>
                    <div class="align-left contents fw-semibold fs-14px">
                      {{ data.id || $t('none') }}
                    </div>
                  </div>
                </div>
              </template>
            </v-list-item>
            <v-list-item
              v-if="!expandOverviewPanel"
              class="px-0 mt-2"
            >
              <template>
                <div class="flex justify-between">
                  <div
                    class="block rounded-lg px-3 py-2 w-50 mr-2 mh-56px"
                    style="background-color: #F9FAFB"
                  >
                    <h5 class="align-left">
                      {{ $t('testRun') }}
                    </h5>
                    <div class="align-left contents fw-semibold fs-14px">
                      {{ data.testRun || $t('none') }}
                    </div>
                  </div>
                  <div
                    class="block rounded-lg px-3 py-2 w-50 ml-2 mh-56px"
                    style="background-color: #F9FAFB"
                  >
                    <h5 class="align-left">
                      {{ $t('testCase') }}
                    </h5>
                    <div class="align-left contents fw-semibold fs-14px">
                      {{ data.testCase || $t('none') }}
                    </div>
                  </div>
                </div>
              </template>
            </v-list-item>
            <v-list-item
              v-if="!expandOverviewPanel"
              class="px-0 mt-2"
            >
              <template>
                <div class="flex justify-between">
                  <div
                    class="block rounded-lg px-3 py-2 w-50 mr-2 mh-56px"
                    style="background-color: #F9FAFB"
                  >
                    <h5 class="align-left">
                      {{ $t('status') }}
                    </h5>
                    <div
                      class="align-left contents fw-semibold fs-14px"
                      :style="{ color: getStatusColor(data.status, statuses) }"
                    >
                      {{ getStatusName(data.status, statuses) || $t('none') }}
                    </div>
                  </div>
                  <div
                    class="block rounded-lg px-3 py-2 w-50 ml-2 mh-56px"
                    style="background-color: #F9FAFB"
                  >
                    <h5 class="align-left">
                      {{ $t('integration') }}
                    </h5>
                    <div class="align-left contents fw-semibold fs-14px">
                      {{ data.integration || $t('none') }}
                    </div>
                  </div>
                </div>
              </template>
            </v-list-item>
            <v-list-item
              v-if="!expandOverviewPanel"
              class="px-0 mt-2"
            >
              <template>
                <div class="flex justify-between">
                  <div
                    class="block rounded-lg px-3 py-2 w-50 mr-2 mh-56px"
                    style="background-color: #F9FAFB"
                  >
                    <h5 class="align-left">
                      {{ $t('priority') }}
                    </h5>
                    <div
                      class="align-left contents fw-semibold fs-14px"
                      :style="{ color: getPriorityColor(data.priority, priorities) }"
                    >
                      {{ getPriorityName(data.priority, priorities) || $t('none') }}
                    </div>
                  </div>
                  <div
                    class="block rounded-lg px-3 py-2 w-50 ml-2 mh-56px"
                    style="background-color: #F9FAFB"
                  >
                    <h5 class="align-left">
                      {{ $t('typeLabel') }}
                    </h5>
                    <div class="align-left contents fw-semibold fs-14px">
                      {{ data.type || $t('none') }}
                    </div>
                  </div>
                </div>
              </template>
            </v-list-item>
            <v-list-item
              v-if="!expandOverviewPanel"
              class="px-0 mt-2"
            >
              <template>
                <div class="flex justify-between">
                  <div
                    class="block rounded-lg px-3 py-2 w-50 mh-56px"
                    style="background-color: #f9fafb"
                  >
                    <h5 class="align-left">
                      {{ $t('lastUpdate') }}
                    </h5>
                    <div class="align-left contents fw-semibold fs-14px">
                      {{ formatDate(data.updatedAt) }}
                    </div>
                  </div>
                </div>
              </template>
            </v-list-item>
          </v-list>

          <h4
            v-if="expandOverviewPanel"
            class="f-color-blue toggle-btn"
            @click="expandOverviewPanel = !expandOverviewPanel"
          >
            {{ $t('defect.showMore') }}
          </h4>

          <h4
            v-else
            class="f-color-blue toggle-btn"
            @click="expandOverviewPanel = !expandOverviewPanel"
          >
            {{ $t('defect.showLess') }}
          </h4>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDate } from '@/utils/util';
import colorPreferencesMixin from '@/mixins/colorPreferences';

export default {
  name: 'ViewDefectDialog',
  mixins: [colorPreferencesMixin],

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: () => ({})
    },
  },

  data () {
    return {
      expandOverviewPanel: true,
      commentsPanel: 0,
      newComment: '',
      showTiptap: false,
      statuses: [],
      priorities: []
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },  
  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      this.expandOverviewPanel = true
      this.newComment = ''
    },
  },
  mounted() {
    this.statuses = this.getPriorities("defect");
    this.priorities = this.getStatuses("defect");
  },

  methods: {
    formatDate(date) {
      if (!date) {
        return ''
      }

      return formatDate(date, 'MM/dd/yy')
    },
  }
}

</script>