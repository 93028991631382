<template>
  <div
    fluid
    class="pa-0"
  >
    <v-card
      class="py-2 mt-3 create-project-height fill-height"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <!-- Simple Progress Bar -->
      <div class="progress-container">
        <div
          class="progress-bar"
          :style="{ width: `${progressPercentage}%` }"
        />
        <span class="progress-text">{{ progressPercentage }}%</span>
      </div>

      <v-row>
        <v-col flex>
          <!-- Back Button -->
          <div
            v-if="currentStep === 1 || currentStep === 0"
            class="back-to-projects cursor-pointer"
            @click="handleBackClick"
          >
            <v-icon color="blue">
              mdi-chevron-left
            </v-icon>
            <p
              v-if="showAddUsersView"
              class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold"
            >
              {{ $t('integrations.create_intergrations.back_to_integrations') }}
            </p>
            <p
              v-else
              class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold"
            >
              {{ $t('integrations.create_intergrations.back_to_integrations') }}
            </p>
          </div>
          <div
            v-if="currentStep > 1"
            class="back-to-projects cursor-pointer"
            @click="
              () => {
                currentStep--;
              }
            "
          >
            <v-icon color="blue">
              mdi-chevron-left
            </v-icon>
            <p
              v-if="currentStep == 2"
              class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold"
            >
              {{ $t('integrations.create_intergrations.back_to_app_integrations', { serviceName: integrationName }) }}
            </p>
          </div>
        </v-col>

        <!-- Step 1 -->
        <v-col
          v-if="currentStep === 1"
          cols="12"
          class="d-flex justify-center align-center"
        >
          <v-card
            class="mt-n4 elevation-0"
            width="450px"
          >
            <h3 class="font-weight-bold">
              {{ $t('integrations.create_intergrations.integration_name', { serviceName: integrationName }) }}
            </h3>
            <p class="text-grey my-1">
              {{
                $t('integrations.create_intergrations.integration_description', { serviceName: integrationDescription })
              }}
            </p>
            <div class="my-2">
              <p class="d-flex fs-14px text-theme-label mb-1 font-weight-medium">
                {{ $t('integrations.create_intergrations.thumbnail') }}
              </p>
              <div class="create-project-layout">
                <v-hover v-slot="{ hover }">
                  <div
                    class="project-logo"
                    :style="{ ...imageStyle, width: '100%' }"
                    :class="{ hovering: hover }"
                    @click="openFileDialog"
                    @drop.prevent="handleDrop"
                    @dragover.prevent
                    @dragenter.prevent
                  >
                    <input
                      ref="fileInput"
                      type="file"
                      :accept="`${projectImageTypes.join(', ')}`"
                      style="display: none"
                      @change="handleFileChange"
                    >
                    <template v-if="!imageSrc">
                      <p class="ma-0 fs-14px">
                        {{ $t('drag_and_drop.part1') }}
                      </p>
                      <p class="ma-0 fw-semibold mt-2 blue--text fs-14px">
                        {{ $t('drag_and_drop.part3') }}
                      </p>
                    </template>
                    <template v-if="hover && imageSrc">
                      <v-icon
                        class="edit-icon"
                        @click.stop="openFileDialog"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        class="delete-icon"
                        @click.stop="removeImage"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </div>
                </v-hover>
              </div>
            </div>
            <div>
              <p class="d-flex fs-14px text-theme-label mb-1 font-weight-medium">
                {{ $t('name') }}
              </p>

              <v-text-field
                v-model="name"
                type="text"
                class="rounded-lg field-theme pt-0"
                background-color="#F9F9FB"
                height="38px"
                :placeholder="$t('name')"
                clear-icon="body-2"
              />
            </div>
            <div>
              <p class="d-flex fs-14px text-theme-label mb-1 font-weight-medium">
                {{ $t('integrations.create_intergrations.description') }}
              </p>

              <v-text-field
                v-model="description"
                type="text"
                class="rounded-lg field-theme pt-0"
                background-color="#F9F9FB"
                height="38px"
                :placeholder="$t('integrations.create_intergrations.description')"
                clear-icon="body-2"
              />
            </div>
            <!-- Redirect Option -->
            <div class="redirect-section mb-6">
              <v-btn
                block
                height="38px"
                color="primary"
                class="text-capitalize btn-theme redirect-btn"
                :loading="isLoading"
                @click="handleRedirect"
              >
                {{ $t('integrations.create_intergrations.loginWithService', { serviceName: integrationName }) }}
              </v-btn>

              <div class="text-center my-4 divider-text">
                <span>{{ $t('integrations.create_intergrations.enter_manually') }}</span>
              </div>
            </div>
            <div>
              <p class="d-flex fs-14px text-theme-label mb-1 font-weight-medium">
                {{ $t('integrations.create_intergrations.organisanion_user_url') }}
              </p>

              <v-text-field
                v-model="organizationUrl"
                type="text"
                class="rounded-lg field-theme pt-0"
                background-color="#F9F9FB"
                height="38px"
                :placeholder="$t('integrations.create_intergrations.organisanion_user_url')"
                :rules="[rules.required]"
                clear-icon="body-2"
              />
            </div>
            <div v-if="showUsernameField">
              <p class="d-flex fs-14px text-theme-label mb-1 font-weight-medium">
                {{ $t('Username') }}
                <strong
                  v-if="isTestRail"
                  class="red--text text--lighten-1"
                >*</strong>
              </p>

              <v-text-field
                v-model="username"
                type="text"
                class="rounded-lg field-theme pt-0"
                background-color="#F9F9FB"
                height="38px"
                :placeholder="$t('Username')"
                :rules="usernameRules"
                clear-icon="body-2"
              />
            </div>
            <div>
              <p class="d-flex fs-14px text-theme-label mb-1 font-weight-medium">
                {{ $t('integrations.create_intergrations.access_token') }}
              </p>

              <v-text-field
                id="password-field"
                v-model="accessToken"
                :placeholder="$t('inputPlaceholder', { field: $t('password') })"
                height="38"
                background-color="#F9F9FB"
                class="field-theme"
                :type="visiblePassword ? 'text' : 'password'"
                :append-icon="visiblePassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                :rules="[rules.required]"
                @click:append="visiblePassword = !visiblePassword"
              />
            </div>
          </v-card>
        </v-col>

        <!-- Step 2 -->
        <v-col
          v-if="currentStep === 2"
          cols="12"
          class="d-flex justify-center align-center"
        >
          <v-card
            class="mt-n2 elevation-0"
            width="450px"
          >
            <h3>{{ $t('integrations.create_intergrations.add_configuration') }}</h3>
            <p style="color: #667085">
              {{ $t('integrations.create_intergrations.select_configuration') }}
            </p>
            
            <!-- Only show configurations list when there are actual configurations AND not editing -->
            <div
              v-if="configurations && configurations.length > 0 && editConfigIndex === null"
              class="mb-4"
            >
              <h4>{{ $t('integrations.create_intergrations.configurations') }}</h4>
              <div
                v-for="(config, index) in configurations"
                :key="index"
                class="configuration-item"
              >
                <div class="d-flex justify-space-between align-center">
                  <p class="configuration-name body-2 font-weight-bold ma-0">
                    {{ $t('integrations.create_intergrations.name') }}
                  </p>
                  <div class="action-icons">
                    <v-btn
                      icon
                      small
                      @click.stop="editConfiguration(index)"
                    >
                      <EditIcon />
                    </v-btn>
                    <v-btn
                      icon
                      small
                      @click.stop="removeConfiguration(index)"
                    >
                      <DeleteIcon />
                    </v-btn>
                  </div>
                </div>
                <div>
                  <p
                    class="configuration-name body-2"
                    style="color: #667085"
                  >
                    {{ config.name }}
                  </p>
                </div>
                <p class="configuration-name body-2 font-weight-bold ma-0 text-capitalize">
                  <span class="text-capitalize mr-1">{{ $t('integrations.create_intergrations.service_projects', { serviceName: integrationName, entityName: getEntityName() }) }}</span>
                </p>

                <div class="d-flex flex-wrap">
                  <v-chip 
                    v-for="(project, i) in getServiceProjects(config)" 
                    :key="i"
                    class="ma-2"
                    small
                  >
                    {{ project }}
                  </v-chip>
                </div>
                <p class="configuration-name body-2 font-weight-bold ma-0">
                  {{ $t('integrations.create_intergrations.testfiesta_projects') }}
                </p>
                <div class="d-flex flex-wrap">
                  <v-chip 
                    v-for="(project, i) in getTestFiestaProjects(config)" 
                    :key="i"
                    class="ma-2"
                    small
                  >
                    {{ project }}
                  </v-chip>
                </div>
              </div>
            </div>

            <!-- Configuration Form Component -->
            <IntegrationConfigurationDropdown
              :resources="resources"
              :testfiesta-projects="testfiestaProjects"
              :service-projects="serviceProjects"
              :integration-uid="integrationUid"
              :service-name="integrationName"
              :edit-config-data="editConfigData"
              @add-configuration="handleAddConfiguration"
              @edit-configuration="handleEditConfiguration"
            />
          </v-card>
        </v-col>
        <!-- Step 0 (OAuth Resource Selection) -->
        <v-col
          v-if="currentStep === 0"
          cols="12"
          class="d-flex justify-center align-center"
        >
          <v-card
            class="pa-6 elevation-0"
            width="438px"
          >
            <p class="text-grey mb-2">
              {{ $t('integrations.success.oauth_success') }}
            </p>

            <h3 class="font-weight-bold mb-2 mt-8">
              {{ $t('integrations.success.jira_resources') }}
            </h3>

            <p class="text-grey mb-4">
              {{ $t('integrations.success.select_resource') }}
            </p>

            <v-select
              v-model="selectedResource"
              :items="resources"
              item-text="resourceName"
              item-value="resourceId"
              :placeholder="$t('integrations.success.select_org', { serviceName: integrationName })"
              class="rounded-lg field-theme"
              @change="handleResourceSelection"
            />
          </v-card>
        </v-col>

        <!-- Continue Button -->
        <v-col cols="12">
          <v-flex class="d-sm-flex justify-end position-absolute right-0 bottom-0 pa-4">
            <div class="d-flex justify-center align-end">
              <v-btn
                height="40px"
                color="primary"
                depressed
                :loading="isCreateDisabled"
                :disabled="isNextButtonDisabled"
                class="text-capitalize btn-theme rounded-lg mt-2"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                @click="handleContinueClick"
              >
                {{ currentStep === 2 ? 'Finish' : 'Continue' }}
              </v-btn>
            </div>
          </v-flex>
        </v-col>
      </v-row>
    </v-card>

    <IntegrationDiscardDialog
      v-model="showConfirmBackDialog"
      :title="$t('projects.create_project.close_dialog.title')"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmClick"
    />
  </div>
</template>

<script>
import makeProjectsService from '@/services/api/project';
import makeIntegrationsService from '@/services/api/integrations';
import { showErrorToast, showSuccessToast } from '@/utils/toast';
import { projectImageTypes } from '@/constants/fileTypes.js';
import fileValidator from '@/mixins/fileValidator.js';
import IntegrationDiscardDialog from '@/components/Integration/IntegrationDiscardDialog.vue';
import IntegrationConfigurationDropdown from '@/components/Integration/IntegrationConfigurationDropdown.vue';
import DeleteIcon from '../../assets/svg/delete.svg';
import EditIcon from '../../assets/svg/edit.svg';

export default {
  name: 'AddIntegration',

  components: {
    IntegrationDiscardDialog,
    IntegrationConfigurationDropdown,
    DeleteIcon,
    EditIcon,
  },

  mixins: [fileValidator],

  props: {
    selectedIntegration: {
      type: Object,
      default: () => ({
        name: '',
        description: '',
      }),
    },
  },

  data() {
    return {
      imageSrc: '',
      file: null,
      showConfirmBackDialog: false,
      projectImageTypes,
      currentStep: 1,
      steps: 2,
      showAddUsersView: false,
      isCreateDisabled: false,
      isLoading: false,
      resources: [],
      serviceProjects: [],
      testfiestaProjects: [],
      configurations: [],
      integrationName: '',
      integrationDescription: '',
      integrationUid: '',
      visiblePassword: false,
      description: '',
      name: '',
      username: '',
      organizationUrl: '',
      accessToken: '',
      rules: {
        required: (v) => !!v || this.$t('integrations.create_intergrations.this_field_is_required'),
      },
      editConfigData: {},
      editConfigIndex: null,
      serviceEntities: {
        'jira': ['projects', 'defects'],
        'github': ['repositories', 'defects'],
      },
    };
  },

  computed: {
    progressPercentage() {
      return Math.round((this.currentStep / this.steps) * 100);
    },
    isStepOneValid() {
      if (this.currentStep === 1) {
        if (this.integrationUid) {
          return true;
        }
        // Basic required fields
        const basicValidation = !!(this.organizationUrl && this.accessToken);

        // Username validation - required only for TestRail
        const usernameValidation = this.isTestRail ? !!this.username : true;

        return basicValidation && usernameValidation;
      }
      return true;
    },
    isNextButtonDisabled() {
      if (this.currentStep === 1) {
        return !this.isStepOneValid;
      }
      if (this.currentStep === 2) {
        return !this.configurations || this.configurations.length === 0;
      }
      return false;
    },
    canAddConfiguration() {
      if (this.configurations.length >= 3) return false;

      const lastConfig = this.configurations[this.configurations.length - 1];
      return lastConfig.testfiestaProjects.length > 0 && lastConfig.serviceProjects.length > 0;
    },
    imageStyle() {
      return this.imageSrc
        ? {
            backgroundImage: `url(${this.imageSrc})`,
            border: 'none',
          }
        : {};
    },
    isTestRail() {
      return this.integrationName?.toLowerCase() === 'testrail';
    },
    showUsernameField() {
      return this.isTestRail || this.username;
    },
    usernameRules() {
      if (this.isTestRail) {
        return [(v) => !!v || this.$t('required')];
      }
      return [];
    },
  },

  watch: {
    currentStep(newVal) {
      if (newVal === 3) {
        this.$router.replace({ name: 'integrations' });
      }
    },
  },

  async mounted() {
    const { name, integrationUid } = this.$route.query;
    // Set initial state based on query parameters
    if (name && integrationUid) {
      this.integrationName = name;
      this.integrationUid = integrationUid;
      this.currentStep = 2; // Set step 2 immediately

      // Fetch data in parallel
      await Promise.all([
        this.fetchTestfiestaProjects(),
        name.toLowerCase() === 'jira' ? this.fetchResources() : Promise.resolve(),
      ]);
    } else {
      this.currentStep = 1;
      await this.fetchTestfiestaProjects();
    }
    // 
    if (!this.integrationName && !this.integrationUid) {
      showErrorToast(this.$swal, this.$t('integrations.error.invalidAccess'));
      this.$router.replace({ name: 'integrations' });
      return;
    }

  },

  created() {
    this.integrationName = this.selectedIntegration.name;
    this.integrationDescription = this.selectedIntegration.description;
  },

  methods: {
    getEntityName() {
      const serviceEntity = this.serviceEntities[this.integrationName.toLowerCase()];
      return serviceEntity[0] || 'projects';
    },
    getTestFiestaProjects(config) {
      return Object.keys(config.projects).map(
        (key) => this.testfiestaProjects.find((item) => item.uid === parseInt(key))?.name || null
      );
    },
    getServiceProjects(config) {
      return Object.values(config.projects).flatMap((projectArray) =>
        projectArray.map((project) => project.projectName)
      );
    },
    async fetchResources() {
      try {
        const integrationsService = makeIntegrationsService(this.$api);
        const res = await integrationsService.getOrganizations(this.$route.params.handle, this.integrationUid);
        this.resources = res.data;
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || 'Failed to fetch resources');
      }
    },

    async fetchTestfiestaProjects() {
      try {
        const projectsService = makeProjectsService(this.$api);
        const params = 'status=active&includeCount=true';
        const res = await projectsService.getProjects(this.$route.params.handle, params);
        this.testfiestaProjects = res.data.projects;
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || 'Failed to fetch Testfiesta projects');
      }
    },

    removeImage() {
      this.imageSrc = '';
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
        this.file = file;
      }
    },
    handleFileChange(event) {
      const files = Array.from(event.target.files);

      const validationResult = this.validateMimeTypes(files, projectImageTypes);

      if (!validationResult.valid) {
        showErrorToast(this.$swal, this.$t('integrations.error.fileFormatNotSupported'));
      } else {
        const file = event.target.files[0];

        this.previewImage(file);
      }
    },
    handleBackClick() {
      if (this.currentStep > 1) {
        this.currentStep--;
      } else {
        this.showConfirmBackDialog = true;
      }
    },

    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleConfirmClick() {
      try {
        if (this.$route.query.integrationUid) {
          const integrationsService = makeIntegrationsService(this.$api);
          integrationsService.removeIntegration(this.$route.params.handle, this.$route.query.integrationUid);
          showSuccessToast(this.$swal, 'deleteSuccess');
        }
        this.showConfirmBackDialog = false;
        this.$router.replace({ name: 'integrations' });
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || 'An error occurred');
      }
    },
    getServiceEntityName() {
      const serviceEntity = this.serviceEntities[this.integrationName.toLowerCase()];
      return serviceEntity || ['projects'];
    },
    async handleContinueClick() {
      if (this.currentStep === 1) {
        // If integrationUid exists, just move to next step
        if (this.integrationUid) {
          this.currentStep++;
          return;
        }

        try {
          this.isCreateDisabled = true;
          const integrationsService = makeIntegrationsService(this.$api);

          // Clean the organizationUrl by removing protocol and trailing slash
          const cleanUrl = this.organizationUrl
            .replace(/^(https?:\/\/)/, '') // Remove http:// or https://
            .replace(/\/$/, ''); // Remove trailing slash

          const integrationData = {
            name: this.name,
            description: this.description,
            username: this.username,
            organizationUrl: cleanUrl,
            accessToken: this.accessToken,
            type: 'auth',
            service: this.integrationName.toLowerCase(),
          };

          const response = await integrationsService.createIntegration(this.$route.params.handle, integrationData);

          if (response.data?.uid) {
            this.integrationUid = response.data.uid;
            this.currentStep++;
            showSuccessToast(this.$swal, 'Integration created successfully');
          }
        } catch (err) {
          showErrorToast(this.$swal, err.response?.data?.message || 'Failed to create integration');
        } finally {
          this.isCreateDisabled = false;
        }
      } else if (this.currentStep === 2) {
        await this.createIntegrationObject();
      }
    },
    async createIntegrationObject() {
      this.isCreateDisabled = true;

      // Extract unique project UIDs from all configurations
      const projectUids = [...new Set(this.configurations.flatMap((config) => Object.keys(config.projects)))];

      const data = {
        service: this.integrationName.toLowerCase(),
        configuration: {
          projectConfigurations: this.configurations,
        },
        entityTypes: this.getServiceEntityName(),
        projectUids: projectUids,
      };

      try {
        const integrationsService = makeIntegrationsService(this.$api);
        if (this.integrationUid) {
          await integrationsService.updateIntegration(this.$route.params.handle, this.integrationUid, data);
        } else {
          await integrationsService.updateIntegration(this.$route.params.handle, data);
        }

        showSuccessToast(this.$swal, 'Integration created successfully');
        this.$router.replace({ name: 'integrations' });
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message);
      } finally {
        this.isCreateDisabled = false;
      }
    },
    async handleRedirect() {
      try {
        this.isLoading = true;
        const handle = this.$route.params.handle;
        const integrationService = makeIntegrationsService(this.$api);

        const params = `description=${this.description}&name=${this.name}`;
        const response = await integrationService.getRedirectUrl(handle, this.integrationName.toLowerCase(), params);
        const redirectUrl = response.data?.url || response.data;

        if (!redirectUrl) {
          throw new Error('No redirect URL received from server');
        }

        window.location.href = redirectUrl;
      } catch (error) {
        console.error('Redirect error:', error);
        showErrorToast(this.$swal, error.response?.data?.message || 'Failed to get authorization URL');
      } finally {
        this.isLoading = false;
      }
    },

    async handleConfirmBack() {
      try {
        if (this.currentStep === 1) {
          // Clear OAuth related data
        }

        // Close dialog and redirect
        this.showConfirmBackDialog = false;
        this.$router.replace({ name: 'integrations' });
      } catch (error) {
        showErrorToast(this.$swal, error.response?.data?.message || 'Failed to go back');
      }
    },
    handleConfigurationUpdate({ index, config }) {
      this.configurations[index] = {
        ...this.configurations[index],
        ...config,
      };
    },

    removeConfiguration(index) {
      this.configurations.splice(index, 1);
    },
    editConfiguration(index) {
      this.editConfigData = this.configurations[index];
      this.editConfigIndex = index;
      //  this.configurations.splice(index, 1);
    },

    handleResourceError(error) {
      showErrorToast(this.$swal, error.response?.data?.message || 'Failed to fetch service projects');
    },
    handleAddConfiguration(configData) {
      this.configurations.push(configData);
    },
    handleEditConfiguration(configData) {
      this.configurations[this.editConfigIndex] = configData;
      this.reset();
    },
    reset() {
      this.editConfigData = {};
      this.editConfigIndex = null;
    },
  },
};
</script>
<style scoped>
.back-to-projects {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}
.project-logo {
  border-radius: 50%;
  border: 2px dashed #d0d5dd;
  width: 136px;
  height: 136px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: #f9fafb;
}

.project-logo:hover {
  border-color: #2196f3;
}
.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}
.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}
.search_input {
  width: 100%;
  @media screen and (min-width: 600px) {
    width: 300px;
  }
}
.project-logo {
  border-radius: 8px;
  border: 2px dashed #d0d5dd;
  width: 438px;
  height: 136px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: #f9fafb;
}

.project-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}

.edit-icon,
.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}
.delete-icon {
  margin-left: 60px;
}

.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}
.create-project-layout {
  width: 100%;
}
.create-project-height {
  min-height: calc(100vh - 24px - 6.25rem);
  height: 100%;
}
.add-config-button {
  height: 40px;
  border-radius: 8px;
  text-transform: none;
  font-weight: 500;
  font-size: 14px;
  padding: 0 16px;
}

.add-config-button:not(:disabled) {
  border-color: #d0d5dd;
  color: #344054;
}

.add-config-button:hover:not(:disabled) {
  background-color: #f9fafb !important;
  border-color: #d0d5dd;
}
.configuration-item {
  background-color: #f9fafb;
  border-radius: 6px;
  padding: 8px 12px;
  margin-bottom: 8px;
}

.configuration-name {
  font-size: 14px;
  color: #344054;
  font-weight: 500;
}

.configuration-item:hover {
  background-color: #f2f4f7;
}

.icon-delete {
  opacity: 0.7;
  cursor: pointer;
  color: #667085;
}

.icon-delete:hover {
  opacity: 1;
}

.progress-container {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #f2f4f7;
  margin-bottom: 20px;
}

.progress-bar {
  position: absolute;
  height: 100%;
  background-color: #0c2ff3;
  transition: width 0.3s ease;
}

.progress-text {
  position: absolute;
  right: 16px;
  top: 8px;
  font-size: 14px;
  color: #344054;
}

.redirect-section {
  margin: 24px 0;
}

.redirect-btn {
  font-size: 14px !important;
  letter-spacing: 0.5px;
  background-color: #0c2ff3 !important;
}

.divider-text {
  position: relative;
  color: #667085;
  font-size: 14px;
  margin: 16px 0;
}

.divider-text::before,
.divider-text::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #e4e7ec;
}

.divider-text::before {
  left: 0;
}

.divider-text::after {
  right: 0;
}

.divider-text span {
  position: relative;
  background: white;
  padding: 0 16px;
  z-index: 1;
}
</style>

