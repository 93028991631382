<template>
  <div>
    <v-sheet
      color="#F2F4F7"
      class="d-flex align-center justify-center pointer"
      height="40px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-3 py-2 d-flex flex-row">{{ $t('filters') }} <v-icon
        size="16px"
        class="ml-2"
      >mdi-filter-variant</v-icon></span>
    </v-sheet>

    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>

          <v-expansion-panels
            v-model="statusPanel"
            flat
            class="mb-5"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('status') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(role, index) in roles"
                  :key="index"
                >
                  <v-checkbox
                    v-model="selectedRoles"
                    dense
                    :value="role"
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <span class="fs-14px text-theme-label">{{ role }}</span>
                    </template>
                  </v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div>
            <div class="text-start">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('numberOfTestRuns') }}
              </v-label>
            </div>
            <v-range-slider
              v-model="testrun"
              class="slider-theme"
              color="blue"
              track-color="#F2F4F7"
              thumb-color="#FFFFFF"
              hide-details
            />
            <div class="d-flex align-center">
              <v-text-field
                v-model="testrun[0]"
                background-color="#F9F9FB"
                class="field-theme pt-0"
                height="38"
                hide-details
                dense
              />
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-text-field
                v-model="testrun[1]"
                background-color="#F9F9FB"
                class="field-theme pt-0"
                height="38"
                hide-details
                dense
              />
            </div>
          </div>

          <div>
            <div class="text-start mt-4">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('numberOfTestCases') }}
              </v-label>
            </div>
            <v-range-slider
              v-model="testcases"
              class="slider-theme"
              color="blue"
              track-color="#F2F4F7"
              thumb-color="#FFFFFF"
              hide-details
            />
            <div class="d-flex align-center">
              <v-text-field
                v-model="testcases[0]"
                background-color="#F9F9FB"
                class="field-theme pt-0"
                height="38"
                hide-details
              />
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-text-field
                v-model="testcases[1]"
                background-color="#F9F9FB"
                class="field-theme pt-0"
                height="38"
                hide-details
              />
            </div>
          </div>

          <div class="text-start pt-6">
            <div class="text-start">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('Start Date') }}
              </v-label>
            </div>
            <div class="d-flex align-center">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                    :value="startDate"
                    background-color="#F9F9FB"
                    height="38"
                    hide-details
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <calendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @change="startDateMenu = false"
                />
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#F9F9FB"
                    class="text-field mt-0 field-theme pa-0 rounded-lg custom-prepend"
                    :value="endDate"
                    height="40"
                    hide-details
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <calendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @change="endDateMenu = false"
                />
              </v-menu>
            </div>
            <div class="text-start mt-4">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('Due Date') }}
              </v-label>
            </div>
            <div class="d-flex align-center">
              <v-menu
                v-model="dueStartDateMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="text-field mt-0 field-theme pa-0 rounded-lg custom-prepend"
                    :value="dueStartDate"
                    background-color="#F9F9FB"
                    height="38"
                    hide-details
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <calendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dueStartDate"
                  @change="dueStartDateMenu = false"
                />
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-menu
                v-model="dueEndDateMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                    :value="dueEndDate"
                    background-color="#F9F9FB"
                    height="38"
                    hide-details
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <calendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dueEndDate"
                  @change="dueEndDateMenu = false"
                />
              </v-menu>
            </div>

            <div>
              <div class="text-start mt-4">
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('Progress') }}
                </v-label>
              </div>
              <v-range-slider
                v-model="users"
                class="slider-theme"
                color="blue"
                track-color="#F2F4F7"
                thumb-color="#FFFFFF"
                hide-details
              />
              <div class="d-flex align-center">
                <v-text-field
                  v-model="users[0]"
                  background-color="#F9F9FB"
                  class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                  height="38"
                  dense
                  hide-details
                />
                <div class="mx-4 font-weight-bold text-h6">
                  -
                </div>
                <v-text-field
                  v-model="users[1]"
                  background-color="#F9F9FB"
                  class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                  height="38"
                  dense
                  hide-details
                />
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="clearAll"
        >
          {{ $t('clearAll') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          elevation="0"
          @click="apply"
        >
          {{ $t('apply') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import calendarBlueIcon from '@/assets/svg/calendar-blue.svg';
export default {
  name: "MilestoneFilter",
  components: {
    calendarBlueIcon,
  },
  data()
  {
    return {
      showDialog: false,
      roles: ["Active", "Upcoming", "Blocked", "Past due"],
      selectedRoles: [],
      testrun: [0, 100],
      testcases: [0, 100],
      users: [0, 100],
      startDate: null,
      endDate: null,
      dueStartDate: null,
      dueEndDate: null,
      startDateMenu: false,
      endDateMenu: false,
      dueStartDateMenu: false,
      dueEndDateMenu: false,
      statusPanel: 0,
    };
  },
  methods: {
    apply()
    {
      this.$emit("applyFilters", {
        selectedRoles: this.selectedRoles,
        testRuns: this.testrun,
        testCases: this.testcases,
        users: this.users,
        dateRange: {
          start: this.startDate,
          end: this.endDate,
        },
        dueDateRange: {
          start: this.dueStartDate,
          end: this.dueEndDate,
        },
      });
      this.showDialog = false;
    },
    clearAll()
    {
      this.selectedRoles = [];
      this.testrun = [0, 100];
      this.testcases = [0, 100];
      this.users = [0, 100];
      this.startDate = null;
      this.endDate = null;
      this.dueStartDate = null;
      this.dueEndDate = null;
    },
  },
};
</script>

<style>
.v-dialog--fullscreen {
  max-height: 100vh !important;
  width: 485px !important;
  right: 0 !important;
  left: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>