<template>
  <div class="d-flex flex-row align-center justify-space-between">
    <v-menu
      open-on-hover
      bottom
      offset-y
      max-width="150px"
      content-class="view-executions-menu"
      z-index="100"
    >
      <template v-slot:activator="{ on }">
        <div
          class="progress-linear"
          v-on="on"
        >
          <div
            class="progress-linear-bar"
            :style="{backgroundColor: percentage == 100 ? 'transparent' : '#EAECF0'}"
          >
            <div
              v-for="(status, index) in executions"
              :key="index"
              class="partial"
              :style="{width: `${status.count/caseCount * 100}%`,
                       'background-color': getStatusColor(status.id, statuses)
              }"
            />
          </div>
          <span class="font-weight-regular ml-3">{{ safePercentage(percentage) }}%</span>
        </div>
      </template>
      <v-list>
        <v-list-item
          v-for="(status, index) in executions"
          :key="index"
          dense
          height="35px"
        >
          <v-list-item-title class="text-left">
            <span
              class="text-capitalize"
              :style="{color: getStatusColor(status?.id, statuses)}"
            >{{ status.name }} {{ status.count }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
  import colorPreferencesMixin from '@/mixins/colorPreferences';
  export default{
    mixins: [colorPreferencesMixin],
    props:{
      executions:{
        type: [Object, Array],
        required: true
      },
      caseCount:{
        type: Number,
        default: 0
      },
      percentage:{
        default: 0
      }
    },
    data(){
      return {
        statuses: []
      }
    },
    created(){
      this.statuses = this.getStatuses("testRun");
    },
    methods:{
      safePercentage(value) {
        const percentage = value;
        return (typeof percentage === 'string' && percentage !== 'NaN') ? percentage : '0';
      },
    }
  }
</script>