<template>
  <v-card
    class="white py-6 px-6 mt-3"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex align-center justify-space-between">
      <h2 class="text-theme-base">
        {{ $t('myWorkspace') }}
      </h2>
      <div
        v-if="progressCalculation"
      >
        <progress-bar
          :executions="progressCalculation?.progress"
          :percentage="progressCalculation?.percentage"
          :case-count="progressCalculation?.length"
        />
      </div>
    </div>
    <div class="mt-4 d-flex justify-start">
      <div class="filter-chips">
        <v-chip 
          :class="{ 'blue--text': filter === 'todo', 
                    'text-theme-secondary': filter !== 'todo'
          }" 
          class="mr-2"
          width="200px" 
          :color="filter === 'todo' ? '#e6ecff' : '#f9fafb'"
          label 
          @click="$emit('updateView', 'todo')"
        >
          <div
            class="px-2"
            :class="[filter === 'todo' ? 'fw-semibold' : 'font-weight-medium']"
          >
            {{ $t('todo') }} <span class="ml-2">{{ todoCount }}</span>
          </div>
        </v-chip>
        <v-chip 
          :class="{ 'blue--text': filter === 'completed', 
                    'text-theme-secondary': filter !== 'completed'
          }" 
          width="200px" 
          :color="filter === 'completed' ? '#e6ecff' : '#f9fafb'"
          label 
          @click="$emit('updateView', 'completed')"
        >
          <div
            class="px-2"
            :class="[filter === 'completed' ? 'fw-semibold' : 'font-weight-medium']"
          >
            {{ $t('completed') }} <span class="ml-2">{{ completedCount }}</span>
          </div>
        </v-chip>
      </div>
    </div>
    <div class="__workspace_filters">
      <v-row class="mt-2">
        <v-col>  
          <v-autocomplete
            v-model="selectedProjects"
            type="text"
            dense
            single-line
            filled
            :placeholder="$t('allProjects')"
            hide-details
            small-chips
            hide-selected
            multiple
            :items="projects"
            deletable-chips
            background-color="#F9F9FB"
            append-icon="mdi-chevron-down"
            item-value="uid"
            item-text="name"
            class="rounded-lg"
            clear-icon="body-2"
            @change="onProjectsChange"
          /> 
        </v-col>
        <v-col> 
          <v-autocomplete
            v-model="selectedMilestones"
            type="text"
            dense
            single-line
            filled
            :placeholder="$t('allMilestones')"
            hide-details
            small-chips
            deletable-chips
            hide-selected
            background-color="#F9F9FB"
            append-icon="mdi-chevron-down"
            multiple
            :items="milestones"
            item-value="uid"
            item-text="name"
            class="rounded-lg"
            clear-icon="body-2"
            @change="onMilestonesChange"
          />  
        </v-col>
        <v-col>  
          <v-autocomplete
            v-model="selectedPlans"
            type="text"
            dense
            single-line
            filled
            :placeholder="$t('allPlans')"
            hide-details
            deletable-chips
            small-chips
            hide-selected
            background-color="#F9F9FB"
            append-icon="mdi-chevron-down"
            multiple
            :items="testPlans"
            item-value="uid"
            item-text="name"
            class="rounded-lg"
            clear-icon="body-2"
            @change="onPlansChange"
          /> 
        </v-col>
        <v-col>   
          <v-autocomplete
            v-model="selectedRuns"
            type="text"
            dense
            single-line
            filled
            deletable-chips
            :placeholder="$t('allRuns')"
            hide-details
            small-chips
            hide-selected
            multiple
            :items="runs"
            item-value="uid"
            item-text="name"
            class="rounded-lg"
            clear-icon="body-2"
            background-color="#F9F9FB"
            append-icon="mdi-chevron-down"
          />
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col class="d-flex">     
          <v-btn
            color="blue"
            class="white--text mr-2"
            dense
            @click="updateFilter"
          >
            <v-icon>mdi-magnify</v-icon>
            <span class="mx-2">{{ $t('search') }}</span>
          </v-btn>
          <v-btn
            dense
            @click="resetFilters"
          >
            <span class="mx-2">{{ $t('reset') }}</span>
          </v-btn>
        </v-col>
      </v-row> -->
    </div>
  </v-card>
</template>

<script>
import ProgressBar from '@/components/base/ProgressBar';

export default {
  components:{
    ProgressBar,
  },
  props: {
   filter: {
     type: String,
     default: '',
   },
   todoCount: {
     type: Number,
     default: 0,
   },
   completedCount: {
     type: Number,
     default: 0, 
   },
   filterData: {
     type: Object,
     default: () => ({}),
   },
   progressCalculation: {
     type: Object,
     default: () => ({}), 
   }
  },
  data(){
    return{
      projects: this.filterData.projects,
      selectedProjects: [],
      selectedMilestones: [],
      selectedRuns: [],
      selectedPlans: [],
    }
  },
  computed:{
    milestones(){
      return this.filterData.milestones.filter(element => this.selectedProjects.includes(element.projectUid));
    },
    testPlans() {
      return this.filterData.testPlans.filter(testPlan => {
      const hasMatchingMilestone = this.selectedMilestones.length > 0 && testPlan.milestoneUids.some((item) => this.selectedMilestones.includes(item.uid));
      const hasMatchingProject = this.selectedProjects.length > 0 && this.selectedProjects.includes(testPlan.projectUid);  
      if (hasMatchingMilestone && hasMatchingProject) {
         return testPlan
      }
     });
    },
    runs(){
      return this.filterData.testRuns.filter(run => {
        const hasMatchingPlan = this.selectedPlans.length > 0 && this.selectedPlans.includes(run.testPlanUid);
        const hasMatchingMilestone = this.selectedMilestones.length > 0 && run.milestoneUids.some((item) => this.selectedMilestones.includes(item.uid));
        const hasMatchingProject = this.selectedProjects.length > 0 && this.selectedProjects.includes(run.projectUid);
        if (hasMatchingPlan && hasMatchingMilestone && hasMatchingProject) {
          return run;
        }
      });
    }
  },
  methods: {
    onProjectsChange(selectedItems) {
      this.selectedMilestones = this.filterData.milestones.filter(milestone => this.selectedMilestones.includes(milestone.uid) && selectedItems.includes(milestone.projectUid)).map(item => item.uid);
      this.selectedPlans = this.selectedPlans.length > 0 && this.filterData.testPlans.filter(plan => this.selectedPlans.includes(plan.uid) && plan.milestoneUids.some((item) => this.selectedMilestones.includes(item.uid))).map(item => item.uid);
      this.selectedRuns = this.selectedRuns.length > 0 && this.filterData.testRuns.filter(run => this.selectedRuns.includes(run.uid) && this.selectedPlans.includes(run.testPlanUid)).map(item => item.uid);
      this.updateFilter();
    },
    onMilestonesChange(selectedItems) {
      this.selectedPlans = this.selectedPlans.length > 0 && this.filterData.testPlans.filter(plan => this.selectedPlans.includes(plan.uid) && plan.milestoneUids.some((item) => selectedItems.includes(item.uid))).map(item => item.uid);
      this.selectedRuns = this.selectedRuns.length > 0 && this.filterData.testRuns.filter(run => this.selectedRuns.includes(run.uid) && this.selectedPlans.includes(run.testPlanUid)).map(item => item.uid);
      this.updateFilter();
    },
    onPlansChange(selectedItems) {
      this.selectedRuns = this.selectedRuns.length > 0 && this.filterData.testRuns.filter(run => this.selectedRuns.includes(run.uid) && selectedItems.includes(run.testPlanUid)).map(item => item.uid);
      this.updateFilter();
    },
    updateFilter() {
      this.$emit('update-filters', {
        projects: this.selectedProjects.length ? this.selectedProjects : undefined ,
        milestones: this.selectedMilestones.length ? this.selectedMilestones : undefined,
        testPlanUids: this.selectedPlans ? this.selectedPlans : undefined,
        testRuns: this.selectedRuns.length ? this.selectedRuns : undefined,
      });
    },
    resetFilters(){
      this.selectedMilestones = []
      this.selectedPlans = []
      this.selectedRuns = []
      this.selectedProjects = []
      
      this.updateFilter();
    },
  }
}
</script>
<style>
.__workspace_filters .row .col .v-select__selections input{
  min-height: 46px !important;
}
.filter-item-close {
  cursor: pointer;
}
</style>