<template>
  <div class="pl-3 pt-3">
    <Header />
    <Loader v-if="loaderState" />
    <v-card
      v-else
      class="py-6 px-6 mt-3 app-height-global"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-row
        justify="space-between"
        class="align-center"
      >
        <v-col
          cols="12"
          md="auto"
          class="d-flex align-center"
        >
          <SearchComponent
            :search="filter.name"
            :placeholder="$t('placeHolder.searchByName')"
            class="mr-2"
            @update:search="filter.name = $event"
          />

          <FilterDialog
            :data="filter"
            :priorities="priorities"
            :statuses="statuses"
            @update-filter-condition="updateFilterCondition"
          />
        </v-col>

        <v-col
          cols="12"
          md="auto"
        >
          <v-row
            justify="end"
            class="align-center"
          >
            <SettingsMenu 
              class="mr-3"
              table-type="projectsDefect"
            />
          </v-row>
        </v-col>
      </v-row>

      <DefectTable
        :headers="filteredHeaders"
        :items="filteredDefects"
        item-key="uid"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :total-items-length="totalItems"
        :priorities="priorities"
        :statuses="statuses"
        @view="onViewDefect"
        @edit="onEditDefect"
        @update:options="handlePageChange"
      />

      <ViewDefectDialog
        v-model="showDetailDialog"
        :data="selectedDefect"
        @add-comment="onAddNewComment"
        @close-dialog="showDetailDialog = false"
      />

      <EditDialog
        v-model="showEditDialog"
        :data="selectedDefect"
        :priorities="priorities"
        :statuses="statuses"
        :loading="editLoading"
        @edit="editDefect"
        @close-dialog="showEditDialog = false"
      />
    </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters, mapActions  } from 'vuex';
import * as _ from 'lodash'

import makeDefectService from '@/services/api/defect';
import makeTagService from '@/services/api/tag';
import loaderMixin from '@/mixins/loader';
import Header from '@/components/Defect/Header';
import FilterDialog from '@/components/Defect/FilterDialog.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import DefectTable from '@/components/Defect/Table.vue';
import ViewDefectDialog from '@/components/Defect/ViewDefectDialog.vue';
import EditDialog from '@/components/Defect/EditDialog.vue';
import {showSuccessToast,showErrorToast} from '@/utils/toast';
import Loader from '@/components/base/Loader.vue';
const { mapState } = createNamespacedHelpers('user');

export default {
  name: 'Defects',
  
  components: {
    Header,
    SearchComponent,
    SettingsMenu,
    FilterDialog,
    DefectTable,
    ViewDefectDialog,
    EditDialog,
    Loader
  },

  mixins: [loaderMixin],
  data() {
    return {
      filter: {
        name: '',
        priorities: [],
        statuses: [],
        startDate: null,
        endDate: null,
      },
      originalDefects: [],
      filteredDefects: [],
      headers: [],
      defectService: null,
      showDetailDialog: false,
      showEditDialog: false,
      selectedDefect: {
        uid: '',
        id: '',
        name: '',
        integration: '',
        priority: '',
        assignedTo: '',
        creator: '',
        status: '',
        updatedAt: null,
        comments: [],
      },
      totalItems: 0,
      page: 1,
      itemsPerPage: 10,
      priorities: [],
      statuses: [],
      editLoading: false,
      systemTags: [],
    }
  },

  computed: {
    ...mapState(['currentAccount']),
    ...mapGetters({
      dynamicHeaders: 'headers/dynamicHeaders'
    }),
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.value != 'actions');
      return filtered;
    },
    filteredHeaders() {
      const filtered = this.filteredMenuHeaders.filter((header) => header.checked);
      return filtered;
    },
    projectId() {
      return this.$route.params.key;
    }
  },

  watch: {
    'filter.name': {
      handler: _.debounce(function () {
        this.filterDefects()
      }, 500),
    },
  },

  created() {
    if(!this.dynamicHeaders.projectsDefect) {
      this.initializeHeaders({ type: 'projectsDefect' });
    }
    this.headers = this.dynamicHeaders.projectsDefect;
  },

  mounted() {
    this.defectService = makeDefectService(this.$api);
    this.init([
      this.getDefectPriorities(),
      this.getDefectStatuses(),
      this.getDefectTags(),
      this.getDefects()
    ]);
  },

  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    async getDefects() {
      try {
        const response = await this.defectService.getDefects(
          this.currentAccount.handle, 
          this.projectId,
          {
            page: 1,
            limit: 9999
          }
        );
        
        this.originalDefects = response.data.data.map(defect => ({
          uid: defect.uid,
          id: `${this.projectId}-${defect.uid}`,
          name: defect.name,
          integration: defect.integration,
          priority: defect.priority,
          assignedTo: defect.customFields?.assignee?.displayName || '',
          creator: defect.creator,
          status: defect.status,
          updatedAt: defect.customFields?.updated ? new Date(defect.customFields.updated) : new Date(defect.updatedAt),
          comments: defect.customFields?.comments || [],
          description: defect.customFields?.description?.content?.[0]?.content?.[0]?.text || '',
          customFields: {
            ...defect.customFields,
            tags: defect.customFields?.tags?.map(tagId => {
              const tag = this.systemTags.find(t => Number(t.uid) === Number(tagId));
              return tag ? {
                id: tag.uid,
                name: tag.name,
                color: tag.customFields?.color
              } : null;
            }).filter(Boolean) || []
          },
          projectUid: defect.projectUid,
        }));

        this.totalItems = this.originalDefects.length;
        this.filterDefects();
        showSuccessToast(this.$swal, this.$t('defect.fetchSuccess'));
      } catch (err) {
        this.errorMessage = err.message;
        showErrorToast(this.$swal, this.$t('defect.fetchError'));
      }
    },

    async refreshDefects() {
      await this.getDefects();
    },

    onViewDefect(item) {
      if (this.showDetailDialog || this.showEditDialog) {
        return;
      }

      this.selectedDefect = {
        uid: item.uid,
        id: item.id,
        name: item.name,
        integration: item.integration,
        priority: item.priority,
        assignedTo: item.assignedTo,
        creator: item.creator,
        status: item.status,
        updatedAt: item.updatedAt,
        comments: item.comments,
        description: item.description,
        customFields: item.customFields,
        projectUid: item.projectUid,
      };

      this.showDetailDialog = true;
    },

    async onAddNewComment(comment) {
      try {
        const response = await this.defectService.addComment(this.currentAccount.handle, this.projectId, {
          uid: this.selectedDefect.uid,
          comment,
        });
        this.originalDefects = this.originalDefects.map(item => {
          if (item.uid !== this.selectedDefect.uid) {
            return item
          }

          item.comments.splice(0, 0, response.data)
          this.selectedDefect.comments.splice(0, 0, response.data)

          return item
        })
      } catch (err) {
        this.errorMessage = err.message;
      }

      this.filterDefects();
    },

    onEditDefect(item) {
      this.selectedDefect = {
        uid: item.uid || '',
        id: item.id || '',
        name: item.name || '',
        integration: item.integration || '',
        priority: item.priority || '',
        assignedTo: item.assignedTo || '',
        creator: item.creator || '',
        status: item.status || '',
        updatedAt: item.updatedAt || null,
        comments: item.comments || [],
      }

      this.showEditDialog = true
    },

    async editDefect(item) {
      try {
        this.editLoading = true;
        await this.defectService.updateDefect(this.currentAccount.handle, this.projectId, item)
        showSuccessToast(this.$swal, this.$t('defect.success'));
        this.showEditDialog = false
        await this.getDefects();
      } catch (err) {
        this.errorMessage = err.message;
        showErrorToast(this.$swal, this.$t('defect.error'));
      } finally {
        this.editLoading = false;
        this.filterDefects();
      }
    },

    updateFilterCondition(data) {
      this.filter = {
        ...this.filter,
        priorities: data.priorities,
        statuses: data.statuses,
        startDate: data.startDate,
        endDate: data.endDate,
      }

      this.filterDefects()
    },

    filterDefects() {
      let filteredDefects = _.cloneDeep(this.originalDefects)
      if (this.filter.name) {
        filteredDefects = filteredDefects.filter(item => item.name && item.name.toLowerCase().includes(this.filter.name.toLowerCase()))
      }

      if (this.filter.priorities.length > 0) {
        filteredDefects = filteredDefects.filter(item => this.filter.priorities.includes(Number(item.priority)))
      }

      if (this.filter.statuses.length > 0) {
        filteredDefects = filteredDefects.filter(item => this.filter.statuses.includes(Number(item.status)))
      }

      if (this.filter.startDate) {
        filteredDefects = filteredDefects.filter(item => item.updatedAt >= this.filter.startDate)
      }

      if (this.filter.endDate) {
        filteredDefects = filteredDefects.filter(item => item.updatedAt <= this.filter.endDate)
      }

      this.filteredDefects = filteredDefects
    },

    handlePageChange(options) {
      let fetch = false;
      
      // Check if page changed
      if (options?.page !== this.page) {
        this.page = options.page;
        fetch = true;
      }

      // Check if items per page changed
      if (options?.itemsPerPage !== this.itemsPerPage) {
        this.itemsPerPage = options.itemsPerPage;
        this.page = 1; // Reset to first page when changing items per page
        fetch = true;
      }

      if (fetch) {
        this.init([this.getDefects()]);
      }
    },

    async getDefectPriorities() {
      try {
        const response = await this.defectService.getDefectPriorities(
          this.currentAccount.handle,
          this.projectId
        );
        this.priorities = response.data;
      } catch (err) {
        console.error('Error fetching priorities:', err);
        showErrorToast(this.$swal, this.$t('defect.fetchPrioritiesError'));
      }
    },

    async getDefectStatuses() {
      try {
        const response = await this.defectService.getDefectStatuses(
          this.currentAccount.handle,
          this.projectId
        );
        this.statuses = response.data;
      } catch (err) {
        console.error('Error fetching statuses:', err);
        showErrorToast(this.$swal, this.$t('defect.fetchStatusesError'));
      }
    },

    async getDefectTags() {
      try {
        const tagService = makeTagService(this.$api);
        const response = await tagService.getTags(
          this.currentAccount.handle,
          'defects'
        );
        this.systemTags = response.data;
      } catch (err) {
        console.error('Error fetching defect tags:', err);
        showErrorToast(this.$swal, this.$t('defect.fetchTagsError'));
      }
    },

    getTagInfo(tagId) {
      const tag = this.systemTags.find(t => t.uid === tagId);
      return tag ? {
        id: tag.uid,
        name: tag.name,
        color: tag.color
      } : null;
    }
  }
}
</script>
