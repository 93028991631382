<template>
  <v-dialog
    v-model="showDialog"
    class="dialog-theme"
    transition="slide-x-transition"
    attach
    fullscreen
    width="485px"
    persistent
  >
    <v-card>
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ $t('billing.addPaymentMethod') }}
          </h2>
          <v-btn
            icon
            @click="$emit('close-dialog')"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
          class="mt-5"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-4"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('billing.card') }}
              </p>
              <div
                id="card-element"
                class="ElementsApp"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0 mt-3"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('firstName') }}
              </p>
              <v-text-field
                v-model="paymentInfo.firstName"
                type="text"
                dense
                filled
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0 pt-1"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('lastName') }}
              </p>
              <v-text-field
                v-model="paymentInfo.lastName"
                type="text"
                dense
                filled
                :rules="requiredRules"
              />
            </v-col>

        

            <v-col
              cols="12"
              class="pb-0 mt-5"
            >
              <p class="font-weight-bold body-2 text-left mb-1">
                {{ $t('billing.billingAddress') }}
              </p>
            </v-col>

            <v-col
              cols="12"
              class="pb-0 pt-1 mt-2"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('address.country') }}
              </p>
              <v-select
                v-model="paymentInfo.country"
                :items="countryList"
                dense
                filled
                item-text="name"
                item-value="code"
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0 pt-1"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('address.addressLine', { index: 1 }) }}
              </p>
              <v-text-field
                v-model="paymentInfo.streetAddress"
                type="text"
                dense
                filled
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0 pt-1"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('address.addressLine', { index: 2 }) }}
              </p>
              <v-text-field
                v-model="paymentInfo.streetAddress2"
                type="text"
                dense
                filled
              />
            </v-col>

            <v-col
              cols="6"
              class="pb-0 pt-1"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('address.city') }}
              </p>
              <v-text-field
                v-model="paymentInfo.city"
                type="text"
                dense
                filled
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="6"
              class="pb-0 pt-1"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('address.postalCode') }}
              </p>
              <v-text-field
                v-model="paymentInfo.postalCode"
                type="text"
                dense
                filled
                :rules="requiredRules"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <div class="actions-container d-flex justify-space-between">
      <v-btn
        width="204.5px"
        color="#F2F4F7"
        height="40"
        :depressed="true"
        class="text-capitalize btn-theme"
        elevation="0"
        @click="$emit('close-dialog')"
      >
        {{ $t('cancel') }}
      </v-btn>

      <v-btn
        width="204.5px"
        class="btn-theme"
        height="40"
        color="primary"
        :depressed="true"
        elevation="0"
        :disabled="!validForm || loading"
        :loading="loading"
        @click="handleSubmit"
      >
        {{ $t('save') }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { countryList } from '@/constants/address';
import makeBillingService from '@/services/api/billing';
import { showSuccessToast, showErrorToast } from '@/utils/toast';

export default {
  name: 'AddPaymentMethod',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    model: {
      type: String,
      default: 'user',
    },
    uid: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      validForm: false,
      paymentInfo: {
        firstName: '',
        lastName: '',
        country: '',
        streetAddress: '',
        streetAddress2: '',
        city: '',
        postalCode: '',
      },
      countryList: countryList,
      cardElement: null,
      billingService: null,
      loading: false,
      error: null,
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },

  watch: {
    showDialog(value) {
      if (!value || this.cardElement) {
        return
      }

      setTimeout(() => {
        const style = {
          base: {
            backgroundColor: '#f9f9fb',
            padding: '11px 10px',
            borderRadius: '4px',
            fontFamily: 'Avenir, "Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#9f9f9f',
            },
          },
        };

        this.cardElement = this.$stripe.elements().create('card', { style });
        this.cardElement.mount('#card-element');
      }, 100)
    },
  },

  beforeDestroy () {
    this.cardElement?.destroy();
  },

  mounted() {
    this.billingService = makeBillingService(this.$api);
  },

  methods: {
    async handleSubmit() {
      if (!this.validForm) return;
      
      try {
        this.loading = true;
        this.error = null;


        const { data: { client_secret } } = await this.billingService.addPaymentMethod({
          model: this.model,
          uid: this.uid,
        });


        const { error: submitError } = await this.$stripe.elements().submit();
        if (submitError) {
          throw new Error(submitError.message);
        }


        const payment_method = {
          card: this.cardElement,
            billing_details: {
              name: `${this.paymentInfo.firstName} ${this.paymentInfo.lastName}`,
              address: {
                line1: this.paymentInfo.streetAddress,
                line2: this.paymentInfo.streetAddress2,
                city: this.paymentInfo.city,
                postal_code: this.paymentInfo.postalCode,
                country: this.paymentInfo.country,
              },
            },
        }


        const { setupIntent, error } = await this.$stripe.confirmCardSetup(client_secret,  { payment_method });

        if (error) {
          throw new Error(error.message);
        }

        showSuccessToast(this.$swal, 'createSuccess', { item: 'Payment method' });
        this.showDialog = false;
        this.$emit('payment-method-added', setupIntent.payment_method);
      } catch (err) {
        showErrorToast(this.$swal, 'createError', { item: 'Payment method', message: err.message });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<style lang="scss">
.ElementsApp{

    background-color: #f9f9fb;
    padding: 11px 10px;
    border-radius: 4px;

}
.payment-method {
  #card-number,
  #card-cvc,
  #card-expiry {
    padding: 11px 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
  }
}

.dialog-theme {
  .actions-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background: white;

  }

  .btn-theme {
    font-weight: 600;
    border-radius: 8px;
    text-transform: none !important;
  }
}
</style>