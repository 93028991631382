<template>
  <div class="dashboard__analytics-card d-flex py-5">
    <div class="analytics-card-icon">
      <div class="v-icon-wraper align-center">
        <component
          :is="types[data.entityType].icon"
          :stroke-width="2"
        />
      </div>
    </div>
    <div class="analytics-card-text">
      <h3>{{ data.value }}</h3>
      <p>{{ types[data.entityType].name }}</p>
    </div>
  </div>
</template>
<script>

import DefectIcon from '@/assets/svg/left-menu/defect.svg';
import MilestoneIcon from '@/assets/svg/left-menu/milestone.svg';
import TestPlansIcon from '@/assets/svg/left-menu/test-plans.svg';
import TestRunsIcon from '@/assets/svg/left-menu/test-runs.svg';
import TestCasesIcon from '@/assets/svg/left-menu/test-cases.svg';
import TestProgramsIcon from '@/assets/svg/left-menu/test-programs.svg'

export default{
  components:{
    DefectIcon,
    MilestoneIcon,
    TestPlansIcon,
    TestRunsIcon,
    TestCasesIcon,
    TestProgramsIcon
  },
  props:{
    data: {
      required: true,
      type: Object,
    },
  },
  data(){
    return{
      types: {
        "testCase": {
          icon: 'TestCasesIcon',
          name: 'Test cases'
        },
        "testRun": {
          icon: 'TestRunsIcon',
          name: 'Test runs'
        },
        "defect": {
          icon: 'DefectIcon',
          name: 'Defects'
        },
        'milestone': {
          icon: 'MilestoneIcon',
          name: 'Milestones'
        },
        'testPlan': {
          icon: 'TestPlansIcon',
          name: 'Test plans',
        },
        'testProgram': {
          icon: 'TestProgramsIcon',
          name: 'Test programs'
        }
      }
    }
  }
}
</script>
<style scoped>
.dashboard__analytics-card{
  background-color: #FFF;
  width: 100%;
  padding: 0px 0px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.v-icon-wraper{
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #F2F4F7;
  display: flex;
  justify-content: center;
  align-content: center;
}
.v-icon-wraper i{
  color: #0C2FF3;
}
.analytics-card-text h3{
  font-size: 24px;
}
.analytics-card-text p{
  margin: 0px !important;
  color: #667085;
}
.v-icon-wraper svg path,
.v-icon-wraper svg path {
  stroke: #0c2ff3 !important;
  stroke-width: 2px;
}
</style>