<template>
  <div
    id="case-index-container"
    class="h-full d-flex flex-column justify-start font-inter align-self-stretch pt-3"
    fluid
  >
    <RunAddCaseView
      v-if="showCases"
      v-model="selectedCases"
      :back-title="$t('testruns.create_testrun.rerunTestRun')"
      @back="onBackShowCases"
      @close="onBackShowCases"
    >
      <template v-slot:action>
        <v-btn
          color="primary"
          depressed
          class="f-color-white ml-2 btn-theme text-capitalize"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
          height="38px"
          :loading="isLoading"
          @click="createTestRuns"
        >
          {{ $t('save') }}
        </v-btn>
      </template>
    </RunAddCaseView>
    <template v-else>
      <RunHeader
        is-duplicate
        :title="$t('testRuns')"
        :action-text="$t('testruns.createTestRun')"
        :active_label="$t('testruns.active')"
        :archived_label="$t('testruns.archived')"
        :btn_show="!isFilterArchived"
        :filter="filter"
        :ongoing-item-count="activeItemCount"
        :archived-item-count="archivedItemCount"
        @update-filter="updateFilter"
      />
      <Loader v-if="loaderState" />
      
      <template v-else>  
        <v-container
          class="d-flex flex-column align-self-stretch"
          fluid
        >
          <div class="row">
            <div
              class="pa-0"
              :style="{ width: isProjectMenuCollapsed ? '5%' : '15%' }"
            >
              <div
                class="white card d-flex flex-column justify-space-between pa-3 rounded-lg mr-3 sticky-on-scroll"
              >
                <v-list 
                  v-if="isProjectMenuCollapsed"
                  nav
                  class="pa-0"
                >
                  <v-list-item-group color="primary">
                    <v-list-item 
                      class="d-flex justify-center" 
                      :ripple="false"
                      @click="searchCollapsedMenu"
                    >
                      <v-list-item-icon class="justify-center mx-0">
                        <SearchIcon />
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item
                      class="d-flex justify-center"
                      :ripple="false"
                      @click="unlinkedCollapsedMenu"
                    >
                      <v-list-item-icon class="justify-center mx-0">
                        <UnlinkedIcon24 />
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item
                      class="d-flex justify-center"
                      :ripple="false"
                      @click="linkedCollapsedMenu"
                    >
                      <v-list-item-icon class="justify-center mx-0">
                        <LinkedIcon24 />
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>  
                <div
                  v-if="!isProjectMenuCollapsed"
                  class="d-flex flex-column"
                >
                  <v-text-field
                    ref="searchField"
                    :placeholder="$t('search')"
                    background-color="#F9F9FB"
                    class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0 mb-3 flex-inherit"
                    height="38"
                    dense
                    hide-details
                  >
                    <template v-slot:prepend-inner>
                      <SearchIcon />
                    </template>
                  </v-text-field>

                  <v-btn
                    text
                    :color="displayedRuns == 'all' ? '#0C2FF3' : '#667085'"
                    :class="displayedRuns == 'all' ? 'bg-theme-primary-light' : 'bg-gray-theme'"
                    class="text-capitalize rounded-lg justify-start mb-3"
                    width="100%"
                    @click="displayedRuns = 'all'"
                  >
                    {{ $t('testruns.all') }}
                  </v-btn>

                  <v-btn
                    text
                    :color="displayedRuns == 'unlinked' ? '#0C2FF3' : '#667085'"
                    :class="displayedRuns == 'unlinked' ? 'bg-theme-primary-light' : 'bg-gray-theme'"
                    class="text-capitalize rounded-lg bg-gray-theme justify-start mb-3"
                    width="100%"
                    @click="displayedRuns = 'unlinked'"
                  >
                    <div class="d-flex align-center">
                      <div class="mr-2">
                        <UnlinkedIcon />
                      </div>
                      <span>{{ $t('testruns.unlinked') }} 0</span>
                    </div>
                  </v-btn>
                  <v-btn
                    text
                    class="text-capitalize rounded-lg bg-gray-theme justify-start mb-2"
                    width="100%"
                    :color="Number.isInteger(displayedRuns) ? '#0C2FF3' : '#667085'"
                    :class="Number.isInteger(displayedRuns) ? 'bg-theme-primary-light' : 'bg-gray-theme'"
                    @click="onToPlanExpanded"
                  >
                    <div class="d-flex justify-space-between w-full">
                      <div class="d-flex align-center">
                        <div class="mt-1 mr-2">
                          <LinkedIcon />
                        </div>
                        <span>{{ $t('testruns.toPlans') }} {{ getActivePlansCount }}</span>
                      </div>
                      <div>
                        <v-icon>
                          {{ isToPlanExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }} 
                        </v-icon>
                      </div>
                    </div>
                  </v-btn>

                  <div
                    v-if="isToPlanExpanded"
                    class="plan-list-wrapper"
                  >
                    <div
                      v-for="(plan, index) in getActivePlans"
                      :key="index"
                    >
                      <v-tooltip
                        bottom
                        left
                        max-width="200px"
                        :disabled="plan.name.length < 15"
                        content-class="tooltip-theme"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            :color="Number.isInteger(displayedRuns) && displayedRuns == plan.uid ? '#0C2FF3' : '#667085'"
                            :class="Number.isInteger(displayedRuns) && displayedRuns == plan.uid ? 'bg-theme-primary-light' : 'bg-gray-theme'"
                            class="text-capitalize btn-full font-weight-regular rounded-lg justify-start mb-2"
                            width="100%"
                            v-bind="attrs"
                            @click="displayedRuns = plan.uid"
                            v-on="on"
                          >
                            <div class="d-flex justify-between align-center w-full">
                              <div class="text-truncate">
                                {{ plan.name }}
                              </div>
                              <div>({{ plan.runs.length }})</div>
                            </div>
                          </v-btn>
                        </template>
                        <span>{{ plan.name }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <div
                    v-else
                    class="plan-list-wrapper"
                  />
                </div>
                <div
                  v-if="!isProjectMenuCollapsed"
                  class="sticky-scroll"
                >
                  <div class="text-left">
                    <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                      {{ $t("createQuickPlan") }} 
                    </v-label>
                    <v-text-field
                      v-model="planName"
                      :placeholder="$t('enterName')"
                      height="38"
                      background-color="#F9F9FB"
                      :class="{
                        'field-theme mt-0 pt-1': true,
                        'disabled-action': isProjectArchived
                      }"
                    />
                  </div>
                  <v-btn
                    type="submit"
                    block
                    color="primary"
                    :depressed="true"
                    :class="{
                      'btn-theme': true,
                      'disabled-action': isProjectArchived || createButtonLoading
                    }"
                    width="100%"
                    height="40"
                    :loading="createButtonLoading"
                    @click="createTestPlan"
                  >
                    {{ $t("create") }}
                  </v-btn>
                </div>

                <div
                  class="collapse-btn btn-runs-sticky"
                  @click="toggleProjectMenu"
                >
                  <v-icon
                    class="collapse-icon"
                    color="#0C2FF3"
                  >
                    {{ isProjectMenuCollapsed ? 'mdi-arrow-right-bottom' : 'mdi-arrow-left-bottom'
                    }}
                  </v-icon>
                  <span
                    v-if="!isProjectMenuCollapsed"
                    class="collapse-text"
                  >
                    {{ $t('collapse') }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="pa-0"
              :style="{ width: isProjectMenuCollapsed ? '95%' : '85%' }"
            >
              <template v-if="activeItemCount == 0 && !appliedFilters && filter != 'archived' && !tableLoadingState">
                <div class="mb-0 white rounded-lg mx-0 project-placeholder-height d-flex justify-center align-center">
                  <ActiveEmptyState
                    :image-src="require('@/assets/png/auth-banner.png')"
                    :title="$t('testruns.empty_state.title')"
                    :button-text="$t('testruns.createTestRun')"
                    :button-route="{ name: 'TestRunCreate' }"
                    button-color="primary"
                    :is-project-archived="isProjectArchived"
                  >
                    <template #description>
                      <p class="mb-0 mt-3">
                        {{ $t('testruns.empty_state.description_part1') }}
                      </p>
                      <p class="ma-0">
                        {{ $t('testruns.empty_state.description_part2') }}
                      </p>
                      <p class="ma-0">
                        {{ $t('testruns.empty_state.description_part3') }}
                      </p>
                    </template>
                  </ActiveEmptyState>
                </div>
              </template>

              <template v-else-if="archivedItemCount == 0 && !appliedFilters && filter != 'ongoing' && !tableLoadingState">
                <div class=" mb-0 white rounded-lg mx-0 project-placeholder-height d-flex justify-center align-center">
                  <ArchivedEmptyState
                    :image-src="require('@/assets/png/auth-banner.png')"
                    :title="$t('archived_empty_state.title', { name: $t('plans.testRuns.testRuns') })"
                  >
                    <template #description>
                      <p class="mb-0 mt-3">
                        {{ $t('archived_empty_state.description.part1', { name: $t('plans.testRuns.testRuns') }) }}
                      </p>
                      <p class="mb-0">
                        {{ $t('projects.archived_empty_state.description.part2') }}
                      </p>
                    </template>
                  </ArchivedEmptyState>
                </div>
              </template>
              <div 
                v-else
                class="container pa-6 white align-start card rounded-lg container--fluid app-height-global"
              >
                <v-row class="align-center">
                  <v-col
                    cols="6"
                    sm="6"
                  >
                    <div class="d-flex flex-row justify-start align-center">
                      <v-responsive
                        class="ma-0"
                        max-width="344"
                      >
                        <v-text-field
                          v-model="searchFilter"
                          :loading="loading"
                          class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
                          :placeholder="$t('searchByName')"
                          height="40"
                          background-color="#F9F9FB"
                          hide-details
                        >
                          <template v-slot:prepend-inner>
                            <SearchIcon />
                          </template>
                        </v-text-field> 
                      </v-responsive>
                      <RunFilter 
                        :configurations="configurations" 
                        :milestones="activeMilestones"  
                        :tags="tags"
                        @applyFilters="applyFilters"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="d-flex justify-end"
                  >
                    <SettingsMenu 
                      table-type="run" 
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="fill-height pt-1"
                  >
                    <run-table
                      :filtered-headers="filteredHeaders"
                      :filtered-items="filteredRuns"
                      :item-key="itemKey"
                      :row-class="rowClass"
                      :clear-selection="clearSelection"
                      @edit-item="editItem"
                      @select-row="handleRowClick"
                      @select-item="setselected"
                      @archive-item="confirmArchiveRun"
                      @unarchive-item="confirmUnArchiveRun"
                      @delete-item="confirmDeleteRun"
                      @open-milestone-dialog="handleAddToMilestoneDialog"
                      @open-plan-dialog="handleAddToTestPlansDialog"
                      @open-duplicate-dialog="handleDuplicateAndApplyConfigDialog"
                    />
                  </v-col>
                  <v-row
                    v-if="isSelected"
                    class="fill-height action-btn-wrapper"
                  >
                    <v-col cols="12">
                      <v-flex class="d-sm-flex justify-end">
                        <v-btn
                          color="primary"
                          depressed
                          class="rounded-lg btn-theme text-capitalize"
                          width="100%"
                          max-width="141px"
                          :disabled="!hasOneSelectedItem"
                          height="40px"
                          @click="onClickDuplicate"
                        >
                          {{ $t('testruns.rerun') }}
                        </v-btn>
                      </v-flex>
                    </v-col>
                  </v-row>
                </v-row>
              </div>
            </div>  
          </div>
        </v-container>
      </template>
    </template>
    <AddToMilestoneDialog
      v-if="addToMilestoneDialog"
      :value="addToMilestoneDialog"
      :milestones="activeMilestones"
      @close="onCloseAddToMilestoneDialog"
      @handleAddMilestone="handleAddMilestone"
    />
    <AddToTestPlansDialog
      v-if="addToTestPlansDialog"
      :value="addToTestPlansDialog"
      :plans="getActivePlans"
      @close="onCloseAddToTestPlansDialog" 
      @handleAddTestPlan="handleConfirmTestPlanClick"
    />
    <DuplicateAndApplyConfigDialog
      v-if="duplicateAndApplyConfigDialog"
      :value="duplicateAndApplyConfigDialog"
      :configurations="configurations"
      @close="onCloseDuplicateAndApplyConfigDialog"
      @handleDuplicateAndApplyConfig="handleDuplicateAndApplyConfig"
    />
    <RunDiscardDialog
      v-model="showConfirmDialog"
      :title="confirmDialogTitle"
      :content="confirmDialogContent"
      :content_part2="confirmDialogContentPartTwo"
      :run_name="confirmDialogRunName"
      :items="confirmDialogItems"
      :type="confirmType"
      :btn_label="confirmDialogBtnLabel"
      :color="confirmDialogBtnColor"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmBtnClick"
    />
  </div>
</template>

<script>
import makePlanService from '@/services/api/plan';
import RunDiscardDialog from '@/components/TestRuns/RunDiscardDialog.vue';
import RunFilter from '@/components/TestRuns/RunFilter.vue';
import RunHeader from '@/components/TestRuns/RunHeader.vue';
import AddToMilestoneDialog from '@/components/TestRuns/AddToMilestoneDialog.vue';
import AddToTestPlansDialog from '@/components/TestRuns/AddToTestPlansDialog.vue';
import DuplicateAndApplyConfigDialog from '@/components/TestRuns/DuplicateAndApplyConfigDialog.vue';
import RunTable from '@/components/TestRuns/RunTable.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import dayjs from 'dayjs';
import { mapGetters, mapState, mapActions } from 'vuex';
import { runStateMap } from '@/constants/grid';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import { redirectToMappedRoute } from '@/utils/util';
import makeMilestonesService from '@/services/api/milestone'
import makeConfigurationService from '@/services/api/configuration' 
import makeTagService from '@/services/api/tag';
import makeRunService from '@/services/api/run';

import { handleNetworkStatusError } from '@/mixins/redirect';
import Loader from '@/components/base/Loader.vue';
import SearchIcon from '@/assets/svg/search-icon.svg';
import UnlinkedIcon from '@/assets/svg/unlinked.svg';
import UnlinkedIcon24 from '@/assets/svg/unlinked24px.svg';
import LinkedIcon from '@/assets/svg/linked.svg';
import LinkedIcon24 from '@/assets/svg/linked24px.svg';
import { sleep } from '@/utils/util';
import handleLoading from '@/mixins/loader.js'
import ActiveEmptyState from '@/components/base/ActiveEmptyState.vue';
import ArchivedEmptyState from '@/components/base/ArchivedEmptyState.vue';
import projectStatus from '@/mixins/projectStatus';
import RunAddCaseView from '@/views/Tests/Runs/Create/RunAddCaseView'

let planService;
let runsService;

export default { 
  components: {
    RunTable,
    RunHeader,
    RunFilter,
    RunDiscardDialog,
    AddToMilestoneDialog,
    AddToTestPlansDialog,
    DuplicateAndApplyConfigDialog,
    Loader,
    SearchIcon,
    SettingsMenu,
    RunAddCaseView,
    UnlinkedIcon,
    LinkedIcon,
    ActiveEmptyState,
    ArchivedEmptyState,
    LinkedIcon24,
    UnlinkedIcon24,
  },
  mixins: [handleNetworkStatusError, projectStatus, handleLoading],
  props: {
    runViewType: {
      type: String, 
      default: ''
    }
  },
  data() {
    return {
      planName: '',
      displayedRuns: 'all',
      appliedFilters: false,
      createButtonLoading: false,
      selectedRun: {},
      selectedStatus: Object.values(runStateMap)[0],
      savingInProgress: false,
      headers: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      expanded: [],
      searchFilter: '',
      menuOpen: false,
      addToMilestoneDialog: false,
      addToTestPlansDialog: false,
      duplicateAndApplyConfigDialog: false,
      openedRow: undefined,
      loading: false,
      loaderState: false,
      checked: false,
      selecteditems: [],
      itemKey: 'uid',
      filter: 'ongoing',
      options: ['ongoing', 'archived'],
      clearSelection: false,
      isFilter: false,
      showConfirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogContent: '',
      confirmDialogContentPartTwo: '',
      confirmDialogItems: [],
      confirmDialogBtnLabel: '',
      confirmDialogBtnColor: 'primary',
      confirmDialogRunName: '',
      confirmType: '',
      isProjectMenuCollapsed: false,
      rowClass: () => 'project-item',
      isToPlanExpanded: false,
      plans: [],
      milestones: [],
      configurations: [],
      tags: [],
      tableLoadingState: false,
      showCases: false,
      selectedCases: [],
      duplicatedRun: {},
      isLoading: false,
      items: [],
    };
  },
  
  computed: {
    ...mapState('user', ['currentAccount']),
    ...mapGetters({
      currentOrg: 'user/currentAccount',
      dynamicHeaders:'headers/dynamicHeaders',
    }),
    filteredHeaders() {
      const filtered = this.headers.filter((header) => header.checked);
      return filtered
    },
    filteredRuns() {
      let filtered = this.runs;
      if (this.searchFilter) {
        filtered = filtered.filter((item) => this.matchesFilter(item));
      }
      if (this.isFilter && this.appliedFilters) {
        filtered = filtered.filter((item) => this.matchApplyFilter(item));
      }
      return filtered;
    },
    isDuplicateRunViewType() {
      return this.runViewType == 'Duplicate';
    },
    isSelected() {
      if (this.selecteditems.length > 0)
        return true
      return false
    },
    hasOneSelectedItem() {
      return this.selecteditems.length === 1;
    },
    runs() {
      let filtered = this.items.filter((item) => {
        if(this.displayedRuns == 'unlinked' && item.testPlanUid)
          return false;
        if(Number.isInteger(this.displayedRuns) && item.testPlanUid !== this.displayedRuns)
          return false;
        return (item.archivedAt == ( this.filter === 'ongoing' ? null : item.archivedAt ? item.archivedAt : false ))
      });
      return filtered.map((item) => {        
        return {
        ...item,
        processedStatus: this.staticValues(item, runStateMap, 'New'),
        }
      });
    },
    selectedProjectKey() {
      return this.$route.params.key;
    },
    footerProps() {
      return {
        itemsPerPageOptions: [15, 50],
        showCurrentPage: true,
        showFirstLastPage: true,
        hideDefaultFooter: true,
      };
    },
    activeItemCount() {
      return this.items.filter((item) => item.archivedAt == null).length;
    },
    archivedItemCount() {
      return this.items.filter((item) => item.archivedAt).length;
    },
    isFilterArchived() {
      return this.filter === 'archived';
    },
    activeMilestones() {
      return this.milestones.filter((milestone) => milestone.archivedAt === null && milestone.deletedAt === null);
    },
    getActivePlans() {
      return this.plans.filter(plan => plan.archivedAt == null);
    },
    getActivePlansCount() {
      return this.plans.filter(plan => plan.archivedAt == null).length;
    },
  },
  watch: {
    currentOrg: 'refreshData',
    filteredRuns(newValue) {
      if (newValue === 'SEARCH_BACKEND') {
        this.searchRuns();
      }
    },
    selectedProjectKey() {
      this.getProjectRun(this.$route.params.handle);
    },
    searchFilter: {
      handler() {
        this.loading = true;
        this.$nextTick(() => {
          this.loading = false;
        });
      },
      immediate: true
    },
  },
  created() {
    planService = makePlanService(this.$api);
    runsService = makeRunService(this.$api);
    if(!this.dynamicHeaders.run) {
      this.initializeHeaders({ type: 'run' });
    }
    this.headers = this.dynamicHeaders.run;
  },
  mounted() {
    const handle = this.$route.params.handle;
    this.init([
        this.getProjectRun(handle),
        this.getMilestones(),
        this.getTestPlans(),
        this.getConfigurations(),
        this.getTags()
    ]);
  },
  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    // header accions
    updateFilter(newFilter) {
      this.filter = newFilter;
    },
    async createTestPlan() { 
      if(!this.isProjectArchived) {
      const payload = {
        name: this.planName,
        source: "testfiesta",
        status: '',
        milestoneUids: [],
        priority: '',
        runs: [],
        customFields: {
          archived: false
        }
     }
     try {
       this.createButtonLoading = true;
        const response = await planService.createTestPlan(
        this.$route.params.handle,
        this.$route.params.key,
        payload
       );
       if (response.status === 200) {
        this.planName = ""; 
        this.$router.push({ name: 'TestPlans' }); 
       }
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || 'Internal server error')
      } finally {
        this.createButtonLoading = false;
     }
    }
    },
    onBackShowCases(){
      this.selecteditems = [];
      this.showCases = false;
    },
    setselected(selecteditems) {
      this.clearSelection = false
      this.selecteditems = selecteditems
    },
    handleAddToMilestoneDialog(item) {
      this.selecteditems = [item];
      this.addToMilestoneDialog = true;
    },
    onCloseAddToMilestoneDialog() {
      this.addToMilestoneDialog = false;
    },
    handleAddToTestPlansDialog(item) {
      this.selecteditems = [item];
      this.addToTestPlansDialog = true;
    },
    onCloseAddToTestPlansDialog() {
      this.addToTestPlansDialog = false;
    },
    handleDuplicateAndApplyConfigDialog(item) {
      this.selecteditems = [item];
      this.duplicateAndApplyConfigDialog = true;
    },
    onCloseDuplicateAndApplyConfigDialog() {
      this.duplicateAndApplyConfigDialog = false;
    },
    toggleProjectMenu(){
      this.isProjectMenuCollapsed = !this.isProjectMenuCollapsed
    },
    searchCollapsedMenu() {
      this.isProjectMenuCollapsed = !this.isProjectMenuCollapsed
      this.$nextTick(() => {
        if (this.$refs.searchField) {
          this.$refs.searchField.focus();
        }
      });
    },
    unlinkedCollapsedMenu(){
      this.isProjectMenuCollapsed = !this.isProjectMenuCollapsed
      this.displayedRuns = 'unlinked'
    },
    linkedCollapsedMenu(){
      this.isProjectMenuCollapsed = !this.isProjectMenuCollapsed
      this.isToPlanExpanded = true
    },

    onToPlanExpanded(){
      this.isToPlanExpanded = !this.isToPlanExpanded
    },
    async getMilestones() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const milestoneService = makeMilestonesService(this.$api);
      try {
        const response = await milestoneService.getMilestones(handle, projectKey);
        this.milestones = response.data?.milestones;
        return response.data?.milestones;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'milestones' });
        return [];
      }
    },
    async getTestPlans() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const testPlanService = makePlanService(this.$api);
      try {
        const response = await testPlanService.getAllTestPlans(handle, projectKey, 1000, 0);
        this.plans = response.data?.plans;
        return response.data?.plans;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'test plans' });
        return [];
      }
    },
    async getConfigurations() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const configurationService = makeConfigurationService(this.$api);
      try {
        const response = await configurationService.getConfigurations(handle, projectKey, 10, 0);
        this.configurations = response.data?.configurations;
        return response.data?.configurations;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'configurations' });
        return [];
      }
    },
    async getTags() {
      const handle = this.$route.params.handle;
            const tagService = makeTagService(this.$api);
            try {
                const response = await tagService.getTags(handle, 'runs');
                this.tags = response.data
            } catch (err) {
              showErrorToast(this.$swal, 'fetchError', { item: 'tags' });
              return [];
            }
    }, 
    async handleConfirmDialog(type) {
      this.showConfirmDialog = true;
      switch (type) {
        case 'multi_archive':
          this.confirmDialogTitle = this.$t('testruns.archive_dialog.title_multi');
          this.confirmDialogContent = this.$t('testruns.archive_dialog.content');
          this.confirmDialogContentPartTwo = '';
          this.confirmDialogBtnLabel = this.$t('testruns.archive_dialog.btn_label');
          this.confirmDialogBtnColor = 'primary';
          this.confirmDialogRunName = '';
          this.confirmType = 'multi_archive';
          break;
        case 'multi_unarchive':
          this.confirmDialogTitle = this.$t('testruns.unarchive_dialog.title_multi');
          this.confirmDialogContent = this.$t('testruns.unarchive_dialog.content');
          this.confirmDialogContentPartTwo = '';
          this.confirmDialogBtnLabel = this.$t('testruns.unarchive_dialog.btn_label');
          this.confirmDialogBtnColor = 'primary';
          this.confirmDialogRunName = '';
          this.confirmType = 'multi_unarchive';
          break;
        case 'multi_delete':
          this.confirmDialogTitle = this.$t('testruns.delete_dialog.title');
          this.confirmDialogContent = this.$t('testruns.delete_dialog.content');
          this.confirmDialogContentPartTwo = (this.filter == 'ongoing' ? this.$t('testruns.delete_dialog.content_part2') : '');
          this.confirmDialogBtnLabel = this.$t('testruns.delete_dialog.btn_label');
          this.confirmDialogBtnColor = 'danger';
          this.confirmDialogRunName = '';
          this.confirmType = 'multi_delete';
          break;
        case 'archive':
          this.confirmDialogTitle = this.$t('testruns.archive_dialog.title');
          this.confirmDialogContent = this.$t('testruns.archive_dialog.content');
          this.confirmDialogContentPartTwo = '';
          this.confirmDialogBtnLabel = this.$t('testruns.archive_dialog.btn_label');
          this.confirmDialogBtnColor = 'primary';
          this.confirmDialogRunName = this.selectedRun.name;
          this.confirmType = 'archive';
          break;
        case 'unarchive':
          this.confirmDialogTitle = this.$t('testruns.unarchive_dialog.title');
          this.confirmDialogContent = this.$t('testruns.unarchive_dialog.content');
          this.confirmDialogContentPartTwo = '';
          this.confirmDialogBtnLabel = this.$t('testruns.unarchive_dialog.btn_label');
          this.confirmDialogBtnColor = 'primary';
          this.confirmDialogRunName = this.selectedRun.name;
          this.confirmType = 'unarchive';
          break;
        case 'delete':
          this.confirmDialogTitle = this.$t('testruns.delete_dialog.title');
          this.confirmDialogContent = this.$t('testruns.delete_dialog.content');
          this.confirmDialogContentPartTwo = (this.filter == 'ongoing' ? this.$t('testruns.delete_dialog.content_part2') : '');
          this.confirmDialogBtnLabel = this.$t('testruns.delete_dialog.btn_label');
          this.confirmDialogBtnColor = 'danger';
          this.confirmDialogRunName = '';
          this.confirmType = 'delete';
          break;
        case 'multi_milestone':
          this.confirmDialogTitle = this.$t('testruns.milestone_dialog.title');
          this.confirmDialogContent = this.$t('testruns.milestone_dialog.content');
          this.confirmDialogItems = await this.getMilestones();
          this.confirmDialogContentPartTwo = '';
          this.confirmDialogBtnLabel = this.$t('testruns.milestone_dialog.btn_label');
          this.confirmDialogRunName = '';
          this.confirmType = 'multi_milestone';
          this.confirmDialogBtnColor = 'primary';
          break;
          case 'multi_testplan': 
            this.confirmDialogTitle = this.$t('testruns.testplan_dialog.title');
            this.confirmDialogContent = this.$t('testruns.testplan_dialog.content');
            this.confirmDialogItems = await this.getTestPlans();
            this.confirmDialogContentPartTwo = '';
            this.confirmDialogBtnLabel = this.$t('testruns.testplan_dialog.btn_label');
            this.confirmDialogRunName = '';
            this.confirmType = 'multi_testplan';
            this.confirmDialogBtnColor = 'primary';
          break;        
      }
    },
    handleConfirmBtnClick(type,items) {
      switch (type) {
        case 'multi_archive':
          this.handleConfirmArchiveClick(true);
          break;
        case 'multi_unarchive':
          this.handleConfirmArchiveClick(false);
          break;
        case 'multi_delete':
          this.handleConfirmDeleteMultiClick();
          break;
        case 'archive':
          this.handleConfirmOneArchiveClick(true);
          break;
        case 'unarchive':
          this.handleConfirmOneArchiveClick(false);
          break;
        case 'delete':
          this.handleConfirmDeleteClick();
          break;
        case 'multi_milestone':
          this.handleConfirmMilestoneClick(items)
          break;
        case 'multi_testplan':
          this.handleConfirmTestPlanClick(items)
        break;
      }
    },
    handleCloseClick() {
      this.showConfirmDialog = false;
      this.selectedRun = [];
      this.selecteditems = [];
      this.confirmDialogItems = [];
      this.clearSelection = true;
      this.addToMilestoneDialog = false;
      this.addToTestPlansDialog = false;
      this.duplicateAndApplyConfigDialog = false;
    },
    confirmArchiveRun(item) {
      this.selectedRun = item;
      this.handleConfirmDialog('archive')
    },
    confirmUnArchiveRun(item) {
      this.selectedRun = item;
      this.handleConfirmDialog('unarchive')
    },
    confirmDeleteRun(item) {
      this.selectedRun = item;
      this.handleConfirmDialog('delete')
    },
    editItem(item) {
      this.selectedRun = item;
      this.$router.push({
        name: 'TestRunEdit',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          id: item.uid,
        },
      });
    },
    async handleConfirmOneArchiveClick(status) {
      const payload = {
        customFields: {
          ...this.selectedRun.customFields,
        },
        archived: status,
        externalId: this.selectedRun.externalId,
        source: this.selectedRun.source,
        name: this.selectedRun.name
      };

      try {
        await runsService.updateTestRun(this.$route.params.handle, this.$route.params.key, this.selectedRun.uid, payload);
        await this.refreshData()
        showSuccessToast(this.$swal, 'archiveSuccess', { item: 'Test run' });
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'archiveError', { item: 'Test run' });
      } finally {
        this.handleCloseClick();
      }

    },
    async createTestRuns() {     

      let payload = {};

        payload = {
          ...this.duplicatedRun
        };

        payload.name = this.$t('copyOf') + ' ' + this.duplicatedRun.name;

      if (this.selectedCases.length) {
        const selectedCases = this.selectedCases.map(item => item.testCaseRef);
        payload.customFields.caseCount = selectedCases.length;
        payload.cases = selectedCases;
      }

        try {
          
          this.isLoading = true;

          await runsService.createTestRun(this.$route.params.handle, this.$route.params.key, payload);
          showSuccessToast(this.$swal, 'addSuccess', { item: 'Test Run' });

          redirectToMappedRoute(this.$route, this.$router, 'Runs', {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
          });
          
        } catch (error) {
          showErrorToast(this.$swal, 'addError', { item: 'Failed to create Test Run' });
        } finally {
          this.isLoading = false;
        }

    },
    async handleConfirmArchiveClick(status) {
      var runs = []
      this.selecteditems.map((item) => {
        var run = {
          customFields: {
            ...item.customFields,
          },
          archived: status,
          externalId: item.externalId,
          source: item.source,
          name: item.name,
          uid: item.uid
        };
        runs = [...runs, run]
      })

      try {
        await runsService.updateTestRuns(this.$route.params.handle, this.$route.params.key, { runs: runs });
        await this.refreshData()
        showSuccessToast(this.$swal, 'archiveSuccess', { item: 'Test run' });
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'archiveError', { item: 'Test run' });
      } finally {
        this.handleCloseClick();
      }

    },
    async handleConfirmDeleteClick() {

      try {
        await runsService.deleteTestRun(this.$route.params.handle, this.$route.params.key, this.selectedRun.uid);
        await this.refreshData()
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Test run' });
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'deleteError', { item: 'Test run' });
      } finally {
        this.handleCloseClick();
      }

    },
    async handleAddMilestone(items){
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const milestoneService = makeMilestonesService(this.$api)
      const runIds = this.selecteditems.map(item => item.uid) 
      const payload = {
        runIds
      }

      if(items.length) {
        for (const element of items) {
          try {
            await milestoneService.addRunsToMilestone(handle, projectKey, element, payload);
            showSuccessToast(this.$swal, 'addSuccess', { item: 'Test runs to milestone' });
            this.handleCloseClick();
          } catch (error) {
            this.redirectOnError(error.response.status);
            showErrorToast(this.$swal, 'addError', { item: 'Test runs to milestone' });
          }
        }
        this.refreshData();
      }
    },
    async handleConfirmTestPlanClick(items){
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const testPlanService = makePlanService(this.$api);
      const runIds = this.selecteditems.map(item => item.uid) 
      const payload = {
        runs: runIds
      }

      if(items.length) {
        for (const element of items) {
          try {
            await testPlanService.addRunsToTestPlan(handle, projectKey, element, payload);
            showSuccessToast(this.$swal, 'addSuccess', { item: 'Test runs to test plan' });
            this.handleCloseClick();
          } catch (error) {
            this.redirectOnError(error.response.status);
            showErrorToast(this.$swal, 'addError', { item: 'Test runs to test plan' });
          }
        }
        this.refreshData();
      }
    },
    async handleDuplicateAndApplyConfig(items){
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const testRunService = makeRunService(this.$api);
      const runIds = this.selecteditems.map(item => item.uid) 

      if(runIds.length) {
        for (const element of runIds) {
          try {
            await testRunService.duplicateTestRun(handle, projectKey, element);
            showSuccessToast(this.$swal, 'addSuccess', { item: 'Duplicate run created' });
            this.handleCloseClick();
          } catch (error) {
            this.redirectOnError(error.response.status);
            showErrorToast(this.$swal, 'addError', { item: 'Error in creating duplicating run' });
          }
        }
        this.refreshData();
      }
    },
    async handleConfirmDeleteMultiClick() {
      var runIds = []
      this.selecteditems.map((item) => {
        runIds = [...runIds, item.uid]
      })

      try {
        await runsService.deleteTestRuns(this.$route.params.handle, this.$route.params.key, { runIds: runIds });
        await this.refreshData()
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Test runs' });
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'deleteError', { item: 'Test runs' });
      } finally {
        this.handleCloseClick();
      }

    },
    applyFilters(filters) {
      if (filters) {
        this.appliedFilters = filters;
        this.isFilter = true;
      } else {
        this.isFilter = false;
      }
      this.refreshData()
    },
    matchApplyFilter(item) {
      if (this.appliedFilters?.panelPriority?.length > 0 && 
          !this.appliedFilters.panelPriority.includes(item.priority)) {
        return false;
      }
      if (this.appliedFilters?.panelStatus?.length > 0 && 
          !this.appliedFilters.panelStatus.includes(item.status)) {
        return false;
      }
      if (this.appliedFilters?.panelMilestone?.length > 0 && 
          !this.appliedFilters.panelMilestone.includes(item.customFields.milestone) && 
          !this.appliedFilters.panelMilestone.includes('None')) {
        return false;
      }

      if (this.appliedFilters?.panelTag?.length > 0 && 
          !this.appliedFilters.panelTag.includes(item.customFields.tags) && 
          !this.appliedFilters.panelTag.includes('none')) {
        return false;
      }
      if (this.appliedFilters?.testCasesRange[0] > item.customFields.caseCount || 
          this.appliedFilters?.testCasesRange[1] < item.customFields.caseCount) {
        return false;
      }
      if (this.appliedFilters?.progressRange[0] > item.customFields.progress || 
          this.appliedFilters?.progressRange[1] < item.customFields.progress) {
        return false;
      }
      if (this.appliedFilters?.dateRange.start && this.appliedFilters?.dateRange.start > item.createdAt || 
          this.appliedFilters?.dateRange.end && this.appliedFilters?.dateRange.end < item.createdAt) {
        return false;
      }
      return true;
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      return item.name.toLowerCase().includes(lowerCaseFilter)
    },
    staticValues(item, obj, defultValue) {
      return obj[item.customFields.state] || obj[defultValue];
    },
    // Refreshes all data
    async refreshData() {
      let handle = this.currentAccount.handle;
      this.getProjectRun(handle);
    },
    async getProjectRun(handle) {
      try {
        const response = await runsService.getTestRuns(handle, this.$route.params.key, 99999, 0);
        this.items = response.data.runs;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'runs' });
        console.error(err);
      }
    },
    async searchRuns() {
      if (this.searchFilter) {
        
        try {
          const response = await runsService.searchRuns(this.$route.params.handle, this.$route.params.key, this.searchFilter, this.perPage, this.currentPage);
          this.items = response.data.runs;
        } catch (err) {
          showErrorToast(this.$swal, 'fetchError', { item: 'runs' });
          console.error(err);
        }

      } else {
        this.refreshData();
      }
    },
    async onClickDuplicate(){
      if(this.hasOneSelectedItem){
        this.duplicatedRun = this.selecteditems[0];
        this.showCases = true;
        await this.getRunById(
          this.$route.params.handle,
          this.$route.params.key,
          this.duplicatedRun.uid
        );
      }
    },
    async getRunById(handle, projectKey, runId) {
      
      try {
        const response = await runsService.getTestRunById(handle, projectKey, runId);
        this.selectedCases = response.data.runCases
      } catch (err) {
        showErrorToast(this.$swal, this.$t('testruns.edit_testrun.not_found'))
      }

    },
    // Handles row click
    handleRowClick(item) {
      this.$router.push({
          name: 'TestRunCaseEdit',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
            id: item.uid,
          }
        });
    },
  },
};
</script>
<style scoped>
.custom-runtable {
  color: #344054 !important;
}

.custom-color-0c111d {
  color: #0C111D !important;
}

.custom-color-d0d5dd {
  color: #D0D5DD !important;
}

.custom-color-061AAE {
  color: #061AAE !important;
}
.custom-menu-item {
  min-height: 36px;
  height: 36px;
  max-height: 36px;
  align-items: center;
}

.custom-text-12 {
  font-size: 12px;
}

.custom-text-14 {
  font-size: 14px;
}

.h-full {
  height: 100%;
  min-height: 100%;
}
.custom-swal-popup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.custom-swal-title {
  text-align: left;
  width: 100%;
}
.custom-swal-content {
  text-align: left;
  width: 100%;
}
.custom-swal-actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.custom-swal-confirm-button,
.custom-swal-cancel-button {
  margin-left: 10px;
}
.action-btn-wrapper {
  position: sticky;
    bottom: 0;
    background-color: white;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    z-index: 8;
}
.sticky-on-scroll {
  position: -webkit-sticky;
  position: sticky;
  top: 12px;
  height: calc(100vh - 6px);
}
.sticky-scroll {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding-bottom: 5rem;
  background: #fff;
    z-index: 1;
    padding-top: 1rem;
}
.plan-list-wrapper {
  height: calc(100vh - 28rem);
  overflow: auto;
  scrollbar-width: thin;
}
.btn-runs-sticky {
  position: -webkit-sticky;
  position: sticky;
  background: #fff;
  justify-content: flex-start;
  z-index: 9;
}
</style>
