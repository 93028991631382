<template>
  <v-container
    fluid
    style="padding: 0;"
  >
    <IntegrationHeader
      :filter="filter"
      :added-item-count="totalAdded"
      :all-item-count="totalAll"
      @update-filter="updateFilter"
      @select-integration="handleIntegrationSelect"
    />

    <Loader
      v-if="loaderState"
      class="integration-loader"
    />
    <template v-else-if="totalAdded == 0 && filter != 'all'">
      <div class="mt-3 mb-0 py-10 white rounded-lg mx-0 empty-integration-container d-flex justify-center align-center">
        <ActiveEmptyState
          :image-src="require('@/assets/png/empty-integrations.png')"
          :title="$t('integrations.empty_state.no_added_apps')"
          :button-text="$t('integrations.button_custom_app.text')"
          :button-route="{ name: 'create-integration' }"
          button-color="primary"
        >
          <template #extra-buttons>
            <v-btn
              color="primary"
              depressed
              height="40px"
              class="ml-3 text-capitalize rounded-lg white--text px-4"
              @click="updateFilter('all')"
            >
              {{ $t('integrations.button_library.text') }}
              <v-icon
                class="ml-1"
                size="16"
              >
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
        </ActiveEmptyState>
      </div>
    </template>

    <template v-else-if="totalAll == 0">
      <div class="mt-3 mb-0 white rounded-lg mx-0 available-integration-container d-flex justify-center align-center">
        <ArchivedEmptyState
          :image-src="require('@/assets/png/empty-integrations.png')"
          :title="$t('integrations.empty_state.no_available_apps')"
        />
      </div>
    </template>

    <template v-else>
      <v-card
        class="py-6 px-6 mt-3 integration-height"
        rounded="lg"
        elevation="0"
        width="100%"
      >
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <SearchComponent
              :placeholder="$t('search')"
              class="mr-3"
              @update:search="search = $event"
            />
            <FilterIntegration
              v-if="filter != 'all'"
              :services="services"
              :projects="projects"
              @applyFilters="applyFilters"
            />
          </div>
          <div class="d-flex align-center justify-end">
            <ToggleView
              :table="table"
              class="mr-3"
              @toggle-table="toggleTable"
            />
            <IntegrationSettingsMenu
              v-if="table"
              table-type="integration"
              :required-items="['Name', 'Projects', 'Actions']"
            />
          </div>
        </div>

        <!-- IntegrationTable component for table view -->
        <IntegrationTable
          v-if="table && filter != 'all' && filteredItems.length > 0"
          :filtered-headers="filteredHeaders"
          :filtered-items="filteredItems"
          :item-key="itemKey"
          :row-class="rowClass"
          @delete-item="confirmDeleteIntegration"
          @refresh="fetchAddedIntegrations"
        />

        <!-- Empty state -->
        <div 
          v-else-if="table && filter != 'all'" 
          class="d-flex align-center justify-center py-8"
        >
          <p class="text-subtitle-1">
            {{ $t('integrations.no_integrations') }}
          </p>
        </div>

        <IntegrationListAll
          v-else-if="!table && filter != 'added'"
          :tools="tools"
          @select-integration="handleIntegrationSelect"
        />

        <!-- Card View (if needed) -->
        <v-row
          v-else-if="!table && filter != 'all'"
          class="mt-6"
        >
          <v-col
            v-for="(item, index) in filteredItems"
            :key="index"
            cols="4"
          >
            <IntegrationCard
              :item="item"
              :filtered-headers="filteredHeaders"
              @delete-item="confirmDeleteIntegration"
              @refresh="fetchAddedIntegrations"
            />
          </v-col>
        </v-row>

        <!-- No data message -->
        <div
          v-else
          class="py-10"
        >
          <IntegrationCardAll
            :tools="tools"
            @select-integration="handleIntegrationSelect"
          />
        </div>
      </v-card>
    </template>

    <!-------------Delete Integration Dialog-------------------->

    <IntegrationDeleteDialog
      v-model="showConfirmDeleteDialog"
      :title="$t('integrations.delete_integration_dialog.title')"
      @close="closeDeleteDialog"
      @handleConfirmClick="confirmDeleteIntegration"
    >
      <template v-slot:content>
        <v-flex class="mt-4 flex-column">
          <p class="text-start">
            {{ $t('integrations.delete_integration_dialog.warning') }}
          </p>
          <p class="text-start">
            {{ $t('integrations.delete_integration_dialog.recommend') }}
          </p>
        </v-flex>
      </template>
      <template v-slot:footer>
        <v-row>
          <v-col cols="6">
            <v-btn
              depressed
              height="40px"
              width="100%"
              class="text-capitalize btn-theme rounded-lg black--text mt-2"
              background-color="#F2F4F7"
              @click="closeDeleteDialog"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              depressed
              height="40px"
              width="100%"
              class="text-capitalize btn-theme rounded-lg white--text mt-2"
              color="danger"
              @click="deleteIntegration"
            >
              {{ $t('integrations.delete_integration_dialog.confirm_button') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </IntegrationDeleteDialog>
  </v-container>
</template>

<script>
import IntegrationHeader from '@/components/Integration/IntegrationHeader.vue';
import ActiveEmptyState from '@/components/base/ActiveEmptyState.vue';
import ArchivedEmptyState from '@/components/base/ArchivedEmptyState.vue';
import IntegrationTable from '@/components/Integration/IntegrationTable.vue';
import IntegrationCard from '@/components/Integration/IntegrationCard.vue';
import IntegrationListAll from '@/components/Integration/IntegrationListAll.vue';
import IntegrationCardAll from '@/components/Integration/IntegrationCardAll.vue';
import Loader from '@/components/base/Loader';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import ToggleView from '@/components/Project/ToggleView.vue';
import { showErrorToast, showSuccessToast } from '@/utils/toast';
import handleLoading from '@/mixins/loader.js';
import FilterIntegration from '../../components/Integration/FilterIntegration.vue';
import IntegrationDeleteDialog from '../../components/Integration/IntegrationDeleteDialog.vue';
import makeIntegrationsService from '@/services/api/integrations';
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import IntegrationSettingsMenu from '@/components/Integration/IntegrationSettingsMenu.vue';

export default {
  name: 'IntegrationIndex',
  components: {
    IntegrationDeleteDialog,
    IntegrationHeader,
    ActiveEmptyState,
    IntegrationTable,
    IntegrationCard,
    IntegrationListAll,
    IntegrationCardAll,
    Loader,
    SearchComponent,
    FilterIntegration,
    ToggleView,
    ArchivedEmptyState,
    IntegrationSettingsMenu,
  },
  mixins: [handleLoading],
  data() {
    return {
      handle: this.$route.params.handle,
      title: 'Main Menu',
      filteredMenuHeaders: null,
      totalAdded: 0,
      totalAll: 0,
      filter: 'added',
      search: '',
      table: true,
      tools: [],
      headers: [],
      filteredItems: [],
      itemKey: 'name',
      rowClass: 'integration-row',
      showConfirmDeleteDialog: false,
      originalItems: [],
      services: [],
      projects: {},
      filteredTotal: 0,
      deleteIntegrationId: 0,
      tableType: 'integration',
    };
  },

  computed: {
    ...mapGetters({
      dynamicHeaders: 'headers/dynamicHeaders'
    }),
    filteredHeaders() {
      if (!this.dynamicHeaders.integration) return [];
      return this.dynamicHeaders.integration.filter(header => header.checked);
    },
  },

  watch: {
    search: {
      handler(newValue) {
        this.filterItems();
      },
      immediate: true,
    },
    filter: {
      handler(newValue) {
        // Reinitialize headers if they're not properly set
        if (!this.headers.length || this.headers.some(h => !h.checked && ['Name', 'Projects', 'Actions'].includes(h.text))) {
          if (!this.dynamicHeaders.integration) {
            this.initializeHeaders({ type: 'integration' });
          }
          this.headers = this.dynamicHeaders.integration;
        }
      },
      immediate: true
    },
    'dynamicHeaders.integration': {
      handler(newHeaders) {
        if (newHeaders) {
          this.headers = newHeaders;
        }
      },
      deep: true
    }
  },

  created() {
    if (!this.dynamicHeaders.integration) {
      this.initializeHeaders({ type: 'integration' });
    }
    this.headers = this.dynamicHeaders.integration;
    this.checkUrlErrors();
  },

  async mounted() {
    await this.init([
      this.fetchAvailableIntegrations(),
      this.fetchAddedIntegrations()
    ]);
  },

  methods: {
    ...mapActions('headers', ['initializeHeaders', 'updateHeaders']),
    filterItems() {
      const searchTerm = this.search.toLowerCase();
      if (!searchTerm) {
        this.filteredItems = [...this.originalItems];
        return;
      }

      this.filteredItems = this.originalItems.filter((integration) => {
        return (
          integration.name.toLowerCase().includes(searchTerm) || integration.service.toLowerCase().includes(searchTerm)
        );
      });
    },

    closeDeleteDialog() {
      this.showConfirmDeleteDialog = false;
    },
    confirmDeleteIntegration(integration) {
      this.deleteIntegrationId = integration;
      this.showConfirmDeleteDialog = true;
    },
    async deleteIntegration() {
      try {
        const integrationsService = makeIntegrationsService(this.$api);
        await integrationsService.deleteIntegration(this.handle, this.deleteIntegrationId);
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'deleted integrations' });
        this.fetchAddedIntegrations();
      } catch (error) {
        showErrorToast(this.$swal, 'deleteError', { item: 'deleted integrations' });
      } finally {
        this.showConfirmDeleteDialog = false;
        this.closeDeleteDialog();
      }
    },

    updateFilter(filter) {
      this.filter = filter;
    },
    toggleTable() {
      this.table = !this.table;
    },
    async applyFilters(filters) {
      // Construct query parameters
      const queryParams = new URLSearchParams();
      if (filters.services?.length) {
        queryParams.append('service', filters.services.join(','));
      }
      if (filters.projectUids?.length) {
        queryParams.append('projectUid', filters.projectUids.join(','));
      }
      try {
        const integrationsService = makeIntegrationsService(this.$api);
        const response = await integrationsService.getIntegrations(this.handle, queryParams.toString());
        if (response?.data) {
          const mappedItems = response.data.integrations.map((integration) => ({
            uid: integration.uid,
            name: integration.name,
            description: integration.description,
            picUrl: integration.picUrl,
            service: integration.service,
            projectLogos: integration.projectLogos || [],
            projectUids: integration.projectUids || [],
            configuration: integration.configuration,
            projectDetails: integration.projectUids?.map(uid => ({
              ...this.projects[uid],
              uid
            })) || []
          }));

          // Update both originalItems and filteredItems
          this.originalItems = mappedItems;
          this.filteredItems = mappedItems;
          this.filteredTotal = response.data.filteredTotal;
        }
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'filtered integrations' });
      }
    },
    async fetchAvailableIntegrations() {
      try {
        const integrationsService = makeIntegrationsService(this.$api);
        const response = await integrationsService.getAvailableIntegrations(this.handle);

        if (response.status === 200) {
          this.tools = response.data ? response.data.map(category => ({
            ...category,
            items: category.items.map(item => ({
              ...item,
              image: item.image ? require(`@/assets/png/${item.image}`) : require('@/assets/png/project.png')
            }))
          })) : [];
          
          this.totalAll = response.data
            ? response.data.reduce((total, category) => total + category.items.length, 0)
            : 0;
        }
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'integrations' });
      }
    },
    async fetchAddedIntegrations() {
      try {
        const integrationsService = makeIntegrationsService(this.$api);
        const response = await integrationsService.getIntegrations(this.handle);
        
        if (response?.data) {
          // Set services array
          this.services = response.data.services || [];
          this.totalAdded = response.data.pagination.totalIntegrations || 0;
          if(this.totalAdded === 0) {
            this.filter='all';
          }
          // Set projects object
          this.projects = response.data.projects || {};
          
          // Map integration items with complete image paths
          const mappedItems = response.data.integrations.map((integration) => ({
            uid: integration.uid,
            name: integration.name,
            description: integration.description,
            picUrl: integration.picUrl,
            service: integration.service,
            projectLogos: integration.projectLogos || [],
            projectUids: integration.projectUids || [],
            configuration: integration.configuration,
            projectDetails: integration.projectUids?.map(uid => ({
              ...this.projects[uid],
              uid
            })) || []
          }));

          this.originalItems = mappedItems;
          this.filteredItems = mappedItems;
        }
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'added integrations' });
      }
    },
    handleIntegrationSelect(integration) {
      this.$router.push({
        name: 'create-integration',
        params: {
          selectedIntegration: {
            name: integration.name,
            description: integration.description
          }
        }
      });
    },
    checkUrlErrors() {
      const errorParam = this.$route.query.error;
      if (errorParam) {
        if(errorParam === 'only_orgs_can_integrate') {
          showErrorToast(this.$swal, this.$t(`integrations.error.onlyOrgsCanIntegrate`));
        }
        else if (errorParam === 'handle_not_found') {
          showErrorToast(this.$swal, this.$t(`integrations.error.handleNotFound`));
        }
        else {
          showErrorToast(this.$swal, this.$t(`error.internalServerError`));
        }
        // Clean up the URL by removing the error parameter
        const query = { ...this.$route.query };
        delete query.error;
        this.$router.replace({ query });
      }
    },
  },
};
</script>

<style scoped>
.integration-loader {
  min-height: 500px;
}
.placeholder-img {
  max-width: 438px;
  width: 100%;
}
.integration-height {
  min-height: calc(100vh - 24px - 6.25rem);
  height: 100%;
}
.empty-integration-container,
.available-integration-container {
  min-height: 500px; /* or whatever height you want to set */
}
</style>
