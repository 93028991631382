<template>
  <div style="height: 100%">
    <div class="d-flex align-center dashboard__charts-form mb-5">
      <h3>Split</h3>
      <PriorityStatusFilter
        :statuses="initialStatuses"
        :priorites="initialPriorities"
        :split-by="splitBy"
        :chart-id="chartId"
      />
      <EntitySelector />
      <ChartViewFilter
        :initial-split="splitBy"
        :chart-id="chartId"
      />
    </div>
    <slot name="chart" />
  </div>
</template>
<script>
import PriorityStatusFilter from '@/components/Dashboard/Filters/PriorityStatusFilter'
import EntitySelector from '@/components/Dashboard/Filters/EntitySelector'
import ChartViewFilter from '@/components/Dashboard/Filters/ChartViewFilter'
export default{
  components: {
    PriorityStatusFilter,
    EntitySelector,
    ChartViewFilter
  },
  props:{
    statuses: Array,
    priorities: Array,
    splitBy: String,
    chartId: Number
  },
  computed:{
    initialPriorities(){
      return JSON.parse(JSON.stringify(this.priorities))
    },
    initialStatuses(){
      return JSON.parse(JSON.stringify(this.statuses))
    },
  }
}
</script>
<style scoped>
.dashboard__charts-form{
  gap: 8px;
  height: 100%
}
</style>
