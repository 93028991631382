<template>
  <div>
    <div
      v-for="category in tools"
      :key="category.category"
      class="mb-6"
    >
      <!-- Category Header -->
      <div class="bg-gray-theme py-4 px-6 mb-2">
        <h3 class="fs-20 fw-semibold">
          {{ category.category }}
        </h3>
      </div>

      <!-- Tool Items -->
      <v-row no-gutters>
        <v-col
          v-for="(tool, index) in category.items"
          :key="tool.name"
          cols="12"
        >
          <!-- Apply a background color conditionally based on index -->
          <v-sheet
            class="pa-3"
            :class="index % 2 === 0 ? 'bg-gray-light' : 'bg-theme-base'"
            rounded="lg"
            style="margin-bottom: 0"
          >
            <div class="d-flex flex-column">
              <div class="d-flex justify-between align-center">
                <div class="d-flex align-center pb-2">
                  <v-avatar
                    class="mr-2"
                    size="40"
                  >
                    <v-img
                      :src="tool.image"
                      :lazy-src="require('@/assets/png/project.png')"
                      width="100%"
                      alt="logo"
                    />
                  </v-avatar>

                  <div class="d-flex flex-column ml-3">
                    <div class="text-start fw-semibold fs-16px">
                      {{ tool.name }}
                    </div>
                    <div class="text-start text-grey-dark fs-14px">
                      {{ tool.description }}
                    </div>
                  </div>
                </div>

                <v-btn
                  color="#0C2FF3"
                  depressed
                  height="40px"
                  class="text-capitalize rounded-lg btn-theme white--text px-4"
                  @click="addIntegration(tool.name, tool.description)"
                >
                  {{ $t('Add') }} <v-icon
                    class="ml-1"
                    size="16px"
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntegrationListAll',
  props: {
    tools: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async addIntegration(name, description) {
      this.$emit('select-integration', { name, description });
    },
  },
};
</script>

<style scoped>
.bg-gray-theme {
  background-color: #f9fafb;
}
.bg-gray-light {
  background-color: #f3f4f7;
}
.bg-light-gray {
  background-color: #e0e0e0; /* Light gray for alternate rows */
}
.text-grey-dark {
  color: #8e8f96;
}
.fs-20 {
  font-size: 20px;
}
.fs-16px {
  font-size: 16px;
}
.fs-14px {
  font-size: 14px;
}
.fw-semibold {
  font-weight: 600;
}
</style>
