<template>
  <v-container
    fluid
    style="padding: 0"
  >
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
      />
      <p class="text-body-1 mt-3">
        {{ loadingText }}...
      </p>
    </v-overlay>
    <error404 v-if="show404" />
    <v-row v-else>
      <v-col :cols="isMenuCollapsed ? 1 : 2">
        <left-menu
          class="ml-3"
          :menu-items="menuItems"
        />
      </v-col>

      <v-col
        :cols="isMenuCollapsed ? 11 : 10"
        style="padding-left: 0 !important"
      >
        <router-view />
      </v-col>
    </v-row>
    <span id="versionSpan">
      {{ versionString }}
    </span>
  </v-container>
</template>

<script>
import { createNamespacedHelpers  } from 'vuex';
import { mapGetters } from 'vuex';
import NotificationIcon from '@/assets/svg/settings-left-menu/notification.svg';
import InfoIcon from '@/assets/svg/settings-left-menu/about.svg';
import error404 from "@/components/base/error404.vue";

const { mapState } = createNamespacedHelpers('user');

import LeftMenu from '@/components/Admin/LeftMenu';

export default {
  name: 'UserSettingsLayout',

  components: {
    LeftMenu,
    error404
  },

  computed: {
    ...mapState(['currentAccount']),
    ...mapGetters("error404", ["show404"]),
    ...mapGetters(['isMenuCollapsed', 'isLoading', 'loadingText']),

    versionString() {
      if (
        process.env.VUE_APP_VERSION &&
        process.env.VUE_APP_STRIPE_PUBLIC_KEY.indexOf('live') < 0
      ) {
        return `FRONTEND VERSION: ${process.env.VUE_APP_VERSION}`;
      }
      return '';
    },
    menuItems() {
      return [
        { title: 'Info',  icon: InfoIcon, to: { name: 'UserOrganizations', params: { handle: this.$route.params.handle } }, isActive: false },
        { title: 'Notifications', icon: NotificationIcon, to: { name: 'UserNotifications', params: { handle: this.$route.params.handle } }, isActive: false },
      ]
    }
  },
}
</script>
<style>
  #versionSpan {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 0 1em;
    color: white;
    background: black
  }
</style>
