<template>
  <v-expansion-panels
    :mandatory="mandatoryStatus"
    flat
  >
    <v-expansion-panel>
      <v-expansion-panel-header
        class="pa-0"
        @click="onHeaderClick"
      >
        <div class="d-flex justify-start align-center">
          <v-checkbox
            v-model="parentCheckbox"
            :indeterminate="isIndeterminate"
            :ripple="false"
            off-icon="icon-checkbox-off"
            on-icon="icon-checkbox-on"
            indeterminate-icon="icon-indeterminate"
            class="text-body-2 cw-custom"
            @click="onParentCheckboxClick($event)"
          >
            <template v-slot:label>
              <span class="text-theme-label mb-2 fs-14px">{{ parentLabel }}</span>
            </template>
          </v-checkbox>
          <p class="text-body-2 mb-0 grey--text ml-custom">
            {{ description }}
          </p>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          v-for="(item) in items"
          :key="item.selected"
          class="d-flex justify-start align-center"
        >
          <v-checkbox
            v-model="selected"
            class="text-body-2 cw-custom ml-5"
            :value="item.value"
            :ripple="false"
            off-icon="icon-checkbox-off"
            on-icon="icon-checkbox-on"
            indeterminate-icon="icon-indeterminate"
            @change="updateParentCheckbox(item)"
          >
            <template v-slot:label>
              <span class="text-theme-label mb-2 fs-14px">{{ item.name }}</span>
            </template>
          </v-checkbox>
          <p class="text-body-2 mb-0 grey--text ml-custom-child">
            {{ item.description }}
          </p>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'CheckboxPanel',
  props: {
    items: {
      type: Array,
      required: true,
    },
    parentLabel: {
      type: String,
      default: 'Select All',
    },
    description: {
      type: String,
      default: '',
    },
    initial: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      parentCheckbox: false,
      mandatoryStatus: true,
      selected: []
    };
  },
  computed: {
    isIndeterminate() {
      return this.selected.length > 0 && this.selected.length <= this.items.length;
    },
    initialSelect(){
      return this.initial
    }
  },
  watch:{
    items(val){
      if(val?.length)
        val.map(item => {
          if(item.selected && !this.selected.includes(item.value)){
            this.selected.push(item.value)
            this.updateParentCheckbox(item)
          }
        })
    }
  },
   created(){
      this.items.forEach(element => {
      if(this.initial.includes(element.value)){
        this.selected.push(element.value)
        this.updateParentCheckbox(element)
      }})

  },
  methods: {
    updateParentCheckbox(permission) {
      if(permission.required?.length){
        this.$emit('update-required', permission.required)
      }
      this.parentCheckbox = this.selected.length > 0 ?? false;
      this.$emit('update-items', this.selected);
    },
    onHeaderClick() {
      this.mandatoryStatus = false;
    },
    onParentCheckboxClick(event) {
      if(Boolean(event.target.getAttribute('aria-checked')) == false){ 
        return
      }
      event.stopPropagation();
      this.items.forEach(element => {
        if(!this.selected.includes(element.value))
        this.selected.push(element.value)
        this.updateParentCheckbox(element)
      })
    }
  }
};
</script>

<style scoped>
.ml-custom {
  margin-left: 20rem;
}
.ml-custom-child {
  margin-left: 300px;
}
.cw-custom {
  width: 100%;
  max-width: 160px;
}
</style>