import './plugins/_globals';
import './plugins/vee-validate';
import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/scss/custom.scss';

import App from './App.vue';
import { StripePlugin } from '@vue-stripe/vue-stripe';
import VTiptap from '@peepi/vuetify-tiptap';
import Vue from 'vue';
import VueInputAutowidth from 'vue-input-autowidth';
import VueSweetalert2 from 'vue-sweetalert2';
import { uuid } from 'vue-uuid';
import i18n from './i18n';
import makeAPI from './services/api/index';
import makeRouter from './router';
import makeStore from './store';
import vuetify from './plugins/vuetify';
import * as Sentry from "@sentry/vue";
import mixinPermissions from '@/mixins/permissions'
const api = makeAPI(process.env.VUE_APP_SERVER_BASEURL);

api.interceptors.request.use(
  function (config) {
    const traceId = uuid.v4();
    config.headers['X-Trace-ID'] = traceId;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401 &&
      router.history._startLocation !== '/login'
    ) {
      store.commit('user/setUser', null);
      store.commit('user/setOrgs', null);
      // Only clear user and orgs from localStorage, preserve currentAccount
      localStorage.setItem('user', JSON.stringify(null));
      localStorage.setItem('orgs', JSON.stringify(null));

      router.push({ name: 'Login' });
    }
    return Promise.reject(error);
  }
);

const store = makeStore(api);
const router = makeRouter(store);
Vue.use(VueInputAutowidth);
Vue.use(VueSweetalert2);
Vue.use(VTiptap);
Vue.use(StripePlugin, { pk: process.env.VUE_APP_STRIPE_PUBLIC_KEY });

Vue.config.productionTip = false;

store.subscribe((mutation, { user }) => {
  localStorage.setItem('user', JSON.stringify(user.user));
  localStorage.setItem('orgs', JSON.stringify(user.orgs));
});

const app = new Vue({
  vuetify,
  router,
  store,
  i18n,
  api,
  mixinPermissions,
  render: (h) => h(App),
});

if (['production', 'staging'].includes(process.env.NODE_ENV)) {
  Sentry.init({
    Vue,
    dsn: "https://6cb831316fbe702b579c2a53b53cc8b9@o4507948401754112.ingest.us.sentry.io/4508568917508096",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/(?:staging\.|)(?:app|api)\.testfiesta.com/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.$mount('#app');

if (window.Cypress) {
  window.app = app;
}
