<template>
  <v-menu
    v-if="progresses"
    open-on-hover
    bottom
    offset-y
    max-width="150px"
    content-class="view-executions-menu"
    z-index="100"
  >
    <template v-slot:activator="{ on }">
      <div
        class="progress-linear"
        v-on="on"
      >
        <div
          class="progress-linear-bar"
          :style="{backgroundColor: progresses.percentage == 100 ? '#EAECF0' : '#EAECF0'}"
        >
          <div
            v-for="(status, index) in progresses.progress"
            :key="index"
            class="partial"
            :style="{width: `${status/progresses.length * 100}%`,
                     'background-color': getStatusColorByName(index, statuses)
            }"
          />
        </div>
        <span class="font-weight-regular ml-3">{{ safePercentage(progresses.percentage) }}%</span>
      </div>
    </template>

    <v-list>
      <v-list-item
        v-for="(status, index) in progresses.progress"
        :key="index"
        dense
        height="35px"
      >
        <v-list-item-title class="text-left">
          <span
            class="text-capitalize"
            :style="{color: getStatusColorByName(index, statuses)}"
          >{{ index }} {{ status }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import colorPreferencesMixin from '@/mixins/colorPreferences';
export default {
  mixins: [colorPreferencesMixin],
  props: {
    progresses: {
      type: [Object, Array],
    }
  },
  data(){
    return{
      statuses: []
    }
  },
  created(){
    this.statuses = this.getStatuses("testRun");
  },
  methods:{
    safePercentage(value) {
      const percentage = value;
      return (typeof percentage === 'string' && percentage !== 'NaN') ? percentage : '0';
    },
  }
}
</script>
