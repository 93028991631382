<template>
  <v-container
    fluid
    class="pb-0 pr-0"
    :class="{ 'app-height-global' : loaderState }"
  >
    <Header
      :write-custom-field="_writeCustomField"
      @create-custom-field="onCreateCustomField"
    />
    <Loader
      v-if="loaderState"
      class="pt-0"
    />
    <template v-else>
      <v-card
        v-if="hasCustomFields || onFetchDataState"
        class="py-6 px-6 mt-3 app-height-global"
        rounded="lg"
        elevation="0"
        width="100%"
      >
        <v-row
          justify="space-between"
          class="align-center"
        >
          <v-col
            cols="12"
            md="auto"
            class="d-flex align-center"
          >
            <SearchComponent
              :search="filter.name"
              :placeholder="$t('placeHolder.searchByName')"
              class="mr-3"
              @update:search="filter.name = $event"
            />

            <FilterDialog
              :data="filter"
              :data-sources="dataSources"
              @update-filter-condition="updateFilterCondition"
            />
          </v-col>

          <v-col
            cols="12"
            md="auto"
          >
            <v-row
              justify="end"
              class="align-center"
            >
              <SettingsMenu 
                table-type="customFields"
              />
            </v-row>
          </v-col>
        </v-row>

        <CustomFieldTable
          :headers="filteredHeaders"
          :items="filteredCustomFields"
          :write-custom-field="_writeCustomField"
          :delete-custom-field="_deleteCustomField"
          item-key="uid"
          @edit="onEditCustomField"
          @delete="onDeleteCustomField"
        />
      </v-card>
      <v-card
        v-else
        class="mt-3 app-height-global d-flex align-center justify-center"
        rounded="lg"
        elevation="0"
        width="100%"
      >
        <ActiveEmptyState
          :image-src="require('@/assets/png/empty-step.png')"
          image-max-width="323px"
          :title="$t('customFieldPage.emptyTitle')"
          :button-text="$t('customFieldPage.createCustomField')"
          :is-project-archived="isProjectArchived"
          button-color="primary"
          @button-click="onCreateCustomField"
        />
      </v-card>
    </template>
    <CreateUpdateDialog
      v-model="showCreateUpdateDialog"
      :data="selectedCustomField"
      @create-custom-field="createCustomField"
      @update-custom-field="updateCustomField"
      @close-dialog="showCreateUpdateDialog = false"
    />

    <DeleteConfirmDialog
      v-model="showDeleteConfirmDialog"
      :custom-field="selectedCustomField"
      @delete="deleteCustomField"
    />
  </v-container>
</template>

<script>
import * as _ from 'lodash'
import { createNamespacedHelpers, mapActions  } from 'vuex';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import makeCustomFieldService from '@/services/api/customField';
import { handleNetworkStatusError } from '@/mixins/redirect';
import { mapGetters } from 'vuex';
import Header from '@/components/Admin/CustomField/Header';
import FilterDialog from '@/components/Admin/CustomField/FilterDialog.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import CustomFieldTable from '@/components/Admin/CustomField/CustomFieldTable.vue';
import CreateUpdateDialog from '@/components/Admin/CustomField/CreateUpdateDialog.vue';
import DeleteConfirmDialog from '@/components/Admin/CustomField/DeleteConfirmDialog.vue';
import Loader from '@/components/base/Loader.vue';
import { sleep } from '@/utils/util';
import ActiveEmptyState from '@/components/base/ActiveEmptyState.vue';
import projectStatus from '@/mixins/projectStatus';

const { mapState } = createNamespacedHelpers('user');

export default {
  name: 'CustomFields',
  components: {
    Header,
    FilterDialog,
    SearchComponent,
    SettingsMenu,
    CustomFieldTable,
    CreateUpdateDialog,
    DeleteConfirmDialog,
    Loader,
    ActiveEmptyState,
  },
  mixins: [handleNetworkStatusError, projectStatus],
  data() {
    return {
      filter: {
        name: '',
        types: [],
        sources: [],
        startDate: null,
        endDate: null,
      },
      headers: [],
      loaderState: false,
      onFetchDataState: false,
      originalCustomFields: [],
      filteredCustomFields: [],
      showCreateUpdateDialog: false,
      showDeleteConfirmDialog: false,
      selectedCustomField: {
        uid: '',
        name: '',
        type: '',
        source: '',
        options: [],
      },
      customFieldService: null,
      isLoading: false,
      errorMessage: '',
      dataSources: [],
    }
  },

  computed: {
    _writeCustomField(){
      return this.authorityTo('write_custom_field')
    },
    _deleteCustomField(){
      return this.authorityTo('delete_custom_field')
    },
    ...mapState(['currentAccount']),
    ...mapGetters({
      dynamicHeaders:'headers/dynamicHeaders',
    }),
    filteredHeaders() {
      const filtered = this.headers.filter((header) => header.checked);
      return filtered;
    },
    hasCustomFields() {
      return this.originalCustomFields.length > 0
    }
  },

  watch: {
    'filter.name': {
      handler: _.debounce(function () {
        this.filterCustomFields()
      }, 500),
    },
  },

  created() {
    if(!this.dynamicHeaders.customFields) {
      this.initializeHeaders({ type: 'customFields' });
    }
    this.headers = this.dynamicHeaders.customFields;
  },

  mounted() {
    this.customFieldService = makeCustomFieldService(this.$api);
    this.init(this.currentAccount.handle);
  },

  async beforeRouteUpdate(to, from, next) {
    const handle = to.params.handle;
    if (handle && handle !== from.params.handle) {
      try {
        await this.init(handle);
        next();
      } catch (error) {
        next();
      }
    } else {
      console.log("not run")
      next();
    }
  },

  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    async init(handle) {
      this.loaderState = false;
      this.onFetchDataState = true;
      let loaderStateTimeout = null;

      loaderStateTimeout = setTimeout(() => {
        this.loaderState = true;
        this.onFetchDataState = false;
      }, 1000);

      try {
        await this.getCustomFields(handle);
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'custom fields' });
      } finally {
        clearTimeout(loaderStateTimeout); 
        const hideLoader = async () => {
          await sleep(1000); 
          this.loaderState = false; 
        };
        hideLoader(); 
        this.onFetchDataState = false;
      }
    },
    async getCustomFields(handle) {
      this.isLoading = true
      try {
        const response = await this.customFieldService.getCustomFields(handle, this.$route.params.key);
        this.originalCustomFields = response.data
        this.dataSources = _.uniq(_.map(this.originalCustomFields, 'source'))
      } catch (err) {
        this.redirectOnError(err.response.status)
        showErrorToast(this.$swal, 'fetchError', { item: 'Custom fields' });
      } finally {
        this.isLoading = false
      }

      this.filterCustomFields();
    },

    onCreateCustomField() {
      this.selectedCustomField = {
        uid: '',
        name: '',
        type: '',
        source: '',
        options: [],
      }

      this.showCreateUpdateDialog = true
    },

    async createCustomField(customField) {
      this.showCreateUpdateDialog = false;
      if(!this._writeCustomField){
        this.unauthorizedToast;
        return ;
      }
      try {
        const response = await this.customFieldService.createCustomField(this.currentAccount.handle, this.$route.params.key, {
          ...customField,
          source: 'Manual',
        });

        this.originalCustomFields.push(response.data);
        showSuccessToast(this.$swal, 'createSuccess', { item: 'Custom field' });
      } catch (err) {
        this.redirectOnError(err.response.status)
        showErrorToast(this.$swal, 'createError', { item: 'Custom field' });
      }

      this.filterCustomFields();
    },

    onEditCustomField(customField) {
      this.selectedCustomField = {
        uid: customField.uid || '',
        name: customField.name ||  '',
        type: customField.type || '',
        source: customField.source || '',
        options: customField.options || [],
      }

      this.showCreateUpdateDialog = true
    },

    async updateCustomField(customField) {
      this.showCreateUpdateDialog = false;

      try {
        const response = await this.customFieldService.updateCustomField(this.currentAccount.handle, this.$route.params.key, customField);

        this.originalCustomFields = this.originalCustomFields.map(item => {
          if (item.uid !== customField.uid) {
            return item;
          }

          return response.data;
        });
        showSuccessToast(this.$swal, 'updateSuccess', { item: 'Custom field' });
      } catch (err) {
        this.redirectOnError(err.response.status)
        showErrorToast(this.$swal, 'updateError', { item: 'Custom field' });
      }

      this.filterCustomFields();
    },

    onDeleteCustomField(customField) {
      this.selectedCustomField = {
        uid: customField.uid || '',
        name: customField.name ||  '',
        type: customField.type || '',
        source: customField.source || '',
        options: customField.options || [],
      }

      this.showDeleteConfirmDialog = true
    },

    async deleteCustomField() {
      this.showDeleteConfirmDialog = false;
      if(!this._deleteCustomField){
        this.unauthorizedToast;
        return ;
      }
      try {
        await this.customFieldService.deleteCustomField(this.currentAccount.handle, this.$route.params.key, this.selectedCustomField.uid);

        this.originalCustomFields = this.originalCustomFields.filter(item => item.uid !== this.selectedCustomField.uid);
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Custom field' });
      } catch (err) {
        this.redirectOnError(err.response.status)
        showErrorToast(this.$swal, 'deleteError', { item: 'Custom field' });
      }

      this.filterCustomFields();
    },

    updateFilterCondition(data) {
      this.filter = {
        ...this.filter,
        types: data.types,
        sources: data.sources,
        startDate: data.startDate,
        endDate: data.endDate,
      }

      this.filterCustomFields()
    },

    filterCustomFields() {
      let filteredCustomFields = _.cloneDeep(this.originalCustomFields)

      if (this.filter.name) {
        const searchTerm = this.filter.name.toLowerCase(); // Convert search term to lowercase
        filteredCustomFields = filteredCustomFields.filter(item => item.name.toLowerCase().includes(searchTerm)); // Convert item name to lowercase
      }

      if (this.filter.types.length > 0) {
        filteredCustomFields = filteredCustomFields.filter(item => this.filter.types.includes(item.type))
      }

      if (this.filter.sources.length > 0) {
        filteredCustomFields = filteredCustomFields.filter(item => this.filter.sources.includes(item.source))
      }

      if (this.filter.startDate) {
        filteredCustomFields = filteredCustomFields.filter(item => item.createdAt >= this.filter.startDate)
      }

      if (this.filter.endDate) {
        filteredCustomFields = filteredCustomFields.filter(item => item.createdAt <= this.filter.endDate)
      }

      this.filteredCustomFields = filteredCustomFields
    }
  }
}
</script>