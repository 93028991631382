<template>
  <div
    id="case-index-container"
    fluid
    class="pl-3 pt-3"
  >
    <StatusesDialog
      v-model="statusDialog"
      @completed="completeTestRun"
    />
    <Loader v-if="loaderState" />
    <v-card
      v-else
      class="py-6 px-6"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <div class="d-flex flex-row justify-space-between">
        <div
          class="d-flex flex-row align-center pointer"
          @click="handleBackClick"
        >
          <v-icon color="black">
            mdi-arrow-left
          </v-icon>
          <h2 class="d-flex-inline justify-center align-center ml-2 ma-0 font-weight-bold">
            {{ name }}
          </h2>
          <div
            v-if="executionsCount"
            class="d-flex flex-row align-center justify-space-between ml-6"
          >
            <ProgressBar
              :executions="executionsProgress"
              :percentage="executionsPercentage"
              :case-count="executionsCount"
            />
          </div>
        </div>
        <div class="d-flex flex-row">
          <v-btn
            v-if="_writeEntity"
            color="#F2F4F7"
            class="text-capitalize btn-theme rounded-lg mr-3"
            height="40px"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '92px'"
            :loading="isRerunLoading"
            :class="{ 'disabled-action': isRerunLoading || isProjectArchived }"
            
            depressed
            @click="duplicateTestRun"
          >
            {{ $t('testruns.rerun') }}
          </v-btn>
          <v-btn
            v-if="_writeEntity"
            depressed
            color="primary"  
            height="40px"
            class="text-capitalize btn-theme rounded-lg"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '118px'"
            :loading="isCompleteRunLoading"
            :class="{ 'disabled-action': isCompleteRunLoading || isProjectArchived }"
            @click="statusDialog = true"
          >
            {{ $t('testruns.complete') }}
          </v-btn>
        </div>
      </div>
    </v-card>
    <ExecutionManagement
      v-if="!loaderState"
      :show-create="false"
      :execution="selectedExecution"
      :executions="testRunExecutions"
      :assignees="assignees"
      :test-results="testResults"
      :show-folders="showFolders"
      :folders="folders"
      :write-activity="_writeActivity"
      :delete-activity="_deleteActivity"
      :write-entity="_writeEntity"
      @bulkRemove="onBulkRemove"
      @getExecution="getExecution"
      @folder-select="getFolder"
      @updateExecution="updateTestExecution"
      @updateExecutions="updateTestExecutions"
      @updateSelectedExecution="updateSelectedExecution"
      @updateResult="updateTestResult"
      @deleteResult="deleteTestResult"
      @deleteExecution="deleteExecution"
      @addResult="addTestResult"
      @moveItem="moveSelectedItem"
    />
    <AddNewDefectDialog
      v-model="showAddDefectDialog"
      :title="$t('defect.addNewDefectDialog.title')"
      :content="$t('defect.addNewDefectDialog.content')"
      :color="'primary'"
      @close="showAddDefectDialog = false"
      @handle-actions-type="handleDefectActionType"
    />
    <CreateDefect
      v-if="showCreateDefectDialog"
      :create-defect-dialog="showCreateDefectDialog"
      :execution="failedExecution"
      :action-selected="selectedDefectAction"
      @closeDialog="showCreateDefectDialog = false"
    />
  </div>
</template>

<script>
// import SectionHeader from '@/components/TestCases/SectionHeader.vue';
import ExecutionManagement from '@/components/Execution/ExecutionManagement'
import { mapActions, mapGetters } from 'vuex';
import makeRunService from '@/services/api/run';
import makeExecutionService from '@/services/api/execution'
import makeResultService from '@/services/api/result'
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import { dateMixin } from '@/mixins/date.js';
import makeFoldersService from '@/services/api/folder';
import handleLoading from '@/mixins/loader.js'
import Loader from '@/components/base/Loader'
import projectStatus from '@/mixins/projectStatus';
import ProgressBar from '@/components/base/ProgressBar.vue';
import StatusesDialog from '@/components/TestRuns/StatusesDialog'
import CreateDefect from '@/components/Defect/CreateDefect'
import AddNewDefectDialog from '@/components/Defect/AddNewDefectDialog'
import colorPreferencesMixin from '@/mixins/colorPreferences';

export default {
  components:{
    ExecutionManagement,
    Loader,
    ProgressBar,
    StatusesDialog,
    CreateDefect,
    AddNewDefectDialog
  },
  mixins: [dateMixin, handleLoading, projectStatus, colorPreferencesMixin],
  props: {
    CustomCases : Array,
    name: String
  },
  data() {
    return {
      actionMenu: false,
      selectedCases: [],
      testRunExecutions: [],
      executionsPercentage: 0,
      executionsProgress: {},
      folderId: null,
      selectedExecution: null,
      assignees: [],
      testResults: [],
      folders: [],
      isRerunLoading: false,
      isCompleteRunLoading: false,
      showFolders: true,
      statusDialog: false,
      showDefectDialog: false,
      failedExecution: null,
      showAddDefectDialog: false,
      showCreateDefectDialog: false,
      selectedDefectAction: null,
      statuses: []
    };
  },
  computed:{
    ...mapGetters({
      user: 'user/user'
    }),
    executionsCount(){
      return this.testRunExecutions?.length
    },
    _readActivity(){
      return this.authorityTo('read_activity')
    },
    _writeActivity(){
      return this.authorityTo('write_activity')
    },
    _deleteActivity(){
      return this.authorityTo('delete_activity')
    },
    _writeEntity(){
      return this.authorityTo('write_entity')
    }

  },
  methods: {
    ...mapActions({
        updateTestRun:'run/updateTestRun',
        uploadToServer: 'attachment/uploadToServer',
      }
    ),
    handleBackClick() {
      //this.showConfirmBackDialog = true;
      this.$router.replace({
        name: 'Runs',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      });
    },
    async getFolders(handle, projectKey) {
      const folderService = makeFoldersService(this.$api);
      await folderService.getProjectFolders(handle, projectKey).then(response => {
        this.folders = response.data.folders;
      }).catch(() => {
        showErrorToast(this.$swal, this.$t("test_folder.refresh_failed"));
      })
    },
    completeTestRun(status) {
      if(!this._writeEntity){
        this.unauthorizedToast;
        return ;
      }
      
      const payload = {
        status,  
      }
      this.updateTestRun({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        uid: this.$route.params.id,
        payload
      })
        .then(() => {
          this.$router.push({
            name: 'Runs',
            params: {
              handle: this.$route.params.handle,
              key: this.$route.params.key
            },
          });
        })
        .catch(error => {
          console.error("Failed to Update Test Run:", error);
        }).finally(() => {
          this.isCompleteRunLoading = false;
        });
    },
    async duplicateTestRun(){
      if(!this._writeEntity){
        this.unauthorizedToast;
        return ;
      }
      const runService = makeRunService(this.$api);

      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const runId = this.$route.params.id;
      this.isRerunLoading = true;
      await runService.duplicateTestRun(handle, projectKey, runId).then(response => {
        showSuccessToast(this.$swal, this.$t('testruns.duplicate_testrun.success'))
        this.$router.push({
            name: 'Runs',
            params: {
              handle: this.$route.params.handle,
              key: this.$route.params.key
            },
          });
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('testruns.duplicate_testrun.error'))
      }).finally(()=> {
        this.isRerunLoading = false;
      })
    },
    async getTestRunExecutions(handle, projectKey, runId){
      if(!this._readActivity){
        this.unauthorizedToast;
        return ;
      }
      const runService = makeRunService(this.$api);
      await runService.getTestRunExecutions(
        handle,
        projectKey
        ,runId
      ).then(response => {
        this.testRunExecutions = response.data.executions;
        this.executionsPercentage = response.data.percentage;
        this.executionsProgress = response.data.executionsProgress;
        this.assignees = response.data.assignees;
        const currentUser = {
          email: this.user.email,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          handle: this.user.handle,
          uid: this.user.uid
        }
        this.assignees.push(currentUser)
      })
    },
    async getFolder(folderId){
      this.folderId = folderId;
    },
    async getExecution(executionId){
      if(!this._readActivity){
        this.unauthorizedToast;
        return ;
      }
      await this.getExecutionResult(executionId);
      const selectedExecution = this.testRunExecutions.find(element => element.uid == executionId);
      const updatedAt = this.formatDate(selectedExecution.updatedAt, 'MM/DD/YY HH:mm:ss');
      this.selectedExecution = {
        ...selectedExecution,
        updatedAt
      };
    },
    updateSelectedExecution(selectedCases) {
      this.selectedCases = selectedCases;
    },
    async getExecutionResult(executionUid){
      if(!this._readActivity){
        this.unauthorizedToast;
        return ;
      }
      const handle = this.$route.params.handle;
      const resultService = makeResultService(this.$api);
      const projectKey = this.$route.params.key; 

      this.testResults = await resultService.getTestResults(handle, projectKey, executionUid).then(async response => {
        return response.data   
      }) 
    },
    onBulkRemove(payload){
      console.log("Delete from Test Run", payload)
    },
    async updateTestExecution(updatedData){
      if(!this._writeActivity) {
        this.unauthorizedToast;
        return;
      }

      const executionService = makeExecutionService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const selectedExecution = this.selectedExecution?.uid;
      let payload = {};
      
      if(typeof(updatedData.value) == 'object') {
        Object.assign(payload, {
          [updatedData.property]: updatedData.value.uid
        })
      } else {
        Object.assign(payload, {
          [updatedData.property]: updatedData.value
        })
      }

      const previousAssignedTo = this.selectedExecution?.assignedTo;
      
      try {
        const response = await executionService.updateExecution(handle, projectKey, selectedExecution, payload);
        const execution = response.data;
        
        if(execution.uid) {
          const findIndex = this.testRunExecutions.findIndex(element => element.uid == execution.uid);
          this.selectedExecution = {...this.selectedExecution, ...execution};
          this.testRunExecutions.splice(findIndex, 1, {...this.selectedExecution, ...execution})
          
          // Show add defect dialog if status is Failed
          
          if (updatedData.property === 'status') {
            const statusName = this.getStatusName(updatedData.value, this.statuses);
            if (statusName?.toLowerCase() === 'failed') {
              this.failedExecution = execution;
              this.showAddDefectDialog = true;
            }
            else {
              this.showAddDefectDialog = false;
              this.showCreateDefectDialog = false;
              this.failedExecution = null;
            }
          }

        }

        if (updatedData.property === 'assignedTo' && previousAssignedTo !== execution.assignedTo) {
          showSuccessToast(this.$swal, this.$t('success.testCaseAssigned'));
        } else {
          showSuccessToast(this.$swal, this.$t('success.executionUpdated'));
        }
      } catch (error) {
        showErrorToast(this.$swal, this.$t('error.executionUpdateFailed'))
      }
    },
    async updateTestExecutions(data){
      if(!this._writeActivity){
        this.unauthorizedToast;
        return ;
      }
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const executionService = makeExecutionService(this.$api);
      const payload = {
        executionIds: this.selectedCases.map(item => item.uid),
        ...data
      }
      await executionService.updateExecutions(handle, projectKey, payload).then((response) => {
        if(response.data.length)
          response.data.forEach(element => {
            const eIndex = this.testRunExecutions.findIndex(obj => obj.uid == element.uid);
            this.testRunExecutions.splice(eIndex, 1, {...this.testRunExecutions[eIndex], ...element})
          })  
        showSuccessToast(this.$swal, this.$t('success.executionUpdated'))
      }).catch((err) => {
        showErrorToast(this.$swal, this.$t('error.executionUpdateFailed'))
      })
      
    },
    async deleteExecution(id){
      if(!this._deleteActivity){
        this.unauthorizedToast;
        return ;
      }
      const handle = this.$route.params.handle
      const projectKey = this.$route.params.key
      const executionService = makeExecutionService(this.$api);
      
      await executionService.deleteExecutions(handle, projectKey, id).then(() => {
        this.testRunExecutions = this.testRunExecutions.filter(element => element.uid !== id)
        showSuccessToast(this.$swal, this.$t('success.executionDeleted'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.executionDeletedFailed'))
      })
      
    },
    async moveSelectedItem(direction){
      let itemIndex = this.testRunExecutions.findIndex(element => element.uid == this.selectedExecution.uid)
      if(direction == 'next' && itemIndex < this.testRunExecutions.length-1)
        this.selectedExecution = this.testRunExecutions[itemIndex + 1]
      else if(direction == 'previous' && itemIndex > 0)
        this.selectedExecution = this.testRunExecutions[itemIndex - 1]

      await this.getExecutionResult(this.selectedExecution.uid)
    },
    async addTestResult(data){
      if(!this._writeActivity){
        this.unauthorizedToast;
        return ;
      }
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const selectedExecution = this.selectedExecution.uid;

      const payload = {
        status: data.status,
        comment: data.comment
      };

      await resultService.addTestResult(handle, projectKey, selectedExecution, payload).then(async (response) => {
        const result = response.data;
        const mediaType = 'attachment'
        const params = {
          handle,
          projectKey,
          resultUid: result.uid
        }
        if(data.files.length)
         await Promise.all(data.files.map(async (file) => {
          await this.uploadToServer( {handle, mediaType, file, apiService: resultService, params});
        })).then(() => {
          showSuccessToast(this.$swal, this.$t('success.testResultAdded'))
        }).catch(() => {
          showErrorToast(this.$swal, this.$t('error.failedToAddTestResult'))
        })
        else
          showSuccessToast(this.$swal, this.$t('success.testResultAdded'))
        
        this.selectedExecution = {...this.selectedExecution, ...{updatedAt : result.updatedAt}}
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.failedToAddTestResult'))
      })
      
      await this.getExecutionResult(selectedExecution)
    },
    async updateTestResult(resultUid,data){
      if(!this._writeActivity){
        this.unauthorizedToast;
        return ;
      }
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const payload = {
        status: data.status,
        comment: data.comment
      }
      
      await resultService.updateTestResult(handle, projectKey, resultUid, payload).then(async () => {
        const resultIndex = this.testResults.findIndex(element => element.resultUid == resultUid)
        this.testResults[resultIndex].status = payload.status[0].toUpperCase()+payload.status.slice(1)
        this.testResults[resultIndex].comment = payload.comment;
        const mediaType = 'attachment'
        const params = {
          handle,
          projectKey,
          resultUid
        }
        if(data.files.length)
          await Promise.all(data.files.map(async (file) => {
            await this.uploadToServer({handle, mediaType, file, apiService: resultService, params});
          })).then(() => {
            showSuccessToast(this.$swal, this.$t('success.testResultUpdated'))
          }).catch(() => {
            showErrorToast(this.$swal, this.$t('success.testResultUpdated'))
          })
        else
          showSuccessToast(this.$swal, this.$t('success.testResultUpdated'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('success.testResultUpdated'))
      })
    },
    async deleteTestResult(resultUid){
      if(!this._deleteActivity){
        this.unauthorizedToast;
        return ;
      }
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      
      await resultService.deleteTestResult(handle, projectKey, resultUid).then(() => {
        showSuccessToast(this.$swal, this.$t('success.testResultDeleted'));
        const resultIndex = this.testResults.findIndex(element => element.resultUid == resultUid)
        this.testResults.splice(resultIndex,1)
      }).catch( () => {
        showErrorToast(this.$swal, this.$t('error.failedToDeleteTestResult'))
      } )
    },
    handleDefectActionType(actionType) {
      this.showAddDefectDialog = false;
      this.selectedDefectAction = actionType;
      
      if (actionType === 'Create defects') {
        this.showCreateDefectDialog = true;
      } else if (actionType === 'Select defects') {
        this.showCreateDefectDialog = true;
      }
    }
  },
  async created(){
    const handle = this.$route.params.handle;
    const projectKey = this.$route.params.key;
    const id = this.$route.params.id;
    this.statuses = this.getStatuses("testCase");
    await this.init([
      this.getTestRunExecutions(handle,projectKey,id),
      this.getFolders(handle, projectKey),
    ])
  },
};
</script>
<style lang="scss" scoped>
.custom-switch {
  ::v-deep .v-input--switch__thumb {
    width: 24px;
    height: 24px;
    top: 0;
    right: 2px;
  }

  ::v-deep .primary--text{
    background-color: #ffffff !important; /* Custom track color when switch is on */
  }

  ::v-deep .primary--text.v-input--switch__track {
    background-color: #0000ff !important; /* Custom thumb color */
    opacity: 1;
  }
}
.toggle-folder{
  gap: 10px;
  align-items: center;
}
</style>
<style>
  .custom-progressbar{
    width: 200px;
  }
  .__attachment_progress{
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 8px;
    flex-direction: column !important;
    z-index: 999;
  }
</style>
