<template>
  <v-dialog
    v-model="showDialog"
    max-width="500"
    persistent
  >
    <v-card class="pa-2">
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ $t('testruns.completeRun') }}
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <p class="text-body-1 text-left font-weight-light mt-3">
          {{ $t('chooseStatus') }}
        </p>
        <v-select
          v-model="status"
          :items="items"
          item-text="name"
          item-value="id"
          dense
          append-icon="mdi-chevron-down"
          class="rounded-lg field-theme custom-prepend"
          height="38px"
          background-color="#F9F9FB"
          :menu-props="{ offsetY: true }"
        />
      </v-card-text>

      <v-card-actions class="pb-3">
        <v-row>
          <v-col cols="6">
            <v-btn
              color="gray-100"
              width="100%"
              class="text-capitalize"
              elevation="0"
              @click="showDialog = false"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="#0C2FF3"
              width="100%"
              elevation="0"
              class="white--text text-capitalize"
              @click="$emit('completed', status)"
            >
              {{ $t('complete') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import colorPreferencesMixin from '@/mixins/colorPreferences';

export default {
  name: 'StatusesDialog',
  mixins: [colorPreferencesMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    
    template: {
      type: Object,
      default: () => ({})
    },
  },
  data(){
    return{
      status: null,
      statuses: []
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    items(){
      return this.statuses.filter(element => element.isCompleted)
    }
  },
  created(){
    this.statuses = this.getStatuses("testRun");
  }
}
</script>