<template>
  <v-data-table
    v-model="selectedItems"
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="table-fixed data-table-style mt-6"
    :headers="filteredHeaders"
    :items="itemsPerView"
    :item-key="itemKey"
    show-select
  >
    <template v-slot:[`header.data-table-select`]="{ props, on }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          indeterminate-icon="icon-indeterminate"
          :input-value="props.value"
          :indeterminate="props.indeterminate"
          @change="on.input"
        />
      </div>
    </template>

    <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          :input-value="isSelected"
          @change="select"
          @click.stop
        />
      </div>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <div class="text-subtitle-2 text-truncate font-weight-bold">
        {{ item.name }}
      </div>
    </template>
    <template />
    <template v-slot:[`item.testruns`]="{ item }">
      <td class="d-flex align-center">
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold">
            {{ item.runs?.length }} {{ $t('milestone.addTestPlan.testRuns') }}
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.milestones`]="{ item }">
      {{ item.milestones.map(milestone => milestone.name).join(', ') || $t('noMilestonesFound') }}
    </template>
    <template v-slot:[`item.progress`]="{item}">
      <ProgressBar
        :executions="item?.executionsProgress"
        :percentage="item?.percentage"
        :case-count="countTestCases(item.runs)"
      />
    </template>

    <template v-slot:[`item.priority`]="{item}">
      <span
        :style="{ color: getPriorityColor(item.priority, priorities) }"
        class="text-capitalize fw-semibold"
      >{{ getPriorityName(item.priority, priorities) }}</span>
    </template>
    <template v-slot:[`item.status`]="{item}">
      <span
        :style="{ color: getStatusColor(item.status, statuses) }"
        class="text-capitalize fw-semibold"
      >{{ getStatusName(item.status, statuses) }}</span>
    </template>
    <template v-slot:[`item.configurations`]="{item}">
      <span>{{ item.customFields?.configurations }}</span>
    </template>
    <template v-slot:[`item.creationdate`]="{item}">
      <span class="">{{ formatDueDate(item.createdAt) }}</span>
    </template>
    <template v-slot:[`item.tags`]="{item}">
      <v-tooltip
        bottom
        left
        max-width="485px"
        :disabled="!Array.isArray(item.customFields?.tags) || item.customFields.tags?.length < 2"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            class="custom-attribute font-weight-regular text-theme-table-text"
            v-bind="attrs"
            v-on="on"
          >
            <template>
              <div class="text-truncate">
                {{
                  Array.isArray(item.customFields?.tags)
                    ? item.customFields.tags?.length > 0
                      ? item.customFields.tags.map(tag => `${tag}`).join(', ')
                      : $t('no_tags')
                    : typeof item.customFields?.tags === 'string' && item.customFields.tags.trim() !== ''
                      ? item.customFields.tags
                      : $t('no_tags')
                }}
              </div>
            </template>
          </span>
        </template>
        <span>
          {{
            Array.isArray(item.customFields?.tags)
              ? item.customFields.tags.map(tag => `${tag}`).join(', ')
              : ''
          }}
        </span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>
  
<script>
import { dateMixin } from '@/mixins/date.js';
import colorPreferencesMixin from '@/mixins/colorPreferences';
import ProgressBar from '@/components/base/ProgressBar'

export default {
    components: {
        ProgressBar,
    },
    mixins: [dateMixin, colorPreferencesMixin],
    props: {
        tableFilter: {
            type: String,
            default: 'all',
        },
        plansData: {
            type: Array,
        },
        filteredHeaders: {
            type: Array,
        },
        filteredItems: {
            type:Array,
        },
        rowClass: {
            type:Function,
        },
        value: {
          type: Array,
        }
    },
    data() {
        return {
            plans: this.plansData,
            itemKey: 'uid',
            isAllPlanChecked: false,
            isSelectedPlanChecked: false,
            statuses: [],
            priorities: []
        };
    },
    computed: {
        itemsPerView() {
            if (this.tableFilter === 'all') {
                return this.filteredItems;
            } else {
                return this.selectedItems;
            }
        },
        selectedItems:{
          get(){
            return this.value;
          },
          set(selectedItems){
            this.$emit('input', selectedItems)
          }
        }
    },
    created() {
      this.priorities = this.getPriorities("testPlan");
      this.statuses = this.getStatuses("testPlan");
    },
    methods: {
      formatDueDate(dueDate) {
        return this.formatDate(dueDate)
      },
      getProgress(progress) {
      const numericProgress = parseFloat(progress);
        return isNaN(numericProgress) ? 0 : numericProgress;
      },
      countTestCases(runs) {
        return Array.isArray(runs) ? runs.reduce((total, run) => total + (Array.isArray(run.testExecutions) ? run.testExecutions.length : 0), 0) : 0;
      },
    },
};
</script>
  