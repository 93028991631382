<template>
  <v-form
    ref="form"
    v-model="validForm"
    class=""
    role="sharedStepForm"
  >
    <v-dialog
      v-model="showDialog"
      transition="slide-x-transition"
      class="test-cases-filter-drawer dialog-theme"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ isEditMode ? $t('sharedStepPage.editSharedStep') : $t('sharedStepPage.createNewSharedStep') }}
            </h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>

          <div
            class="text-start mt-10"
          >
            <v-label class="text-left fs-14px text-theme-label font-weight-medium">
              {{ $t("title") }} <strong class="red--text text--lighten-1">*</strong>
            </v-label>
            <v-text-field
              v-model="sharedStep.name"
              type="text"
              height="38"
              background-color="#F9F9FB"
              placeholder="Title"
              :rules="requiredRules"
              class="field-theme"
            />
          </div>
        
          <div class="child-steps-area d-flex flex-column">
            <step-item
              v-for="(item, index) in sharedStep.steps"
              :key="item?.title + index"
              :data="item"
              :index="index"
              @delete-step="deleteStepItem"
              @input-step="onEditStepItem"
            />
          </div>
          <div class="d-flex flex-row justify-end">
            <v-btn
              class="text-capitalize btn-theme"
              depressed
              color="primary"
              height="40"
              @click="addNewStep"
            >
              <v-icon size="16">
                mdi-plus
              </v-icon>
              {{ $t('sharedStepPage.addNewStep') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          full-width
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="onCancel()"
        >
          {{ $t('cancel') }}
        </v-btn>
          
        <v-btn
          width="204.5px"
          color="primary"
          full-width
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          :loading="isCreateStepLoading"
          :class="{ 'disabled-action': isCreateStepLoading }"
          :disabled="!isFormValid"
          @click="onCreate()"
        >
          {{ isEditMode ? $t('save') : $t('create') }}
        </v-btn>
      </div>
    </v-dialog>
  </v-form>
</template>

<script>
import { tagTypes } from '@/constants/tag.js';
import StepItem from '@/components/Admin/SharedStep/StepItem';
import { requiredFieldValidationRules } from "@/utils/validation";

export default {
  name: 'CreateUpdateSharedStepDialog',

  components: {
    StepItem,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isCreateStepLoading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data()
  {
    return {
      requiredRules: requiredFieldValidationRules(this),
      requiredMultiSelectRules: [(value) => !!value || value.length > 0 || this.$t('error.requiredField')],
      validForm: false,
      tagTypes: tagTypes,
      sharedStep: {
        uid: '',
        name: '',
        steps: [],
      },
    };
  },

  computed: {
    showDialog: {
      get()
      {
        return this.value;
      },
      set(v)
      {
        this.$emit('input', v);
      },
    },

    isEditMode()
    {
      return !!this.sharedStep.uid;
    },

    isFormValid() {
      return this.validForm && this.sharedStep.steps.length > 0;
    },
  },

  watch: {
    showDialog(value)
    {
      if (!value) {
        return;
      }

      this.sharedStep = {
        uid: this.data.uid || '',
        name: this.data.name || '',
        steps: this.data.steps || '',
      };
    },
  },

  methods: {
    onCreate()
    {
      const isValidForm = this.$refs.form.validate();

      if (!isValidForm) {
        return;
      }
      this.$emit(this.isEditMode ? 'update-shared-step' : 'create-shared-step', this.sharedStep);
    },
    reset () {
        return this.$refs.form.reset()
      },
      resetValidation () {
        return this.$refs.form.resetValidation()
      },
    addNewStep()
    {
      const step = {
        title: '',
        description: '',
        expectedResult: '',
      };
      this.sharedStep.steps.push(step);
    },

    deleteStepItem(itemIndex)
    {
      const steps = [...this.sharedStep.steps];
      steps.splice(itemIndex, 1);
      this.sharedStep.steps = steps;
    },

    onEditStepItem(itemIndex, stepItem)
    {
      this.sharedStep.steps[itemIndex] = stepItem;
    },

    onCancel()
    {
      this.resetValidation();
      this.reset();
      this.$emit('close-dialog');
    },
  },
};
</script>

