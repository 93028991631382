<template>
  <div>
    <v-sheet
      color="#F2F4F7"
      class="d-flex align-center justify-center pointer"
      height="40px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-3 py-2 d-flex flex-row">{{ $t('filters') }} <v-icon
        size="16px"
        class="ml-2"
      >mdi-filter-variant</v-icon></span>
    </v-sheet>

    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="close()"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>

          <v-expansion-panels
            v-model="priorityPanel"
            flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('priority') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(item, index) in priorities"
                  :key="index"
                >
                  <v-checkbox
                    v-model="selectedPriorities"
                    :value="item.id"
                    color="blue"
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <span class="fs-14px text-theme-label">{{ item.name }}</span>
                    </template>
                  </v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels
            v-model="datePanel"
            flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('date') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      v-model="showStartDateCalendar"
                      :close-on-content-click="false"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          dense
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          clearable
                          :placeholder="$t('customFieldPage.startDate')"
                          height="38"
                          background-color="#F9F9FB"
                          class="text-field mt-0 field-theme pa-0 rounded-lg custom-prepend"
                          v-on="on"
                        >
                          <template v-slot:prepend-inner>
                            <calendarBlueIcon />
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        :max="today"
                        @input="showStartDateCalendar = false"
                      />
                    </v-menu>
                  </v-col>

                  <v-col cols="6">
                    <v-menu
                      v-model="showEndDateCalendar"
                      :close-on-content-click="false"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          dense
                          filled
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          clearable
                          background-color="#F9F9FB"
                          class="text-field mt-0 field-theme pa-0 rounded-lg custom-prepend"
                          :placeholder="$t('customFieldPage.endDate')"
                          height="38"
                          hide-details
                          v-on="on"
                        >
                          <template v-slot:prepend-inner>
                            <calendarBlueIcon />
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        :max="today"
                        @input="showEndDateCalendar = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels
            v-model="statusPanel"
            flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('status') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(item, index) in statuses"
                  :key="index"
                >
                  <v-checkbox
                    v-model="selectedStatuses"
                    :value="item.id"
                    color="blue"
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <span
                        class="fs-14px text-theme-label"
                        :style="{ color: item.color }"
                      >
                        {{ item.name }}
                      </span>
                    </template>
                  </v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="clearAll"
        >
          {{ $t('clearAll') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          elevation="0"
          @click="apply"
        >
          {{ $t('apply') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { formatDate } from '@/utils/util';
import calendarBlueIcon from '@/assets/svg/calendar-blue.svg';

export default {
  name: 'DefectFilterDialog',
  components: {
    calendarBlueIcon,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        priorities: [],
        statuses: [],
        startDate: null,
        endDate: null,
      })
    },
    priorities: {
      type: Array,
      default: () => []
    },
    statuses: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showDialog: false,
      priorityPanel: 0,
      selectedPriorities: [],
      statusPanel: 0,
      selectedStatuses: [],
      datePanel: 0,
      showStartDateCalendar: false,
      startDate: null,
      showEndDateCalendar: false,
      endDate: null,
    };
  },

  computed: {
    today() {
      return formatDate(new Date(), 'yyyy-MM-dd')
    }
  },
  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      this.selectedPriorities = this.data.priorities || []
      this.selectedStatuses = this.data.statuses || []
      this.startDate = this.data.startDate
      this.endDate = this.data.endDate
    },
  },
  methods: {
    apply() {
      this.$emit('update-filter-condition', {
        priorities: this.selectedPriorities,
        statuses: this.selectedStatuses,
        startDate: this.startDate,
        endDate: this.endDate,
      });

      this.showDialog = false;
    },

    clearAll() {
      this.selectedPriorities = []
      this.selectedStatuses = []
      this.startDate = null
      this.endDate = null
    },

    close() {
      this.showDialog = false;
    }
  }
}
</script>