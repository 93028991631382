<template>
  <div
    class="white fill-height font-inter rounded-lg app-height-global pb-3 mt-3 flex-grow-1 ai-assist"
  >
    <v-form>
      <div class="ai-assit-header">
        <h3>{{ $t('aiAssist.aiCreate') }}</h3>
        <div class="header-actions">
          <v-btn icon>
            <v-icon
              color="#0C111D"
              @click="$emit('closeAssist')"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="ai-assist-inputs mt-6">
        <div class="text-left">
          <v-label class="text-left fs-14px text-theme-label font-weight-medium">
            {{ $t('prompt') }}
          </v-label>
          <v-text-field
            v-model="userPrompt"
            :placeholder="$t('title')"
            class="round-8 field-theme"
            background-color="#F9F9FB"
            dense
            height="38px"
            required
          />
        </div>
        <div class="text-left">
          <v-label class="text-left fs-14px text-theme-label font-weight-medium">
            {{ $t('template') }}
          </v-label>
          <v-select
            v-model="selectedTemplate"
            append-icon="mdi-chevron-down"
            :items="templates"
            item-value="uid"
            item-text="name"
            class=" mt-0 pt-1 rounded-lg field-theme custom-prepend"
            :placeholder="$t('text')"
            background-color="#F9F9FB"
            height="38px"
            :menu-props="{ offsetY: true }"
          />
        </div>
        <div>
          <div
            class="item-area mb-2"
          >
            <v-label class="text-left fs-14px text-theme-label font-weight-medium">
              {{ $t('addAttachment') }}
            </v-label>
          </div>
          <div
            class="item-area position-relative"
          >
            <fileInput
              class="w-full"
              @onFileChange="pullText"
            >
              <p class="text-center">
                Drag&Drop or any files you would like AI to consider in test case creation
              </p>
            </fileInput>
          </div>
        </div>
      </div>
      <div class="ai-assist-panel text-left mt-6">
        <v-label class="text-left fs-14px text-theme-label font-weight-medium mb-3 d-block">
          {{ $t('aiAssist.suggestedPrompts') }}
        </v-label>
        <div class="panel-list">
          <transition-group
            name="ai-list-item"
            tag="ul"
          >
            <li
              v-for="(item, index) in prompts"
              :key="item"
              class="ai-assist-item"
              :style="{'transition-delay': `${index * 250}ms`}"
              @click="setPrompt(item)"
            >
              <span>{{ item }}</span>
            </li>
          </transition-group>
        </div>
      </div>
      <div class="ai-assist-action d-flex mt-6">
        <v-btn
          depressed
          background-color="#F2F4F7"
          class="text-capitalize btn-theme"
          height="38px"
          width="100%"
          max-width="128px"
          @click="cancelResponse"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          depressed
          class="f-color-white btn-theme"
          color="primary"
          :loading="assistLoading"
          :disabled="!validateGenerate"
          @click="getResponse"
        >
          {{ $t('generate') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>


<script>
import { mapGetters, mapMutations } from 'vuex';
import makeAssistService from '@/services/api/assist';
import { showErrorToast } from '@/utils/toast';
import fileInput from "@/components/base/FileInput.vue";
import extractContent from '@/utils/extractContent'
import axios from 'axios'
export default {
  name: 'TextAssist',
  components: {
    fileInput
    // CustomFieldFromTemplate,
  },
  data() {
    return {
      dialog: [],
      newMessage: { id: null, author: "user", content: "" },
      originalText: "",
      currentText: "", 
      handle: null,
      loading: false,
      files: [],
      assistLoading: false,
      user: null,
      userPrompt: null,
      prompts: [],
      selectedTemplate: null,
      source: null
    };
  },
  computed: {
    ...mapGetters(['isTextAssistOpen', 'textAssistField', 'textAssistCallback']),
    templateFields(){
      return this.templates.find(element => element.uid == this.selectedTemplate)?.customFields
    },
    validateGenerate(){
      return !!this.selectedTemplate && !!this.userPrompt && this.templateFields.length;
    },
  },
  props:{ 
    templates: Array
  },
  mounted() {
    this.suggestedPrompts()
  },

  async beforeRouteUpdate(to, from, next) {
    const handle = to.params.handle;
    if (handle && handle !== from.params.handle) {
      this.handle = handle;
      try {
        await this.init(handle);
        next();
      } catch (error) {
        next();
      }
    } else {
      next();
    }
  },

  methods: {
    ...mapMutations(['toggleTextAssist']),

    scrollToBottom() {
      const container = this.$refs.chatContainer;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
    setPrompt(val){
      this.userPrompt = val;
    },
    async getResponse() {
      this.assistLoading = true
      const cancelToken = axios.CancelToken;
      this.source = cancelToken.source();

      const textFields = this.templateFields.reduce((acc, curr) => {
        if(curr.dataType == 'text')
          acc.push(curr.name)
        return acc;
      }, [])
      
      const data = {
        userPrompt: this.userPrompt,
        textFields,
        files: this.files,
        type: 'caseCreation'
      };

      const handle = this.$route.params.handle;
      const assistService = makeAssistService(this.$api);
      await assistService.getAssistResponse(handle, data, this.source).then(response => {
        if(response.data.assistResponse.message)
          showErrorToast(this.$swal, response.data?.response?.message || this.$t('error.internalServerError'));
        else
          this.$emit('response', response.data.assistResponse, this.selectedTemplate)
      }).catch(err => {
        if(axios.isCancel(err)){
          console.log("Request has been canceled");
        }
      })
      this.showPrompts = false;
      this.assistLoading = false;
    },
    async cancelResponse(){
      if(this.source){
        this.source.cancel();
        this.assistLoading = false;
      }
    },
    async pullText(files){
      this.files = []
      for(const file of files){
        this.files.push({
          name: file.name,
          content: await extractContent.handleFile(file)
        })
      }
    },
    acceptChanges() {
      this.textAssistField.value = this.currentText;
      
      this.originalText = this.currentText;
      this.onCloseAssist();
    },

    rejectChanges() {
      this.textAssistField.value = this.originalText;
      this.onCloseAssist();
    },

    revertChanges(messageId) {
      const message = this.dialog.find((msg) => msg.id === messageId);
      
      if (message) {
        this.currentText = message.newValue;
        if (this.textAssistField) {
          this.textAssistField.value = message.newValue;
        }
      }
    },

    onCloseAssist() {
      this.textAssistField.value = this.originalText;
      this.toggleTextAssist();
      this.dialog = [];
      this.newMessage.content = "";
    },
    async suggestedPrompts(){
    // API Retrieve user prompt here
      this.prompts = [
        "Generate a functional test case for Password Strength Validation, focused on the following features:Ensuring that users' passwords meet strength criteria (e.g., length, complexity, special characters) during account creation or password change.",
        "Generate a functional test case for Remember Me functionality, focused on the following features: Persistence of user session across sessions User should remain logged in after closing and reopening the browser (based on 'Remember Me' option)",
        "Create a functional test case to validate the Email Verification functionality, focusing on the following features: Ensuring that users receive a verification email upon registration and are able to verify their email address through the provided link.",
        'Design a functional test case to verify the User Session Timeout functionality, concentrating on the following features: Ensuring that user sessions automatically expire after a set period of inactivity and that the user is logged out after the timeout'
      ];
    }
  },

};  
</script>


<style scoped lang="scss">
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ai-assist{
  padding: 24px;
  width: 477px;
  .ai-assit-header{
    display: flex;
    align-items: center;
    justify-content: space-between  ;
    h3{
      font-size: 24px;
      color: #18181A
    }
  }
  .ai-assist-inputs{
    display: flex;
    flex-direction: column;
    gap: 16px;
    .item-area {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      justify-content: flex-start;
      .v-input__prepend-inner{
        position: absolute !important;
      }
    }
  }
  .ai-assist-panel{
    width: 100%;
    min-height: 250px;
    .panel-list{
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
      ul{
        list-style: none;
        padding: 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      li.ai-assist-item{
        background-color: #F3F4F6;
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid transparent;
        span{
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          font-size: 14px;
        }
        &:hover{
          cursor: pointer;
          border: 1px solid rgb(112, 112, 112);
        }
      }
    }
    .loader{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: inline-block;
      border-top: 3px solid #0C2FF3;
      border-right: 3px solid transparent;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
  }

  .ai-assist-action{
    gap: 20px;
    flex-wrap: wrap;
    button{
      width: calc(50% - 10px) !important;
      max-width: none !important;
    }
  }
}
</style>
<style>
li.ai-assist-item {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  display: inline-block;
  margin-right: 10px;
}
.ai-list-item-enter{
  opacity: 0;
  transform: translateX(30px);
}
.ai-list-item-leave-active {
  position: absolute;
}
.ai-list-item-leave{
  transition: opacity 0s;
}
</style>