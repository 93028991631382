import DefaultLayout from '@/Layouts/DefaultLayout.vue';
import AdminLayout from '@/Layouts/AdminLayout.vue';

const routes = [
  {
    path: '/:handle/admin',
    component: DefaultLayout,
    children: [ // This weird structure is to allow nesting of Layouts
      {
        path: '',
        component: AdminLayout,
        children: [
          {
            path: '',
            redirect: 'users',
          },
          {
            path: 'users',
            name: 'UsersView',
            component: () => import('@/views/Admin/Users/UsersView'),
            meta: {
              requiredAuthz: ['read_member'],
            },
          },
          {
            path: 'roles',
            name: 'Roles',
            component: () => import('@/views/Admin/Roles/Index'),
            meta: {
              requiredAuthz: ['read_role'], 
            },
          },
          {
            path: 'tags',
            name: 'Tags',
            component: () => import('@/views/Admin/Tags/Index'),
          },
        
          {
            path: 'roles/create',
            name: 'AdminRoleCreate',
            component: () => import('@/views/Admin/Roles/RolesCreateView'),
            meta: {
              requiredAuthz: ['write_role'],      
            },
          },
          {
            path: 'roles/:id/edit',
            name: 'AdminRoleEdit',
            component: () => import('@/views/Admin/Roles/RolesEditView'),
            props: true,
            meta: {
              requiredAuthz: ['write_role'],    
            },
          },
          {
            path: 'roles/:id/member',
            name: 'AdminRolesMember',
            component: () => import('@/views/Admin/Roles/RolesMemberView'),
            props: true,
            meta: {
              requiredAuthz: ['read_member'],
            },
          },
        ],
      },
    ],
  },
];

export default routes.map(route => {
  const meta = {
    ...route.meta,
    requiresAuth: true,
    authzScope: 'org',
  };
  return { ...route, meta };
});
