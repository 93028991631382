var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"white py-6 px-6 mt-3",attrs:{"rounded":"lg","elevation":"0","width":"100%"}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('h2',[_vm._v(_vm._s(_vm.$t('integrations.name')))])]),_c('div',{staticClass:"mt-4 d-flex"},[_c('v-chip',{class:{
        'blue--text': _vm.filter === 'added',
        'fw-semibold': _vm.filter === 'added',
        'font-weight-medium': _vm.filter != 'added',
      },attrs:{"width":"200px","color":_vm.filter === 'added' ? '#e6ecff' : '#f9fafb',"label":""},on:{"click":function($event){return _vm.updateFilter('added')}}},[_c('div',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$t('integrations.header.added'))+" "),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.addedItemCount))])])]),_c('div',{staticClass:"ml-2"},[_c('v-chip',{class:{
          'blue--text': _vm.filter === 'all',
          'fw-semibold': _vm.filter === 'all',
          'font-weight-medium': _vm.filter != 'all',
        },attrs:{"width":"200px","color":_vm.filter === 'all' ? '#e6ecff' : '#f9fafb',"label":""},on:{"click":function($event){return _vm.updateFilter('all')}}},[_c('div',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$t('integrations.header.all'))+" "),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.allItemCount))])])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }