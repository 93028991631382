import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import ProjectLayout from '@/Layouts/ProjectLayout.vue';
import projectRoutes from '@/router/projects';
import adminRoutes from '@/router/admin';
import runRoutes from '@/router/runs';
import milestoneRoutes from '@/router/milestones';
import caseRoutes from '@/router/cases';
import testPlansRoutes from '@/router/plans';
import projectSettingsRoutes from '@/router/projectSettings';
import settingsRoutes from '@/router/settings'
import workspaceRoutes from '@/router/workspace';
import integrationRoutes from '@/router/integration';
import defectRoutes from '@/router/defect';

Vue.use(VueRouter);

export default function makeRouter(store) {
  const routes = [
    {
      path: '/:handle/:key/dashboard',
      component: ProjectLayout,
      meta: {
        requiresAuth: true,
        isDashboard: true,
      },
      children: [
        {
          path: ':id?/',
          name: 'Dashboard',
          component: () => import('@/views/Dashboard'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    ...settingsRoutes,
    {
      // TODO combine with /organizations/ in settings
      path: '/organizations/:handle/invite/:token',
      component: AuthLayout,
      children: [
        {
          path: '',
          name: 'GetInvite',
          component: () => import('@/views/Org/GetInvite.vue'),
          beforeEnter: (to, from, next) => {
            if (!store.getters['user/isAuthenticated']) {
              next({
                name: 'signup',
                query: { 
                  token: to.params.token,
                  org: to.params.handle 
                },
                replace: true
              });
            } else {
              next();
            }
          }
        },
      ],
    },
    {
      path: '/',
      component: AuthLayout,
      beforeEnter: (to, from, next) => {
        if (to.fullPath === '/') {
          if (store.state?.user?.currentAccount?.handle) {
            next({
              name: 'Workspace',
              params: { handle: store.state.user.currentAccount.handle },
            });
          } else {
            next({ name: 'Login' });
          }
        } else {
          next();
        }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: () => import('@/views/Auth/LoginPage'),
        },
        {
          path: 'signup',
          name: 'signup',
          component: () => import('@/views/Auth/RegisterPage'),
        },
        {
          path: 'confirmEmail',
          name: 'confirmEmail',
          component: () => import('@/views/Auth/ConfirmEmailPage'),
        },
        {
          path: 'createPassword',
          name: 'createPassword',
          component: () => import('@/views/Auth/CreatePasswordPage'),
        },
        {
          path: 'forgotPassword',
          name: 'forgotPassword',
          component: () => import('@/views/Auth/ForgotPasswordPage'),
        },
        {
          path: 'resetPassword',
          name: 'resetPassword',
          component: () => import('@/views/Auth/ResetPasswordPage'),
        },
        {
          path: 'twoFactor',
          name: 'twoFactor',
          component: () => import('@/views/Auth/TwoFactorPage'),
        },
      ],
    },
    ...projectRoutes,
    ...adminRoutes,
    ...caseRoutes,
    ...runRoutes,
    ...milestoneRoutes,
    ...testPlansRoutes,
    ...projectSettingsRoutes,
    ...workspaceRoutes,
    ...integrationRoutes,
    ...defectRoutes,
    {
      path: '/setup',
      name: 'Setup',
      component: () => import('@/views/Setup.vue'),
    },
    {
      path: '/notFound',
      name: '404',
      component: () => import('@/views/404.vue'),
    },
    {
      path: '*',
      name: 'NotFound',
      component: () => import('@/views/404.vue'),
    },
  ];

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });

  const populateAuthz = async (scope, handle, projectKey) => {
    if (!handle) {
      throw 'Authz scoped pages must provide a handle';
    }
    const orgIndex = store.state.user.orgs.findIndex(
      (org) => org.handle === handle
    );
    if(!store.state.user.orgs?.map(item => item.handle).includes(handle)){
      if (
        !projectKey &&
        (!store.state.user.orgAuthzPermissions || store.state.user.orgAuthzUpdatedAt < Date.now() - (5 * 60 * 1000))
      ){
        await store.dispatch('user/updateOrgAuthz', handle)
      }
      if(
          projectKey
          &&
          (!store.state.user?.projectAuthz?.[projectKey]?.permissions 
          || !store.state.user?.projectAuthz?.[projectKey]?.updatedAt < Date.now() - (5 * 60 * 100)
          )
        ){
          await store.dispatch('user/updateProjectAuthz', {handle, projectKey})
        }
    }else{
      if (scope === 'org') {
        if (
          !store.state.user.orgs[orgIndex].orgAuthzUpdatedAt ||
          store.state.user.orgs[orgIndex].orgAuthzUpdatedAt < Date.now() - (5 * 60 * 1000)
        ) {
          await store.dispatch('user/updateOrgAuthz', handle);
        }
      }
      if (scope === 'project') {
        if (!projectKey) {
          throw 'Project authz scoped pages must provide a key';
        }
        if (
          !store.state.user.orgs[orgIndex]?.projectAuthz?.[projectKey]?.updatedAt ||
          store.state.user.orgs[orgIndex]?.projectAuthz?.[projectKey]?.updatedAt < Date.now() - (5 * 60 * 1000)
        ) {
          await store.dispatch('user/updateProjectAuthz', { handle, projectKey });
        }
      }
    }
  };

  router.beforeEach(async (to, from, next) => {
    // Excluded Routes from auth
    const excludedRoutes = [
      'GetInvite'
    ];
    const show404 = store.getters['error404/show404'];
    if (show404) {
      store.commit('error404/SET_SHOW_404', false);
    }
    // Check if the route requires authentication
    if ( !excludedRoutes.includes(to.name) && (to.matched.some((record) => record.meta.requiresAuth) || to.fullPath === '/')) {
      // If user is authenticated, continue
      if (store.getters['user/isAuthenticated']) {
        // Set "currentAcount" on direct navigation
        let authzd = false;
        if (to.params.handle && to.params.handle !== store.state.user.user.handle) {
          // Populate permissions from the backend
          const scope = to.matched.map((r) => r.meta.authzScope).findLast((s) => s);
          await populateAuthz(
            scope,
            to.params.handle,
            to.params.key
          );
          const orgIndex = store.state.user.orgs.findIndex(
            (org) => org.handle === to.params.handle
          );
          const isWorkspacePermissions = !store.state.user.orgs?.map(item => item.handle).includes(to.params.handle)
          const currentPermissions =
            scope === 'org' ?
              (isWorkspacePermissions ? store.state.user.orgAuthzPermissions : store.state.user.orgs[orgIndex].orgAuthzPermissions):
              (isWorkspacePermissions ? store.state.user.projectAuthz[to.params.key].permissions  : store.state.user.orgs[orgIndex].projectAuthz[to.params.key]?.permissions);

          const requiredPermissions =
            to.matched.flatMap((r) => r.meta.requiredAuthz);
          if ( requiredPermissions.every((p) => !p || currentPermissions.includes(p)) ) {
            authzd = true;
          }
        } else {
          authzd = true;
        }
        if (authzd === true) {
          // Set "currentAcount" on direct navigation
          if ( to.params.handle && to.params.handle !== store.state.user.currentAccount.handle ) {
            store.state.user.currentAccount = store.state.user.orgs.find((org) => org.handle === to.params.handle);
          }
          if (to.fullPath === '/') {
            return next({
              name: 'Workspace',
              params: { handle: store.state.user.currentAccount.handle }
            });
          } else {
            return next();
          }
        } else {
          store.commit('error404/SET_SHOW_404', true);
          return next();
        }
      } else {
        return next({ name: 'Login' });
      }
    } else if (to.fullPath === '/') {
      if (store.state?.user?.currentAccount?.handle) {
        // If they do, redirect to ProjectsView
        return next({
          name: 'Workspace',
          params: { handle: store.state.user.currentAccount.handle },
        });
      } else {
        // If not, allow navigation (which will be handled by the route's beforeEnter)
        return next();
      }
    } else if (to.matched.length === 0) {
      store.commit('error404/SET_SHOW_404', true); 
      return next(false);
    }  
    else {
      // For all other routes, allow navigation
      return next();
    }
  });

  return router;
}
