<template>
  <div>
    <v-divider class="divider-custom" />
    <div class="step-container position-relative mt-3">
      <span class="step-label-corner">
        {{ stepType }}
      </span>
      <div class="case-contents pl-4 pr-4">
        <div class="step-header mb-4 d-flex align-center">
          <!-- left side -->
          <div class="flex align-center justify-start">
            <v-checkbox
              id="remember-me-checkbox"
              v-model="localSelected"
              class="field-theme"
              :ripple="false"
              off-icon="icon-checkbox-off"
              on-icon="icon-checkbox-on"
            />
            <icon-dots />
            <h4 class="ml-1">
              {{ title }}
            </h4>
          </div>
          <!-- right side -->
          <v-menu
            offset-y
            bottom
            class="rounded-lg"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                depressed
                class="property"
                v-bind="attrs"
                :plain="true"
                icon
                v-on="on"
              >
                <v-icon size="24px">
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-card
              rounded="8"
              elevation="0"
            >
              <v-list>
                <v-list-item
                  :key="1"
                  @click="$emit('duplicate-step', stepIndex, false)"
                >
                  <div class="d-flex align-center">
                    <duplicateIcon />
                  </div>
                  <v-list-item-title class="pl-3">
                    {{ $t('duplicate') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :key="2"
                  @click="$emit('create-shared-step', false)"
                >
                  <div class="d-flex align-center">
                    <createSharedStepIcon />
                  </div>
                  <v-list-item-title class="pl-3">
                    {{ $t('createSharedStep') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :key="3"
                  @click="createChildStep"
                >
                  <div class="d-flex align-center">
                    <addChildStepIcon />
                  </div>
                  <v-list-item-title class="pl-3">
                    {{ $t('addChildStep') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :key="4"
                  @click="$emit('delete-item', stepIndex, localIsSharedStep)"
                >
                  <div class="d-flex align-center">
                    <removeIcon />
                  </div>
                  <v-list-item-title class="color-red pl-3">
                    {{ $t('remove') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
        <div class="ml-9 block">
          <div class="text-left">
            <v-label class="fs-14px text-theme-label font-weight-medium">
              {{ $t('stepDescription') }} <strong class="red--text text--lighten-1">*</strong>
            </v-label>
          </div>
          <v-textarea
            ref="description"
            v-model="caseStep.description"
            auto-grow
            :placeholder="$t('stepDescription')"
            background-color="#F9F9FB"
            class="round-8 mt-1 mb-2 pt-0 stepDescription"
            :rules="descriptionValidation"
          >
            <template v-slot:append>
              <v-btn
                icon
                :fab="false"
                @click="openAssist(`step-${stepIndex}`, 'description', 'Step Description')"
              >
                <v-icon>mdi-shimmer</v-icon>
              </v-btn>
            </template>
          </v-textarea>
          <div class="text-left">
            <v-label class="fs-14px text-theme-label font-weight-medium">
              {{ $t('expectedResult') }} <strong class="red--text text--lighten-1">*</strong>
            </v-label>
          </div>
          <v-textarea
            ref="expectedResult"
            v-model="caseStep.expectedResult"
            auto-grow
            :placeholder="$t('expectedResult')"
            background-color="#F9F9FB"
            class="round-8 mt-1 mb-2 pt-0 expectedResult"
            :rules="resultValidation"
          >
            <template v-slot:append>
              <v-btn
                icon
                :fab="false"
                @click="openAssist(`step-${stepIndex}`, 'expectedResult', 'Step Expected Result')"
              >
                <v-icon>mdi-shimmer</v-icon>
              </v-btn>
            </template>
          </v-textarea>
          <!-- child steps area -->
          <div>
            <child-step
              v-for="(item, index) in caseStep.children"
              :key="item.id"
              :parent-title="title"
              :step-index="index"
              :child-step="item"
              @description-change-child="changeDescription"
              @result-change-child="changeResult"
              @delete-child="removeChildStep"
              @duplicate-child="duplicateChildStep(index)"
              @add-child="handleAddChild(index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconDots from '@/assets/svg/dots-20x20-gray.svg';
import ChildStep from './ChildStep.vue';
import removeIcon from '@/assets/svg/remove.svg';
import createSharedStepIcon from '@/assets/svg/create-shared-step.svg';
import addChildStepIcon from '@/assets/svg/add-child-step.svg';
import duplicateIcon from '@/assets/svg/duplicate.svg';
import { requiredFieldValidationRules } from '@/utils/validation';

export default {
  components: {
    ChildStep,
    iconDots,
    removeIcon,
    createSharedStepIcon,
    addChildStepIcon,
    duplicateIcon,
  },
  props: ['caseStep', 'title', 'stepIndex', 'selected', 'fieldId'],

  data() {
    return {
      children: [],
      stepCount: 0,
      description: '',
      expectedResult: '',
      descriptionValidation: requiredFieldValidationRules(this),
      resultValidation: requiredFieldValidationRules(this),
      localSelected: this.selected,
    };
  },
  computed: {
    isSharedStep() {
      return this.caseStep && this.caseStep.sharedStepUid !== undefined && this.caseStep.sharedStepUid !== null;
    },
    stepType() {
      return this.isSharedStep ? 'Shared step' : 'Step';
    },
  },
  watch: {
    selected(newValue) {
      this.localSelected = newValue;
    },
    localSelected(newValue) {
      this.$emit('toggle-selection', this.stepIndex, newValue);
    },
  },

  created() {
    this.description = this.caseStep.description;
    this.expectedResult = this.caseStep.expectedResult;
    this.children = this.caseStep.children;
    this.stepCount = this.children?.length ?? 0;
  },
  methods: {
    createChildStep() {
      let key = this.stepCount + 1;
      this.stepCount++;
      let title = 'Child ' + ((this.children?.length ?? 0) + 1);
      let newItem = {
        id: key,
        description: '',
        expectedResult: '',
        title,
      };
      if (!this.children) {
        this.children = [];
      }
      this.children.push(newItem);
      this.$emit('children-change', this.children, this.stepIndex);
    },
    duplicateChildStep(index) {
      const childToDuplicate = this.children[index];
      const duplicatedChild = {
        ...JSON.parse(JSON.stringify(childToDuplicate)),
        id: this.stepCount + 1,
        title: `Child ${this.children.length + 1}`,
      };
      this.stepCount++;
      this.children.push(duplicatedChild);
      this.$emit('children-change', this.children, this.stepIndex);
    },
    async openAssist(fieldKey, fieldType, fieldName) {
      this.$emit('openAssist', fieldKey ,fieldType, fieldName);
    },
    handleAddChild(index) {
      let key = this.stepCount + 1;
      this.stepCount++;
      let title = 'Child ' + ((this.children?.length ?? 0) + 1);
      let newItem = {
        id: key,
        description: '',
        expectedResult: '',
        title,
      };
      if (!this.children) {
        this.children = [];
      }
      this.children.splice(index + 1, 0, newItem);
      this.$emit('children-change', this.children, this.stepIndex);
    },
    removeChildStep(index) {
      this.children.splice(index, 1);
    },
    changeResult(result, index) {
      this.children[index].expectedResult = result;
      this.$emit('children-change', this.children, this.stepIndex);
    },
    changeDescription(description, index) {
      this.children[index].description = description;
      this.$emit('children-change', this.children, this.stepIndex);
    },
  },
};
</script>

<style>
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.divider-custom {
  border-color: #eaecf0 !important;
}
.stepDescription.v-textarea .v-input__control .v-input__slot .v-input__append-inner,
.expectedResult.v-textarea .v-input__control .v-input__slot .v-input__append-inner{
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
