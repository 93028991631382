<template>
  <v-card
    class="white py-6 px-6 mt-3"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex align-center justify-space-between">
      <h2>Projects list </h2> 
      <v-btn
        v-if="writeProject"
        color="blue"
        depressed
        height="40px"
        class="text-capitalize rounded-lg btn-theme white--text"
        :to="{ name: 'ProjectCreateView'}"
      >
        {{ $t('createProject') }} <v-icon
          class="ml-1"
          size="16px"
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </div>
    <div class="mt-4 d-flex">
      <v-chip 
        :class="{ 'blue--text': filter === 'active',
                  'fw-semibold': filter === 'active',
                  'font-weight-medium': filter != 'active'
        }"
        width="200px" 
        :color="filter === 'active' ? '#e6ecff' : '#f9fafb'"
        label 
        @click="updateFilter('active')"
      >
        <div class="px-2">
          {{ $t('active') }} <span class="ml-2">{{ activeItemCount }}</span>
        </div>
      </v-chip>
      <div class="ml-2">
        <v-chip 
          :class="{ 'blue--text': filter === 'archived',
                    'fw-semibold': filter === 'archived',
                    'font-weight-medium': filter != 'archived'
          }" 
          width="200px" 
          :color="filter === 'archived' ? '#e6ecff' : '#f9fafb'"
          label 
          @click="updateFilter('archived')"
        >
          <div class="px-2">
            {{ $t('archived') }} <span class="ml-2">{{ archivedItemCount }}</span>
          </div>
        </v-chip>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    filter: String,
    activeItemCount: Number,
    archivedItemCount: Number,
    writeProject: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateFilter(filter) {
      this.$emit('update-filter', filter);
    },
  }
}
</script>
