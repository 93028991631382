<template>
  <v-main class="mt-3 pl-3 elevation-0">
    <customize-charts 
      v-if="showCustomize" 
      :chart-data="counterCharts" 
      @removeCounterChart="removeCounterChart"
      @addChart="addChart($event)" 
      @customize="showCustomize = false" 
    />
    <div
      v-else
      class="dashboard"
    >
      <DashboardHeader 
        class="mb-2" 
        @customize="showCustomize = true"
      />
      <grid-layout
        :layout.sync="layout"
        :col-num="12"
        :row-height="30"
        :is-draggable="true"
        :is-resizable="true"
        :prevent-collision="true"
        :auto-size="true"
        :margin="[10,10]"
        :vertical-compact="false"
        :use-css-transforms="true"
      >
        <grid-item
          v-for="(chart, index) in charts"
          :key="index"
          :x="layout[index]?.x ?? 0"
          :y="layout[index]?.y ?? 0"                            
          :w="layout[index]?.w ?? 0"
          :h="layout[index]?.h ?? 0"
          :i="layout[index]?.i ?? 0"
          @resized="resizedEvent"
          @moved="movedEvent"
        >
          <div class="dashboard__charts-card">
            <Component
              :is="chart.type"
              :key="chart.split + chart.entityType"
              :data="chart"
              @updateEntity="updateEntity"
              @deleteChart="deleteChart"
              @expandChart="expandChart"
            />
          </div>
        </grid-item>
      </grid-layout>
    </div>
    <!-- <v-row v-if="orgs.length > 0">
      <dashboard-grid :theme="classic" :dark-mode="false" :col-num="12" :row-height="30" :layout-editable="true" :datasets="dataSet" ref="board" />
    </v-row> -->

    <!-- <v-row v-else class="mt-12 text-center" align="center">
      <v-col cols="12">
        <h3>{{ $t('noOrg') }}</h3>
      </v-col>
      <v-col cols="12">
        <p>{{ $t('toAccessDashboard') }}</p>
      </v-col>
      <v-col cols="12">
        <v-btn to="/settings/organizations" color="primary">{{ $t('startHere') }}</v-btn>
      </v-col>
    </v-row> -->
  </v-main>
</template>

<script>
import DashboardHeader from '@/components/Dashboard/DashboardHeader'
import DonutChart from '@/components/Dashboard/Charts/DonutChart'
import CounterChart from '@/components/Dashboard/Charts/CounterChart'
import LineChart from '@/components/Dashboard/Charts/LineChart'
import CustomizeCharts from '@/components/Dashboard/CustomizeCharts';
import makeDashboardService from '@/services/api/dashboards'
import { GridLayout, GridItem } from 'vue-grid-layout';
import * as _ from 'lodash'

export default {
  name: 'Dashboard',
  components:{
    DashboardHeader,
    DonutChart,
    CustomizeCharts,
    CounterChart,
    GridLayout,
    GridItem,
    LineChart
  },
  data()
  {
    return {
      layout:[],
      charts: [],
      items: [],
      classic: '',
      showCustomize: false,
      chartData: {},
    };
  },
  computed: {
    orgs(){
      return this.accounts?.filter(a => a.type === "org")
    },
    counterCharts(){
      return this.charts.filter(element => element.type == 'counterChart').map(item => item.entityType);
    }
  },
  async mounted(){
    await this.getDashboard()
    this.updateLayout();
  },
  methods: {
    async getDashboard(){
      const handle = this.$route.params.handle;
      const id = this.$route.params.id;
      const dashboardService = makeDashboardService(this.$api);
      await dashboardService.getDashboard(handle,id).then(response => {
       this.charts = response.data
      })
      
    },
    changeSplitBy(val, chartID){
      const chartIndex = this.charts.findIndex(element => element.id == chartID)
      this.charts[chartIndex].split = val
    },
    changeSelected(type, values, chartID){
      const chartIndex = this.charts.findIndex(element => element.id == chartID);
      this.charts[chartIndex][type] = values;
    },
    expandChart(chartID){
      const chartIndex = this.charts.findIndex(element => element.id == chartID);
      this.charts[chartIndex].w = 12;
      this.updateCharts();
    },
    deleteChart(chartID){
      const chartIndex = this.charts.findIndex(element => element.id == chartID);
      this.charts.splice(chartIndex, 1);
      this.updateCharts();
    },
    async updateEntity(entity, chartID){
      const chartIndex = this.charts.findIndex(element => element.id == chartID);
      const charts = _.cloneDeep(this.charts);
      charts[chartIndex].entityType = entity;

      const dashboardService = makeDashboardService(this.$api);
      const handle = this.$route.params.handle;
      const payload = {
        charts
      }
      const id = this.$route.params.id;
      await dashboardService.updateDashboard(handle, id, payload).then(() => this.updateLayout())

      this.getDashboard();
    },
    findPosition(){
      
    },
    resizedEvent(id, newH, newW){
      const findIndex = this.charts.findIndex(element => element.id == id);
      this.charts[findIndex].h = newH;
      this.charts[findIndex].w = newW;
      this.updateCharts();
    },
    movedEvent(id, newX, newY){
      const findIndex = this.charts.findIndex(element => element.id == id);
      this.charts[findIndex].x = newX;
      this.charts[findIndex].y = newY;
      this.updateCharts();
    },
    async updateCharts(){
      const chartsPaylod = this.charts.map(item => {
        return {
          id: item.id,
          type: item.type,
          entityType: item.entityType,
          ...(item.split ? {split: item.split} : undefined ),
          x: item.x,
          y: item.y,
          h: item.h,
          w: item.w,

        }
      })

      const dashboardService = makeDashboardService(this.$api);
      
      const handle = this.$route.params.handle;
      const payload = {
        charts: chartsPaylod
      }
      const id = this.$route.params.id;
      await dashboardService.updateDashboard(handle, id, payload).then(() => this.updateLayout())
      
      this.updateLayout();

    },
    async addChart({chartType, entityType, w, h}){
      /*
      * Add Chart Flow Feature
      * - User clicks on 'Add' from customizeComponent.
      * - Hide customizeComponent, and a virtual element appears showing where this chart should be placed in the grid (dragging).
      * - Once the user selects the place and right-clicks, call the method for adding the chart to the layout and updating the backend.
      ==> Now we're placing it this way:
      * - Get the maximum Y value, then add the item's height to it with x:0, so the item will be placed at the left bottom of all items.
      */
      const Ids = this.charts.map(item => item.id).filter(Boolean);
      const uniqueId = Ids.length ? _.max(Ids) + 1 : 1;
    
      let maxY = 0;
      this.layout.forEach(chart => {
        if(chart.y + chart.h > maxY)
            maxY = chart.y + chart.h 
      })
     

      this.charts.push({
        id: uniqueId,
        type: chartType,
        entityType: entityType,
        ...(['donutChart', 'lineChart'].includes(chartType) ? { split: 'status' } : undefined),
        x: 0,
        y: maxY,
        w: w,
        h: h,
      })
      await this.updateCharts();
      
      await this.getDashboard();
      

      this.showCustomize = false
    },
    updateLayout(){
      this.layout =  this.charts?.map(item => {
        return {
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          i: item.id
        }
      })
    },
    async removeCounterChart(entityType){
      const chart = this.charts.find(element => element.type == 'counterChart' && element.entityType == entityType);
      await this.deleteChart(chart.id);
      this.showCustomize = false;
    }
  }
  
};
</script>
<style>
  .vue-grid-item{
    padding: 8px;
    background-color: #FFF;
    /* overflow: hidden; */
  }
  .apex_chart-filters{
    gap: 8px !important;
  }
</style>
<style scoped>
.col-4{
  background-color: #FFF;
}
.row{
  margin: 0px !important;
}
.dashboard__charts{
  display: flex !important;
  gap: 8px;

}
.dashboard__charts-form{
  gap: 10px;
}
.dashboard__charts-card{
  padding: 24px;
  border-radius: 8px;
  gap: 16px;
}
</style>