<template>
  <div>
    <v-sheet
      color="#F2F4F7"
      class="d-flex align-center justify-center pointer"
      height="40px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span
        class="px-3 py-2 d-flex flex-row"
      >{{ $t('filters') }} <v-icon
        size="16px"
        class="ml-2"
      >mdi-filter-variant</v-icon></span>
    </v-sheet>
    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>

          <div class="text-start pt-6">
            <v-expansion-panels
              v-model="appsMenu"
              flat
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">
                  {{ $t('Apps') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field
                    v-model="searchServices"
                    color="blue"
                    class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                    height="40"
                    background-color="#F9F9FB"
                    :hide-details="true"
                    :placeholder="$t('search')"
                  >
                    <template v-slot:prepend-inner>
                      <SearchIcon />
                    </template>
                  </v-text-field>
                  <v-checkbox
                    v-for="service in filteredServices"
                    :key="service"
                    v-model="selectedServices"
                    :value="service"
                    dense
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :label="service"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels
              v-model="projectsMenu"
              flat
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">
                  {{ $t('Projects') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field
                    v-model="searchProjects"
                    color="blue"
                    class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                    height="40"
                    background-color="#F9F9FB"
                    :hide-details="true"
                    :placeholder="$t('search')"
                  >
                    <template v-slot:prepend-inner>
                      <SearchIcon />
                    </template>
                  </v-text-field>
                  <v-checkbox
                    v-for="(project, uid) in filteredProjects"
                    :key="uid"
                    v-model="selectedProjects"
                    :value="uid"
                    dense
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :label="project.name"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          full-width
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="clearAll"
        >
          {{ $t('clearAll') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme text-capitalize"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="apply"
        >
          {{ $t('apply') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import SearchIcon from '@/assets/svg/search-icon.svg';

export default {
  name: 'ProjectFilter',
  components: {
    SearchIcon,
  },

  props: {
    services: {
      type: Array,
      default: () => [],
    },
    projects: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      appsMenu: 0,
      projectsMenu: 0,
      showDialog: false,
      selectedServices: [],
      selectedProjects: [],
      searchServices: '',
      searchProjects: '',
    };
  },
  computed: {
    filteredServices() {
      if (!this.searchServices) return this.services;
      const searchTerm = this.searchServices.toLowerCase();
      return this.services.filter(service => 
        service.toLowerCase().includes(searchTerm)
      );
    },
    
    filteredProjects() {
      // Convert projects object to array for filtering
      const projectsArray = Object.entries(this.projects);
      if (!this.searchProjects) {
        return Object.fromEntries(projectsArray);
      }
      
      const searchTerm = this.searchProjects.toLowerCase();
      const filteredEntries = projectsArray.filter(([uid, project]) => 
        project.name.toLowerCase().includes(searchTerm)
      );
      
      return Object.fromEntries(filteredEntries);
    }
  },
  watch: {
    // Reset selections when props change
    services: {
      handler(newVal) {
        if (!newVal.length) {
          this.selectedServices = [];
        }
      },
      immediate: true
    },
    projects: {
      handler(newVal) {
        if (Object.keys(newVal).length === 0) {
          this.selectedProjects = [];
        }
      },
      immediate: true
    }
  },
  methods: {
    apply() {
      const filters = {
        services: this.selectedServices,
        projectUids: this.selectedProjects.map(uid => parseInt(uid)), // Convert UIDs to integers
      };
      this.$emit('applyFilters', filters);
      this.showDialog = false;
    },
    clearAll() {
      this.selectedServices = [];
      this.selectedProjects = [];
      this.searchServices = '';
      this.searchProjects = '';
      this.$emit('applyFilters', {
        services: [],
        projectUids: [],
      });
      this.showDialog = false;
    },
  }
};
</script>

<style>
.v-dialog--fullscreen {
  max-height: 100vh !important;
  width: 485px !important;
  right: 0 !important;
  left: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
