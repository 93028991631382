var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"white py-6 px-6 mt-3",attrs:{"rounded":"lg","elevation":"0","width":"100%"}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('h2',[_vm._v("Projects list ")]),(_vm.writeProject)?_c('v-btn',{staticClass:"text-capitalize rounded-lg btn-theme white--text",attrs:{"color":"blue","depressed":"","height":"40px","to":{ name: 'ProjectCreateView'}}},[_vm._v(" "+_vm._s(_vm.$t('createProject'))+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"size":"16px"}},[_vm._v(" mdi-plus ")])],1):_vm._e()],1),_c('div',{staticClass:"mt-4 d-flex"},[_c('v-chip',{class:{ 'blue--text': _vm.filter === 'active',
                'fw-semibold': _vm.filter === 'active',
                'font-weight-medium': _vm.filter != 'active'
      },attrs:{"width":"200px","color":_vm.filter === 'active' ? '#e6ecff' : '#f9fafb',"label":""},on:{"click":function($event){return _vm.updateFilter('active')}}},[_c('div',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$t('active'))+" "),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.activeItemCount))])])]),_c('div',{staticClass:"ml-2"},[_c('v-chip',{class:{ 'blue--text': _vm.filter === 'archived',
                  'fw-semibold': _vm.filter === 'archived',
                  'font-weight-medium': _vm.filter != 'archived'
        },attrs:{"width":"200px","color":_vm.filter === 'archived' ? '#e6ecff' : '#f9fafb',"label":""},on:{"click":function($event){return _vm.updateFilter('archived')}}},[_c('div',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$t('archived'))+" "),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.archivedItemCount))])])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }