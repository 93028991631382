export const users = [
  {
    id: 1,
    name: "Alex O’brien",
    role: "Account admin",
    project: "Amazon +2",
    lastActivity: "1 h ago",
    selected: false,
    tag: ["#projectateam", "finance", "remote"],
    email: "alexxxx@gmail.com",
    image: "https://i.pravatar.cc/150?img=3",
  },
  {
    id: 2,
    name: "Emily Johnson",
    role: "Project admin",
    project: "Netflix",
    lastActivity: "1 wk ago",
    selected: false,
    tag: ["#projectateam", "finance", "remote"],
    email: "emilyjohnson@email.com",
    image: "https://i.pravatar.cc/150?img=3",
  },
  {
    id: 3,
    name: "Ava Rodriguez",
    role: "Tester",
    project: "Amazon",
    selected: false,
    lastActivity: "3 min ago",
    tag: ["#projectateam", "finance", "remote"],
    email: "avarodriguez@gmail.com",
    image: "https://i.pravatar.cc/150?img=3",
  },
  {
    id: 4,
    name: "Noah Thompson",
    role: "Tester",
    selected: false,
    project: "Hue&Machine +2",
    tag: ["#projectateam", "finance", "remote"],
    lastActivity: "1 hr ago",
    email: "noahthomp@gmail.com",
    image: "https://i.pravatar.cc/150?img=3",
  },
  {
    id: 5,
    name: "Ethan Foster",
    role: "Tester",
    selected: false,
    project: "Facebook",
    tag: ["#projectateam", "finance", "remote"],
    lastActivity: "3 min ago",
    email: "ethanfoster@gmail.com",
    image: "https://i.pravatar.cc/150?img=3",
  },
];

export const testruns = [
  {
    id: 1,
    name: "Smoke Test Run",
    priority: "High",
    testcases: "200",
    creationdate: "06/17/2023",
    tag: ["#projectateam"],
    progress: "5",
    selected:false,
  },
  {
    id: 2,
    name: "Regresssion Test Run",
    priority: "Medium",
    testcases: "15",
    creationdate: "12/03/2023",
    tag: ["#projectateam"],
    progress: "20",
    selected:false
  },
  {
    id: 3,
    name: "Feature Validation Run",
    priority: "Low",
    testcases: "703",
    creationdate: "03/22/2022",
    tag: ["#projectateam"],
    progress: "40",
    selected:false
  },
  {
    id: 4,
    name: "Security Test Run",
    priority: "Medium",
    testcases: "223",
    creationdate: "09/21/2023",
    tag: ["#projectateam"],
    progress: "80",
    selected:false
  },
  {
    id: 5,
    name: "Integration Test Run",
    priority: "High",
    testcases: "450",
    creationdate: "02/17/2024",
    tag: ["#projectateam"],
    progress: "70",
    selected:false
  },
];

export const roles = [
  { id: '1', name: 'Account admin' },
  { id: '2', name: 'Project admin' },
  { id: '3', name: 'QA Lead' },
  { id: '4', name: 'Tester' },
  { id: '5', name: 'Client' }
];

export const projects = [
  { id: '1', name: 'Amazon' },
  { id: '2', name: 'Netflix' },
  { id: '3', name: 'Facebook' },
  { id: '4', name: 'PayPal' }
];

export const tags = [
  { id: '1', title: '#projectateam', description: '', type: '' },
  { id: '2', title: '##projectbteam', description: '', type: '' },
  { id: '3', title: '#finance', description: '', type: '' },
  { id: '4', title: '#remote', description: '', type: '' }
];

export const permissions = [
  {
    id: '1',
    name: "Projects",
    description: "Manage projects",
    items: [
      { id: "1-1", name: "View", description: "View projects", value: "view_projects" },
      { id: "1-2", name: "Create / Edit", description: "Create and edit projects", value: "write_projects" },
      { id: "1-3", name: "Manage users", description: "Manage users", value: "manage_org_users" },
      { id: "1-4", name: "Close", description: "Close project so it no longer can be edited", value: "close_projects" },
      { id: "1-5", name: "Archive / Unarchive", description: "Move the project to the archive and back. The project will not be visible in the archive", value: "archive_projects" },
      { id: "1-6", name: "Delete", description: "Delete projects", value: "delete_projects" },
    ],
  },
  {
    id: '2',
    name: "Milestones",
    description: "Manage milestones",
    items: [
      { id: "2-1", name: "View", description: "View milestones", value: "view_milestones" },
      { id: "2-2", name: "Create / Edit", description: "Create and edit milestones", value: "write_milestones" },
      { id: "2-3", name: "Close", description: "Close milestones", value: "close_milestones" },
      { id: "2-4", name: "Block", description: "Block milestones", value: "block_milestones" },
      { id: "2-5", name: "Delete", description: "Delete milestones", value: "delete_milestones" },
    ],
  },
  {
    id: '3',
    name: "Dashboard",
    description: "Manage dashboard",
    items: [
      { id: "3-1", name: "View", description: "View Dashboard", value: "view_dashboards" },
      { id: "3-2", name: "Customize", description: "Customize Dashboard", value: "write_dashboard_charts" },
      { id: "3-3", name: "Edit", description: "Edit Dashboard", value: "edit_dashboards" },
      { id: "3-4", name: "Delete", description: "Delete Dashboard", value: "delete_dashboards" },
    ],
  },
  {
    id: '4',
    name: "Test cases",
    description: "Manage test cases",
    items: [
      { id: "4-1", name: "View", description: "View test cases and test suites", value: "view_cases" },
      { id: "4-2", name: "Create / Edit", description: "Create and edit test cases", value: "write_cases" },
      { id: "4-3", name: "Change position", description: "Drag and drop test cases", value: "view_cases" },
      { id: "4-4", name: "Add comments", description: "Add and edit comments", value: "add_case_comments" },
      { id: "4-5", name: "Import", description: "Import test cases", value: "import_cases" },
      { id: "4-6", name: "Export", description: "Export test cases", value: "export_cases" },
      { id: "4-7", name: "Remove", description: "Remove test cases from project", value: "remove_cases" },
      {
        id: "4-8",
        name: "Test suites",
        description: "Manage the organization of test cases within test folders",
        items: [
          { id: "4-8-1", name: "Create/Eidt", description: "Create and edit test suites", value: "write_suites" },
          { id: "4-8-2", name: "Change position", description: "Drag and drop test suites", value: "view_cases" },
          { id: "4-8-3", name: "Delete", description: "Delete test suites", value: "delete_suites" },
        ]
      },
    ],
  },
  {
    id: '5',
    name: "Test runs",
    description: "Manage test runs",
    items: [
      { id: "5-1", name: "View", description: "View test runs", value: "view_runs" },
      { id: "5-2", name: "Create / Edit", description: "Create and edit test runs", value: "write_runs" },
      { id: "5-3", name: "Start", description: "Start test run and add statuses", value: "start_runs" },
      { id: "5-4", name: "Delete", description: "Delete test runs", value: "delete_runs" },
    ],
  },
  {
    id: '6',
    name: "Defects",
    description: "Manage defects",
    items: [
      { id: "6-1", name: "Link / Edit", description: "Create and edit defects", value: "link_defects" },
      { id: "6-2", name: "Unlink", description: "Delete defects", value: "unlink_defects" },
    ],
  },
  {
    id: '7',
    name: "Main Dashboard",
    description: "Manage main dashboard",
    items: [
      { id: "7-1", name: "View", description: "View main dashboard", value: "view_dashboards" },
      { id: "7-2", name: "Customize", description: "Add new chart", value: "write_dashboard_charts" },
      { id: "7-3", name: "Edit", description: "Edit dashboard view and charts settings", value: "edit_dashboards" },
      { id: "7-4", name: "Delete", description: "Delete charts", value: "delete_dashboards" },
    ],
  },
  {
    id: '8',
    name: "Reports",
    description: "Manage reports",
    items: [
      { id: "8-1", name: "View", description: "Allow only to view reports", value: "view_reports" },
      { id: "8-2", name: "Create / Edit public report", description: "Create and edit public report", value: "write_reports" },
      { id: "8-3", name: "Export", description: "Export reports", value: "export_reports" },
      { id: "8-4", name: "Delete", description: "Delete reports", value: "delete_reports" },
    ],
  },
  {
    id: '9',
    name: "Users",
    description: "Manage users",
    items: [
      { id: "9-1", name: "Add", description: "Add new user", value: "add_user" },
      { id: "9-2", name: "Request invite", description: "Invite new user, the user will be added only after the consent of the administrator", value: "invite_user" },
      { id: "9-3", name: "Delete", description: "Delete users", value: "delete_user" },
    ],
  },
  {
    id: '10',
    name: "Roles",
    description: "Manage roles",
    items: [
      {
        id: "9-1",
        name: "Add / Edit",
        description: "Add and edit role",
        items: [
          { id: "9-1-1", name: "Account admin", description: "Add users with account admin role" },
          { id: "9-1-2", name: "Project admin", description: "Add users with project admin role" },
          { id: "9-1-3", name: "QA Lead", description: "Add users with QA lead role" },
          { id: "9-1-4", name: "Tester", description: "Add users with tester role" },
          { id: "9-1-5", name: "Client", description: "Add users with client role" },
          { id: "9-1-6", name: "Billing role", description: "Add users with billing role" },
        ]
      },
      { id: "9-2", name: "Set roles by project", description: "Set roles by project" },
      { id: "9-3", name: "Assign roles", description: "Assign roles" },
      { id: "9-4", name: "Delete roles", description: "Delete roles" },
    ],
  },
  {
    id: '10',
    name: "Tags",
    description: "Manage tags",
    items: [
      { id: "10-1", name: "Add / Edit", description: "Add and edit tag", value: "write_tags" },
      { id: "10-2", name: "Archive / Unarchive", description: "Move the tag to the archive and back. The tag will not be visible in the archive", value: "archive_tags" },
      { id: "10-3", name: "Delete", description: "Delete tags", value: "delete_tags" },
    ],
  },
  {
    id: '11',
    name: "Custom fields",
    description: "Manage custom fields",
    items: [
      { id: "11-1", name: "Add / Edit", description: "Add and edit custom field" },
      { id: "11-2", name: "Use", description: "Use the custom fields created by others in their test design" },
      { id: "11-3", name: "Delete", description: "Delete custom field" },
    ],
  },
  {
    id: '12',
    name: "Shared steps",
    description: "Manage shared steps",
    items: [
      { id: "12-1", name: "Create / Edit", description: "Create and edit shared steps" },
      { id: "12-2", name: "Archive / Unarchive", description: "Move the shared step to the archive and back. The shared step will not be visible in the archive" },
      { id: "12-3", name: "Use", description: "Use the shared steps created by others in test cases" },
      { id: "12-4", name: "Delete", description: "Delete shared steps" },
    ],
  },
  {
    id: '13',
    name: "Repository",
    description: "Manage Repository",
    items: [
      { id: "13-1", name: "Add / Edit", description: "Add and edit test cases" },
      { id: "13-2", name: "Import", description: "Import test cases" },
      { id: "13-4", name: "Export", description: "Export test cases" },
      { id: "13-5", name: "Delete", description: "Delete test cases from Repository" },
      {
        id: "13-6",
        name: "Test suites",
        description: "Manage the organization of test cases within test folders",
        items: [
          { id: "13-6-1", name: "Create/Eidt", description: "Create and edit test suites" },
          { id: "13-6-2", name: "Change position", description: "Drag and drop test suites" },
          { id: "13-6-3", name: "Delete", description: "Delete test suites" },
        ],
      },
      { id: "13-7", name: "Delete", description: "Delete test cases from Repository" },
    ],
  },
  {
    id: '14',
    name: "Billing role",
    description: "Manage billing information",
    singleRole: true,
    items: [
      { id: "14-1", name: "Full access", description: "Full access to subscription details, payment history and to modify billing settings and payment methods" },
      { id: "14-2", name: "Limited access", description: "No access to modify billing settings and payment methods" },
      { id: "14-3", name: "No access", description: "No access" },
    ],
  },
  {
    id: '15',
    name: "Integration",
    description: "Manage integration",
    items: [
      { id: "15-1", name: "Add / Edit", description: "Add and edit integration" },
      { id: "15-2", name: "Delete", description: "Delete integration" },
    ],
  },
];


