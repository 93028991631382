<template>
  <BaseDialog
    v-model="dialog"
    max-width="500px"
  >
    <v-card class="bg-white">
      <v-card-text class="py-8 px-sm-10">
        <v-flex class="d-flex align-start justify-space-between">
          <p
            v-if="run_name"
            class="ma-0 font-weight-bold text-h6 text-sm-h5 text-start black--text"
          >
            {{ title }} "{{ run_name }}"?
          </p>
          <p
            v-else
            class="ma-0 font-weight-bold text-h6 text-sm-h5 text-start black--text"
          >
            {{ title }}
          </p>
          <v-icon
            class="mt-1 ml-4 pointer"
            @click="$emit('close')"
          >
            mdi-close
          </v-icon>
        </v-flex>
        <slot name="content">
          <div class="mt-4 d-flex flex-column">
            <p class="text-start">
              {{ content }}
            </p>
          </div>
        </slot>
        <slot name="footer">
          <v-row>
            <v-col cols="6">
              <v-btn
                height="40"
                width="100%"
                class="text-capitalize fw-semibold black--text btn-theme"
                color="#F2F4F7"
                depressed
                @click="$emit('close')"
              >
                {{ $t('defect.addNewDefectDialog.skip-btn') }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    height="40"
                    width="100%"
                    class="text-capitalize fw-semibold white--text btn-theme"
                    :color="color"
                    v-bind="attrs"
                    v-on="on"
                    @click="menuOpen = !menuOpen"
                  >
                    {{ $t('defect.addNewDefectDialog.add-defect') }} <v-icon v-if="menuOpen">
                      mdi-chevron-up
                    </v-icon><v-icon v-else>
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                  >
                    <v-list-item-title @click="handleTypeOfDefects(item)">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </slot>
      </v-card-text>
    </v-card>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog.vue';

export default {
  components: {
    BaseDialog,
  },
  props: {
    value: Boolean,
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    content_part2: {
      type: String,
    },
    color: {
      type: String,
    },

    run_name: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      dialog: this.value,
      showAgainConfirmBackDialog: false,
      selectedItems: null,
      items: [{ title: 'Create defects' }, { title: 'Select defects' }],
      menuOpen: false,
    };
  },
  watch: {
    value(newVal) {
      this.dialog = newVal;
    },
    dialog(newVal) {
      this.$emit('input', newVal);
    },
  },
  methods: {
    handleTypeOfDefects(item) {
      this.$emit('handle-actions-type', item.title);
    },
  },
};
</script>

<style></style>
