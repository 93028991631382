<template>
  <v-select
    :value="value"
    class="rounded-lg pt-0 field-theme custom-prepend mh-38px"
    :placeholder="$t('tags')"
    :items="items"
    append-icon="mdi-chevron-down"
    background-color="#F9F9FB"
    :menu-props="{ offsetY: true }"
    chips
    multiple
    hide-details
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="{ item }">
      <v-chip
        small
        class=""
        close-icon="mdi-close"
        :rounded="1"
        :pill="true"
        close
        @click:close="remove(item)"
      >
        <span>{{ item.name }}</span>
      </v-chip>
    </template>

    <template v-slot:item="{ item, on }">
      <v-list-item
        :ripple="false"
        v-on="on"
        @click="toggleSelection(item)"
      >
        <v-list-item-action class="custom-checkbox-container">
          <input
            type="checkbox"
            :checked="value.map(v => v.uid).includes(item.uid)"
            :value="item"
            class="custom-checkbox"
          >
          <span class="checkmark" />
          <span class="checkbox-label">{{ item.name }}</span>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'TagSelector',
  props: {
    value: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },


  methods: {
    remove(item) {
      const newValue = [...this.value];
      const index = newValue.indexOf(item);
      newValue.splice(index, 1);
      this.$emit('input', newValue);
    },
    toggleSelection(item) {
      const newValue = [...this.value];
      const index = newValue.indexOf(item.text);
      if (index === -1) {
        newValue.push(item.text);
      } else {
        newValue.splice(index, 1);
      }
      this.$emit('input', newValue);
    }
  }
}
</script>


<style scoped>

.v-list-item{
  display: flex;
  align-items: center;
}
.v-chip {
  background-color: #fff !important;
  border: 1px solid #D0D5DD !important;
  border-radius: 8px !important;
}
.v-list-item__action--stack{
  margin: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 16px !important;
  align-self: center;
}

.custom-checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-size: 14px;
  margin: 0 !important;
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid #D0D5DD;
  border-radius: 6px;
}

.custom-checkbox:checked ~ .checkmark {
  background-color: #0C2FF3;
  border-color: #0C2FF3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox:checked ~ .checkmark:after {
  display: block;
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-label {
  margin-left: 10px;
  color: #344054;
}
</style>
