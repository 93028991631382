<template>
  <div>
    <v-btn
      color="blue"
      depressed
      height="40px"
      class="btn-theme rounded-lg white--text"
      @click="onAdd('Add')"
    >
      {{ personalizationType == 'Status' ? $t('dataColors.addStatus') : $t('dataColors.addPriority') }}
    </v-btn>

    <v-dialog
      v-model="localShowDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card class="flex-column justify-space-between">
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ action + ' ' + (personalizationType == 'Status' ? $t('status') : $t('priority')) }}
            </h2>
            <v-btn
              icon
              @click="modalOpen(false)"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>

          <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
            class="mt-10"
          >
            <v-row>
              <v-col
                cols="12"
                class="pb-0"
              >
                <p class="font-weight-medium body-2 text-left mb-1">
                  {{ $t('dataColors.name') }} <strong class="red--text">*</strong>
                </p>
                <v-text-field
                  v-model="status.name"
                  type="text"
                  value="dfs"
                  dense
                  background-color="#F9F9FB"
                  height="38"
                  class="field-theme"
                  :placeholder="$t('name')"
                  :rules="requiredRules"
                />
              </v-col>

              <v-col cols="12">
                <p class="font-weight-medium body-2 text-left mb-1">
                  {{ $t('dataColors.category') }} <strong class="red--text">*</strong>
                </p>
                <v-select
                  v-model="status.entityType"
                  :items="entityTypes"
                  class="rounded-lg field-theme custom-prepend"
                  dense
                  background-color="#F9F9FB"
                  item-text="name"
                  item-value="value"
                  :placeholder="$t('dataColors.category')"
                  height="38px"
                  append-icon="mdi-chevron-down"
                  persistent-placeholder
                  :rules="requiredRulesForArrayType"
                  return-object
                />
              </v-col>

              <v-col cols="12">
                <p class="font-weight-medium body-2 text-left mb-1">
                  {{ $t('dataColors.color') }}
                </p>
                <template>
                  <ColorItem
                    :value="status.color"
                    default-color="#000"
                    class="color-picker"
                    @input="changeColor('chart', 0, $event)"
                  />
                </template>
              </v-col>
              <v-col
                v-if="status.entityType != '' && isTypeStatus"
                cols="12"
                class="pb-0"
              >
                <v-checkbox
                  v-model="status.isDefault"
                  class="field-theme"
                  :ripple="false"
                  off-icon="icon-checkbox-off"
                  on-icon="icon-checkbox-on"
                  @change="onDefaultStatusChange"
                >
                  <template v-slot:label>
                    <span class="fs-14 text-theme-label">{{ `${$t("newStatus")} ${status.entityType?.name || findEntityNameByValue(status.entityType)}` }}</span>
                  </template>
                </v-checkbox>
              </v-col>

              <v-col
                v-if="action === 'Edit'"
                cols="12"
                class="pt-0"
              >
                <v-checkbox
                  v-model="status.isCompleted"
                  class="field-theme mt-0"
                  :ripple="false"
                  off-icon="icon-checkbox-off"
                  on-icon="icon-checkbox-on"
                  @change="onCompletedStatusChange"
                >
                  <template v-slot:label>
                    <span class="fs-14 text-theme-label">
                      {{ $t("thisStatusComplete", { category: status.entityType?.name || findEntityNameByValue(status.entityType) }) }}
                    </span>
                  </template>
                </v-checkbox>
              </v-col>

              <v-col class="flex justify-end">
                <v-btn
                  v-if="action === 'Edit'"
                  color="red"
                  height="40"
                  depressed
                  elevation="0"
                  class="white--text text-capitalize btn-theme rounded-lg"
                  @click="onDeleteClick()"
                >
                  {{ $t('delete') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="modalOpen(false)"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          elevation="0"
          :disabled="!validForm"
          @click="onCreateUpdateDelete(action)"
        >
          {{ action + ' ' + (personalizationType == 'Status' ? 'status' : 'priority') }}
        </v-btn>
      </div>
    </v-dialog>

    <DeleteConfirmDialog
      v-model="showDeleteConfirmDialog"
      :template="status"
      @delete="deleteColor"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { entityTypes } from '@/constants/colors.js';
import { mapActions } from 'vuex';
import ColorItem from '../DataColors/ColorItem.vue';
import DeleteConfirmDialog from '@/components/Admin/CustomTemplates/DeleteConfirmDialog.vue';
import makeHandleService from '@/services/api/handle';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import colorPreferencesMixin from '@/mixins/colorPreferences';
import { requiredFieldValidationRules, requiredFieldValidationRulesForArray } from "@/utils/validation";

const { mapState } = createNamespacedHelpers('user');
let handleService;

export default {
  name: "AddStatus",

  components: {
    ColorItem,	
    DeleteConfirmDialog,
  },
  mixins: [colorPreferencesMixin],
  props: {
    personalizationType: String,
    action: String,
    totalCount: Number,
    currentOrg: Object,
    currentUser: Object,
    value: String,
    type: String,
    singleItemIndex: String,
    showDialog: Boolean,
    modalOpen: Function,
    onAdd: Function,
    selectedItem: [Object, Array],
    selectedCounts: Number,
  },

  data() {
    return {
      requiredRules: requiredFieldValidationRules(this),
      requiredRulesForArrayType: requiredFieldValidationRulesForArray(this),
      showPickColorDialog: false,
      status: {
        name: '',
        entityType: '',
        color: null,
        id: '',
        isDefault: false,
        isCompleted: false,
      },
      validForm: false,
      entityTypes: entityTypes,
      showDeleteConfirmDialog: false,
      defaultChanged: false,
      statusDefaultCounted: false,
    }
  },
  created() {
    handleService = makeHandleService(this.$api);
  },
  computed: {
    ...mapState(['currentAccount']),
    localShowDialog: {
      get() {
        return this.showDialog; 
      },
      set(value) {
        this.$emit('update:showDialog', value);
      }
    },
    isTypeStatus() {
      return this.personalizationType === 'Status';
    },
  },
  watch: {
    selectedItem: {
      immediate: true,
      handler(newItem) {
        if (newItem) {
          this.status.name = newItem.name || '';
          this.status.entityType = newItem.entityType || '';
          this.status.color = newItem.color || this.generateRandomHexColor();
          this.status.id = newItem.id || '';
          this.status.isDefault = newItem.isDefault || false;
          this.status.isCompleted = newItem.isCompleted || false;
          this.defaultChanged = newItem.isDefault || false;
          this.statusDefaultCounted = newItem.isCompleted || false;
        }
      },
    },
    localShowDialog(value) {
    if (value && this.action === 'Add') {
      this.status.color = this.generateRandomHexColor();
    }
  },
  },
  methods: {
    ...mapActions({
      setUser: 'user/setUser',
      setLoading: 'setLoading',
    }),
    findEntityNameByValue(value) {
      if(typeof value === 'string'){
        const entity = entityTypes.find((item) => item?.value?.toLowerCase() === value?.toLowerCase());
        return entity ? entity?.name : null;
      }
    },
    openDialog() {
      this.localShowDialog = true;
    },
    closeDialog() {
      this.localShowDialog = false;
    },
    onDeleteClick() {
      this.showDeleteConfirmDialog = true;
    },
    deleteColor() {
      this.onCreateUpdateDelete('delete');
    },
    async onCreateUpdateDelete(action) {
      const isValidForm = this.$refs.form.validate();
      if (action !== 'delete' && !isValidForm) {
        return;
      }
    
      if(this.defaultChanged && !this.status.isDefault)
        return showErrorToast(this.$swal, this.$t('defaultValueError'));
        
      if(this.selectedCounts == 1 && this.statusDefaultCounted && !this.status.isCompleted)
        return showErrorToast(this.$swal, this.$t('atLeastOneStatusCounted'));

      const dataColor = {
        name: this.status.name,
        entityType: this.status.entityType.value || this.status.entityType,
        color: this.status.color || this.generateRandomHexColor(),
        isDefault: this.status.isDefault,
        isCompleted: this.status.isCompleted,
      };

      let payload = {};

      const isStatusType = this.personalizationType === 'Status';
      const updatedColor = dataColor;

      let statusColors = [];
      let priorityColors = [];
      if(this.currentAccount.type == 'org'){
        statusColors = this.getOrgPreferences(this.currentAccount.handle)?.statusColors || [];
        priorityColors = this.getOrgPreferences(this.currentAccount.handle)?.priorityColors || [];
      } else {
        statusColors = this.getUserPreferences?.statusColors || [];
        priorityColors = this.getUserPreferences?.priorityColors || [];
      }
      if (action === 'delete' && this.status.id) {
        this.showDeleteConfirmDialog = false;

        if (isStatusType) {
          const index = statusColors.findIndex(color => color.id === this.status.id);
          if (index !== -1) {
            statusColors.splice(index, 1);
          }
        } else {
          const index = priorityColors.findIndex(color => color.id === this.status.id);
          if (index !== -1) {
            priorityColors.splice(index, 1);
          }
        }
      } else {
        if (isStatusType) {
          if (this.status.id) {
            const index = statusColors.findIndex(color => color.id === this.status.id);
            if (index !== -1) {
              if(updatedColor.isDefault){
                statusColors = statusColors.map((color) => {
                  if (color.entityType === updatedColor.entityType) {
                    return { ...color, isDefault: false };
                  }
                  return color;
                });
              }
              statusColors[index] = { ...updatedColor, id: this.status.id };
            }
          } else {
            if(updatedColor.isDefault){
              statusColors = statusColors.map((color) => {
                if (color.entityType === updatedColor.entityType) {
                  return { ...color, isDefault: false };
                }
                return color;
              });
            }
            statusColors.push({ ...updatedColor, id: this.totalCount + 1 });
          }
        } else {
          if (this.status.id) {
            const index = priorityColors.findIndex(color => color.id === this.status.id);
            if (index !== -1) {
              priorityColors[index] = { ...updatedColor, id: this.status.id };
            }
          } else {
            priorityColors.push({ ...updatedColor, id: this.totalCount + 1 });
          }
        }
      }

      payload = {
        preferences: {
          statusColors: statusColors,
          priorityColors: priorityColors,
        },
      };
      
      try {

        const response = await handleService.updatePreferences(this.currentAccount.handle, payload);
        if(response.status === 200) {
          if(this.currentAccount.type == 'org'){
            this.setOrgPreferences({
              handle: this.currentAccount.handle,
              preferences: payload.preferences,
            });
          } else {
            this.setUserPreferences(payload.preferences);
          }

          this.$emit("update:colors", payload.preferences);
          showSuccessToast(this.$swal, this.$t('dataColors.colorsUpdated'));
        }
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || 'Internal server error');
      } finally {
        this.modalOpen(false);
        this.setLoading({
          loading: false,
        });	
      }
    },
    changeColor(type, index, color) {
      this.status.color = color;
    },
    onDefaultStatusChange(value) {
      if (value && this.status.isCompleted) {
        this.status.isCompleted = false;
      }
    },
    onCompletedStatusChange(value) {
      if (value && this.status.isDefault) {
        this.status.isDefault = false;
      }
    },
  },
};
</script>

<style>
.v-dialog--fullscreen {
  max-height: 100vh !important;
  width: 485px !important;
  right: 0 !important;
  left: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.color-picker {
  padding: 5px 10px;
  background: lightgray;
  margin-left: 0 !important;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
}
</style>
